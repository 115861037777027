const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
};

const FACIAL_HAIR_TYPES = {
  NONE: 'none',
  NONE_2: 'none2',
  NONE_3: 'none3',
  STUBBLE: 'stubble',
  MEDIUM_BEARD: 'mediumBeard',
};

const BODY_TYPES = {
  CHEST: 'chest',
  BREASTS: 'breasts',
};

const MOUTH_TYPES = {
  GRIN: 'grin',
  SAD: 'sad',
  OPEN_SMILE: 'openSmile',
  LIPS: 'lips',
  OPEN: 'open',
  SERIOUS: 'serious',
  TONGUE: 'tongue',
};

const HAIR_TYPES = {
  NONE: 'none',
  LONG: 'long',
  BUN: 'bun',
  SHORT: 'short',
  PIXIE: 'pixie',
  BALDING: 'balding',
  BUZZ: 'buzz',
  AFRO: 'afro',
  BOB: 'bob',
};

export default Object.freeze({
  GENDER,

  FACIAL_HAIR: {
    [GENDER.MALE]: [
      FACIAL_HAIR_TYPES.NONE,
      FACIAL_HAIR_TYPES.NONE_2,
      FACIAL_HAIR_TYPES.NONE_3,
      FACIAL_HAIR_TYPES.STUBBLE,
      FACIAL_HAIR_TYPES.MEDIUM_BEARD,
    ],
    [GENDER.FEMALE]: [FACIAL_HAIR_TYPES.NONE, FACIAL_HAIR_TYPES.NONE_2, FACIAL_HAIR_TYPES.NONE_3],
    DEFAULT: [
      FACIAL_HAIR_TYPES.NONE,
      FACIAL_HAIR_TYPES.NONE_2,
      FACIAL_HAIR_TYPES.NONE_3,
      FACIAL_HAIR_TYPES.STUBBLE,
      FACIAL_HAIR_TYPES.MEDIUM_BEARD,
    ],
  },
  BODY: {
    [GENDER.MALE]: [BODY_TYPES.CHEST],
    [GENDER.FEMALE]: [BODY_TYPES.BREASTS],
    DEFAULT: [BODY_TYPES.CHEST, BODY_TYPES.BREASTS],
  },

  MOUTH: {
    [GENDER.MALE]: [
      MOUTH_TYPES.GRIN,
      MOUTH_TYPES.SAD,
      MOUTH_TYPES.OPEN_SMILE,
      MOUTH_TYPES.OPEN,
      MOUTH_TYPES.SERIOUS,
      MOUTH_TYPES.TONGUE,
    ],
    [GENDER.FEMALE]: [
      MOUTH_TYPES.GRIN,
      MOUTH_TYPES.SAD,
      MOUTH_TYPES.OPEN_SMILE,
      MOUTH_TYPES.OPEN,
      MOUTH_TYPES.SERIOUS,
      MOUTH_TYPES.TONGUE,
      MOUTH_TYPES.LIPS,
    ],
    DEFAULT: Object.values(MOUTH_TYPES),
  },

  HAIR: {
    [GENDER.MALE]: [
      HAIR_TYPES.NONE,
      HAIR_TYPES.SHORT,
      HAIR_TYPES.PIXIE,
      HAIR_TYPES.BALDING,
      HAIR_TYPES.BUZZ,
      HAIR_TYPES.BUN,
    ],
    [GENDER.FEMALE]: [HAIR_TYPES.LONG, HAIR_TYPES.PIXIE, HAIR_TYPES.BOB, HAIR_TYPES.AFRO],
    DEFAULT: Object.values(HAIR_TYPES),
  },
});
