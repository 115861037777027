import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, Slide } from '@mui/material';
import * as handlebars from 'handlebars';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function StatisticDialog({ isOpened, setStatisticOpen, template, reviewStatistic }) {
  const handleClose = () => {
    setStatisticOpen(false);
  };

  // eslint-disable-next-line func-names
  handlebars.registerHelper('ifEquals', function (arg1, arg2, option) {
    return arg1 === arg2 ? option.fn(this) : option.inverse(this);
  });

  const hbs = handlebars.compile(template.trim().replaceAll('\\n', ''));
  const result = hbs(reviewStatistic);
  return (
    <Dialog
      hideBackdrop
      open={isOpened}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="dialog-congratulation"
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: result }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

StatisticDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  setStatisticOpen: PropTypes.func.isRequired,
  template: PropTypes.string.isRequired,
  reviewStatistic: PropTypes.objectOf(PropTypes.object.isRequired).isRequired,
};
