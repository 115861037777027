import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import LevelsListItem from './LevelsListItem';

export default function LevelsList({ isLoading, levels, onDragEnd, onRemove, onInput, setError }) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <Grid ref={provided.innerRef} {...provided.droppableProps}>
            {levels.map((level, index) => (
              <Box key={level._id}>
                <LevelsListItem
                  setError={setError}
                  isLoading={isLoading}
                  onInput={onInput}
                  onRemove={onRemove}
                  index={index}
                  item={level}
                />
                <Divider />
              </Box>
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
}

LevelsList.propTypes = {
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};
