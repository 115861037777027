import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FolderIcon from '@mui/icons-material/Folder';
import TreeView from '@mui/lab/TreeView';
import {
  Box,
  Snackbar,
  Alert,
  styled,
  Divider,
  Typography,
  ListItemButton,
  ListItemText,
  List,
  ListItemIcon,
  ListItem,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { StyledBreadcrumb } from '../../../../components/BreadcrumbsComponent';
import NodeAndDetailStatus from '../enums/NodeAndDetailStatusEnum';
import AddDefaultNodeModal from './AddDefaultNodeModal';
import AddDefaultTaskModal from './AddDefaultTaskModal';
import DialogTreeNode from './DialogTreeNode';

const StyledTreeView = styled(TreeView)(() => ({
  maxHeight: '69vh',
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: '20px',
}));

const titleStyle = {
  fontSize: 12,
  fontWeight: 400,
  fontFamily: 'Roboto","Helvetica","Arial",sans-serif',
};
export default function DialogTreeViewForm({
  treeData,
  setTreeData,
  defaultTreeData,
  setDefaultTreeData,
  replacedTasks,
  setReplacedTasks,
  replacedNodes,
  setReplacedNodes,
}) {
  const [saved, setSaved] = useState(false);
  const [commonExpanded, setCommonExpanded] = useState([]);
  const [defaultExpanded, setDefaultExpanded] = useState([]);
  const [isTaskModalOpened, setIsTaskModalOpened] = useState(false);
  const [conflictedTasks, setConflictedTasks] = useState({});
  const [conflictedNodes, setConflictedNodes] = useState({});
  const [isNodesModalOpened, setIsNodesModalOpened] = useState(false);
  const [defaultTasksDepartment, setDefaultTasksDepartment] = useState('');

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

  const findNodesToBeRemoved = useCallback((arr, id) => {
    const selectedItem = arr.find((item) => item.itemId === id);
    let resArr = [id];

    if (selectedItem.children.length) {
      const nodeChildren = arr
        .filter((item) => selectedItem.children.includes(item.itemId))
        .map((item) => findNodesToBeRemoved(arr, item.itemId));
      resArr = [...resArr, ...nodeChildren];
    }

    return resArr.flat(Infinity);
  }, []);

  const modifyStatus = (status, node) => ({ ...node, status });

  const filteredDepartmentTreeData = defaultTreeData
    .filter((treeItem) => {
      const depPath = treeItem.path.split('/')[0];

      return depPath.toLowerCase() === defaultTasksDepartment.toLowerCase();
    })
    .map((treeItem) => ({
      ...treeItem,
      path: treeItem.path.split('/').splice(2).join('/'),
    }));

  const modifyDetailsStatus = (node, isNewNode) => {
    return node.details.map((detail) =>
      modifyStatus(
        isNewNode ? NodeAndDetailStatus.IS_NEW : NodeAndDetailStatus.IS_REPLACED_DETAIL,
        detail,
      ),
    );
  };

  const getRootNodes = (data) => {
    const childIds = new Set(data.flatMap((item) => item.children));
    return data.filter((item) => !childIds.has(item.itemId));
  };

  const getNodeParents = (firstParent, data) => {
    const parents = [];
    let parent = data.find((item) => item.children.includes(firstParent.itemId));

    while (parent) {
      parents.unshift(parent);
      // eslint-disable-next-line no-loop-func
      parent = data.find((item) => item.children.includes(parent.itemId));
    }

    return parents;
  };

  const autoScroll = (node, elementId) => {
    const scrollDelay = node?.details.length || 0 * 85;

    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, scrollDelay);
  };

  function getNodeChildren(node, data) {
    const children = [];

    const recursion = (parent) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const childId of parent.children) {
        const childData = data.find((el) => el.itemId === childId);
        // eslint-disable-next-line no-continue
        if (!childData) continue;

        children.push(childData);
        recursion(childData);
      }
    };

    recursion(node);
    return children;
  }

  const updateDetailsStatus = (detail, task) => {
    if (detail.name === task.name && detail.successCriteria === task.successCriteria) {
      return { ...detail, status: NodeAndDetailStatus.NONE };
    }

    return detail;
  };

  const getOppositeFirstParentIndex = (node, data) => {
    let firstParentIndex = -1;

    function recursion(path) {
      const parentIndex = data.findIndex((el) => {
        const splitPath = path.toLowerCase().split('/');
        const splitElPath = el.path.toLowerCase().split('/');

        return splitPath[splitPath.length - 1] === splitElPath[splitElPath.length - 1];
      });

      if (parentIndex > -1) {
        firstParentIndex = parentIndex;
      } else {
        let splitPath = path.split('/');

        if (
          splitPath[1] &&
          (splitPath[1].toLowerCase() === 'hard-skills' ||
            splitPath[1].toLowerCase() === 'soft-skills')
        ) {
          splitPath = splitPath.slice(2);
        }

        const parentPath = splitPath.slice(0, -1).join('/');
        if (parentPath) {
          recursion(parentPath);
        }
      }
    }

    recursion(node.path);
    return firstParentIndex;
  };

  const getOppositeFirstTaskParentIndex = (node, data) => {
    let firstParentIndex = -1;
    function recursion(path) {
      const parentIndex = data.findIndex((el) => {
        const parentIndexPath = el.path.split('/').splice(2).join('/');
        return (
          path.toLowerCase() === parentIndexPath.toLowerCase() ||
          path.toLowerCase() === el.path.toLowerCase()
        );
      });
      if (parentIndex > -1) {
        firstParentIndex = parentIndex;
      } else {
        const parentPath = path.split('/').slice(0, -1).join('/');
        if (parentPath) {
          recursion(parentPath);
        }
      }
    }

    recursion(node.path);
    return firstParentIndex;
  };

  const filterNodesChildren = (nodes) => {
    return nodes.map((node) => {
      const children = node.children.filter((child) => nodes.some((el) => el.itemId === child));
      return { ...node, children };
    });
  };

  const getNodeTree = (firstParent, data) => {
    const nodeTree = [firstParent];

    if (firstParent.children.length) {
      const treeDataParent = data.find((el) =>
        el.path.toLowerCase().includes(firstParent.path.toLowerCase()),
      );
      nodeTree.push(...getNodeChildren(treeDataParent, data));
    }

    const parentsParent = data.find((el) => el.children.includes(firstParent.itemId));

    if (parentsParent) {
      nodeTree.unshift(...getNodeParents(parentsParent, data), parentsParent);
    }

    return nodeTree;
  };

  const updateStatusToNone = (modifiedDefaultTreeData, node) => {
    return modifiedDefaultTreeData.map((el) => {
      if (el.itemId === node.itemId) return modifyStatus(NodeAndDetailStatus.NONE, el);

      return el;
    });
  };

  const removeNode = (nodesToBeRemoved, modifiedDefaultTreeData, commonParentIndex) => {
    return (node) => {
      const filteredChildren = node.children.filter(
        (child) =>
          ![...nodesToBeRemoved, treeData[commonParentIndex]]
            .map((el) => el.itemId)
            .includes(child),
      );

      if (!filteredChildren.length && !node.details.length) {
        setDefaultTreeData(updateStatusToNone(modifiedDefaultTreeData, node));
      }

      return { ...node, children: filteredChildren };
    };
  };

  const setDetailsStatusNone = (defaultData, defaultParentIndex, task) => {
    return defaultData[defaultParentIndex].details.map((detail) =>
      updateDetailsStatus(detail, task),
    );
  };

  const modifyNodeAndDetailsStatus = (el, status) => {
    return modifyStatus(status, {
      ...el,
      details: el.details.map((detail) => modifyStatus(status, detail)),
    });
  };

  const modifyDefaultTreeData = (fullyAddedNodes) => {
    return defaultTreeData.map((el) => {
      if (fullyAddedNodes.some((item) => item.itemId === el.itemId)) {
        return modifyNodeAndDetailsStatus(el, NodeAndDetailStatus.IS_ADDED);
      }
      return el;
    });
  };

  const getParentNodesToBeRemoved = (id) => {
    const parentNodesToBeRemoved = [];

    const parents = getNodeParents(
      treeData.find((el) => el.itemId === id),
      treeData,
    ).reverse();

    // eslint-disable-next-line no-restricted-syntax
    for (const el of parents) {
      if (el.children.length === 1) {
        parentNodesToBeRemoved.unshift(el);
      } else {
        break;
      }
    }

    return parentNodesToBeRemoved;
  };
  const removeLastDetail = (commonParent, commonParentIndex) => {
    const nodesToBeRemoved = getParentNodesToBeRemoved(commonParent.itemId);
    const defaultNodeIndex = defaultTreeData.findIndex((el) => el.itemId === commonParent.itemId);
    const defaultNodeChildren = getNodeChildren(defaultTreeData[defaultNodeIndex], defaultTreeData);
    const modifiedDefaultTreeData = defaultTreeData.map((el) => {
      const isAncestorOfDetail = [
        defaultTreeData[defaultNodeIndex].itemId,
        ...defaultNodeChildren.map((child) => child.itemId),
        ...nodesToBeRemoved.map((parent) => parent.itemId),
      ].includes(el.itemId);

      if (isAncestorOfDetail) {
        return modifyNodeAndDetailsStatus(el, NodeAndDetailStatus.NONE);
      }
      return el;
    });

    setDefaultTreeData(modifiedDefaultTreeData);
    setTreeData([
      ...treeData
        .map(removeNode(nodesToBeRemoved, modifiedDefaultTreeData, commonParentIndex))
        .filter(
          (item) =>
            ![...nodesToBeRemoved, treeData[commonParentIndex]]
              .map((el) => el.itemId)
              .includes(item.itemId),
        ),
    ]);
  };

  const removeNotLastDetail = (task, commonParent) => {
    const modifiedCommonParent = {
      ...commonParent,
      details: commonParent.details.filter((detail) => detail.itemId !== task.itemId),
    };
    const modifiedTreeData = treeData.map((node) =>
      node.itemId === commonParent.itemId ? modifiedCommonParent : node,
    );
    setTreeData(modifiedTreeData);

    const defaultNodeIndex = defaultTreeData.findIndex((el) => {
      const [department] = el.path.split('/');

      return (
        el.name.toLowerCase() === commonParent.name.toLowerCase() &&
        department.toLowerCase() === defaultTasksDepartment.toLowerCase()
      );
    });
    const modifiedDetails = defaultTreeData[defaultNodeIndex].details.map((detail) =>
      updateDetailsStatus(detail, task),
    );
    const modifiedDefaultNode = {
      ...defaultTreeData[defaultNodeIndex],
      details: modifiedDetails,
    };

    const modifiedDefaultTreeData = defaultTreeData.map((node) => {
      const [department] = node.path.split('/');

      return node.path.toLowerCase().includes(commonParent.path.toLowerCase()) &&
        department === defaultTasksDepartment
        ? modifiedDefaultNode
        : node;
    });
    setDefaultTreeData(modifiedDefaultTreeData);
  };

  const removeDetail = (task) => {
    const commonParent = treeData.find((el) => el.details.includes(task));
    const commonParentIndex = treeData.indexOf(commonParent);

    const isLastDetail = commonParent.details.length === 1;
    if (isLastDetail) {
      removeLastDetail(commonParent, commonParentIndex);
    } else {
      removeNotLastDetail(task, commonParent);
    }
  };

  const removeTreeNode = (arr, id) => {
    const removedNodes = findNodesToBeRemoved(arr, id);
    const parentNodesToBeRemoved = getParentNodesToBeRemoved(id);

    const filteredCommonExpanded = commonExpanded.filter(
      (item) => ![...removedNodes, parentNodesToBeRemoved].includes(item),
    );
    setCommonExpanded(filteredCommonExpanded);

    const defaultNodeIndex = defaultTreeData.findIndex((el) => el.itemId === id);
    const defaultNodeChildren = getNodeChildren(defaultTreeData[defaultNodeIndex], defaultTreeData);

    const modifiedDefaultTreeData = defaultTreeData.map((node) => {
      const isTargetNode = [
        defaultTreeData[defaultNodeIndex].itemId,
        ...defaultNodeChildren.map((child) => child.itemId),
        ...parentNodesToBeRemoved.map((parent) => parent.itemId),
      ].includes(node.itemId);
      return isTargetNode ? modifyNodeAndDetailsStatus(node, NodeAndDetailStatus.NONE) : node;
    });
    setDefaultTreeData(modifiedDefaultTreeData);

    const filteredTreeData = arr.filter((node) => {
      const filteredChildren = node.children.filter(
        (child) =>
          !removedNodes
            .concat(parentNodesToBeRemoved.map((parent) => parent.itemId))
            .includes(child),
      );

      const hasNoChildren = filteredChildren.length === 0;
      const hasNoDetails = node.details.length === 0;

      if (hasNoChildren && hasNoDetails) {
        setDefaultTreeData(updateStatusToNone(modifiedDefaultTreeData, node));
      }

      return !removedNodes
        .concat(parentNodesToBeRemoved.map((parent) => parent.itemId))
        .includes(node.itemId);
    });

    setTreeData(filteredTreeData);
  };

  const changeDefaultTreeData = (modifiedTreeData, defaultParent, task) => {
    const defaultDetailIndex = defaultParent.details.findIndex((el) => {
      return (
        el.name.toLowerCase() === task.name.toLowerCase() &&
        el.successCriteria === task.successCriteria &&
        el.materialName === task.materialName
      );
    });

    defaultParent.details.splice(defaultDetailIndex, 1, {
      ...defaultParent.details[defaultDetailIndex],
      status: NodeAndDetailStatus.IS_ADDED,
    });

    const isNodeFullyAdded = (testedNodeId) => {
      const visited = new Set();
      const stack = [testedNodeId];

      while (stack.length > 0) {
        const nodeId = stack.pop();
        visited.add(nodeId);
        const node = defaultTreeData.find((el) => el.itemId === nodeId);

        if (
          node &&
          node.children.every((item) => modifiedTreeData.find((a) => a.itemId === item)) &&
          node.details.every((detail) => detail.status === NodeAndDetailStatus.IS_ADDED)
        ) {
          node.children.forEach((item) => {
            if (!visited.has(item)) {
              stack.push(item);
            }
          });
        } else {
          return false;
        }
      }

      return true;
    };

    const fullyAddedNodes = getNodeTree(defaultParent, modifiedTreeData).filter((el) =>
      isNodeFullyAdded(el.itemId),
    );

    const modifiedDefaultTreeData = modifyDefaultTreeData(fullyAddedNodes);

    setDefaultTreeData(modifiedDefaultTreeData);
  };

  const addSubBranch = (defaultParentIndex, lastCommonParentIndex, task) => {
    const defaultNodeParents = getNodeParents(
      filteredDepartmentTreeData[defaultParentIndex],
      filteredDepartmentTreeData,
    );
    const slicePoint =
      defaultNodeParents.findIndex((el) => {
        const splitPath = treeData[lastCommonParentIndex].path.toLowerCase().split('/');
        const splitElPath = el.path.toLowerCase().split('/');
        return splitElPath[splitElPath.length - 1] === splitPath[splitPath.length - 1];
      }) + 1;
    const missingDefaultNodeParents = slicePoint ? defaultNodeParents.slice(slicePoint) : [];

    const lastCommonParent = {
      ...treeData[lastCommonParentIndex],
      children: [
        ...treeData[lastCommonParentIndex].children,
        missingDefaultNodeParents.length
          ? missingDefaultNodeParents[0].itemId
          : filteredDepartmentTreeData[defaultParentIndex].itemId,
      ],
    };

    const nodesChildren = filterNodesChildren([
      ...missingDefaultNodeParents,
      { ...filteredDepartmentTreeData[defaultParentIndex], details: [task] },
      ...getNodeChildren(
        filteredDepartmentTreeData[defaultParentIndex],
        filteredDepartmentTreeData,
      ),
    ]);

    const childrenIndex = nodesChildren.findIndex((el) => el.itemId === lastCommonParent.itemId);

    if (childrenIndex > -1) {
      lastCommonParent.children.push(nodesChildren[childrenIndex + 1].itemId);
    }

    const modifiedTreeData = [
      ...treeData.slice(0, lastCommonParentIndex),
      lastCommonParent,
      ...(childrenIndex === -1
        ? nodesChildren.map((el) => modifyNodeAndDetailsStatus(el, NodeAndDetailStatus.IS_NEW))
        : [
            modifyNodeAndDetailsStatus(
              nodesChildren[childrenIndex + 1],
              NodeAndDetailStatus.IS_NEW,
            ),
          ]),
      ...treeData.slice(lastCommonParentIndex + 1),
    ];

    setTreeData(modifiedTreeData);

    const newExpandedIds = [
      ...commonExpanded,
      ...filterNodesChildren([
        ...missingDefaultNodeParents,
        filteredDepartmentTreeData[defaultParentIndex],
        ...getNodeChildren(
          filteredDepartmentTreeData[defaultParentIndex],
          filteredDepartmentTreeData,
        ),
      ]).map((el) => el.itemId),
      treeData[lastCommonParentIndex].itemId,
    ];
    setCommonExpanded(newExpandedIds);

    changeDefaultTreeData(modifiedTreeData, filteredDepartmentTreeData[defaultParentIndex], task);

    autoScroll(treeData[lastCommonParentIndex], task.itemId);
  };

  const addFullTreeBranch = (defaultParentIndex, task) => {
    const parents = getNodeParents(
      filteredDepartmentTreeData[defaultParentIndex],
      filteredDepartmentTreeData,
    );

    const modifiedTreeData = [
      ...treeData,
      ...filterNodesChildren([
        ...parents,
        {
          ...filteredDepartmentTreeData[defaultParentIndex],
          details: [modifyStatus(NodeAndDetailStatus.IS_NEW, task)],
        },
      ]).map((el) => modifyStatus(NodeAndDetailStatus.IS_NEW, el)),
    ];

    setTreeData(modifiedTreeData);

    setCommonExpanded([
      ...commonExpanded,
      ...filterNodesChildren([...parents, filteredDepartmentTreeData[defaultParentIndex]]).map(
        (el) => el.itemId,
      ),
    ]);

    changeDefaultTreeData(modifiedTreeData, filteredDepartmentTreeData[defaultParentIndex], task);
    autoScroll(parents.at(-1), task.itemId);
  };

  const performConflictedMerge = (commonParentIndex, defaultParentIndex, task) => {
    const possibleConflictedTask = treeData[commonParentIndex].details.find(
      (detail) =>
        detail.name.toLowerCase() === task.name.toLowerCase() &&
        detail.successCriteria === task.successCriteria,
    );

    if (possibleConflictedTask) {
      setIsTaskModalOpened(true);
      setConflictedTasks({ replaceableTask: possibleConflictedTask, replacingTask: task });
      return;
    }

    treeData[commonParentIndex].details.push(modifyStatus(NodeAndDetailStatus.IS_NEW, task));

    setTreeData(treeData);

    setCommonExpanded([
      ...commonExpanded,
      ...getNodeParents(treeData[commonParentIndex], treeData).map((el) => el.itemId),
      treeData[commonParentIndex].itemId,
    ]);

    changeDefaultTreeData([...treeData], filteredDepartmentTreeData[defaultParentIndex], task);
  };

  const addDefaultTask = (task) => {
    const defaultParentIndex = filteredDepartmentTreeData.findIndex((item) =>
      item.details.includes(task),
    );

    const commonParentIndex = treeData.findIndex((el) => {
      const commonParentPath = el.path.split('/').splice(2).join('/');
      const defaultParentPath = filteredDepartmentTreeData[defaultParentIndex].path;
      return (
        commonParentPath.toLowerCase() === defaultParentPath.toLowerCase() ||
        el.path.toLowerCase() === defaultParentPath.toLowerCase()
      );
    });

    const lastCommonParentIndex = getOppositeFirstTaskParentIndex(
      filteredDepartmentTreeData[defaultParentIndex],
      treeData,
    );

    if (lastCommonParentIndex > -1 && lastCommonParentIndex !== commonParentIndex) {
      addSubBranch(defaultParentIndex, lastCommonParentIndex, task);
    } else if (commonParentIndex === -1) {
      addFullTreeBranch(defaultParentIndex, task);
    } else {
      performConflictedMerge(commonParentIndex, defaultParentIndex, task);
    }
  };

  const replaceTaskInDetails = (details, currentTaskIndex, newTask) => {
    const modifiedDetails = [...details];
    modifiedDetails.splice(
      currentTaskIndex,
      1,
      modifyStatus(NodeAndDetailStatus.IS_REPLACED, newTask),
    );
    return modifiedDetails;
  };

  const replaceDetailsInTreeData = (data, commonParentIndex, updatedDetails) => {
    const modifiedTreeData = [...data];
    modifiedTreeData[commonParentIndex] = {
      ...modifiedTreeData[commonParentIndex],
      details: updatedDetails,
    };
    return modifiedTreeData;
  };

  const getCommonExpandedIds = (data, commonParentIndex) => {
    return getNodeParents(data[commonParentIndex], data)
      .map((parent) => parent.itemId)
      .concat([data[commonParentIndex].itemId]);
  };

  const replaceTask = (task) => {
    const defaultParentIndex = filteredDepartmentTreeData.findIndex((item) =>
      item.details.includes(task),
    );

    const commonParentIndex = treeData.findIndex((el) => {
      const commonParentPath = el.path.split('/').splice(2).join('/');
      const defaultParentPath = filteredDepartmentTreeData[defaultParentIndex].path;
      return (
        commonParentPath.toLowerCase() === defaultParentPath.toLowerCase() ||
        el.path.toLowerCase() === defaultParentPath.toLowerCase()
      );
    });

    const currentTaskIndex = treeData[commonParentIndex].details.findIndex(
      (detail) =>
        detail.name.toLowerCase() === task.name.toLowerCase() &&
        detail.successCriteria === task.successCriteria,
    );

    setReplacedTasks([...replacedTasks, treeData[commonParentIndex].details[currentTaskIndex]]);

    const updatedDetails = replaceTaskInDetails(
      treeData[commonParentIndex].details,
      currentTaskIndex,
      task,
    );
    const updatedTreeData = replaceDetailsInTreeData(treeData, commonParentIndex, updatedDetails);

    setTreeData(updatedTreeData);
    setIsTaskModalOpened(false);

    const commonExpandedIds = getCommonExpandedIds(updatedTreeData, commonParentIndex);
    setCommonExpanded([...commonExpanded, ...commonExpandedIds]);

    changeDefaultTreeData(updatedTreeData, defaultTreeData[defaultParentIndex], task);

    autoScroll(updatedTreeData[commonParentIndex], task.itemId);
  };

  const revertReplacedTask = (task) => {
    const parent = treeData.find((item) => item.details.includes(task));
    const currentTaskIndex = parent.details.findIndex(
      (detail) =>
        detail.name.toLowerCase() === task.name.toLowerCase() &&
        detail.successCriteria === task.successCriteria,
    );
    const replacedTaskIndex = replacedTasks.findIndex(
      (item) =>
        item.name.toLowerCase() === task.name.toLowerCase() &&
        item.successCriteria === task.successCriteria,
    );

    const updatedParent = {
      ...parent,
      details: [
        ...parent.details.slice(0, currentTaskIndex),
        replacedTasks[replacedTaskIndex],
        ...parent.details.slice(currentTaskIndex + 1),
      ],
    };
    const updatedTreeData = treeData.map((item) => (item === parent ? updatedParent : item));

    const updatedReplacedTasks = [
      ...replacedTasks.slice(0, replacedTaskIndex),
      ...replacedTasks.slice(replacedTaskIndex + 1),
    ];

    setTreeData(updatedTreeData);
    setReplacedTasks(updatedReplacedTasks);

    const defaultParent = defaultTreeData.find((el) => {
      const defaultParentPath = el.path.split('/').splice(2).join('/');
      return defaultParentPath.toLowerCase() === parent.path.toLowerCase();
    });
    const updatedDefaultParent = {
      ...defaultParent,
      status: NodeAndDetailStatus.NONE,
      details: setDetailsStatusNone(defaultTreeData, defaultTreeData.indexOf(defaultParent), task),
    };

    const updatedDefaultTreeData = defaultTreeData.map((el) =>
      el === defaultParent ? updatedDefaultParent : el,
    );

    const defaultNodeParents = getNodeParents(defaultParent, defaultTreeData);
    const updatedDefaultTreeDataWithNodeStatus = updatedDefaultTreeData.map((el) =>
      defaultNodeParents
        .filter((item) => item.children.length === 1)
        .map((a) => a.itemId)
        .includes(el.itemId)
        ? modifyStatus(NodeAndDetailStatus.NONE, el)
        : el,
    );

    setDefaultTreeData(updatedDefaultTreeDataWithNodeStatus);
  };

  const findConflictedNode = (node) => {
    return treeData.find(
      (el) => el.path === node.path || el.name.toLowerCase() === node.name.toLowerCase(),
    );
  };

  const handleConflictedNode = (conflictedNode, newNode) => {
    const replaceableNodes = [conflictedNode, ...getNodeChildren(conflictedNode, treeData)];
    const replacingNodes = filterNodesChildren([
      newNode,
      ...getNodeChildren(newNode, defaultTreeData),
    ]);
    setConflictedNodes({ replaceableNodes, replacingNodes });
    setIsNodesModalOpened(true);
  };

  const handleLastCommonParent = (lastCommonParent, newNode) => {
    const defaultNodeParents = getNodeParents(newNode, defaultTreeData);
    const parentAdded = defaultNodeParents.reduceRight((foundParent, defaultParent) => {
      if (!foundParent) {
        return treeData.find((node) => {
          const splitNodePath = node.path.toLowerCase().split('/');
          const splitParentPath = defaultParent.path.toLowerCase().split('/');
          return (
            splitNodePath[splitNodePath.length - 1] === splitParentPath[splitParentPath.length - 1]
          );
        });
      }
      return foundParent;
    }, null);

    const slicePoint =
      defaultNodeParents.findIndex((el) =>
        el.path.toLowerCase().includes(lastCommonParent.path.toLowerCase()),
      ) + 1;

    const missingDefaultNodeParents = defaultNodeParents.slice(slicePoint);

    const modifiedLastCommonParent = {
      ...lastCommonParent,
      children: [
        ...lastCommonParent.children,
        missingDefaultNodeParents.length ? missingDefaultNodeParents[0].itemId : newNode.itemId,
      ],
    };

    const modifiedTreeData = [
      ...treeData.slice(0, treeData.indexOf(lastCommonParent)),
      modifiedLastCommonParent,
      ...filterNodesChildren([
        ...missingDefaultNodeParents,
        newNode,
        ...getNodeChildren(newNode, defaultTreeData),
      ]).map((el) => modifyNodeAndDetailsStatus(el, NodeAndDetailStatus.IS_NEW)),
      ...treeData.slice(treeData.indexOf(lastCommonParent) + 1),
    ];

    setTreeData(modifiedTreeData);

    const newCommonExpanded = filterNodesChildren([
      ...defaultNodeParents,
      modifiedLastCommonParent,
    ]).map((el) => el.itemId);

    setCommonExpanded((prevCommonExpanded) => [...prevCommonExpanded, ...newCommonExpanded]);

    const isNodeFullyAdded = (testedNodeId) => {
      let status = true;
      const recursion = (childId) => {
        const child = defaultTreeData.find((el) => el.itemId === childId);
        if (
          child &&
          child.children.every((item) => modifiedTreeData.find((a) => a.itemId === item))
        ) {
          child.children.forEach((item) => recursion(item));
        } else {
          status = false;
        }
      };

      recursion(testedNodeId);
      return status;
    };

    const fullyAddedNodes = getNodeTree(newNode, modifiedTreeData).filter((el) =>
      isNodeFullyAdded(el.itemId),
    );

    const modifiedDefaultTreeData = modifyDefaultTreeData(fullyAddedNodes);

    setDefaultTreeData(modifiedDefaultTreeData);
    autoScroll(newNode, newNode.itemId);

    if (parentAdded && parentAdded !== lastCommonParent) {
      handleLastCommonParent(parentAdded, newNode);
    }
  };

  const handleNewNode = (node) => {
    const commonRootNode = getRootNodes(treeData).find(
      (el) => el.path === node.path.split('/').slice(0, 1),
    );
    if (!commonRootNode) {
      const defaultNodeChildren = getNodeChildren(node, defaultTreeData);
      const defaultNodeParents = getNodeParents(node, defaultTreeData);

      const newNodes = filterNodesChildren([
        ...defaultNodeParents,
        node,
        ...defaultNodeChildren,
      ]).map((el) => modifyNodeAndDetailsStatus(el, NodeAndDetailStatus.IS_NEW));
      setTreeData([...treeData, ...newNodes]);

      const newExpandedNodes = filterNodesChildren([...defaultNodeParents, node]).map(
        (el) => el.itemId,
      );
      setCommonExpanded([...commonExpanded, ...newExpandedNodes]);

      const defaultNodeChildrenIds = defaultNodeChildren.map((el) => el.itemId);

      const modifiedDefaultTreeData = defaultTreeData
        .map((el) => {
          if ([node.itemId, ...defaultNodeChildrenIds].includes(el.itemId)) {
            return modifyNodeAndDetailsStatus(el, NodeAndDetailStatus.IS_ADDED);
          }
          return el;
        })
        .map((item, _index, array) => {
          if (
            item.children.length &&
            item.children
              .map((child) => array.find((l) => l.itemId === child))
              .every((e) => e.status === NodeAndDetailStatus.IS_ADDED)
          ) {
            return modifyStatus(NodeAndDetailStatus.IS_ADDED, item);
          }
          return item;
        });

      setDefaultTreeData(modifiedDefaultTreeData);
      autoScroll(node, node.itemId);
    }
  };

  const addDefaultTreeNode = (node) => {
    const conflictedNode = findConflictedNode(node);

    if (conflictedNode) {
      handleConflictedNode(conflictedNode, node);
      return;
    }

    const lastCommonParentIndex = getOppositeFirstParentIndex(node, treeData);
    if (lastCommonParentIndex > -1) {
      handleLastCommonParent(treeData[lastCommonParentIndex], node);
      return;
    }

    handleNewNode(node);
  };

  const getModifiedDefaultTreeData = (data, modifiedDefaultNodeChildren) => {
    return data.map((node) => {
      const modifiedNode = modifiedDefaultNodeChildren.find((el) => el.itemId === node.itemId);
      return modifiedNode || node;
    });
  };

  const replaceNode = (node) => {
    const replaceableNode = conflictedNodes.replaceableNodes.find(
      (el) => el.path === node.path || el.name.toLowerCase() === node.name.toLowerCase(),
    );

    const currentNodeIndex = treeData.findIndex((el) => el.itemId === replaceableNode.itemId);

    const replaceableNodeChildren = getNodeChildren(replaceableNode, treeData);

    setReplacedNodes(
      _.unionBy([...replacedNodes, replaceableNode, ...replaceableNodeChildren], 'path'),
    );

    const nodesToBeRemoved = findNodesToBeRemoved(treeData, replaceableNode.itemId);

    const isNewNode = replaceableNode.status === NodeAndDetailStatus.IS_NEW;

    const filteredTreeData = treeData.filter((el) => !nodesToBeRemoved.includes(el.itemId));

    filteredTreeData.splice(currentNodeIndex, 0, {
      ...replaceableNode,
      ...modifyStatus(isNewNode ? NodeAndDetailStatus.IS_NEW : NodeAndDetailStatus.IS_REPLACED, {
        ...node,
        details: modifyDetailsStatus(node, isNewNode),
      }),
      itemId: replaceableNode.itemId,
    });

    const defaultNodeChildren = getNodeChildren(node, defaultTreeData);

    const modifiedReplaceableNodeChildren = defaultNodeChildren.map((el) =>
      modifyStatus(isNewNode ? NodeAndDetailStatus.IS_NEW : NodeAndDetailStatus.IS_REPLACED_CHILD, {
        ...el,
        details: modifyDetailsStatus(el, isNewNode),
      }),
    );

    filteredTreeData.push(...modifiedReplaceableNodeChildren);

    setTreeData(filteredTreeData);

    const modifiedDefaultNodeChildren = defaultNodeChildren.map((el) =>
      modifyNodeAndDetailsStatus(el, NodeAndDetailStatus.IS_ADDED),
    );
    const modifiedDefaultTreeData = getModifiedDefaultTreeData(defaultTreeData, [
      modifyNodeAndDetailsStatus(node, NodeAndDetailStatus.IS_ADDED),
      ...modifiedDefaultNodeChildren,
    ]);

    setDefaultTreeData(modifiedDefaultTreeData);

    const modifiedCommonExpanded = [
      ...commonExpanded,
      ...getNodeParents(replaceableNode, filteredTreeData).map((parent) => parent.itemId),
      replaceableNode.itemId,
    ];

    setCommonExpanded(modifiedCommonExpanded);
    setIsNodesModalOpened(false);
    autoScroll(node, replaceableNode.itemId);
  };

  const revertReplacedNode = (node) => {
    const replacedNode = replacedNodes.find((el) => el.itemId === node.itemId);
    const replacedNodeIndex = treeData.findIndex((el) => el.itemId === replacedNode.itemId);
    const replacedNodeChildren = getNodeChildren(replacedNode, replacedNodes);
    const nodesToRemoving = findNodesToBeRemoved(treeData, node.itemId);
    const filteredTreeData = treeData.filter((el) => !nodesToRemoving.includes(el.itemId));

    filteredTreeData.splice(replacedNodeIndex, 0, replacedNode);

    [filteredTreeData[replacedNodeIndex], ...replacedNodeChildren].forEach((el) => {
      const replacedIndex = replacedNodes.findIndex((item) => item.itemId === el.itemId);
      setReplacedNodes([...replacedNodes].splice(replacedIndex, 1));

      const modifiedReplacedTasks = [
        ...replacedTasks.filter(
          (replacedTask) => !el.details.some((detail) => detail.itemId === replacedTask.itemId),
        ),
      ];
      setReplacedTasks(modifiedReplacedTasks);
    });

    filteredTreeData.push(...replacedNodeChildren);

    setTreeData(filteredTreeData);

    setCommonExpanded(commonExpanded.filter((el) => !nodesToRemoving.includes(el)));

    const defaultNodeIndex = defaultTreeData.findIndex((el) => el.path === node.path);

    const defaultNodeChildren = getNodeChildren(defaultTreeData[defaultNodeIndex], defaultTreeData);

    defaultTreeData.splice(
      defaultNodeIndex,
      1,
      modifyNodeAndDetailsStatus(defaultTreeData[defaultNodeIndex], NodeAndDetailStatus.NONE),
    );

    const modifiedDefaultNodeChildren = defaultNodeChildren.map((el) => {
      if (replacedTasks.includes(el.details)) {
        return el;
      }
      return modifyNodeAndDetailsStatus(el, NodeAndDetailStatus.NONE);
    });

    const modifiedDefaultTreeData = getModifiedDefaultTreeData(
      defaultTreeData,
      modifiedDefaultNodeChildren,
    );

    setDefaultTreeData([...modifiedDefaultTreeData]);
  };

  const filteredTree = useMemo(
    () => treeData.filter((i) => !treeData.some((item) => item.children.includes(i.itemId))),
    [treeData],
  );

  const filteredDefaultTree = useMemo(
    () =>
      defaultTreeData
        .filter((i) => !defaultTreeData.some((item) => item.children.includes(i.itemId)))
        .reduce((s, c) => {
          const department = c.path.split('/')[0];

          if (s[department]) {
            s[department].push(c);
          } else {
            // eslint-disable-next-line no-param-reassign
            s[department] = [c];
          }

          return s;
        }, {}),
    [defaultTreeData],
  );

  const departmentsWithTasks = Object.keys(filteredDefaultTree);

  const handleClickDepartmentRoute = (department) => {
    setDefaultTasksDepartment(department);
    return department;
  };

  const handleClearDepartmentRoute = () => {
    setDefaultTasksDepartment('');
    return '';
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: { md: 'row', xs: 'column' },
      }}
    >
      <Box sx={{ width: { md: '50%', xs: '100%' } }}>
        <StyledTreeView
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          expanded={commonExpanded}
        >
          <StyledBreadcrumb component="span" label="Current" />
          {filteredTree.map((node) => (
            <DialogTreeNode
              key={node.itemId}
              node={node}
              treeData={treeData}
              addDefaultTreeNode={addDefaultTreeNode}
              removeTreeNode={(id) => removeTreeNode(treeData, id)}
              onClick={(id) => {
                setCommonExpanded(
                  commonExpanded.includes(id)
                    ? commonExpanded.filter((item) => item !== id)
                    : [...commonExpanded, id],
                );
              }}
              revertReplacedTask={revertReplacedTask}
              revertReplacedNode={revertReplacedNode}
              removeDetail={removeDetail}
            />
          ))}
        </StyledTreeView>
      </Box>
      <Divider
        orientation={isMediumScreen ? 'vertical' : 'horizontal'}
        flexItem
        sx={{
          mb: 1,
          mr: 1,
          display: 'flex',
          alignItems: 'center',
          width: isMediumScreen ? 'auto' : '100%',
        }}
      />
      <Box sx={{ width: { md: '50%', xs: '100%' } }}>
        <StyledTreeView
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          expanded={defaultExpanded}
        >
          <List sx={{ padding: 0, marginBottom: '20px', display: 'flex' }}>
            <ListItem sx={{ width: 'auto', padding: 0 }}>
              <ListItemButton
                sx={{ padding: 0, borderRadius: '16px' }}
                onClick={() => handleClearDepartmentRoute()}
              >
                <ListItemText sx={{ margin: 0 }}>
                  <StyledBreadcrumb component="a" label="Incoming" />
                </ListItemText>
              </ListItemButton>
            </ListItem>
            {defaultTasksDepartment.length === 0 ? (
              ''
            ) : (
              <>
                <ListItem sx={{ width: '4%', padding: 0 }}>
                  <ListItemText sx={{ margin: 0 }}>
                    <Typography style={{ ...titleStyle, textAlign: 'center' }}>/</Typography>
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ width: 'auto', padding: 0 }}>
                  <ListItemText sx={{ margin: 0 }}>
                    <StyledBreadcrumb component="span" label={defaultTasksDepartment} />
                  </ListItemText>
                </ListItem>
              </>
            )}
          </List>
          {defaultTasksDepartment.length === 0 ? (
            <List>
              {departmentsWithTasks.map((department) => (
                <ListItem key={department}>
                  <ListItemButton onClick={() => handleClickDepartmentRoute(department)}>
                    <ListItemIcon>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary={department} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          ) : (
            filteredDefaultTree[defaultTasksDepartment].map((node) => (
              <DialogTreeNode
                key={node.itemId}
                node={node}
                treeData={defaultTreeData}
                addDefaultTreeNode={addDefaultTreeNode}
                removeTreeNode={(id) => removeTreeNode(treeData, id)}
                onClick={(id) => {
                  setDefaultExpanded(
                    defaultExpanded.includes(id)
                      ? defaultExpanded.filter((item) => item !== id)
                      : [...defaultExpanded, id],
                  );
                }}
                addDefaultTask={addDefaultTask}
                isDefault
              />
            ))
          )}
        </StyledTreeView>
      </Box>

      <Snackbar open={saved} autoHideDuration={6000} onClose={() => setSaved(false)}>
        <Alert onClose={() => setSaved(false)} severity="success" sx={{ width: '100%' }}>
          Saved!
        </Alert>
      </Snackbar>
      {isTaskModalOpened && (
        <AddDefaultTaskModal
          open={isTaskModalOpened}
          setIsOpened={setIsTaskModalOpened}
          conflictedTasks={conflictedTasks}
          replaceTask={replaceTask}
        />
      )}
      {isNodesModalOpened && (
        <AddDefaultNodeModal
          conflictedNodes={conflictedNodes}
          open={isNodesModalOpened}
          setIsOpened={setIsNodesModalOpened}
          replaceNode={replaceNode}
        />
      )}
    </Box>
  );
}

const skillTree = {
  itemId: PropTypes.string,
  name: PropTypes.string,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      successCriteria: PropTypes.string,
      materialName: PropTypes.string,
      materialType: PropTypes.string,
      link: PropTypes.string,
      level: PropTypes.string,
    }),
  ),
  children: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DialogTreeViewForm.propTypes = {
  treeData: PropTypes.arrayOf(PropTypes.shape(skillTree)).isRequired,
  setTreeData: PropTypes.func.isRequired,
  defaultTreeData: PropTypes.arrayOf(PropTypes.shape(skillTree)).isRequired,
  setDefaultTreeData: PropTypes.func.isRequired,
  replacedTasks: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      successCriteria: PropTypes.string,
      materialName: PropTypes.string,
      materialType: PropTypes.string,
      link: PropTypes.string,
      level: PropTypes.string,
    }),
  ).isRequired,
  setReplacedTasks: PropTypes.func.isRequired,
  replacedNodes: PropTypes.arrayOf(PropTypes.shape(skillTree)).isRequired,
  setReplacedNodes: PropTypes.func.isRequired,
};
