import React from 'react';
import PropTypes from 'prop-types';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { Box, TextField, Tooltip, IconButton, ThemeProvider, Grid } from '@mui/material';
import NodeAndDetailStatus from '../enums/NodeAndDetailStatusEnum';
import StyledTreeItemCustom from '../styled-components/StyledTreeItemCustom';
import StyledTextFieldContainer from '../styled-components/TextFieldContainer';
import TreeNodeTheme from '../themes/TreeNodeTheme';
import DialogCodeDetailsForm from './DialogCodeDetailsForm';

export default function DialogTreeNode({
  node,
  treeData,
  addDefaultTreeNode,
  removeTreeNode,
  onClick,
  isDefault,
  addDefaultTask,
  revertReplacedTask,
  revertReplacedNode,
  removeDetail,
}) {
  const nodeChildren = treeData.filter((item) => node.children.includes(item.itemId));

  return (
    <ThemeProvider theme={TreeNodeTheme}>
      <StyledTreeItemCustom
        nodeId={node.itemId}
        onClick={() => onClick(node.itemId)}
        id={isDefault ? '' : node.itemId}
        label={
          <StyledTextFieldContainer container>
            <Grid item md={6} sm={9}>
              <TextField
                defaultValue={node.name}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  '& .MuiOutlinedInput-input': {
                    backgroundColor:
                      [
                        NodeAndDetailStatus.IS_NEW,
                        NodeAndDetailStatus.IS_REPLACED,
                        NodeAndDetailStatus.IS_REPLACED_CHILD,
                      ].includes(node.status) && 'rgba(0, 255, 45, 0.24)',
                  },
                }}
              />
            </Grid>
            <Box>
              {node.status !== NodeAndDetailStatus.IS_ADDED && isDefault && (
                <Tooltip title="Add child">
                  <IconButton onClick={() => addDefaultTreeNode(node)}>
                    <AddCircleRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
              {node.status === NodeAndDetailStatus.IS_NEW && (
                <Tooltip title="Remove">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      removeTreeNode(node.itemId);
                    }}
                  >
                    <RemoveCircleRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
              {node.status === NodeAndDetailStatus.IS_REPLACED && (
                <Tooltip title="Revert">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      revertReplacedNode(node);
                    }}
                  >
                    <HistoryRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </StyledTextFieldContainer>
        }
      >
        {node.children.length > 0
          ? nodeChildren.map((child) => (
              <DialogTreeNode
                key={child.itemId}
                node={child}
                treeData={treeData}
                addDefaultTreeNode={addDefaultTreeNode}
                removeTreeNode={removeTreeNode}
                onClick={onClick}
                isDefault={isDefault}
                addDefaultTask={addDefaultTask}
                revertReplacedTask={revertReplacedTask}
                revertReplacedNode={revertReplacedNode}
                removeDetail={removeDetail}
              />
            ))
          : node.details.map((item) => {
              return (
                <DialogCodeDetailsForm
                  key={item.itemId}
                  node={item}
                  removeDetails={removeDetail}
                  isDefault={isDefault}
                  addDefaultTask={addDefaultTask}
                  revertReplacedTask={revertReplacedTask}
                />
              );
            })}
      </StyledTreeItemCustom>
    </ThemeProvider>
  );
}

const treeNodeShape = {
  itemId: PropTypes.string,
  name: PropTypes.string,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      successCriteria: PropTypes.string,
      materialName: PropTypes.string,
      materialType: PropTypes.string,
      link: PropTypes.string,
      level: PropTypes.string,
    }),
  ),
  children: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DialogTreeNode.propTypes = {
  node: PropTypes.shape(treeNodeShape).isRequired,
  treeData: PropTypes.arrayOf(PropTypes.shape(treeNodeShape)).isRequired,
  addDefaultTreeNode: PropTypes.func.isRequired,
  removeTreeNode: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isDefault: PropTypes.bool,
  addDefaultTask: PropTypes.func,
  revertReplacedTask: PropTypes.func,
  revertReplacedNode: PropTypes.func,
  removeDetail: PropTypes.func,
};

DialogTreeNode.defaultProps = {
  isDefault: false,
  addDefaultTask: () => null,
  revertReplacedTask: () => null,
  revertReplacedNode: () => null,
  removeDetail: () => null,
};
