import React from 'react';
import PropTypes from 'prop-types';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Modal, Box, Button, styled, Typography, Chip, Tooltip } from '@mui/material';

const StyledModalBody = styled(Box)(() => ({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  padding: '40px 30px',
  background: 'rgb(255, 255, 255)',
  boxShadow: 24,
  p: 4,
  borderRadius: '15px',
}));

const StyledButtonContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '30px',
}));

const StyledItemContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '8px',
}));

const StyledItemTitle = styled(Typography)(() => ({
  marginRight: '8px',
  fontSize: '13px',
  minWidth: '80px',
}));

const StyledChipContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledChip = styled(Chip)(() => ({
  width: '70px',
}));

const StyledModalTitle = styled(Typography)(() => ({
  marginBottom: '24px',
}));

export default function ModalWindow({ modal, onAccept, onDecline }) {
  const {
    isOpen,
    prevGoal,
    prevDepartment,
    prevLevel,
    prevIsAdmin,
    newGoal,
    newDepartment,
    newLevel,
    newEnglishLevel,
    prevEnglishLevel,
    newIsAdmin,
  } = modal;

  return (
    <Modal open={isOpen}>
      <StyledModalBody>
        <Box>
          <StyledModalTitle variant="h6" component="h4" sx={{ fontWeight: 400 }}>
            Are you sure want to set new values?
          </StyledModalTitle>
          <Box>
            {newDepartment && (
              <StyledItemContainer>
                <StyledItemTitle>Department:</StyledItemTitle>
                <StyledChipContainer>
                  <Tooltip title={prevDepartment.title} placement="right">
                    <StyledChip label={prevDepartment.title} />
                  </Tooltip>
                  <ArrowForwardRoundedIcon sx={{ margin: '0 16px' }} fontSize="small" />
                  <Tooltip title={newDepartment.title} placement="right">
                    <StyledChip label={newDepartment.title} />
                  </Tooltip>
                </StyledChipContainer>
              </StyledItemContainer>
            )}
            {newLevel && (
              <StyledItemContainer>
                <StyledItemTitle>Level:</StyledItemTitle>
                <StyledChipContainer>
                  <Tooltip title={prevLevel.key} placement="right">
                    <StyledChip label={prevLevel.key} />
                  </Tooltip>
                  <ArrowForwardRoundedIcon sx={{ margin: '0 16px' }} fontSize="small" />
                  <Tooltip title={newLevel.key} placement="right">
                    <StyledChip label={newLevel.key} />
                  </Tooltip>
                </StyledChipContainer>
              </StyledItemContainer>
            )}
            {newGoal && (
              <StyledItemContainer>
                <StyledItemTitle>Goal:</StyledItemTitle>
                <StyledChipContainer>
                  <Tooltip title={prevLevel.key} placement="right">
                    <StyledChip label={prevGoal.key} />
                  </Tooltip>
                  <ArrowForwardRoundedIcon sx={{ margin: '0 16px' }} fontSize="small" />
                  <Tooltip title={newGoal.key} placement="right">
                    <StyledChip label={newGoal.key} />
                  </Tooltip>
                </StyledChipContainer>
              </StyledItemContainer>
            )}
            {newEnglishLevel && (
              <StyledItemContainer>
                <StyledItemTitle>English Level:</StyledItemTitle>
                <StyledChipContainer>
                  <Tooltip title={prevEnglishLevel?.display} placement="right">
                    <StyledChip label={prevEnglishLevel?.value} />
                  </Tooltip>
                  <ArrowForwardRoundedIcon sx={{ margin: '0 16px' }} fontSize="small" />
                  <Tooltip title={newEnglishLevel?.display} placement="right">
                    <StyledChip label={newEnglishLevel?.value} />
                  </Tooltip>
                </StyledChipContainer>
              </StyledItemContainer>
            )}
            {newIsAdmin !== null && (
              <StyledItemContainer>
                <StyledItemTitle>Admin:</StyledItemTitle>
                <StyledChipContainer>
                  <Tooltip title={`${prevIsAdmin}`} placement="right">
                    <StyledChip label={`${prevIsAdmin}`} />
                  </Tooltip>
                  <ArrowForwardRoundedIcon sx={{ margin: '0 16px' }} fontSize="small" />
                  <Tooltip title={`${newIsAdmin}`} placement="right">
                    <StyledChip label={`${newIsAdmin}`} />
                  </Tooltip>
                </StyledChipContainer>
              </StyledItemContainer>
            )}
          </Box>
        </Box>
        <StyledButtonContainer>
          <Button
            sx={{ marginRight: '10px' }}
            variant="contained"
            onClick={onAccept}
            color="primary"
          >
            Accept
          </Button>
          <Button onClick={onDecline}>Decline</Button>
        </StyledButtonContainer>
      </StyledModalBody>
    </Modal>
  );
}

ModalWindow.propTypes = {
  modal: PropTypes.shape({
    isOpen: PropTypes.bool,
    prevGoal: PropTypes.shape({ key: PropTypes.string }),
    prevDepartment: PropTypes.shape({ title: PropTypes.string }),
    prevLevel: PropTypes.shape({ key: PropTypes.string }),
    prevEnglishLevel: PropTypes.shape({ value: PropTypes.string, display: PropTypes.string }),
    prevIsAdmin: PropTypes.bool,
    newGoal: PropTypes.shape({ key: PropTypes.string }),
    newDepartment: PropTypes.shape({ title: PropTypes.string }),
    newLevel: PropTypes.shape({ key: PropTypes.string }),
    newEnglishLevel: PropTypes.shape({ value: PropTypes.string, display: PropTypes.string }),
    newIsAdmin: PropTypes.bool,
  }).isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};
