import React, { useEffect, useState } from 'react';
import { Grid, Paper, styled } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import NoInfoFoundAlert from '../../../components/NoInfoFoundAlert';
import ReviewConstants from '../../../constants/review.constants';
import ApiService from '../../../services/api.service';
import ReviewsTable from './components/ReviewsTable';

const StyledPaper = styled(Paper)(() => ({
  width: '100%',
  marginBottom: '1em',
  padding: '2em 3em',
  borderRadius: '.15em',
}));

export default function ReviewsPage() {
  const history = useHistory();
  const { department } = useParams();

  const [reviews, setReviews] = useState([]);

  const handleOnClick = (review) => {
    history.push(`${history.location.pathname}/${review._id}?user=${review.user.email}`);
  };

  useEffect(() => {
    ApiService.head.getDepartmentReviews(department).then((data) => {
      setReviews(data);
    });
  }, [department]);

  return (
    <Grid mt={4} mr={4} ml={4}>
      <Grid container sx={{ justifyContent: 'center' }}>
        {!reviews.length ? (
          <Grid item xs={10} lg={6}>
            <NoInfoFoundAlert message={ReviewConstants.ERROR_MESSAGES.REVIEWS_ARE_NOT_FOUND} />
          </Grid>
        ) : (
          <Grid container>
            <Grid container component={StyledPaper} elevation={8} justifyContent="center">
              <ReviewsTable onReview={handleOnClick} reviews={reviews} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
