import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TreeView } from '@mui/lab';
import { Box, Button, Paper, styled, Typography, Grid, Chip } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { componentTheme } from '../../../Home/components/AllSkillsTable';
import SkillNodePreview from './SkillNodePreview';

const StyledTreeView = styled(TreeView)(() => ({
  maxHeight: '63vh',
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: '20px',
}));

const StyledBox = styled(Paper)(() => ({
  padding: '20px',
  width: '100%',
  height: '100%',
}));

const StyledTitle = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500,
}));

export default function SkillsTreePreview({ softSkillsData, hardSkillsData }) {
  const [expandedHardSkills, setExpandedHardSkills] = useState([]);
  const [expandedSoftSkills, setExpandedSoftSkills] = useState([]);

  const filteredHardSkills = useMemo(
    () => hardSkillsData.filter((skill) => skill.details.length),
    [hardSkillsData],
  );

  const filteredSoftSkills = useMemo(
    () => softSkillsData.filter((skill) => skill.details.length),
    [softSkillsData],
  );

  const hardSkillDetails = useMemo(() => {
    const skillNamesSet = new Set();
    hardSkillsData.forEach((skill) => {
      skill.details.forEach((detail) => {
        skillNamesSet.add(detail.name);
      });
    });
    return Array.from(skillNamesSet);
  }, [filteredHardSkills]);

  const softSkillDetails = useMemo(() => {
    const skillNamesSet = new Set();
    softSkillsData.forEach((skill) => {
      skill.details.forEach((detail) => {
        skillNamesSet.add(detail.name);
      });
    });
    return Array.from(skillNamesSet);
  }, [filteredSoftSkills]);

  const handleHardSkillsExpand = useCallback(() => {
    setExpandedHardSkills(
      expandedHardSkills.length ? [] : hardSkillsData.map((item) => item.itemId),
    );
  }, [expandedHardSkills.length, hardSkillsData]);

  const handleSoftSkillsExpand = useCallback(() => {
    setExpandedSoftSkills(
      expandedSoftSkills.length ? [] : softSkillsData.map((item) => item.itemId),
    );
  }, [expandedSoftSkills.length, softSkillsData]);

  const handleHardSkillItem = useCallback(
    (itemId) => {
      setExpandedHardSkills(
        expandedHardSkills.includes(itemId)
          ? expandedHardSkills.filter((skill) => skill !== itemId)
          : [...expandedHardSkills, itemId],
      );
    },
    [expandedHardSkills],
  );

  const handleSoftSkillItem = useCallback(
    (itemId) => {
      setExpandedSoftSkills(
        expandedSoftSkills.includes(itemId)
          ? expandedSoftSkills.filter((skill) => skill !== itemId)
          : [...expandedSoftSkills, itemId],
      );
    },
    [expandedSoftSkills],
  );

  const handleDetailLinkClick = useCallback((link) => {
    console.log(link);
  }, []);

  return (
    <ThemeProvider theme={componentTheme}>
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StyledBox elevation={3}>
              <StyledTreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                expanded={expandedHardSkills}
              >
                <Box width="100%">
                  <Box display="flex" justifyContent="space-between">
                    <StyledTitle>
                      Hard skills
                      <Chip label={hardSkillDetails.length} />
                    </StyledTitle>
                    <Button onClick={handleHardSkillsExpand}>
                      {expandedHardSkills.length ? 'Collapse' : 'Expand'}
                    </Button>
                  </Box>
                  {filteredHardSkills.map((node) => (
                    <SkillNodePreview
                      key={node.itemId}
                      node={node}
                      onClick={handleHardSkillItem}
                      onLinkClick={handleDetailLinkClick}
                    />
                  ))}
                </Box>
              </StyledTreeView>
            </StyledBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledBox elevation={3}>
              <StyledTreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                expanded={expandedSoftSkills}
              >
                <Box width="100%">
                  <Box display="flex" justifyContent="space-between">
                    <StyledTitle>
                      Soft skills
                      <Chip label={softSkillDetails.length} />
                    </StyledTitle>
                    <Button onClick={handleSoftSkillsExpand}>
                      {expandedSoftSkills.length ? 'Collapse' : 'Expand'}
                    </Button>
                  </Box>
                  {filteredSoftSkills.map((node) => (
                    <SkillNodePreview
                      key={node.itemId}
                      node={node}
                      onClick={handleSoftSkillItem}
                      onLinkClick={handleDetailLinkClick}
                    />
                  ))}
                </Box>
              </StyledTreeView>
            </StyledBox>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

const handleProps = {
  itemId: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.string).isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      successCriteria: PropTypes.string.isRequired,
      materialName: PropTypes.string.isRequired,
      materialType: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  skillType: PropTypes.string.isRequired,
  status: PropTypes.string,
};

SkillsTreePreview.propTypes = {
  softSkillsData: PropTypes.arrayOf(PropTypes.shape(handleProps)).isRequired,
  hardSkillsData: PropTypes.arrayOf(PropTypes.shape(handleProps)).isRequired,
};
