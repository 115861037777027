import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import weekday from 'dayjs/plugin/weekday';
import DashboardCards from './components/DashboardCards';
import DashboardLinkViews from './components/DashboardLinkViews/DashboardLinkViews';
import LinkViewsErrorBoundary from './components/DashboardLinkViews/LinkViewsErrorBoundary';
import DashboardReviews from './components/DashboardReviews';
import DashboardRowSector from './components/DashboardRowSector';
import DashboardTitle from './components/DashboardTitle';
import EmployeesActivityChart from './components/EmployeesActivityChart';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export default function HeadDashboardPage() {
  const [startPickerValue, setStartPickerValue] = useState(dayjs().subtract(1, 'month'));
  const [endPickerValue, setEndPickerValue] = useState(dayjs());

  return (
    <Grid container sx={{ padding: '48px 64px 0' }}>
      <DashboardRowSector>
        <DashboardTitle title="Dashboard" />
        <DashboardCards />
      </DashboardRowSector>

      <DashboardRowSector sx={{ marginTop: '48px', justifyContent: 'end' }}>
        <DatePicker.RangePicker
          onChange={(data) => {
            if (data) {
              const [start, end] = data;
              setStartPickerValue(start);
              setEndPickerValue(end);
            }
          }}
          defaultValue={[startPickerValue, endPickerValue]}
        />
      </DashboardRowSector>

      <DashboardRowSector sx={{ marginTop: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <DashboardTitle title="Task activity" />
            <EmployeesActivityChart
              startPickerValue={startPickerValue}
              endPickerValue={endPickerValue}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <DashboardTitle title="Most visited links" />
            <LinkViewsErrorBoundary>
              <DashboardLinkViews
                startPickerValue={startPickerValue}
                endPickerValue={endPickerValue}
              />
            </LinkViewsErrorBoundary>
          </Grid>
        </Grid>
      </DashboardRowSector>

      <DashboardRowSector sx={{ marginTop: '16px' }}>
        <DashboardTitle title="Recent reviews" />
        <DashboardReviews startPickerValue={startPickerValue} endPickerValue={endPickerValue} />
      </DashboardRowSector>
    </Grid>
  );
}
