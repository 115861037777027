import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Helmet } from 'react-helmet-async';
import BreadcrumbsComponent from '../components/BreadcrumbsComponent';
import AdminTopBar from '../sections/AdminTopBar';

const theme = createTheme({ palette: { primary: { main: '#7553EA' } } });

export default function AdminBasicPage({ children, crumbs, title }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={theme}>
          <AdminTopBar />
          <BreadcrumbsComponent crumbs={crumbs} />
          {children}
        </ThemeProvider>
      </SnackbarProvider>
    </>
  );
}

AdminBasicPage.propTypes = {
  children: PropTypes.node.isRequired,
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};
