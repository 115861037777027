import { useEffect } from 'react';
import axios from '../services/http.service';
import TokenService from '../services/token.service';

export default function HydraCallback() {
  async function getTokens() {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const code = params.get('code');

    if (!code) {
      window.location.href = '/login';
    }

    const { accessToken, refreshToken } = await axios
      .get(`/auth/hydra-callback?code=${code}`)
      .catch(() => {
        window.location.href = '/login';
      });

    TokenService.updateLocalAccessToken(accessToken);
    TokenService.updateLocalRefreshToken(refreshToken);

    window.location.href = '/home';
  }
  useEffect(() => {
    getTokens();
  }, []);

  return <div />;
}
