import React, { useState, useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ProfileStepper from '../../components/stepper/ProfileStepper';
import StepperContext from '../../hooks/context/StepperContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7553EA',
    },
  },
});

export default function Avatar() {
  const [isLoaded, setIsLoaded] = useState(false);

  const memo = useMemo(() => ({ isLoaded, setIsLoaded }), [isLoaded, setIsLoaded]);

  return (
    <ThemeProvider theme={theme}>
      <StepperContext.Provider value={memo}>
        <ProfileStepper />
      </StepperContext.Provider>
    </ThemeProvider>
  );
}
