import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import growImage from '../../assets/images/logo-purple.svg';
import Loading from '../../components/Loading';
import axios from '../../services/http.service';
import HeadBasicPage from '../../templates/HeadBasicPage';

const DEPARTMENT_MENU_LIST = [
  {
    title: 'Grow',
    route: 'grow',
  },
  {
    title: 'Users',
    route: 'users',
  },
  {
    title: 'Levels',
    route: 'levels',
  },
  {
    title: 'Reviews',
    route: 'reviews',
  },
  {
    title: 'Review History',
    route: 'review-history',
  },
  {
    title: 'Skills',
    route: 'skills',
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
  },
];

const StyledItem = styled(Paper)(({ theme }) => ({
  marginBottom: '1em',
  borderRadius: '.15em .15em 0 0',
  minWidth: '300px',
  [theme.breakpoints.up('md')]: {
    minWidth: '400px',
  },
}));

const DepartmentsAccordion = styled(({ isHideShadow, ...props }) => <Accordion {...props} />)(
  ({ theme, isHideShadow }) =>
    isHideShadow
      ? {
          '&::after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '20px',
            zIndex: 1,
            backgroundColor: theme.palette.background.paper,
            top: '-5px',
          },
        }
      : {},
);

export default function HeadDepartmentsPage({ crumbs, title }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState([]);

  const saveToStorage = (event, isExpanded, department) => {
    const objectTabs = localStorage.getItem('activeTabs')
      ? JSON.parse(localStorage.getItem('activeTabs'))
      : {};
    objectTabs[department._id] = isExpanded;
    localStorage.setItem('activeTabs', JSON.stringify(objectTabs));
  };

  const getFromStorage = (department) => {
    try {
      const activeTabs = localStorage.getItem('activeTabs');
      if (!activeTabs) {
        return null;
      }
      const parsedActiveTabs = JSON.parse(activeTabs);
      if (!parsedActiveTabs[department._id]) {
        return null;
      }

      return JSON.parse(activeTabs)[department._id];
    } catch (err) {
      console.error(err);

      return null;
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        await axios({
          method: 'GET',
          url: 'head/departments/',
        }).then((data) => {
          setDepartments(data);
          setIsLoading(false);
        });
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    }

    fetchData();
  }, []);

  const handleChange = (department) => (page) => {
    history.push(`/head/departments/${department.name}/${page}`);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <HeadBasicPage crumbs={crumbs} title={title}>
      <Grid container justifyContent="center">
        <Grid item mt={5} mb={3} xs={10} sm={8} md={6} lg={4}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <StyledItem
                sx={{
                  mb: 0,
                  position: 'relative',
                }}
                elevation={3}
              >
                <Grid padding={1} container justifyContent="center">
                  <Grid my={2}>
                    <Box component="img" alt="logo" src={growImage} width={140} />
                  </Grid>
                </Grid>
              </StyledItem>
            </Grid>
            <Grid item xs={12}>
              {departments.map((department, index) => (
                <DepartmentsAccordion
                  defaultExpanded={getFromStorage(department)}
                  isHideShadow={!index}
                  elevation={3}
                  square
                  key={department.name}
                  onChange={(event, isExpanded) => saveToStorage(event, isExpanded, department)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-controls"
                    id="panel-header"
                  >
                    <Typography>{department.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <Grid item xs={12}>
                      <List sx={{ p: 0 }}>
                        <Divider />
                        {DEPARTMENT_MENU_LIST.map((el) => (
                          <React.Fragment key={el.title}>
                            <ListItem
                              disablePadding
                              button
                              onClick={() => handleChange(department)(el.route)}
                            >
                              <ListItemButton>
                                <ListItemText primary={el.title} />
                                <NavigateNextIcon fontSize="small" />
                              </ListItemButton>
                            </ListItem>
                            <Divider />
                          </React.Fragment>
                        ))}
                      </List>
                    </Grid>
                  </AccordionDetails>
                </DepartmentsAccordion>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HeadBasicPage>
  );
}

HeadDepartmentsPage.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};
