import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, MenuItem } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

export default function FinishReviewPanel({
  isAllApproved,
  isAllFinished,
  isAllApprovedWithPercentage,
  isLastLevel,
  onArchive,
  onDelete,
  onNextGoalClick,
}) {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Archive');
  const anchorRef = React.useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (isAllApproved || isAllApprovedWithPercentage) {
      setSelectedItem('Set Next Goal');
    } else {
      setSelectedItem('Archive');
    }
  }, [isAllApproved, isAllApprovedWithPercentage]);

  const handleMenuItemClick = (value) => {
    setSelectedItem(value);

    setOpen(false);
  };

  const onClickAll = () => {
    if (
      (isAllApproved || isAllApprovedWithPercentage) &&
      selectedItem !== 'Archive' &&
      selectedItem !== 'Delete'
    ) {
      onNextGoalClick();
    } else if (selectedItem !== 'Delete') {
      onArchive();
    } else {
      onDelete();
    }
  };

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
        <Button
          disabled={selectedItem === 'Archive' && !isAllFinished}
          onClick={onClickAll}
          variant="contained"
        >
          {selectedItem}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    disabled={!isAllFinished}
                    onClick={() => handleMenuItemClick('Archive')}
                    variant="contained"
                  >
                    Archive
                  </MenuItem>
                  <MenuItem
                    disabled={!isAllApproved && !isAllApprovedWithPercentage && !isLastLevel}
                    onClick={() => handleMenuItemClick('Set Custom Goal')}
                    variant="contained"
                  >
                    Set Custom Goal
                  </MenuItem>
                  <MenuItem
                    disabled={!isAllApproved || !isAllApprovedWithPercentage || isLastLevel}
                    onClick={() => handleMenuItemClick('Set Next Goal')}
                    variant="contained"
                  >
                    Set Next Goal
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('Delete')} variant="contained">
                    Delete
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

FinishReviewPanel.propTypes = {
  isAllApproved: PropTypes.bool.isRequired,
  isAllFinished: PropTypes.bool.isRequired,
  isAllApprovedWithPercentage: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isLastLevel: PropTypes.bool.isRequired,
  goals: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ),
  onDelete: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onNextGoalClick: PropTypes.func.isRequired,
};

FinishReviewPanel.defaultProps = { goals: [] };
