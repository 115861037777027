import React from 'react';
import PropTypes from 'prop-types';
import HeadsetOutlinedIcon from '@mui/icons-material/HeadsetOutlined';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import OpenInBrowserOutlinedIcon from '@mui/icons-material/OpenInBrowserOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import { styled, Tooltip } from '@mui/material';
import { capitalize } from 'lodash';
import materialTypes from '../../../constants/materialTypes';

const StyledTooltip = styled(Tooltip)(() => ({ marginRight: '5px' }));

export default function MaterialTypeIcon({ type }) {
  const capitalizedType = capitalize(type);
  switch (type) {
    case materialTypes.video:
      return (
        <StyledTooltip title={capitalizedType}>
          <VideoLibraryOutlinedIcon fontSize="medium" />
        </StyledTooltip>
      );
    case materialTypes.audio:
      return (
        <StyledTooltip title={capitalizedType}>
          <HeadsetOutlinedIcon fontSize="medium" />
        </StyledTooltip>
      );
    case materialTypes.site:
      return (
        <StyledTooltip title={capitalizedType}>
          <OpenInBrowserOutlinedIcon fontSize="medium" />
        </StyledTooltip>
      );
    case materialTypes.book:
      return (
        <StyledTooltip title={capitalizedType}>
          <ImportContactsIcon fontSize="medium" />
        </StyledTooltip>
      );
    case materialTypes.training:
      return (
        <StyledTooltip title={capitalizedType}>
          <ModelTrainingIcon fontSize="medium" />
        </StyledTooltip>
      );
    case materialTypes.other:
      return (
        <StyledTooltip title={capitalizedType}>
          <InsertDriveFileIcon fontSize="medium" />
        </StyledTooltip>
      );
    default:
      return null;
  }
}

MaterialTypeIcon.propTypes = { type: PropTypes.string.isRequired };
