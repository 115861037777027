import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DriveFolderUploadOutlined,
  InsertDriveFileOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { Dialog, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import ContentTypes from '../../../constants/content-types';
import FileRegexps from '../../../constants/file-regexps.constants';
import FileTypes from '../../../constants/file-types.constants';
import apiService from '../../../services/api.service';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function ConfirmSkillPopUp({
  open,
  setOpen,
  type,
  itemId,
  input,
  setIsOpenPreview,
  setFileContent,
  setFileContentType,
  uploadedItems,
  setUploadedItems,
}) {
  const [previewFile, setPreviewFile] = useState({ type: '', content: '' });
  const [comment, setComment] = useState('');
  const [isCommentChanged, setIsCommentChanged] = useState(false);

  const isFileImage = (fileName) => FileRegexps.IMG.test(fileName);

  const isReadableFile = (fileName) => FileRegexps.READABLE.test(fileName);

  const handleClose = () => {
    setOpen(false);
    setIsOpenPreview(false);
    setFileContent('');
    setFileContentType('');
    setComment('');
    setIsCommentChanged(false);
    setPreviewFile({ type: '', content: '' });
  };

  const handleConfirm = () => {
    if (type === ContentTypes.file) {
      const formData = new FormData();
      formData.append('file', previewFile.content);

      apiService.developer.uploadAttachment(formData).then((res) => {
        apiService.developer.sendGoalContent(itemId, res.Location);

        setUploadedItems({
          ...uploadedItems,
          [itemId]: res.Location,
        });
        setOpen(false);
        setIsOpenPreview(false);
        setFileContent('');
        setFileContentType('');
        setComment('');
        setIsCommentChanged(false);
        setPreviewFile({ type: '', content: '' });
      });
    } else if (type === ContentTypes.comment) {
      apiService.developer.sendGoalContent(itemId, comment);

      setUploadedItems({
        ...uploadedItems,
        [itemId]: comment,
      });
      setOpen(false);
      setIsOpenPreview(false);
      setFileContent('');
      setFileContentType('');
      setComment('');
      setIsCommentChanged(false);
      setPreviewFile({ type: '', content: '' });
    }
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="dialog-congratulation"
      maxWidth="sm"
    >
      <DialogContent
        id="alert-dialog-slide-description"
        sx={{
          width: type === ContentTypes.comment ? '400px' : 'inherit',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '16px 8px 0 8px',
        }}
      >
        {type === ContentTypes.comment && (
          <TextField
            placeholder="Comment"
            multiline
            fullWidth
            value={(isCommentChanged ? comment : input) || ''}
            onChange={(event) => {
              if (!isCommentChanged) {
                setIsCommentChanged(true);
              }
              setComment(event.target.value);
            }}
            inputProps={{ maxLength: 400 }}
          />
        )}
        {type === ContentTypes.file && (
          <Button
            component="label"
            variant="contained"
            startIcon={<DriveFolderUploadOutlined />}
            sx={{
              maxWidth: '150px',
            }}
          >
            Upload file
            <VisuallyHiddenInput
              type="file"
              id="goal-file-input"
              onChange={(event) => {
                const [file] = event.target.files;

                if (file) {
                  setPreviewFile({
                    type: isFileImage(file.name) ? FileTypes.image : FileTypes.file,
                    content: file,
                  });
                }
              }}
            />
          </Button>
        )}
        {type === ContentTypes.file && input && !previewFile.type.length && (
          <Button startIcon={<InsertDriveFileOutlined />} sx={{ overflow: 'hidden' }}>
            {input.split('/').slice(-1)}
          </Button>
        )}
        {!!previewFile.type.length && (
          <Button startIcon={<InsertDriveFileOutlined />}>{previewFile.content.name}</Button>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Box>
          {
            /* eslint-disable-next-line consistent-return */
            (() => {
              const filename =
                uploadedItems[itemId] && uploadedItems[itemId].split('/').slice(-1)[0];
              const isReadableUploaded = !!uploadedItems[itemId] && isReadableFile(filename);

              if (
                (previewFile.type === FileTypes.file && isReadableFile(previewFile.content.name)) ||
                (isReadableFile(input) && !previewFile.type.length) ||
                isReadableUploaded
              ) {
                return (
                  <IconButton
                    onClick={() => {
                      const reader = new FileReader();

                      reader.onload = (event) => {
                        setFileContent(event.target.result);
                        setFileContentType(FileTypes.file);
                        setIsOpenPreview(true);
                      };

                      if (previewFile.content) {
                        reader.readAsText(previewFile.content);
                      } else {
                        fetch(input)
                          .then((res) => res.blob())
                          .then((res) => {
                            reader.readAsText(res);
                          });
                      }
                    }}
                  >
                    <VisibilityOutlined color="primary" />
                  </IconButton>
                );
              }

              const isUploadedImage = !!uploadedItems[itemId] && isFileImage(filename);

              if (
                previewFile.type === FileTypes.image ||
                (isFileImage(input) && !previewFile.type.length) ||
                isUploadedImage
              ) {
                return (
                  <IconButton
                    onClick={() => {
                      setFileContent(
                        previewFile.content ? URL.createObjectURL(previewFile.content) : input,
                      );
                      setFileContentType(FileTypes.image);
                      setIsOpenPreview(true);
                    }}
                  >
                    <VisibilityOutlined color="primary" />
                  </IconButton>
                );
              }
            })()
          }
        </Box>
        <Button disabled={!previewFile.type.length && !comment.length} onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmSkillPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  type: PropTypes.string,
  itemId: PropTypes.string,
  input: PropTypes.string,
  setIsOpenPreview: PropTypes.func.isRequired,
  setFileContent: PropTypes.func.isRequired,
  setFileContentType: PropTypes.func.isRequired,
  uploadedItems: PropTypes.shape({}).isRequired,
  setUploadedItems: PropTypes.func.isRequired,
};

ConfirmSkillPopUp.defaultProps = {
  type: null,
  itemId: null,
  input: null,
};
