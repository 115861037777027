import React from 'react';
import PropTypes from 'prop-types';
import { Box, Autocomplete, styled, TextField, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: '150px',
          margin: '5px 0',
        },
        input: { padding: '8.5px' },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: { padding: '1px 0' },
      },
    },
  },
  palette: {
    primary: { main: '#7553EA' },
  },
});

const StyledTextField = styled(TextField)(() => ({ padding: '5px' }));

export default function HeadUserAutocomplete({
  options,
  value,
  setValue,
  optionLabel,
  onOptionDisable,
}) {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Autocomplete
          isOptionEqualToValue={(option) => option._id === value._id}
          getOptionLabel={optionLabel}
          options={options}
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          disableClearable
          getOptionDisabled={onOptionDisable}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: params.InputProps.endAdornment,
              }}
            />
          )}
        />
      </Box>
    </ThemeProvider>
  );
}

HeadUserAutocomplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
  setValue: PropTypes.func.isRequired,
  optionLabel: PropTypes.func.isRequired,
  onOptionDisable: PropTypes.func,
};

HeadUserAutocomplete.defaultProps = { onOptionDisable: () => null };
