import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Chip, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

export default function StyledAutocomplete({
  label,
  keyName,
  options,
  value,
  setValue,
  setIsLoading,
  handleInputChange,
}) {
  const [autocompleteWidth, setAutocompleteWidth] = useState(0);
  const autocompleteRef = useRef(null);

  const autocompleteContainerPadding = 70;
  const maxTagWidth = 100;
  const plusNWidth = 30;
  const resultWidth = autocompleteWidth - autocompleteContainerPadding - plusNWidth;
  const maxTagsAtRow = Math.floor(resultWidth / maxTagWidth);

  useEffect(() => {
    if (autocompleteRef.current) {
      setAutocompleteWidth(autocompleteRef.current.offsetWidth);
    }
  }, [autocompleteRef.current, value]);

  return (
    <div ref={autocompleteRef}>
      <Autocomplete
        multiple
        limitTags={maxTagsAtRow}
        options={options}
        value={value}
        disableCloseOnSelect
        getOptionLabel={(option) => option[keyName]}
        onChange={(event, v) => {
          setValue(v);
          setIsLoading(true);
        }}
        renderInput={(params) => (
          <TextField {...params} size="small" label={label} onChange={handleInputChange} />
        )}
        renderOption={(propsLi, option, { selected }) => (
          <li {...propsLi}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option[keyName]}
          </li>
        )}
        renderTags={(v, getTagProps, ownerState) => {
          if (ownerState.focused) {
            return v.map((option, index) => (
              <Chip
                style={{ maxWidth: maxTagWidth }}
                {...getTagProps({ index })}
                key={option[keyName]}
                label={option[keyName]}
              />
            ));
          }

          return (
            <>
              {v.slice(0, maxTagsAtRow).map((option, index) => (
                <Chip
                  style={{ maxWidth: maxTagWidth }}
                  {...getTagProps({ index })}
                  key={option[keyName]}
                  label={option[keyName]}
                />
              ))}
              {v.length > maxTagsAtRow && `+${v.length - maxTagsAtRow}`}
            </>
          );
        }}
      />
    </div>
  );
}

StyledAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  setValue: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func,
};

StyledAutocomplete.defaultProps = {
  handleInputChange: () => {},
};
