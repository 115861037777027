const useNotificationScroll = (event) => {
  const { scrollTop, scrollHeight, clientHeight } = event.target;
  const isScrollingTop = scrollTop === 0;
  const isScrollingBottom = scrollTop + clientHeight === scrollHeight;
  const dialogBody = event.currentTarget;

  if (isScrollingTop && isScrollingBottom) {
    dialogBody.classList.add('scrolling');
  } else {
    dialogBody.classList.remove('scrolling');
  }
};

export default useNotificationScroll;
