import React from 'react';
import { Skeleton, TableCell, TableRow } from '@mui/material';

export default function LinkViewsTableRowSkeleton() {
  return (
    <TableRow>
      <TableCell>
        <Skeleton sx={{ minHeight: 22 }} />
      </TableCell>
      <TableCell>
        <Skeleton sx={{ minHeight: 22 }} />
      </TableCell>
    </TableRow>
  );
}
