import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TreeView } from '@mui/lab';
import { styled } from '@mui/material';
import localStorageConstant from '../../../constants/localStorage.constants';
import { setLocalStorageItem, getLocalStorageItem } from '../../../utils/localStorage';
import UserSkillsDetails from './UserSkillDetails';

const StyledTreeView = styled(TreeView)(() => ({
  maxHeight: '76vh',
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: '20px',
}));

const useLocalStorage = (item) => {
  const [localExpanded, setLocalExpanded] = useState(item || []);
  setLocalStorageItem(localStorageConstant.LOCAL_EXPANDED, localExpanded);

  return [localExpanded, setLocalExpanded];
};

const updatedLocalStorageItem = (skillsData, localStorageItem) => {
  const itemIds = skillsData.map(({ itemId }) => itemId);
  return localStorageItem.filter((itemId) => itemIds.includes(itemId));
};

export default function UserSkillsView({
  skillsData,
  admin,
  onApprove,
  onDecline,
  onLinkClick,
  isLoading,
  isDisabled,
  expanded,
  isInteractive,
  isHomePage,
  isReviewsPage,
  isReviewedPage,
}) {
  const localStorageItem = getLocalStorageItem(localStorageConstant.LOCAL_EXPANDED);
  const updatedItem = updatedLocalStorageItem(skillsData, localStorageItem);
  const [localExpanded, setLocalExpanded] = useLocalStorage(updatedItem);
  const [skillsDataLength, setSkillsDataLength] = useState(Infinity);
  const [previousValues, setPreviousValues] = useState([]);

  const onTreeItemClick = (itemId) => {
    setLocalExpanded(
      localExpanded.includes(itemId)
        ? localExpanded.filter((id) => id !== itemId)
        : [...localExpanded, itemId],
    );
  };

  const onSetDefaultExpanded = useCallback(
    (itemId) => {
      if (!localExpanded.includes(itemId)) {
        setLocalExpanded([...localExpanded, itemId]);
      }
    },
    [isInteractive ? localExpanded : expanded, setLocalExpanded],
  );

  const onSetHomeDefaultExpanded = useCallback(() => {
    const [item] = skillsData;

    setSkillsDataLength(skillsData.length);

    if (item && skillsData.length > skillsDataLength && skillsData[0] !== previousValues[0]) {
      setPreviousValues(skillsData);
      onSetDefaultExpanded(item.itemId);
    }
  }, [skillsData, onSetDefaultExpanded]);

  useEffect(() => {
    if (!isHomePage) {
      onSetDefaultExpanded();
    } else {
      onSetHomeDefaultExpanded(expanded);
    }
  }, [skillsData, expanded, isHomePage ? onSetHomeDefaultExpanded : onSetDefaultExpanded]);

  return (
    <StyledTreeView
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      expanded={localExpanded}
    >
      {!isLoading &&
        skillsData.map((node) => (
          <UserSkillsDetails
            admin={admin}
            key={node.itemId}
            node={node}
            onClick={onTreeItemClick}
            onApprove={onApprove}
            onDecline={onDecline}
            isDisabled={isDisabled}
            isInteractive={isInteractive}
            isHomePage={isHomePage}
            onLinkClick={onLinkClick}
            isReviewsPage={isReviewsPage}
            isReviewedPage={isReviewedPage}
          />
        ))}
    </StyledTreeView>
  );
}

UserSkillsView.propTypes = {
  skillsData: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(PropTypes.string).isRequired,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          itemId: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          successCriteria: PropTypes.string.isRequired,
          materialName: PropTypes.string.isRequired,
          materialType: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
          level: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      name: PropTypes.string.isRequired,
      skillType: PropTypes.string.isRequired,
      status: PropTypes.string,
    }).isRequired,
  ).isRequired,
  admin: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onApprove: PropTypes.func,
  onDecline: PropTypes.func,
  expanded: PropTypes.string,
  isInteractive: PropTypes.bool,
  onLinkClick: PropTypes.func,
  isHomePage: PropTypes.bool,
  isReviewsPage: PropTypes.bool,
  isReviewedPage: PropTypes.bool,
};

UserSkillsView.defaultProps = {
  admin: false,
  isLoading: false,
  isDisabled: false,
  expanded: null,
  onApprove: () => null,
  onDecline: () => null,
  onLinkClick: () => null,
  isInteractive: true,
  isHomePage: false,
  isReviewsPage: false,
  isReviewedPage: false,
};
