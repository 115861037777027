import { forwardRef, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Slide,
  Snackbar,
  Tab,
  Tabs,
  Toolbar,
  styled,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import Loading from '../../../../components/Loading';
import axios from '../../../../services/http.service';
import GrowPageContext from '../contexts/GrowPageContext';
import TabsWrapper from '../styled-components/TabsWrapper';
import TreeViewFormWrapper from '../styled-components/TreeViewFormWrapper';
import DialogTreeViewForm from './DialogTreeViewForm';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={750} />;
});

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderBottom: `2px solid ${theme.palette.primary.main}`,
}));

export default function DefaultTasksDialog({
  isOpened,
  commonHardSkillTree,
  commonSoftSkillTree,
  setCommonHardSkillTree,
  setCommonSoftSkillTree,
  saved,
  setSaved,
  closeSnackbar,
  enqueueSnackbar,
  activeTab,
}) {
  const { setIsDialogOpened, department } = useContext(GrowPageContext);

  const [alertOpen, setAlertOpen] = useState(false);
  const [shallowHardSkillTree, setShallowHardSkillTree] = useState([]);
  const [shallowSoftSkillTree, setShallowSoftSkillTree] = useState([]);
  const [defaultHardSkillTree, setDefaultHardSkillTree] = useState([]);
  const [defaultSoftSkillTree, setDefaultSoftSkillTree] = useState([]);
  const [replacedTasks, setReplacedTasks] = useState([]);
  const [replacedNodes, setReplacedNodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getPath = (node, data) => {
    const path = [node.name.replace(/ /g, '-')];

    const recursion = (id) => {
      const parentIndex = data.findIndex((el) => el.children.includes(id));

      if (parentIndex > -1) {
        path.unshift(data[parentIndex].name.replace(/ /g, '-'));
        recursion(data[parentIndex].itemId);
      }
    };

    recursion(node.itemId);

    return path.join('/');
  };

  const removeReferencesAndAddPath = (data) =>
    data.map((el) => ({
      ...el,
      children: [...el.children],
      details: el.details.map((detail) => ({ ...detail })),
      path: getPath(el, data),
    }));

  useEffect(() => {
    setShallowHardSkillTree(removeReferencesAndAddPath(commonHardSkillTree));
    setShallowSoftSkillTree(removeReferencesAndAddPath(commonSoftSkillTree));
  }, [commonHardSkillTree, commonSoftSkillTree, saved]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: `head/grow/${department}/default`,
    })
      .then((res) => {
        setDefaultHardSkillTree(res.hard);
        setDefaultSoftSkillTree(res.soft);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }, [commonHardSkillTree, commonSoftSkillTree, saved]);

  const handleClose = () => setIsDialogOpened(false);

  const generateSnackbarKey = () => uuid();

  const handleCloseSnackbar = (key) => () => {
    closeSnackbar(key);
  };

  const action = (key) => (
    <IconButton sx={{ color: '#FFFFFF' }} onClick={handleCloseSnackbar(key)}>
      <CloseIcon />
    </IconButton>
  );

  const validateField = (field, fieldName, detailName, itemId) => {
    const message = detailName
      ? `Invalid data in field "${fieldName}" of skill "${detailName}".`
      : `Invalid data in field "${fieldName}" of skill "${itemId}" .`;
    try {
      if (!field.trim()) {
        generateSnackbarKey();
        enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: null,
          action,
        });
        return true;
      }
      return false;
    } catch (error) {
      generateSnackbarKey();
      enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: null,
        action,
      });
      return true;
    }
  };

  const validateFields = (arr) => {
    let errorFound = false;

    arr.forEach((item) => {
      const { name, details } = item;

      errorFound = validateField(name, 'name', item.itemId) || errorFound;

      details.forEach((detail) => {
        errorFound =
          validateField(detail.successCriteria, 'successCriteria', detail.name, item.itemId) ||
          errorFound;
        errorFound =
          validateField(detail.materialName, 'materialName', detail.name, item.itemId) ||
          errorFound;
        errorFound =
          validateField(detail.materialType, 'materialType', detail.name, item.itemId) ||
          errorFound;
        errorFound = validateField(detail.link, 'link', detail.name, item.itemId) || errorFound;
      });
    });

    return errorFound;
  };

  const prepareData = (array) =>
    array.map(({ status, path, ...node }) => ({
      ...node,
      name: node.name.trim(),
      details: node.details.map(({ status: detailStatus, path: detailPath, ...detail }) => ({
        ...detail,
        name: detail.name.trim(),
        successCriteria: detail.successCriteria.trim(),
        materialName: detail.materialName.trim(),
        materialType: detail.materialType.trim().toLowerCase(),
        link: detail.link.trim(),
        contentType: !detail.contentType ? null : detail.contentType.trim().toLowerCase(),
      })),
    }));

  const handleSave = (treeData, type) => {
    const isError = validateFields(treeData);

    if (isError) {
      setAlertOpen(true);
      return false;
    }

    const preparedData = prepareData(treeData);

    axios({
      method: 'POST',
      url: 'head/grow',
      data: {
        treeData: preparedData,
        department,
        skillType: type,
      },
    })
      .then((response) => {
        if (type === 'hard') {
          setCommonHardSkillTree(response);
        } else {
          setCommonSoftSkillTree(response);
        }
        setSaved(true);
      })
      .catch((error) => {
        generateSnackbarKey();
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: null,
          action,
        });
      });

    return true;
  };

  return (
    <Dialog fullScreen open={isOpened} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              if (activeTab === 0) {
                handleSave(shallowHardSkillTree, 'hard');
              } else {
                handleSave(shallowSoftSkillTree, 'soft');
              }
              handleClose();
            }}
          >
            save
          </Button>
        </Toolbar>
      </AppBar>
      <Box marginLeft={2} marginRight={2}>
        <TabsWrapper>
          <Tabs value={activeTab} style={{ display: 'flex' }}>
            {activeTab === 0 && <StyledTab label="Hard Skills" />}
            {activeTab === 1 && <StyledTab label="Soft Skills" />}
          </Tabs>
        </TabsWrapper>
        <TreeViewFormWrapper
          sx={{
            padding: activeTab === 2 ? 1 : { xs: '1em 2em', md: '2em 3em' },
            justifyContent: 'space-around',
          }}
          elevation={8}
          container="true"
        >
          {isLoading ? (
            <Box display="flex" justifyContent="center">
              <Loading />
            </Box>
          ) : (
            <Box>
              {activeTab === 0 && (
                <DialogTreeViewForm
                  treeData={shallowHardSkillTree}
                  setTreeData={setShallowHardSkillTree}
                  defaultTreeData={defaultHardSkillTree}
                  setDefaultTreeData={setDefaultHardSkillTree}
                  replacedTasks={replacedTasks}
                  setReplacedTasks={setReplacedTasks}
                  replacedNodes={replacedNodes}
                  setReplacedNodes={setReplacedNodes}
                />
              )}
              {activeTab === 1 && (
                <DialogTreeViewForm
                  treeData={shallowSoftSkillTree}
                  setTreeData={setShallowSoftSkillTree}
                  defaultTreeData={defaultSoftSkillTree}
                  setDefaultTreeData={setDefaultSoftSkillTree}
                  replacedTasks={replacedTasks}
                  setReplacedTasks={setReplacedTasks}
                  replacedNodes={replacedNodes}
                  setReplacedNodes={setReplacedNodes}
                />
              )}
            </Box>
          )}
        </TreeViewFormWrapper>
      </Box>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity="error" sx={{ width: '100%' }}>
          All fields are required!
        </Alert>
      </Snackbar>
      <Snackbar open={saved} autoHideDuration={6000} onClose={() => setSaved(false)}>
        <Alert onClose={() => setSaved(false)} severity="success" sx={{ width: '100%' }}>
          Saved!
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

const skillTree = {
  itemId: PropTypes.string,
  name: PropTypes.string,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      successCriteria: PropTypes.string,
      materialName: PropTypes.string,
      materialType: PropTypes.string,
      link: PropTypes.string,
      level: PropTypes.string,
    }),
  ),
  children: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DefaultTasksDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  commonHardSkillTree: PropTypes.arrayOf(PropTypes.shape(skillTree)).isRequired,
  commonSoftSkillTree: PropTypes.arrayOf(PropTypes.shape(skillTree)).isRequired,
  setCommonHardSkillTree: PropTypes.func.isRequired,
  setCommonSoftSkillTree: PropTypes.func.isRequired,
  saved: PropTypes.bool.isRequired,
  setSaved: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};
