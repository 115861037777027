import * as React from 'react';
import PropTypes from 'prop-types';
import { BigHead } from '@bigheads/core';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Tooltip } from 'antd';

export default function UserProfileCard({ userData, approvedSkills, isPersonalTasks }) {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 380,
      }}
    >
      <Card variant="outlined">
        <CardContent>
          <Box display="flex" alignItems="center">
            <Box minWidth="75px" width="100%" sx={{ maxHeight: 115, maxWidth: 115 }}>
              <BigHead {...userData.avatar} />
            </Box>
            <Box>
              <Typography variant="h6" component="div">
                {userData.firstName} {userData.lastName}
              </Typography>
              <Tooltip title={userData.email} arrow>
                <Typography
                  color="text.secondary"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px',
                  }}
                >
                  {userData.email}
                </Typography>
              </Tooltip>
              {!isPersonalTasks && (
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: 'Roboto',
                      marginRight: '10px',
                    }}
                  >
                    Approved skills:
                  </Typography>
                  <Typography variant="body1">{approvedSkills}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

UserProfileCard.propTypes = {
  userData: PropTypes.shape({
    avatar: PropTypes.shape({}).isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  approvedSkills: PropTypes.number.isRequired,
  isPersonalTasks: PropTypes.bool.isRequired,
};
