import PropTypes from 'prop-types';
import { Box, Modal, Grid } from '@mui/material';
import { BigHead } from '../../lib/core.cjs.development';
import Loading from '../Loading';
import { StyledButton, StyledButtonContainer } from '../ReviewRecordModal/ReviewRecordModal.styles';
import { StyledModalBody } from './RegenerateAvatarModal.styles';

export default function RegenerateAvatarModal({
  isOpen,
  onClose,
  onSave,
  isSaveDisabled,
  isSaveLoading,
  gender,
  userAvatar,
  generateAvatar,
  department,
}) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <StyledModalBody>
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <Grid
            sx={{
              width: 340,
              mt: '5px',
              position: 'relative',
            }}
          >
            <BigHead {...userAvatar} />
          </Grid>
          <Box>
            <StyledButtonContainer>
              <StyledButton onClick={() => generateAvatar(department, gender)} variant="contained">
                Regenerate
              </StyledButton>
            </StyledButtonContainer>
            <StyledButtonContainer>
              <StyledButton
                onClick={onSave}
                variant="contained"
                disabled={isSaveDisabled || isSaveLoading}
              >
                {isSaveLoading ? <Loading /> : 'Save'}
              </StyledButton>
            </StyledButtonContainer>
          </Box>
        </Box>
      </StyledModalBody>
    </Modal>
  );
}

const userAvatarPropTypes = PropTypes.shape({
  skinTone: PropTypes.string.isRequired,
  eyes: PropTypes.string.isRequired,
  eyebrows: PropTypes.string.isRequired,
  mouth: PropTypes.string.isRequired,
  hair: PropTypes.string.isRequired,
  facialHair: PropTypes.string.isRequired,
  clothing: PropTypes.string.isRequired,
  accessory: PropTypes.string.isRequired,
  graphic: PropTypes.string.isRequired,
  hat: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  hairColor: PropTypes.string.isRequired,
  clothingColor: PropTypes.string.isRequired,
  circleColor: PropTypes.string.isRequired,
  lipColor: PropTypes.string.isRequired,
  hatColor: PropTypes.string.isRequired,
  faceMaskColor: PropTypes.string.isRequired,
  mask: PropTypes.bool.isRequired,
  faceMask: PropTypes.bool.isRequired,
  lashes: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
});

RegenerateAvatarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  gender: PropTypes.string.isRequired,
  isSaveDisabled: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  userAvatar: userAvatarPropTypes.isRequired,
  generateAvatar: PropTypes.func.isRequired,
  department: PropTypes.string.isRequired,
};

RegenerateAvatarModal.defaultProps = {
  isSaveDisabled: false,
  isSaveLoading: false,
};
