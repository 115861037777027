import React from 'react';
import PropTypes from 'prop-types';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { Modal, Box, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import reviewConstants from '../../constants/review.constants';
import {
  StyledButton,
  StyledButtonContainer,
  StyledModalBody,
  StyledModalTitle,
  StyledTextField,
} from './ReviewRecordModal.styles';

export default function ReviewRecordModal({
  linkValue,
  handleLinkInput,
  isOpen,
  onSave,
  onClose,
  onClear,
  isInputError,
  isSaveDisabled,
  isSaveLoading,
}) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <StyledModalBody sx={{ width: { xs: '90%', sm: '70%', md: '30%' } }}>
        <Box>
          <StyledModalTitle variant="h6" component="h4">
            Review record
          </StyledModalTitle>
          <Box>
            <StyledTextField
              sx={{ width: '100%' }}
              value={linkValue}
              variant="outlined"
              onChange={handleLinkInput}
              label="Link"
              error={isInputError}
              helperText={isInputError && reviewConstants.ERROR_MESSAGES.URL_IS_INVALID}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={onClear} size="small" m={0}>
                      <CancelSharpIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <StyledButtonContainer>
          <StyledButton
            onClick={onSave}
            variant="contained"
            disabled={isSaveDisabled || isSaveLoading}
          >
            {isSaveLoading ? <CircularProgress size={24} /> : 'Save'}{' '}
          </StyledButton>
        </StyledButtonContainer>
      </StyledModalBody>
    </Modal>
  );
}

ReviewRecordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  linkValue: PropTypes.string,
  handleLinkInput: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  isInputError: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
};

ReviewRecordModal.defaultProps = {
  linkValue: null,
  isInputError: false,
  isSaveDisabled: false,
  isSaveLoading: false,
};
