import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Dropzone from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { ACTION_TYPES } from '../../pages/Admin/Skills/reducer';
import getCroppedImg from './cropImage';

const minZoom = 0.1;

const useStyles = makeStyles(() => ({
  condDescription: (props) => ({
    backgroundColor: '#fafafa',
    borderRadius: '.15em',
    height: '300px',
    border: '1px dashed gray',
    ...(!props?.image && { display: 'flex', justifyContent: 'center', alignItems: 'center' }),
  }),
}));

export default function ImageCropper({ dispatch, state }) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(minZoom);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const classes = useStyles({ image: state.image });

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      dispatch({
        type: ACTION_TYPES.BATCH_SET,
        payload: { isImageUpload: true, image: reader.result },
      });
    };

    reader.readAsDataURL(file);
  };

  const onCropChange = (event) => setPosition(event);

  const onCropComplete = (croppedArea, croppedAreaPixels1) => {
    setCroppedAreaPixels(croppedAreaPixels1);
  };

  const onZoomChange = (event) => setZoom(event);

  const cropImage = async () => {
    const croppedImg = await getCroppedImg(state.image, croppedAreaPixels);

    dispatch({
      type: ACTION_TYPES.BATCH_SET,
      payload: {
        croppedImg,
        isUpdate: state.isUpdate,
      },
    });
  };

  useEffect(() => {
    if (state.isCropImage) cropImage();
  }, [state.isCropImage]);

  return (
    <Box width="100%">
      <Dropzone
        onDrop={onDrop}
        accept={{
          'image/*': ['.jpeg', '.jpg', '.png', '.svg'],
        }}
        maxFiles={1}
        disabled={!!state.image}
      >
        {({ getRootProps }) => (
          <div {...getRootProps()} className={classes.condDescription}>
            {state.image ? (
              <div style={{ position: 'relative' }}>
                <Cropper
                  image={state.image}
                  minZoom={minZoom}
                  crop={position}
                  zoom={zoom}
                  zoomSpeed={0.1}
                  cropShape="round"
                  aspect={1}
                  objectFit="horizontal-cover"
                  restrictPosition={false}
                  onCropChange={onCropChange}
                  onCropComplete={onCropComplete}
                  onZoomChange={onZoomChange}
                  showGrid={false}
                  style={{
                    containerStyle: {
                      height: '300px',
                    },
                  }}
                />
                <IconButton
                  onClick={() => {
                    dispatch({
                      type: ACTION_TYPES.BATCH_SET,
                      payload: {
                        isImageUpload: false,
                        image: null,
                      },
                    });
                  }}
                  style={{ position: 'absolute', top: 0, right: 0, zIndex: '1000' }}
                >
                  <CloseIcon
                    style={{
                      fontSize: '1.2em',
                      color: 'white',
                    }}
                  />
                </IconButton>
              </div>
            ) : (
              <Typography fontSize="medium" textAlign="center">
                Drag and drop an image, or click to select a file.
              </Typography>
            )}
          </div>
        )}
      </Dropzone>
    </Box>
  );
}

ImageCropper.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.shape({
    isCropImage: PropTypes.bool.isRequired,
    isImageUpload: PropTypes.bool.isRequired,
    image: PropTypes.string,
    formValues: PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      version: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
      imageUrl: PropTypes.string.isRequired,
    }).isRequired,
    formErrors: PropTypes.shape({
      title: PropTypes.shape({
        status: PropTypes.bool.isRequired,
        messages: PropTypes.string,
      }),
      url: PropTypes.shape({
        status: PropTypes.bool.isRequired,
        messages: PropTypes.string,
      }),
      tags: PropTypes.shape({
        status: PropTypes.bool.isRequired,
        messages: PropTypes.string,
      }),
      description: PropTypes.shape({
        status: PropTypes.bool.isRequired,
        messages: PropTypes.string,
      }),
      version: PropTypes.shape({
        status: PropTypes.bool.isRequired,
        messages: PropTypes.string,
      }),
    }).isRequired,
    previewImage: PropTypes.string,
    isCreate: PropTypes.bool.isRequired,
    isUpdate: PropTypes.bool.isRequired,
  }).isRequired,
};

ImageCropper.defaultPropTypes = {
  state: {
    previewImage: null,
    image: null,
  },
};
