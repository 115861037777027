import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

export default function TextFieldWrapper({
  placeholder,
  inputName,
  value,
  onChange,
  onBlur,
  error,
  ...rest
}) {
  return (
    <TextField
      required
      placeholder={placeholder}
      variant="outlined"
      size="small"
      sx={{ width: '100%' }}
      value={value}
      onChange={(event) => onChange({ name: inputName, value: event.target.value })}
      onBlur={(event) => onBlur({ name: inputName, value: event.target.value })}
      error={error}
      {...rest}
    />
  );
}

TextFieldWrapper.propTypes = {
  placeholder: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

TextFieldWrapper.defaultProps = {
  error: false,
};
