import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { Button, IconButton, Menu, MenuItem, styled, Box } from '@mui/material';

const StyledButtonContainer = styled(Box)(() => ({ display: 'flex' }));

export default function RowOperations({
  editable,
  onEditClick,
  onSaveClick,
  onCancelClick,
  isSaving,
}) {
  const [anchorMenu, setAnchorMenu] = useState(null);

  const handleMoreClick = (event) => {
    event.stopPropagation();
    setAnchorMenu(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorMenu(null);
  };

  const handleSave = () => {
    setAnchorMenu(null);
    onSaveClick();
  };

  const handleCancel = () => {
    setAnchorMenu(null);
    onCancelClick();
  };

  const handleOnEditClick = () => {
    setAnchorMenu(null);
    return onEditClick();
  };

  return !editable ? (
    <>
      <IconButton onClick={handleMoreClick}>
        <MoreHorizIcon fontSize="medium" />
      </IconButton>
      <Menu anchorEl={anchorMenu} open={!!anchorMenu} onClose={handleMoreClose}>
        <MenuItem onClick={handleOnEditClick}>Edit</MenuItem>
      </Menu>
    </>
  ) : (
    <StyledButtonContainer>
      <Button onClick={handleCancel} sx={{ marginRight: '8px' }}>
        Cancel
      </Button>
      <Button
        variant="contained"
        endIcon={<SendRoundedIcon />}
        onClick={handleSave}
        disabled={isSaving}
      >
        Save
      </Button>
    </StyledButtonContainer>
  );
}

RowOperations.propTypes = {
  editable: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};
