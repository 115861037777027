import { Badge, createTheme, styled, Tab, Tabs } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const theme = createTheme({
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          selected: { color: '#7553EA' },
        },
      },
    },
  },
  palette: {
    primary: { main: '#7553EA' },
    secondary: { main: '#FFFFFF' },
  },
});

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'relative',
  color: 'white',
  borderRadius: '20px',
  width: '35px',
  height: '35px',
  marginRight: '15px',
}));

export const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    width: '15px',
    height: '15px',
    minWidth: '15px',
    borderRadius: '50%',
    fontSize: '0.75rem',
  },
}));

export const StyledMenuContainer = styled(Tabs)(() => ({
  paddingLeft: '16px',
  flexGrow: 1,
  [theme.breakpoints.up('xs')]: { display: 'none' },
  [theme.breakpoints.up('md')]: { display: 'flex' },
}));

export const StyledTab = styled(Tab)(() => ({
  color: '#FFFFFF',
  padding: '24px 8px',
}));
