import dayjs from 'dayjs';

const generateDailyCategories = (startDate, endDate) => {
  const categories = [];
  let currentDate = dayjs(startDate);

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
    categories.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }

  return categories;
};

export default generateDailyCategories;
