import HydraCallback from '../../pages/HydraCallback';
import Login from '../../pages/Login';
import Register from '../../pages/Register';

const publicRoutes = [
  {
    path: '/login',
    title: 'Login',
    component: () => <Login />,
  },
  {
    path: '/register',
    title: 'Register',
    component: () => <Register />,
  },
  {
    path: '/hydra-callback',
    title: 'Hydra Callback',
    component: () => <HydraCallback />,
  },
];

export default publicRoutes;
