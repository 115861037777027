import React from 'react';
import { styled, keyframes } from '@mui/material/styles';
import { ReactComponent as Logo } from '../assets/images/logo-header.svg';

const CenteredContainer = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const drawAnimation = keyframes`
  0%, 20%, 100% {
    stroke-dashoffset: 150;
  }
  50% {
    stroke-dashoffset: 0;
  }
`;

const AnimatedLogo = styled(Logo)(() => ({
  width: '10rem',
  height: 'auto',
  path: {
    fill: 'none',
    stroke: '#7553EA',
    strokeWidth: 1,
    strokeDasharray: 150,
    transform: 'translate(1px)',
    animation: `${drawAnimation} 1s ease-in-out infinite`,
  },
}));

export default function Loading() {
  return (
    <CenteredContainer>
      <AnimatedLogo />
    </CenteredContainer>
  );
}
