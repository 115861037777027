import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import growLogo from '../assets/images/logo-purple.svg';
import VantaBackground from '../components/VantaBackground';
import useAuth from '../hooks/useAuth';

const theme = createTheme({
  palette: {
    primary: { main: '#7553EA' },
  },
});

export default function Login() {
  const { loginUser } = useAuth();
  const handleLogin = async (e) => {
    e.preventDefault();
    await loginUser();
  };

  return (
    <ThemeProvider theme={theme}>
      <VantaBackground>
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100vh">
          <Box display="flex" flexDirection="column" alignItems="center" my={8} mx={4}>
            <Box component="img" width={120} height={138} alt="logo" src={growLogo} />
            <Box component="form" noValidate onSubmit={handleLogin}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                Continue with Hydra
              </Button>
            </Box>
          </Box>
        </Box>
      </VantaBackground>
    </ThemeProvider>
  );
}
