import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, styled } from '@mui/material';

const IframePreview = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: 'auto',
  height: 'auto',
  maxWidth: '400px',
  maxHeight: '400px',
  padding: theme.spacing(1),
  border: '1px solid #ccc',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
  pointerEvents: 'none',
  overflow: 'hidden',
}));

const ImageContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

const cutText = (text, maxLength) => {
  if (text.length > maxLength) {
    const truncatedText = text.substring(0, maxLength);
    const lastSpaceIndex = truncatedText.lastIndexOf(' ');
    return lastSpaceIndex !== -1
      ? `${truncatedText.substring(0, lastSpaceIndex)}...`
      : `${truncatedText}...`;
  }
  return text;
};

function LinkPreview({ previewData, position }) {
  const [previewPosition, setPreviewPosition] = useState({ top: 0, left: 0 });

  const determinePreviewPosition = () => {
    let topOffset = position.top;
    if (previewData) {
      const { title, description, image } = previewData;
      if ((title && image) || (image && description)) {
        topOffset = position.top - 200;
      }
      if (title && description) {
        topOffset = position.top - 50;
      }
    }
    return { top: Math.max(topOffset, 0), left: position.left };
  };

  useState(() => {
    setPreviewPosition(determinePreviewPosition());
  }, [position, previewData]);

  const renderPreviewContent = () => {
    if (!previewData) {
      return <Typography>No preview data</Typography>;
    }

    const { image, title, description } = previewData;

    const imageStyle = {
      maxWidth: '100%',
      maxHeight: '250px',
      height: 'auto',
    };

    if (image && title && description) {
      return (
        <>
          <ImageContainer>
            <img src={image} alt={title} style={imageStyle} />
          </ImageContainer>
          <Typography variant="h6">{title}</Typography>
          <Typography>{cutText(description, 100)}</Typography>
        </>
      );
    }
    if (!image && title && description) {
      return (
        <>
          <Typography variant="h6">{title}</Typography>
          <Typography>{cutText(description, 100)}</Typography>
        </>
      );
    }
    if (image && title && !description) {
      return (
        <>
          <ImageContainer>
            <img src={image} alt={title} style={imageStyle} />
          </ImageContainer>
          <Typography variant="h6">{title}</Typography>
        </>
      );
    }
    if (!image && !title && description) {
      return <Typography>{cutText(description, 100)}</Typography>;
    }
    if (image && !title && !description) {
      return (
        <ImageContainer>
          <img src={image} alt="Preview" style={imageStyle} />
        </ImageContainer>
      );
    }
    return <Typography>No preview data</Typography>;
  };

  return (
    <IframePreview style={{ ...previewPosition, zIndex: 1000 }}>
      {renderPreviewContent()}
    </IframePreview>
  );
}

LinkPreview.propTypes = {
  previewData: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  position: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }).isRequired,
};

LinkPreview.defaultProps = {
  previewData: null,
};

export default LinkPreview;
