import { useEffect, useState } from 'react';
import useDebouncedHandler from '../../../../hooks/useDebouncedHandler';
import apiService from '../../../../services/api.service';

const useDepartmentsData = () => {
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [pickedDepartments, setPickedDepartments] = useState([]);

  const fetchDepartments = async () => {
    try {
      const response = await apiService.developer.getDepartments();
      setDepartmentOptions(response);
    } catch (error) {
      console.error('Failed to fetch departments:', error);
    }
  };

  const { debouncedHandler } = useDebouncedHandler(fetchDepartments);

  useEffect(() => {
    debouncedHandler();
  }, [debouncedHandler]);

  return {
    departmentOptions,
    pickedDepartments,
    setPickedDepartments,
  };
};

export default useDepartmentsData;
