import PropTypes from 'prop-types';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Box, Button, Modal, Typography } from '@mui/material';
import StyledModalBody from '../styled-components/ModalBody';
import PlainCodeDetailsForm from './PlainCodeDetailsForm';

export default function AddDefaultTaskModal({ open, setIsOpened, conflictedTasks, replaceTask }) {
  return (
    <Modal
      open={open}
      onClose={() => setIsOpened(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledModalBody>
        <Typography>Do you want to completely replace your current task?</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '1em 0' }}>
          <PlainCodeDetailsForm node={conflictedTasks.replaceableTask} />
          <AutorenewIcon sx={{ fontSize: '3rem', margin: '0 1rem' }} />
          <PlainCodeDetailsForm node={conflictedTasks.replacingTask} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => setIsOpened(false)}
            sx={{ mr: 1 }}
          >
            Decline
          </Button>
          <Button variant="contained" onClick={() => replaceTask(conflictedTasks.replacingTask)}>
            Confirm
          </Button>
        </Box>
      </StyledModalBody>
    </Modal>
  );
}

const nodeItem = {
  itemId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  successCriteria: PropTypes.string.isRequired,
  materialName: PropTypes.string.isRequired,
  materialType: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
};

AddDefaultTaskModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  conflictedTasks: PropTypes.shape({
    replaceableTask: PropTypes.shape(nodeItem),
    replacingTask: PropTypes.shape(nodeItem),
  }).isRequired,
  replaceTask: PropTypes.func.isRequired,
};
