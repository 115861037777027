import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { InfoOutlined } from '@mui/icons-material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import {
  Box,
  createTheme,
  IconButton,
  styled,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
import TreeItemCustom from '../../../components/TreeItemCustom';
import GoalsConstants from '../../../constants/goals.constants';
import reviewConstants from '../../../constants/review.constants';
import UserContext from '../../../hooks/context/UserContext';
import { mergeSimilarSkillDetails } from '../../Head/helpers/reviews.helpers';

const theme = createTheme({
  components: {
    MuiTreeItem: {
      styleOverrides: {
        textAlign: 'initial',
        root: { marginLeft: '13px' },
        label: { fontSize: '16px' },
      },
    },
  },
  palette: { primary: { main: '#7553EA' } },
});

const StyledLabelBox = styled(Box)(({ singleElement, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  ...(singleElement && { padding: '5px 0' }),
  ...(disabled && { color: 'gray' }),
}));

const StyledIconBox = styled(Box)(() => ({ padding: '8px' }));

export default function SkillNode({
  node,
  skillsData,
  onClick,
  updateTreeData,
  isDisabled,
  reviewData,
}) {
  const { socketInstance } = useContext(UserContext);

  const nodeChildren = useMemo(() => {
    if (!node.children.length && node.details) {
      return mergeSimilarSkillDetails(node.details);
    }

    return skillsData.filter((item) => node.children.includes(item.itemId));
  }, [node, skillsData]);

  const handleAddSkill = (event, itemId) => {
    event.stopPropagation();

    if (node.isDetails || isDisabled) {
      return;
    }

    socketInstance.emit('send-goal-status', {
      itemId,
      status: GoalsConstants.GOAL_STATUSES.IN_PROGRESS,
      skillType: node.skillType,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <TreeItemCustom
        nodeId={node.itemId}
        label={
          <StyledLabelBox
            disabled={node.status !== GoalsConstants.GOAL_STATUSES.ON_HOLD}
            sx={{ minHeight: 34 }}
          >
            <Typography variant="p" component="p">
              {node.name}
            </Typography>
            {node.status === GoalsConstants.GOAL_STATUSES.ON_HOLD && (
              <Tooltip title="Add in progress">
                <IconButton onClick={(event) => handleAddSkill(event, node.itemId)}>
                  <AddCircleRoundedIcon fontSize="small" color="primary" />
                </IconButton>
              </Tooltip>
            )}
            {node.status === GoalsConstants.GOAL_STATUSES.IN_PROGRESS && (
              <Tooltip title="In Progress">
                <StyledIconBox>
                  <HourglassTopRoundedIcon sx={{ display: 'block' }} fontSize="small" />
                </StyledIconBox>
              </Tooltip>
            )}
            {node.status === GoalsConstants.GOAL_STATUSES.DONE &&
              reviewData?.status !== reviewConstants.REVIEW_STATUSES.PROCESSED && (
                <Tooltip title="Ready for review">
                  <StyledIconBox>
                    <CheckCircleRoundedIcon
                      sx={{ display: 'block' }}
                      fontSize="small"
                      color="warning"
                    />
                  </StyledIconBox>
                </Tooltip>
              )}
            {reviewData?.status === reviewConstants.REVIEW_STATUSES.PROCESSED && (
              <Tooltip title="Ready for review">
                <StyledIconBox>
                  <CheckCircleRoundedIcon
                    sx={{ display: 'block' }}
                    fontSize="small"
                    color="warning"
                  />
                </StyledIconBox>
              </Tooltip>
            )}
            {node.status === GoalsConstants.GOAL_STATUSES.APPROVED &&
              reviewData?.status !== reviewConstants.REVIEW_STATUSES.PROCESSED && (
                <>
                  <Tooltip title="Approved">
                    <StyledIconBox>
                      <CheckCircleRoundedIcon
                        sx={{ display: 'block' }}
                        fontSize="small"
                        color="success"
                      />
                    </StyledIconBox>
                  </Tooltip>
                  {node.recommendation && (
                    <Tooltip title={node.recommendation} placement="right">
                      <InfoOutlined fontSize="small" />
                    </Tooltip>
                  )}
                </>
              )}
            {node.status === GoalsConstants.GOAL_STATUSES.DECLINED &&
              !node.isDetails &&
              reviewData?.status !== reviewConstants.REVIEW_STATUSES.PROCESSED && (
                <Tooltip title="Declined">
                  <StyledIconBox>
                    <AddCircleRoundedIcon
                      onClick={(event) => handleAddSkill(event, node.itemId)}
                      sx={{ display: 'block' }}
                      fontSize="small"
                      color="warning"
                    />
                  </StyledIconBox>
                </Tooltip>
              )}
            {node.status === GoalsConstants.GOAL_STATUSES.DECLINED &&
              node.isDetails &&
              reviewData?.status !== reviewConstants.REVIEW_STATUSES.PROCESSED && (
                <>
                  <Tooltip title="Declined">
                    <StyledIconBox>
                      <CancelRoundedIcon
                        onClick={(event) => handleAddSkill(event, node.itemId)}
                        sx={{ display: 'block' }}
                        fontSize="small"
                        color="error"
                      />
                    </StyledIconBox>
                  </Tooltip>
                  {node.comment && (
                    <Tooltip title={node.comment} placement="right">
                      <DescriptionIcon fontSize="small" />
                    </Tooltip>
                  )}
                </>
              )}
          </StyledLabelBox>
        }
        onClick={() => onClick(node.itemId)}
      >
        {nodeChildren.map((child) => (
          <SkillNode
            key={child.itemId}
            node={{
              isDetails: !node.children.length,
              details: [],
              children: [],
              skillType: node.skillType,
              ...child,
            }}
            skillsData={skillsData}
            onClick={onClick}
            updateTreeData={updateTreeData}
            isDisabled={isDisabled}
            reviewData={reviewData}
          />
        ))}
      </TreeItemCustom>
    </ThemeProvider>
  );
}

const nodeDetails = {
  itemId: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.string).isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      name: PropTypes.string,
      successCriteria: PropTypes.string,
      materialName: PropTypes.string,
      materialType: PropTypes.string,
      link: PropTypes.string,
      level: PropTypes.string,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  skillType: PropTypes.string.isRequired,
  status: PropTypes.string,
  isDetails: PropTypes.bool,
};

SkillNode.propTypes = {
  node: PropTypes.shape(nodeDetails).isRequired,
  skillsData: PropTypes.arrayOf(PropTypes.shape(nodeDetails)).isRequired,
  onClick: PropTypes.func.isRequired,
  updateTreeData: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  reviewData: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }),
};

SkillNode.defaultProps = {
  isDisabled: false,
  reviewData: PropTypes.shape({}),
};
