import React from 'react';
import PropTypes from 'prop-types';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Link } from '@mui/material';
import TokenService from '../../../../services/token.service';

function ExportButton({ url }) {
  const getHref = () => {
    const accessToken = TokenService.getLocalAccessToken();
    url.searchParams.set('accessToken', accessToken);
    return url.toString();
  };

  return (
    <Link
      href={getHref()}
      underline="none"
      color="inherit"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        border: '1px solid #c1c1c1',
        borderRadius: '4px',
        padding: '0 12px',
        '&:hover': {
          border: '1px solid #050505',
        },
      }}
    >
      <FileDownloadOutlinedIcon fontSize="small" sx={{ color: '#c1c1c1' }} />
    </Link>
  );
}

ExportButton.propTypes = {
  url: PropTypes.instanceOf(URL).isRequired,
};

export default ExportButton;
