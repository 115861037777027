import './App.css';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PageVisibility from 'react-page-visibility';
import { BrowserRouter as Router } from 'react-router-dom';

import UserContext from './hooks/context/UserContext';
import Routes from './routes';
import ApiService from './services/api.service';
import axios from './services/http.service';
import SocketService from './services/socket.service';

export default function App() {
  const [user, setUser] = useState(null);
  const [socketInstance, setSocketInstance] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function findUser() {
      if (window.location.pathname !== '/login' && window.location.pathname !== '/hydra-callback') {
        await axios
          .get('/auth/token')
          .then((res) => {
            setUser(res);
            window.user_id = res.email;
            window.passport_id = res.hydraPassportId;
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
    findUser();
  }, []);

  useEffect(() => {
    setSocketInstance(SocketService.getSocketInstance());
  }, []);

  useEffect(() => {
    const { search } = window.location;
    const guestToken = new URLSearchParams(search).get('guestToken');

    if (guestToken) {
      sessionStorage.setItem('guestToken', guestToken);
      window.location.replace('/home');
    }
  }, []);

  const memo = useMemo(
    () => ({
      user,
      setUser,
      isLoading,
      socketInstance,
      setSocketInstance,
    }),
    [user, setUser, isLoading, socketInstance, setSocketInstance],
  );

  let startVisibleAt = Date.now();
  const handleIsVisibleChange = (isVisible) => {
    if (isVisible) {
      startVisibleAt = Date.now();
    } else {
      const time = Math.floor((Date.now() - startVisibleAt) / 1000);

      // Less than 5 seconds to avoid unnecessary calls
      if (time < 5) {
        return;
      }

      ApiService.developer.sendSession(time);
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Grow</title>
      </Helmet>
      <Router>
        <UserContext.Provider value={memo}>
          <PageVisibility onChange={handleIsVisibleChange} />
          <Routes />
        </UserContext.Provider>
      </Router>
    </HelmetProvider>
  );
}
