import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TreeView from '@mui/lab/TreeView';
import { Box, Snackbar, Alert, styled } from '@mui/material';
import { isEqual } from 'lodash';
import ApiService from '../../../../services/api.service';
import axios from '../../../../services/http.service';
import randomId from '../../../../utils/randomId';
import GrowPageContext from '../contexts/GrowPageContext';
import ErrorsModalWindow from './ErrorsModalWindow';
import TreeNode from './TreeNode';
import TreeViewFooter from './TreeViewFooter';

const StyledTreeView = styled(TreeView)(() => ({
  maxHeight: '69vh',
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: '20px',
}));

export default function TreeViewForm({
  type,
  treeData,
  setTreeData,
  setInitialTreeData,
  initialTreeData,
  activeTab,
  saved,
  setSaved,
  errorMessage,
  setErrorMessage,
  foundedSkills,
}) {
  const { department, growLevels, setIsDialogOpened } = useContext(GrowPageContext);

  const [alertOpen, setAlertOpen] = useState({ open: false, message: '' });
  const [sendRequest, setSendRequest] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [removed, setRemoved] = useState([]);
  const [added, setAdded] = useState([]);
  const [updated, setUpdated] = useState([]);
  const [isDetailActive, setIsDetailActive] = useState(true);
  const [errors, setErrors] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (foundedSkills.length > 0) {
      setExpanded((prevExpanded) => {
        return foundedSkills.includes(prevExpanded)
          ? prevExpanded
          : [...prevExpanded, ...foundedSkills];
      });
    }
  }, [foundedSkills]);

  const addTreeNode = useCallback(
    (arr, id) => {
      const childId = randomId();
      const updatedTree = arr.map((item) =>
        item.itemId === id
          ? {
              ...item,
              children: [...item.children, childId],
              details: [],
            }
          : item,
      );

      const newSkill = {
        itemId: childId,
        name: '',
        details: [
          {
            itemId: randomId(),
            name: '',
            successCriteria: '',
            materialName: '',
            materialType: '',
            link: '',
            level: growLevels[0]._id,
            contentType: null,
          },
        ],
        children: [],
      };

      setAdded([...added, newSkill].filter((elem) => elem !== id));

      setExpanded(id ? [...expanded, childId, id] : [...expanded, childId]);

      return [...updatedTree, { ...newSkill }];
    },
    [expanded, growLevels, added, setAdded],
  );

  const findNodesToRemoving = useCallback((arr, id) => {
    const selectedItem = arr.find((item) => item.itemId === id);
    let resArr = [id];

    if (selectedItem.children.length) {
      const nodeChildren = arr
        .filter((item) => selectedItem.children.includes(item.itemId))
        .map((item) => findNodesToRemoving(arr, item.itemId));
      resArr = [...resArr, ...nodeChildren];
    }

    return resArr.flat(Infinity);
  }, []);

  const removeTreeNode = useCallback(
    (arr, id) => {
      const rmNodes = findNodesToRemoving(arr, id);

      const foundItem = arr.find((item) => item.itemId === id);

      if (foundItem) {
        setRemoved([...removed, foundItem]);
      }

      setExpanded(expanded.filter((item) => !rmNodes.includes(item)));

      return arr
        .map((node) => {
          const children = node.children.filter((item) => item !== id);

          return {
            ...node,
            children,
            ...(!isEqual(node.children, children) && !children.length
              ? {
                  details: [
                    {
                      itemId: randomId(),
                      name: '',
                      successCriteria: '',
                      materialName: '',
                      materialType: '',
                      link: '',
                      level: growLevels[0]._id,
                    },
                  ],
                }
              : {}),
          };
        })
        .filter((item) => !rmNodes.includes(item.itemId));
    },
    [expanded, findNodesToRemoving, growLevels, removed],
  );

  const updateTreeNode = (arr, id, updatedValue) => {
    const foundItem = arr.find((item) => item.itemId === id && item._id);

    if (foundItem) {
      setUpdated([...new Set([...updated, foundItem.itemId])]);
    }

    return arr.map((node) =>
      node.itemId === id
        ? {
            ...node,
            ...updatedValue,
          }
        : node,
    );
  };

  const validateFields = (arr) => {
    let error = false;

    arr.forEach((item) => {
      const { name, details } = item;
      if (!name.trim()) {
        error = true;
        setAlertOpen({ open: true, message: 'All fields are required!' });
      }

      details.forEach((detail) => {
        if (
          !detail.successCriteria.trim() ||
          !detail.materialName.trim() ||
          !detail.materialType.trim() ||
          !detail.link.trim()
        ) {
          error = true;
          setAlertOpen({ open: true, message: 'All fields are required!' });
        }
      });
    });

    return error;
  };

  const prepareData = (array) =>
    array.map((node) => ({
      ...node,
      name: node.name.trim(),
      details: node.details.map(({ contentType, ...detail }) => ({
        ...detail,
        name: detail.name.trim(),
        successCriteria: detail.successCriteria.trim(),
        materialName: detail.materialName.trim(),
        materialType: detail.materialType.trim(),
        link: detail.link.trim(),
        contentType,
      })),
    }));

  const handleSave = () => {
    try {
      const isError = validateFields(treeData);

      if (isError) {
        return false;
      }

      setSendRequest(true);

      axios({
        method: 'POST',
        url: 'head/grow',
        data: {
          treeData: prepareData(treeData),
          department,
          skillType: type,
        },
      }).then((response) => {
        setInitialTreeData(response);
        setTreeData(response);
        setSendRequest(false);
        setSaved(true);
      });
    } catch (error) {
      setSendRequest(false);
      setErrorMessage({ isOpen: true, message: `ERROR: ${error.message}` });
    }

    return true;
  };

  const sendJson = async (file) => {
    try {
      setSendRequest(true);

      const formData = new FormData();

      formData.append('file', file);

      const result = await ApiService.head.sendJson({
        department,
        skillType: type,
        body: formData,
      });

      const addedResult = result.jsonData.filter((task) => !task.createdAt);

      setTreeData(result.jsonData);
      setAdded(addedResult);
      setSendRequest(false);

      if (result.errors.length) {
        setErrors(result.errors);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error(error);
      setSendRequest(false);
    }
  };

  const sendCsv = async (file) => {
    try {
      setSendRequest(true);

      const formData = new FormData();
      formData.append('file', file);
      const result = await ApiService.head.sendCsv({ department, skillType: type, body: formData });

      const addedResult = result.csvData.filter((task) => !task.createdAt);

      setTreeData(result.csvData);
      setAdded(addedResult);
      setSendRequest(false);

      if (result.errors.length) {
        setErrors(result.errors);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error(error);
      console.dir(error, { depth: null });
      setAlertOpen({ open: true, message: error.response.data.messages });
      setSendRequest(false);
    }
  };

  const filteredTree = useMemo(
    () => treeData.filter((i) => !treeData.some((item) => item.children.includes(i.itemId))),
    [treeData],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <StyledTreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        expanded={expanded}
      >
        {filteredTree.map((node) => (
          <TreeNode
            key={node.itemId}
            node={node}
            treeData={treeData}
            updateTreeNode={(id, newValue) => setTreeData(updateTreeNode(treeData, id, newValue))}
            addTreeNode={(id) => setTreeData(addTreeNode(treeData, id))}
            removeTreeNode={(id) => setTreeData(removeTreeNode(treeData, id))}
            growLevels={growLevels}
            onClick={(id) =>
              setExpanded(
                expanded.includes(id) ? expanded.filter((item) => item !== id) : [...expanded, id],
              )
            }
            isDetailActive={isDetailActive}
            setIsDetailActive={setIsDetailActive}
          />
        ))}
      </StyledTreeView>
      <TreeViewFooter
        addTreeNodeHandler={() => setTreeData(addTreeNode(treeData, null))}
        saveHandler={handleSave}
        disabledSave={sendRequest || (!added.length && !updated.length && !removed.length)}
        disabledExportCsv={!treeData.length || sendRequest}
        disabledExportJson={!treeData.length || sendRequest}
        disabledImportCvs={sendRequest}
        type={type}
        sendJson={sendJson}
        sendCsv={sendCsv}
        removed={removed}
        added={added}
        updated={updated}
        treeSkills={treeData}
        initialTreeData={initialTreeData}
        clearData={() => {
          setAdded([]);
          setRemoved([]);
          setUpdated([]);
        }}
        setTreeData={setTreeData}
        activeTab={activeTab}
        setAdded={setAdded}
        setAlertOpen={setAlertOpen}
        validateFields={validateFields}
      />
      <ErrorsModalWindow
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        errors={errors}
        setIsDialogOpened={setIsDialogOpened}
      />
      <Snackbar
        open={alertOpen.open}
        autoHideDuration={6000}
        onClose={() => setAlertOpen({ open: false, message: '' })}
      >
        <Alert
          onClose={() => setAlertOpen({ open: false, message: '' })}
          severity="error"
          sx={{ width: '100%' }}
        >
          All fields are required!
        </Alert>
      </Snackbar>
      <Snackbar open={saved} autoHideDuration={6000} onClose={() => setSaved(false)}>
        <Alert onClose={() => setSaved(false)} severity="success" sx={{ width: '100%' }}>
          Saved!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorMessage.isOpen}
        autoHideDuration={6000}
        onClose={() => setErrorMessage({ isOpen: false, message: '' })}
      >
        <Alert
          onClose={() => setErrorMessage({ isOpen: false, message: '' })}
          severity="error"
          sx={{ width: '100%' }}
        >
          {errorMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

TreeViewForm.propTypes = {
  type: PropTypes.string.isRequired,
  treeData: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      name: PropTypes.string,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          itemId: PropTypes.string,
          successCriteria: PropTypes.string,
          materialName: PropTypes.string,
          materialType: PropTypes.string,
          link: PropTypes.string,
          level: PropTypes.string,
        }),
      ),
      children: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
  foundedSkills: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTreeData: PropTypes.func.isRequired,
  initialTreeData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setInitialTreeData: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  saved: PropTypes.bool.isRequired,
  setSaved: PropTypes.func.isRequired,
  errorMessage: PropTypes.shape({
    isOpen: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};
