import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, Grid, Paper } from '@mui/material';
import NoInfoFoundAlert from '../../../components/NoInfoFoundAlert';
import adminConstants from '../../../constants/adminPages';
import apiService from '../../../services/api.service';
import AdminBasicPage from '../../../templates/AdminBasicPage';
import ApiKeysTable from './components/ApiKeysTable';
import ModalWindow from './components/ModalWindow';

const StyledItem = styled(Paper)(() => ({
  marginBottom: '1em',
  padding: '0 3em',
  borderRadius: '.15em',
  marginTop: '4em',
}));

export default function AdminApplicationsPage({ crumbs, title }) {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const [apiKeysData, setApiKeysData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [apiKeysSort, setApiKeysSort] = useState('expirationDate');

  const fetchUsers = async (skip, limit, sort) => {
    try {
      const response = await apiService.admin.getApiKeys({
        params: {
          skip: skip * limit,
          limit,
          sort,
        },
      });

      setCurrentPage(skip);
      setApiKeysData(response.paginatedResult);
      setTotalCount(response.totalCount ?? 0);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchUsers(currentPage, rowsPerPage, apiKeysSort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <AdminBasicPage crumbs={crumbs} title={title}>
      <Grid sx={{ margin: '8px 36px 0' }} item>
        <StyledItem
          elevation={8}
          container="true"
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Grid display="flex" justifyContent="flex-end">
            <Fab
              sx={{ top: '-28px' }}
              color="primary"
              aria-label="add"
              onClick={() => setIsModalOpened(true)}
            >
              <AddIcon />
            </Fab>
            <ModalWindow
              isOpened={isModalOpened}
              setIsOpened={setIsModalOpened}
              setIsLoading={setIsLoading}
            />
          </Grid>
          {apiKeysData.length || isLoading ? (
            <Grid xs={12} item>
              <ApiKeysTable
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                apiKeysData={apiKeysData}
                totalCount={totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setSort={setApiKeysSort}
              />
            </Grid>
          ) : (
            <Box display="flex" justifyContent="center" marginBottom="24px">
              <NoInfoFoundAlert message={adminConstants.ERROR_MESSAGES.NO_AVAILABLE_KEYS} />
            </Box>
          )}
        </StyledItem>
      </Grid>
    </AdminBasicPage>
  );
}

AdminApplicationsPage.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};
