import { useEffect, useState } from 'react';
import apiService from '../../../../../../services/api.service';
import useHeadDepartment from '../../../hooks/useHeadDepartment';
import useTableOrder from '../LinkViewsTable/hooks/useTableOrder';
import useTablePagination from '../LinkViewsTable/hooks/useTablePagination';

const useLinksViewsData = (startPickerValue, endPickerValue) => {
  const [, setError] = useState();

  const [linkViewsData, setLinkViewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { departmentId } = useHeadDepartment();

  const { totalCount, rowsPerPage, currentPage, handleChangePagination } = useTablePagination();
  const { order, orderBy, handleChangeOrder } = useTableOrder('desc', 'views');

  const fetchReviewHistory = async () => {
    if (departmentId === null) {
      console.warn('Department is not set yet.');
      return;
    }

    setIsLoading(true);

    try {
      const result = await apiService.head.getHeadLinkViews({
        createdAt: { gte: startPickerValue.toISOString(), lte: endPickerValue.toISOString() },
        skip: currentPage * rowsPerPage,
        limit: rowsPerPage,
        order: order === 'asc' ? 1 : -1,
        orderBy,
        departmentId,
      });

      setLinkViewsData(result.paginatedResult);
      handleChangePagination({ totalCount: result.totalCount });
    } catch (error) {
      setError(() => {
        throw new Error(error);
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (departmentId !== null) {
      fetchReviewHistory();
    }
  }, [currentPage, rowsPerPage, order, orderBy, departmentId, startPickerValue, endPickerValue]);

  return {
    linkViewsData,
    isLoading,
    totalCount,
    rowsPerPage,
    currentPage,
    order,
    orderBy,
    handleChangePagination,
    handleChangeOrder,
  };
};

export default useLinksViewsData;
