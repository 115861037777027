import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TableCell,
  TableRow,
  Chip,
  styled,
  Box,
  Autocomplete,
  TextField,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import Axios from 'axios';
import { differenceBy } from 'lodash';
import { Link } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import { DepartmentIcon } from '../../../../lib/core.cjs.development';
import apiService from '../../../../services/api.service';
import ModalWindow from './ModalWindow';
import RowOperations from './RowOperations';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: { margin: '5px 0' },
        input: { padding: '8.5px' },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: { padding: '1px 0' },
      },
    },
  },
  palette: {
    primary: { main: '#7553EA' },
  },
});

const StyledHeadItem = styled(Chip)(() => ({
  margin: '1px 3px',
}));

const StyledTextField = styled(TextField)(() => ({
  padding: '5px',
}));

const StyledAutocomplete = styled(({ isDefaultWidth, ...props }) => <Autocomplete {...props} />)(
  ({ isDefaultWidth }) => {
    if (isDefaultWidth) {
      return { width: 300 };
    }

    return {};
  },
);

export default function DepartmentTableRow({ department, setSuccessSnackbar, setErrorSnackbar }) {
  const [employeeList, setEmployees] = useState(
    department.heads.map((head) => ({
      id: head._id,
      email: head.email,
    })),
  );

  const [isEmployeeLoading, setIsEmployeeLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [departmentName, setDepartmentName] = useState(department.title);
  const [currentHeads, setCurrentHeads] = useState(
    department.heads.map((head) => ({
      id: head._id,
      email: head.email,
    })),
  );
  const [isSaving, setIsSaving] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
    prevDepartment: department.title,
    prevHeads: department.heads.map((head) => ({
      id: head._id,
      email: head.email,
    })),
    newDepartment: null,
    addedHeads: null,
    removedHeads: null,
  });
  const cancelToken = useRef(null);

  const handleCancel = () => {
    setDepartmentName(modal.prevDepartment);
    setCurrentHeads(modal.prevHeads);
    setModal({
      ...modal,
      isOpen: false,
      newDepartment: null,
      addedHeads: null,
      removedHeads: null,
    });
  };

  const handleInputChange = async (event) => {
    const search = event.target.value;

    if (search.length < 3) {
      return 1;
    }

    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }

    cancelToken.current = Axios.CancelToken.source();

    try {
      setIsEmployeeLoading(true);

      const result = await apiService.admin.getUsers({
        params: { 'filter[email]': search },
        cancelToken: cancelToken.current.token,
      });

      const newEmployeesArr = [
        ...currentHeads,
        ...result.paginatedResult.map((item) => ({
          id: item._id,
          email: item.email,
        })),
      ].filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

      setEmployees(newEmployeesArr);
      setIsEmployeeLoading(false);
    } catch (error) {
      setIsEmployeeLoading(false);
    }

    return 0;
  };

  const saveDepartmentDetails = async () => {
    const preparedHeadArr = currentHeads.map((head) => head.id).sort();

    try {
      setIsSaving(true);

      await apiService.admin.updateDepartment(department._id, {
        title: departmentName,
        headIds: preparedHeadArr,
      });

      setIsSaving(false);
      setSuccessSnackbar(true);
      setModal({
        isOpen: false,
        prevDepartment: modal.newDepartment || modal.prevDepartment,
        prevHeads: currentHeads || modal.prevHeads,
        newDepartment: null,
        addedHeads: null,
        removedHeads: null,
      });
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      handleCancel();
    }
  };

  const handleValidate = () => {
    if (!departmentName.trim()) {
      setErrorSnackbar({
        isOpen: true,
        message: 'Department name is required!',
      });
      return 1;
    }

    const { prevDepartment, prevHeads } = modal;

    const addedHeads = differenceBy(currentHeads, prevHeads, 'id');
    const removedHeads = differenceBy(prevHeads, currentHeads, 'id');

    if (prevDepartment !== departmentName || addedHeads.length || removedHeads.length) {
      setModal({
        ...modal,
        isOpen: true,
        newDepartment: prevDepartment !== departmentName ? departmentName : null,
        addedHeads: addedHeads.length ? addedHeads : null,
        removedHeads: removedHeads.length ? removedHeads : null,
      });
    }
    setEditable(false);
    return 0;
  };

  const onCancelClick = () => {
    handleCancel();
    setEditable(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <TableRow>
        <TableCell align="left">
          <DepartmentIcon
            name={department.name}
            style={{
              width: '40px',
              height: '40px',
            }}
          />
        </TableCell>
        <TableCell align="left">
          <Grid container justifyContent="start">
            {editable ? (
              <StyledTextField
                value={departmentName}
                onChange={(event) => setDepartmentName(event.target.value)}
              />
            ) : (
              departmentName
            )}
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Grid
            xs={12}
            item
            alignItems="center"
            container
            justifyContent="start"
            sx={{ minHeight: 32 }}
          >
            {editable ? (
              <Box>
                <StyledAutocomplete
                  isDefaultWidth={!currentHeads.length}
                  isOptionEqualToValue={(option, value) => option.email === value.email}
                  getOptionLabel={(option) => option.email}
                  options={employeeList}
                  loading={isEmployeeLoading}
                  value={currentHeads}
                  onChange={(event, newValue) => {
                    setCurrentHeads(newValue);
                  }}
                  multiple
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      onChange={handleInputChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isEmployeeLoading ? <Loading /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            ) : (
              currentHeads.map((employee) => (
                <StyledHeadItem key={employee.id} label={employee.email} />
              ))
            )}
          </Grid>
        </TableCell>
        <TableCell align="right">
          <Grid container justifyContent="end">
            <Link
              to={`/admin/users?${department._id}`}
              style={{ color: 'inherit', textDecoration: 'inherit' }}
            >
              {department.employeesCount}
            </Link>
          </Grid>
        </TableCell>
        <TableCell align="left">
          <RowOperations
            editable={editable}
            onEditClick={() => setEditable(true)}
            onSaveClick={handleValidate}
            isSaving={isSaving}
            onCancelClick={onCancelClick}
            department={department.name}
          />
        </TableCell>
      </TableRow>
      <ModalWindow modal={modal} onAccept={saveDepartmentDetails} onDecline={handleCancel} />
    </ThemeProvider>
  );
}

const userData = {
  _id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  avatar: PropTypes.shape({}).isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  goal: PropTypes.string.isRequired,
};

DepartmentTableRow.propTypes = {
  department: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    employeesCount: PropTypes.number.isRequired,
    heads: PropTypes.arrayOf(PropTypes.shape(userData)).isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  setSuccessSnackbar: PropTypes.func.isRequired,
  setErrorSnackbar: PropTypes.func.isRequired,
};
