import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import RowSkeleton from '../../Users/components/RowSkeleton';
import NewsLinksTableRow from './NewsLinksTableRow';

const tableCells = [
  {
    id: 'link',
    name: 'Link',
    width: '15%',
    textAlign: 'left',
    align: 'left',
    sort: false,
  },
  {
    id: 'departments',
    name: 'Departments',
    width: '15%',
    textAlign: 'left',
    align: 'left',
    sort: true,
  },
];

export default function NewsLinksTable({
  isLoading,
  setIsLoading,
  linksData,
  totalCount,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  setSort,
  setSuccessSnackbarText,
  setInfoSnackbarText,
  setErrorSnackbarText,
  setWarningSnackbar,
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('expirationDate');

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setIsLoading(true);
  };

  const handleChangeRowsPerPage = (event) => {
    const parsedPage = parseInt(event.target.value, 10);
    setRowsPerPage(parsedPage);
    setCurrentPage(0);
    setIsLoading(true);
  };

  useEffect(() => {
    if (orderBy) {
      setSort(order === 'asc' ? orderBy : `-${orderBy}`);
      setIsLoading(true);
    }
  }, [order, orderBy]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getTableSortLabelDirection = (tableCell) => (orderBy === tableCell.id ? order : 'asc');

  const getTableCells = () =>
    tableCells.map((tableCell) => (
      <TableCell
        key={tableCell.id}
        width={tableCell.width}
        sx={{
          textAlign: tableCell.textAlign,
          minWidth: 100,
        }}
        align={tableCell.align}
      >
        {tableCell.sort ? (
          <TableSortLabel
            active={orderBy === tableCell.id}
            direction={getTableSortLabelDirection(tableCell)}
            onClick={() => handleSort(tableCell.id)}
          >
            {tableCell.name}
          </TableSortLabel>
        ) : (
          tableCell.name
        )}
      </TableCell>
    ));

  return (
    <TableContainer>
      <Table size="small" aria-label="api keys table">
        <TableHead>
          <TableRow>
            {getTableCells()}
            <TableCell
              width="5%"
              sx={{
                textAlign: 'center',
                minWidth: 100,
              }}
              align="center"
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? [1, 2, 3, 4, 5].map((item) => <RowSkeleton key={item} />)
            : linksData.map((link) => (
                <NewsLinksTableRow
                  key={link._id}
                  linksData={link}
                  isLoading={isLoading}
                  setSuccessSnackbar={(text) => setSuccessSnackbarText(text)}
                  setInfoSnackbar={(text) => setInfoSnackbarText(text)}
                  setErrorSnackbar={(text) => setErrorSnackbarText(text)}
                  setIsLoading={setIsLoading}
                  setWarningSnackbar={setWarningSnackbar}
                />
              ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={10}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

const linkData = {
  _id: PropTypes.string.isRequired,
  rssLink: PropTypes.string.isRequired,
  department: PropTypes.arrayOf(PropTypes.object).isRequired,
};

NewsLinksTable.propTypes = {
  setWarningSnackbar: PropTypes.func.isRequired,
  setSuccessSnackbarText: PropTypes.func.isRequired,
  setInfoSnackbarText: PropTypes.func.isRequired,
  setErrorSnackbarText: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  linksData: PropTypes.arrayOf(PropTypes.shape(linkData)).isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
};
