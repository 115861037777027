import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  createTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  ThemeProvider,
} from '@mui/material';
import TableSkeleton from './TableSkeleton';
import UserReviewHistoryTableRow from './UserReviewHistoryTableRow';

const theme = createTheme({
  components: {
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          marginRight: '-1.1em',
          marginLeft: '0.1em',
        },
      },
    },
  },
});

const tableCells = [
  { id: 'reviewGoal', name: 'Review Goal', width: '10%', textAlign: 'left', minWidth: 100 },
  { id: 'newGoal', name: 'New Goal', width: '10%', textAlign: 'left', minWidth: 100 },
  { id: 'status', name: 'Status', width: '5%', textAlign: 'left', minWidth: 100 },
  { id: 'confirmed', name: 'Confirmed', width: '10%', textAlign: 'right', minWidth: 100 },
  { id: 'declined', name: 'Declined', width: '10%', textAlign: 'right', minWidth: 100 },
  { id: 'progress', name: 'Progress', width: '25%', textAlign: 'center', minWidth: 200 },
  { id: 'updatedAt', name: 'Review Date', width: '30%', textAlign: 'left', minWidth: 100 },
];

export default function UserReviewHistoryTable({
  setIsLoading,
  reviewHistoryData,
  totalCount,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  setSort,
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('updatedAt');

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setIsLoading(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
    setIsLoading(true);
  };

  useEffect(() => {
    if (orderBy) {
      setSort(order === 'asc' ? orderBy : `-${orderBy}`);
      setIsLoading(true);
    }
  }, [order, orderBy, setSort, setIsLoading]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getTableSortLabelDirection = (tableCell) => (orderBy === tableCell.id ? order : 'asc');

  const getTableCells = () =>
    tableCells.map((tableCell) => (
      <TableCell
        key={tableCell.id}
        width={tableCell.width}
        sx={{ textAlign: tableCell.textAlign, minWidth: tableCell.minWidth }}
      >
        <TableSortLabel
          active={orderBy === tableCell.id}
          direction={getTableSortLabelDirection(tableCell)}
          onClick={() => handleSort(tableCell.id)}
        >
          {tableCell.name}
        </TableSortLabel>
      </TableCell>
    ));

  return (
    <ThemeProvider theme={theme}>
      <TableContainer>
        <Table size="small" aria-label="users table">
          <TableHead>
            <TableRow>{getTableCells()}</TableRow>
          </TableHead>
          <TableBody>
            {reviewHistoryData.length
              ? reviewHistoryData.map((history) => (
                  <UserReviewHistoryTableRow key={history._id} {...history} />
                ))
              : Array.from({ length: 10 }, (_, index) => <TableSkeleton key={index} />)}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                colSpan={7}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}

UserReviewHistoryTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  reviewHistoryData: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      declined: PropTypes.number.isRequired,
      confirmed: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      reviewGoal: PropTypes.string.isRequired,
      newGoal: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
    }),
  ).isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
};
