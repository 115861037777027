import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Grid,
  TableCell,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Collapse,
  Box,
  TableBody,
  Table,
  Tooltip,
} from '@mui/material';
import reviewConstants from '../../../../constants/review.constants';
import { BigHead } from '../../../../lib/core.cjs.development';
import apiService from '../../../../services/api.service';
import LevelProgress from '../../components/LevelProgress';
import ReviewRecordLink from './ReviewRecordLink';
import StatisticDialog from './StatisticDialog';
import SummaryDialog from './SummaryDialog';

export default function HistoryTableRow({
  _id,
  userId,
  declined,
  confirmed,
  status,
  updatedAt,
  email,
  avatar,
  department,
  reviewGoal,
  newGoal,
  fetchData,
  currentPage,
  rowsPerPage,
  setIsLoading,
  revertedIds,
  reviewHistoryData,
  progress,
  departmentName,
  historySearchInput,
  historySort,
  template,
  skillStatistic,
  reviewRecordLink,
  setErrorSnackbarText,
}) {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [open, setOpen] = useState(false);
  const [isSummaryDialogOpened, setIsSummaryDialogOpened] = useState(false);
  const [declinedSummary, setDeclinedSummary] = useState('');
  const [isStatisticDialogOpened, setIsStatisticDialogOpened] = useState(false);
  const [reviewStatistic, setReviewStatistic] = useState({});
  const [hasApprovedReview, setHasApprovedReview] = useState(false);

  useEffect(() => {
    const hasApproved = reviewHistoryData.some(
      (data) =>
        data.status === reviewConstants.REVIEW_STATUSES.CONFIRMED && data.reviewGoal === reviewGoal,
    );
    setHasApprovedReview(hasApproved);
  }, [reviewHistoryData, reviewGoal]);

  const handleMoreClick = (event) => {
    event.stopPropagation();
    setAnchorMenu(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorMenu(null);
  };

  const revertClick = async () => {
    try {
      setIsLoading(true);
      await apiService.head.processedReview(departmentName, _id);
      await fetchData(currentPage, rowsPerPage, historySearchInput, historySort);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const updateReviewLink = async ({ depName, reviewId, link }) => {
    try {
      setIsLoading(true);
      await apiService.head.updateReviewRecordLink({
        department: depName,
        reviewId,
        body: { reviewRecordLink: link },
      });
      await fetchData(currentPage, rowsPerPage, historySearchInput, historySort);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setErrorSnackbarText(err.response.data?.messages?.join(', ') || 'Something went wrong');
    }
  };

  const summaryClick = async (id) => {
    try {
      const summary = await apiService.developer.getDeclinedSummaryById(id);
      setDeclinedSummary(summary);
      setIsSummaryDialogOpened(true);
    } catch (err) {
      console.error(err);
    }
  };

  const statisticClick = async () => {
    try {
      const index = reviewHistoryData.findIndex((item) => item._id === _id);

      setReviewStatistic(skillStatistic[index]);
      setIsStatisticDialogOpened(true);
    } catch (error) {
      console.error(error);
    }
  };

  const reviewDate = new Date(updatedAt).toDateString();

  const filteredReviewHistoryData = reviewHistoryData.filter((reviewData) =>
    revertedIds?.includes(reviewData._id),
  );

  const reviewIndex = reviewHistoryData.findIndex((item) => item._id === _id);
  const isStatisticDisabled = !skillStatistic[reviewIndex].tasksWithStatistic.length;

  return (
    <>
      <TableRow>
        <TableCell padding="none">
          <Grid container alignItems="center">
            <Grid item width={50} height={50}>
              <BigHead {...avatar} />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="left">{email}</TableCell>
        <TableCell align="left">{department}</TableCell>
        <TableCell align="left">{reviewGoal}</TableCell>
        <TableCell align="left">
          <ReviewRecordLink
            reviewRecordLink={reviewRecordLink}
            updateReviewLink={async (link) => {
              await updateReviewLink({
                depName: departmentName,
                reviewId: _id,
                link,
              });
            }}
          />
        </TableCell>
        <TableCell align="left">{newGoal}</TableCell>
        <TableCell align="left">
          {status === reviewConstants.REVIEW_STATUSES.CONFIRMED && (
            <CheckCircleRoundedIcon color="success" />
          )}
          {status === reviewConstants.REVIEW_STATUSES.DECLINED && <CancelIcon color="error" />}
          {status === reviewConstants.REVIEW_STATUSES.REVERTED && <KeyboardReturnRoundedIcon />}
          {status === reviewConstants.REVIEW_STATUSES.PROCESSED && (
            <AccessTimeFilledIcon color="warning" />
          )}
          {revertedIds && status === reviewConstants.REVIEW_STATUSES.CONFIRMED && (
            <KeyboardReturnRoundedIcon />
          )}
          {revertedIds && status === reviewConstants.REVIEW_STATUSES.DECLINED && (
            <KeyboardReturnRoundedIcon />
          )}
        </TableCell>
        <TableCell align="right">{confirmed}</TableCell>
        <TableCell align="right">{declined}</TableCell>
        <TableCell align="center">
          <Grid container justifyContent="center">
            <LevelProgress value={progress} />
          </Grid>
        </TableCell>
        <TableCell align="left">{reviewDate}</TableCell>
        <TableCell>
          <IconButton disableRipple aria-label="more" id="long-button" onClick={handleMoreClick}>
            <MoreHorizIcon />
          </IconButton>
          <Menu
            anchorEl={anchorMenu}
            id="account-menu"
            open={!!anchorMenu}
            onClose={handleMoreClose}
            onClick={handleMoreClose}
          >
            <MenuItem
              disabled={
                status === reviewConstants.REVIEW_STATUSES.REVERTED ||
                status === reviewConstants.REVIEW_STATUSES.PROCESSED ||
                status === reviewConstants.REVIEW_STATUSES.CONFIRMED ||
                hasApprovedReview
              }
              onClick={() => {
                summaryClick(userId);
              }}
            >
              Summary
            </MenuItem>
            <MenuItem
              disabled={
                status === reviewConstants.REVIEW_STATUSES.REVERTED ||
                status === reviewConstants.REVIEW_STATUSES.PROCESSED ||
                status === reviewConstants.REVIEW_STATUSES.CONFIRMED ||
                hasApprovedReview
              }
              onClick={revertClick}
            >
              Revert
            </MenuItem>
            {isStatisticDisabled ? (
              <Tooltip title="Data is not available">
                <div>
                  <MenuItem disabled>Statistic</MenuItem>
                </div>
              </Tooltip>
            ) : (
              <MenuItem
                onClick={() => {
                  statisticClick();
                }}
              >
                Statistic
              </MenuItem>
            )}
          </Menu>
        </TableCell>
        <TableCell>
          {status !== reviewConstants.REVIEW_STATUSES.REVERTED && revertedIds && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={13} sx={{ borderBottom: 'none', padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableBody>
                {filteredReviewHistoryData.map((data) => (
                  <TableRow padding="0">
                    <TableCell sx={{ minWidth: 10, padding: 0 }}>
                      <Grid width={50} height={50}>
                        <BigHead {...data.avatar} />
                      </Grid>
                    </TableCell>
                    <TableCell width="10%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      {data.email}
                    </TableCell>
                    <TableCell width="15%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      {data.department}
                    </TableCell>
                    <TableCell width="5%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      {data.reviewGoal}
                    </TableCell>
                    <TableCell width="10%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      <ReviewRecordLink
                        reviewRecordLink={data.reviewRecordLink}
                        updateReviewLink={(link) => {
                          updateReviewLink({
                            depName: data.departmentName,
                            reviewId: data._id,
                            link,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell width="5%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      {data.newGoal}
                    </TableCell>
                    <TableCell width="5%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      <KeyboardReturnRoundedIcon />
                    </TableCell>
                    <TableCell width="5%" sx={{ textAlign: 'right', minWidth: 100 }}>
                      {data.confirmed}
                    </TableCell>
                    <TableCell width="5%" sx={{ textAlign: 'right', minWidth: 100 }}>
                      {data.declined}
                    </TableCell>
                    <TableCell width="15%" sx={{ textAlign: 'center', minWidth: 200 }}>
                      <Box container="true" justifyContent="center">
                        <LevelProgress value={data.progress} />
                      </Box>
                    </TableCell>
                    <TableCell width="15%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      {new Date(data.updatedAt).toDateString()}
                    </TableCell>
                    <TableCell width="10.5%" sx={{ textAlign: 'left', minWidth: 100 }} />
                    <TableCell width="9.5%" sx={{ textAlign: 'left', minWidth: 100 }} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
      {isSummaryDialogOpened && (
        <SummaryDialog
          isOpened={isSummaryDialogOpened}
          declinedSummary={declinedSummary}
          setSummaryOpen={setIsSummaryDialogOpened}
        />
      )}
      {isStatisticDialogOpened && (
        <StatisticDialog
          isOpened={isStatisticDialogOpened}
          setStatisticOpen={setIsStatisticDialogOpened}
          template={template}
          reviewStatistic={reviewStatistic}
        />
      )}
    </>
  );
}

const userData = {
  _id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatar: PropTypes.shape({}).isRequired,
};

HistoryTableRow.propTypes = {
  _id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  declined: PropTypes.number.isRequired,
  confirmed: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatar: PropTypes.shape({}).isRequired,
  department: PropTypes.string.isRequired,
  departmentName: PropTypes.string.isRequired,
  reviewGoal: PropTypes.string.isRequired,
  newGoal: PropTypes.string,
  progress: PropTypes.number.isRequired,
  fetchData: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  revertedIds: PropTypes.arrayOf(PropTypes.string),
  reviewHistoryData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  user: PropTypes.shape(userData),
  historySearchInput: PropTypes.string.isRequired,
  historySort: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired,
  skillStatistic: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  reviewRecordLink: PropTypes.string,
  setErrorSnackbarText: PropTypes.func.isRequired,
};

HistoryTableRow.defaultProps = {
  user: null,
  revertedIds: null,
  newGoal: '',
  reviewRecordLink: null,
};
