import { useEffect, useState, useReducer } from 'react';
import { Autorenew } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Alert, Snackbar } from '@mui/material';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useParams } from 'react-router-dom';
import apiService from '../../../services/api.service';
import ModalWindow from '../../Admin/Skills/components/ModalWindow';
import { ACTION_TYPES, initialState, reducer } from '../../Admin/Skills/reducer';
import HexGridTabs from './components/HexGridTabs';

export default function SkillsPage() {
  const { department } = useParams();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [successSnackBar, setSuccessSnackbar] = useState({
    status: false,
    message: '',
    alertColor: 'success',
  });

  const [skills, setSkills] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [itemForAction, setItemForAction] = useState(null);
  const createSkill = async (skillData) => {
    try {
      const result = await apiService.head.saveSkill(department, skillData);

      dispatch({ type: ACTION_TYPES.RESET_ALL });

      setSuccessSnackbar({
        status: true,
        message: 'Saved!',
      });
      setSkills({
        approvedSkills: skills.approvedSkills,
        requestedSkills: skills.requestedSkills.concat([result]),
      });
      setIsModalOpened(false);

      return result;
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.BATCH_SET,
        payload: { isCreate: false },
      });

      if (error.response?.data?.error instanceof Array) {
        error.response.data.error.forEach((err) => {
          dispatch({
            type: ACTION_TYPES.SET_FORM_ERRORS,
            payload: {
              ...state.formErrors,
              [err.source.pointer.split('/')[2]]: {
                status: true,
                messages: err.meta.join(', '),
              },
            },
          });
        });

        return null;
      }

      setSuccessSnackbar({
        status: true,
        message: error.response.data?.messages,
        alertColor: 'error',
      });

      return null;
    }
  };

  const updateSkill = async (id, skillData) => {
    try {
      const result = await apiService.head.updateSkill({
        id,
        data: skillData,
        department,
      });

      setSuccessSnackbar({
        status: true,
        message: 'Updated!',
      });
      dispatch({ type: ACTION_TYPES.RESET_ALL });
      setIsModalOpened(false);

      return result;
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.BATCH_SET,
        payload: { isUpdate: false },
      });

      if (error.response.data.error instanceof Array) {
        error.response.data.error.forEach((err) => {
          dispatch({
            type: ACTION_TYPES.SET_FORM_ERRORS,
            payload: {
              ...state.formErrors,
              [err.source.pointer.split('/')[2]]: {
                status: true,
                messages: err.meta.join(', '),
              },
            },
          });
        });

        return null;
      }

      setSuccessSnackbar({
        status: true,
        message: error.response.data?.messages,
        alertColor: 'error',
      });

      return null;
    }
  };

  const deleteSkill = async (id) => {
    try {
      const result = await apiService.head.deleteSkill(id, department);

      setSuccessSnackbar({
        status: true,
        message: 'Deleted!',
      });
      setSkills({
        approvedSkills: skills.approvedSkills,
        requestedSkills: skills.requestedSkills.filter((elem) => elem._id !== id),
      });

      dispatch({ type: ACTION_TYPES.RESET_ALL });

      return result;
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.BATCH_SET,
        payload: { isDelete: false },
      });

      return setSuccessSnackbar({
        status: true,
        message: error.response.data?.messages,
        alertColor: 'error',
      });
    }
  };

  const getSkill = async () => {
    try {
      const result = await apiService.head.getSkill(department);
      setSkills(result);
      setIsLoading(false);

      return skills;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return null;
    }
  };

  const uploadImage = async (url) => {
    try {
      const response = await fetch(url);
      const blobParts = await response.blob();
      const file = new File([blobParts], 'filename.jpeg', { type: 'image/jpeg' });
      const formData = new FormData();
      formData.append('file', file);

      const result = await apiService.admin.uploadSkillImage(formData);

      dispatch({
        type: ACTION_TYPES.SET_FORM_VALUES,
        payload: {
          formValues: {
            ...state.formValues,
            imageKey: result.Key,
            croppedImg: null,
          },
        },
      });

      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const update = async (url, formValues) => {
    try {
      const skillData = {
        title: formValues.title,
        description: formValues.description,
        tags: formValues.tags,
        version: parseInt(formValues.version, 10),
        url: formValues.url,
      };

      if (url) {
        const image = await uploadImage(url);

        if (!image) return null;

        const result = await updateSkill(formValues._id, {
          ...skillData,
          imageKey: image?.Key,
        });

        if (result) {
          const updatedSkills = skills.requestedSkills.map((skill) => {
            if (skill._id === formValues._id) {
              return {
                ...skill,
                ...skillData,
                imageUrl: image.Location,
              };
            }
            return skill;
          });

          setSkills({
            approvedSkills: skills.approvedSkills,
            requestedSkills: updatedSkills,
          });
        }

        return result;
      }

      const result = await updateSkill(formValues._id, skillData);

      if (!result) return null;

      const updatedSkills = skills.requestedSkills.map((skill) => {
        if (skill._id === formValues._id) {
          return {
            ...skill,
            ...skillData,
          };
        }
        return skill;
      });

      setSkills({
        approvedSkills: skills.approvedSkills,
        requestedSkills: updatedSkills,
      });

      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  useEffect(async () => {
    if (!state.isDelete) return;

    await deleteSkill(state.formValues._id);
  }, [state.isDelete]);

  useEffect(async () => {
    if (!state.isCreate || !state.croppedImg) return;

    if (!state.formValues.imageKey) await uploadImage(state.croppedImg);

    if (state.formValues.imageKey) {
      await createSkill({
        title: state.formValues.title,
        version: parseInt(state.formValues.version, 10),
        url: state.formValues.url,
        tags: state.formValues.tags,
        description: state.formValues.description,
        imageKey: state.formValues.imageKey,
      });
    }
  }, [state.isCreate, state.formValues.imageKey, state.croppedImg]);

  useEffect(async () => {
    if (!state.isUpdate) return;
    if (state.isUpdate && state.image && !state.croppedImg) return;

    await update(state.croppedImg, state.formValues);
  }, [state.croppedImg, state.isUpdate]);

  useEffect(async () => {
    if (isLoading) getSkill();
  }, [isLoading]);

  const menuEditItemOnClick = () => {
    setAnchorEl(null);
    dispatch({
      type: ACTION_TYPES.SET_FORM_VALUES,
      payload: {
        formValues: { ...state.formValues, ...itemForAction },
        isOpenedUpdate: true,
      },
    });
    setIsModalOpened(true);
  };

  const menuDeleteItemOnClick = () => {
    setAnchorEl(null);
    dispatch({
      type: ACTION_TYPES.SET_FORM_VALUES,
      payload: {
        formValues: { ...state.formValues, ...itemForAction },
        isDelete: true,
      },
    });
  };

  const menuRequestItemOnClick = async () => {
    await apiService.head.requestSkill(itemForAction._id, department);

    setSkills({
      approvedSkills: skills.approvedSkills,
      requestedSkills: skills.requestedSkills.map((elem) => {
        if (elem._id !== itemForAction._id) {
          return elem;
        }

        return {
          ...elem,
          status: 'REQUESTED',
        };
      }),
    });
    setAnchorEl(null);
    setSuccessSnackbar({ status: true, message: 'Requested!' });
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch({ type: ACTION_TYPES.RESET_ALL });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box>
        <ModalWindow
          isOpened={isModalOpened}
          setIsOpened={setIsModalOpened}
          dispatch={dispatch}
          state={state}
        />
      </Box>
      <Box width="70%" mt="-30px">
        <HexGridTabs
          isHeadPage
          setAnchorEl={setAnchorEl}
          setItemForAction={setItemForAction}
          skills={skills}
          setIsModalOpened={setIsModalOpened}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
        disableScrollLock
        PaperProps={{
          elevation: 0,
          sx: {
            border: 'solid 1px gray',
            borderRadius: '5px',
          },
        }}
      >
        <MenuItem onClick={menuEditItemOnClick}>
          <EditIcon
            sx={{
              color: 'rgba(0, 0, 0, 0.6)',
              marginRight: '12px',
            }}
          />
          Edit
        </MenuItem>
        <MenuItem onClick={menuDeleteItemOnClick}>
          <DeleteIcon
            sx={{
              color: 'rgba(0, 0, 0, 0.6)',
              marginRight: '12px',
            }}
          />
          Delete
        </MenuItem>
        {itemForAction && itemForAction.status === 'REJECTED' && (
          <MenuItem onClick={menuRequestItemOnClick}>
            <Autorenew
              sx={{
                color: 'rgba(0, 0, 0, 0.6)',
                marginRight: '12px',
              }}
            />
            Request
          </MenuItem>
        )}
      </Menu>
      <Snackbar
        open={successSnackBar.status}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          if (reason !== 'clickaway') {
            setSuccessSnackbar((prev) => ({
              ...prev,
              status: false,
            }));
          }
        }}
      >
        <Alert
          onClose={() =>
            setSuccessSnackbar((prev) => ({
              ...prev,
              status: false,
            }))
          }
          severity={successSnackBar.alertColor}
          sx={{ width: '100%' }}
        >
          {successSnackBar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
