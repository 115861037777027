import React from 'react';
import PropTypes from 'prop-types';
import CongratulatePopUp from './CongratulatePopUp';
import SchoolPride from './SchoolPride';

export default function Congratulation({ handleClose, congratulate }) {
  return (
    <>
      <SchoolPride start={congratulate} />
      <CongratulatePopUp handleClose={handleClose} open={congratulate} />
    </>
  );
}

Congratulation.propTypes = {
  congratulate: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
