import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Chip, TextField, Checkbox, FormControl, styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
    padding: '8px 2px',
  },
});

export default function FilterByTag({ tags, selectedTags, handleTagSelect }) {
  const [uniqueTags, setUniqueTags] = useState([]);

  useEffect(() => {
    const tagSet = new Set(tags);
    setUniqueTags(Array.from(tagSet));
  }, [tags]);

  const handleDeleteTag = (tagToDelete) => {
    const updatedTags = selectedTags.filter((tag) => tag !== tagToDelete);
    handleTagSelect(updatedTags);
  };

  return (
    <Box width="100%">
      <FormControl
        sx={{
          width: '100%',
          borderRadius: '.15em',
          maxWidth: '450px',
          padding: 2,
        }}
        size="small"
      >
        <StyledAutocomplete
          multiple
          id="tags-filter"
          options={uniqueTags}
          value={selectedTags}
          onChange={(event, newValue) => handleTagSelect(newValue)}
          size="small"
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((tag, index) => (
              <Chip
                key={tag}
                label={tag}
                onDelete={() => handleDeleteTag(tag)}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={<Box sx={{ paddingTop: '4px', textAlign: 'center' }}>Filter skills</Box>}
            />
          )}
          disableCloseOnSelect
        />
      </FormControl>
    </Box>
  );
}

FilterByTag.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleTagSelect: PropTypes.func.isRequired,
};
