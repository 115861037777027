import { Box, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const mainColor = '#7553EA';

export const StyledModalBody = styled(Box)(() => ({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  padding: '40px 32px',
  background: 'rgb(255, 255, 255)',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
}));

export const StyledButtonContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '32px',
}));

export const StyledButton = styled(Button)(() => ({
  background: mainColor,
  '&:hover': {
    backgroundColor: mainColor,
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  width: 440,
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: mainColor,
    },
  },
  '& textarea': {
    overflow: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  '& label.Mui-focused': {
    color: mainColor,
  },
}));

export const StyledModalTitle = styled(Typography)(() => ({
  fontWeight: 400,
  marginBottom: '24px',
}));
