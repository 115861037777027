import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Grid, TableCell, TableRow, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import UserConstants from '../../../../constants/user.constants';
import userRoles from '../../../../constants/userRoles';
import apiService from '../../../../services/api.service';
import dateFormatter from '../../../../utils/dateFormatter';
import getUserLastLoginDate from '../../../../utils/lastLoginDate';
import EnglishLevelsEnum from '../enums/englishLevels.enum';
import ModalWindow from './ModalWindow';
import RowOperations from './RowOperations';
import UserAutocomplete from './UserAutocomplete';
import UserAvatar from './UserAvatar';

export default function UserTableRow({ userData, departments, setSuccessSnackbar }) {
  const { enqueueSnackbar } = useSnackbar();
  const [editable, setEditable] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentDepartment, setCurrentDepartment] = useState({});
  const [englishLevel, setEnglishLevel] = useState(userData.englishLevel || '');
  const [currentGoal, setCurrentGoal] = useState({});
  const [currentLevel, setCurrentLevel] = useState({});
  const [currentIsAdmin, setCurrentIsAdmin] = useState(userData.roles.includes(userRoles.ADMIN));
  const [modal, setModal] = useState({
    isOpen: false,
    prevGoal: null,
    prevDepartment: null,
    prevLevel: null,
    prevEnglishLevel: null,
    prevIsAdmin: userData.roles.includes(userRoles.ADMIN),
    newGoal: null,
    newDepartment: null,
    newLevel: null,
    newEnglishLevel: null,
    newIsAdmin: null,
  });

  const englishLevelsArray = Object.entries(EnglishLevelsEnum).map(([key, { display, value }]) => ({
    key,
    display,
    value,
  }));

  useEffect(() => {
    if (departments?.length) {
      const foundedDepartment = departments.find(
        (department) => department._id === userData.departmentId,
      );
      const foundedGoal = foundedDepartment.levels.find((level) => level._id === userData.goal);
      const foundedLevel = foundedDepartment.levels.find((level) => level._id === userData.level);

      const { englishLevel: userEnglishLevel } = userData;
      const initialEnglishLevel = userEnglishLevel
        ? englishLevelsArray.find((item) => item.value === userEnglishLevel)
        : null;

      setEnglishLevel(initialEnglishLevel);

      setCurrentDepartment(foundedDepartment);
      setCurrentGoal(foundedGoal);
      setCurrentLevel(foundedLevel);
      setModal({
        ...modal,
        prevDepartment: foundedDepartment,
        prevGoal: foundedGoal,
        prevLevel: foundedLevel,
        prevEnglishLevel: initialEnglishLevel,
      });
      setCurrentIsAdmin(userData.roles.includes(userRoles.ADMIN));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    departments,
    userData.departmentId,
    userData.goal,
    userData.level,
    userData.roles,
    userData.englishLevel,
  ]);

  const handleCancel = () => {
    setCurrentDepartment(modal.prevDepartment);
    setCurrentGoal(modal.prevGoal);
    setCurrentLevel(modal.prevLevel);
    setCurrentIsAdmin(modal.prevIsAdmin);
    setEnglishLevel(modal.prevEnglishLevel);
    setModal({
      ...modal,
      isOpen: false,
      newGoal: null,
      newDepartment: null,
      newLevel: null,
      newEnglishLevel: null,
      newIsAdmin: null,
    });
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);

      const { prevGoal, prevDepartment, prevLevel, prevIsAdmin } = modal;

      const body = {
        ...(currentDepartment._id !== prevDepartment._id && {
          departmentId: currentDepartment._id,
        }),
        ...(currentLevel._id !== prevLevel._id && { level: currentLevel._id }),
        ...(currentGoal._id !== prevGoal._id && { goal: currentGoal._id }),
        ...(prevIsAdmin !== currentIsAdmin && { isAdmin: currentIsAdmin }),
        ...(englishLevel && { englishLevel: englishLevel.value }),
      };

      await apiService.admin.updateUser(userData._id, body);

      setIsSaving(false);
      setEditable(false);
      setModal({
        isOpen: false,
        prevGoal: modal.newGoal || modal.prevGoal,
        prevDepartment: modal.newDepartment || modal.prevDepartment,
        prevLevel: modal.newLevel || modal.prevLevel,
        prevEnglishLevel: modal.newEnglishLevel || modal.prevEnglishLevel,
        prevIsAdmin: modal.newIsAdmin === null ? modal.prevIsAdmin : modal.newIsAdmin,
        newGoal: null,
        newDepartment: null,
        newLevel: null,
        newEnglishLevel: null,
        newIsAdmin: null,
      });
      setSuccessSnackbar(true);
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      setEditable(false);
      handleCancel();
    }
  };

  const handleValidate = () => {
    const { prevGoal, prevDepartment, prevLevel, prevIsAdmin, prevEnglishLevel } = modal;

    if (
      prevGoal._id !== currentGoal._id ||
      prevDepartment._id !== currentDepartment._id ||
      prevLevel._id !== currentLevel._id ||
      prevIsAdmin !== currentIsAdmin ||
      prevEnglishLevel !== englishLevel
    ) {
      setModal({
        ...modal,
        isOpen: true,
        newGoal: prevGoal._id !== currentGoal._id ? currentGoal : null,
        newDepartment: prevDepartment._id !== currentDepartment._id ? currentDepartment : null,
        newLevel: prevLevel._id !== currentLevel._id ? currentLevel : null,
        newIsAdmin: prevIsAdmin !== currentIsAdmin ? currentIsAdmin : null,
        newEnglishLevel: prevEnglishLevel !== englishLevel ? englishLevel : null,
      });
    }
    setEditable(false);
  };

  const updateCurrentLevel = (newValue) => {
    setCurrentLevel(newValue);
    if (newValue.position > currentGoal.position) {
      setCurrentGoal(newValue);
    }
  };

  const updateEnglishLevel = (newValue) => {
    setEnglishLevel(newValue);
  };

  const onCancelClick = () => {
    handleCancel();
    setEditable(false);
  };

  const handleOnMakeEditable = () => {
    if (!userData.isSentReview) {
      setEditable(true);
    } else {
      enqueueSnackbar(UserConstants.ERROR_MESSAGES.CANNOT_EDIT_USER_PENDING_REVIEW, {
        variant: 'error',
      });
    }
  };
  return (
    <>
      <TableRow>
        <TableCell align="left">
          <Grid container justifyContent="left">
            <UserAvatar
              cardContainerSize={20}
              growAvatarWidth={40}
              growAvatarHeight={40}
              hydraAvatarWidth={30}
              hydraAvatarHeight={30}
              hydraMarginTop={-2}
              hydraProfileLink={userData.hydraProfileLink}
              avatar={userData.avatar}
            />
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Grid container justifyContent="left">
            {userData.lastName}
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Grid container justifyContent="left">
            {userData.firstName}
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Grid container justifyContent="left">
            {userData.email}
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Grid container justifyContent="left">
            {editable ? (
              <UserAutocomplete
                options={departments}
                value={currentDepartment}
                setValue={(newValue) => {
                  setCurrentDepartment(newValue);
                  setCurrentGoal(newValue.levels[0]);
                  setCurrentLevel(newValue.levels[0]);
                }}
                optionLabel={(option) => option.title}
              />
            ) : (
              currentDepartment.title
            )}
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Grid container justifyContent="center">
            {editable ? (
              <UserAutocomplete
                options={currentDepartment.levels}
                value={currentLevel}
                setValue={updateCurrentLevel}
                optionLabel={(option) => option.key}
              />
            ) : (
              currentLevel.key
            )}
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Grid container justifyContent="center">
            {editable ? (
              <UserAutocomplete
                options={currentDepartment.levels}
                value={currentGoal}
                setValue={(newValue) => {
                  setCurrentGoal(newValue);
                }}
                optionLabel={(option) => option.key}
                onOptionDisable={(option) => option.position < currentLevel.position}
              />
            ) : (
              currentGoal.key
            )}
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Grid container justifyContent="left">
            <Checkbox
              margin="0"
              align="left"
              checked={currentIsAdmin}
              onChange={(event) => setCurrentIsAdmin(event.target.checked)}
              disabled={!editable}
            />
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Grid container justifyContent="center">
            {editable ? (
              <UserAutocomplete
                options={englishLevelsArray}
                value={englishLevel}
                setValue={updateEnglishLevel}
                optionLabel={(option) => {
                  return option?.display || '';
                }}
              />
            ) : (
              englishLevel?.value || ''
            )}
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Grid container justifyContent="center">
            {dateFormatter.format(new Date(userData.createdAt)).split(',')[0]}
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Tooltip title={getUserLastLoginDate(userData.lastLoginAt)} placement="top">
            <Grid container justifyContent="center">
              {getUserLastLoginDate(userData.lastLoginAt).split(',')[0]}
            </Grid>
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          <RowOperations
            editable={editable}
            onEditClick={handleOnMakeEditable}
            onSaveClick={handleValidate}
            onCancelClick={onCancelClick}
            isSaving={isSaving}
          />
        </TableCell>
      </TableRow>
      <ModalWindow modal={modal} onAccept={handleSave} onDecline={handleCancel} />
    </>
  );
}

UserTableRow.propTypes = {
  userData: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    avatar: PropTypes.shape({}).isRequired,
    hydraProfileLink: PropTypes.string.isRequired,
    departmentId: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    goal: PropTypes.string.isRequired,
    englishLevel: PropTypes.string.isRequired,
    createdAt: PropTypes.string,
    lastLoginAt: PropTypes.string,
    isSentReview: PropTypes.bool.isRequired,
  }).isRequired,
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      levels: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          position: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          _id: PropTypes.string.isRequired,
        }),
      ).isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setSuccessSnackbar: PropTypes.func.isRequired,
};
