import { Box, styled } from '@mui/material';

const StyledIconContainer = styled(Box)(() => ({
  margin: '1em 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export default StyledIconContainer;
