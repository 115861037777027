import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

export default function ImagePreviewPopUp({ admin, imageUrl, openPopup, setOpenPopup }) {
  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  return (
    <Dialog open={openPopup} onClose={handleClosePopup} maxWidth={admin ? 'xl' : 'md'}>
      <DialogTitle />
      <DialogContent>
        {imageUrl && (
          <img
            src={imageUrl}
            alt="attachment"
            style={{
              maxWidth: '100%',
              maxHeight: 'calc(100vh - 64px)',
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

ImagePreviewPopUp.propTypes = {
  admin: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string.isRequired,
  openPopup: PropTypes.bool.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
};
