import PropTypes from 'prop-types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, Chip, TextField } from '@mui/material';

export default function CustomAutocomplete({ options, setIsLoading, setDepartmentsFilter }) {
  return (
    <Autocomplete
      multiple
      limitTags={1}
      disableCloseOnSelect
      options={options}
      size="small"
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      style={{ width: '240px' }}
      renderInput={(params) => <TextField {...params} label="Departments" />}
      renderTags={(value, getTagProps) => {
        return (
          <>
            {value.slice(0, 1).map((option, index) => (
              <Chip
                style={{ maxWidth: 80 }}
                {...getTagProps({ index })}
                key={option.name}
                label={option.title}
              />
            ))}

            {value.length > 1 && `+${value.length - 1}`}
          </>
        );
      }}
      onChange={(event, value) => {
        setDepartmentsFilter(value.map((elem) => elem.name).join(','));
        setIsLoading(true);
      }}
    />
  );
}

CustomAutocomplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setDepartmentsFilter: PropTypes.func.isRequired,
};
