import PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import {
  StyledButton,
  StyledButtonContainer,
} from '../../../components/RegenerateAvatarModal/RegenerateAvatarModal.styles';
import useOutdatedModal from '../hooks/UseOutdatedModal';
import { StyledBox, StyledModalBody, StyledTextField } from '../styles/OutdatedModal.styled';

export default function OutdatedModal({
  isOpen,
  onClose,
  onSave,
  isSaveDisabled,
  isSaveLoading,
  setSaveDisabled,
  details,
  parent,
}) {
  const {
    selectedMaterials,
    description,
    disabledMaterialIds,
    handleSave,
    handleChangeDescription,
    handleChangeMaterials,
  } = useOutdatedModal(setSaveDisabled, parent, details, onSave);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <StyledModalBody>
        <Box>
          <Typography
            margin="8px"
            color="#7553EA"
            fontSize="medium"
            fontWeight={500}
            display="block"
            textTransform="uppercase"
            align="center"
          >
            Report material?
          </Typography>
        </Box>
        <StyledBox>
          <Typography
            margin="8px"
            color="#7553EA"
            fontSize="medium"
            fontWeight={500}
            textTransform="uppercase"
          >
            {details.name}
          </Typography>
        </StyledBox>
        <Box>
          <FormControl sx={{ width: '100%', margin: '8px' }}>
            <Autocomplete
              multiple
              id="materials-autocomplete"
              options={details.materials.map((material) => material.name)}
              disableCloseOnSelect
              value={selectedMaterials}
              onChange={handleChangeMaterials}
              renderInput={(params) => <TextField {...params} label="Material" />}
              renderOption={(props, option, { selected }) => {
                const isDisabled = disabledMaterialIds.includes(
                  parent.details.find((material) => material.materialName === option)?.itemId,
                );
                return (
                  <li {...props}>
                    <Checkbox checked={selected} sx={{ marginRight: 2 }} disabled={isDisabled} />
                    <ListItemText
                      sx={{
                        color: isDisabled ? 'text.disabled' : 'text.primary',
                      }}
                      primary={option}
                    />
                  </li>
                );
              }}
            />
          </FormControl>
        </Box>
        <StyledTextField
          label="Description"
          multiline
          maxRows={4}
          value={description}
          onChange={handleChangeDescription}
        />
        <StyledButtonContainer>
          <StyledButton
            onClick={handleSave}
            variant="contained"
            disabled={isSaveDisabled || isSaveLoading}
          >
            {isSaveLoading ? <CircularProgress size={24} /> : 'Send'}
          </StyledButton>
        </StyledButtonContainer>
      </StyledModalBody>
    </Modal>
  );
}

OutdatedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isSaveDisabled: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  details: PropTypes.shape({
    itemId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    successCriteria: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.string.isRequired,
    materials: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  parent: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    skillType: PropTypes.string.isRequired,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        itemId: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
        materialType: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  setSaveDisabled: PropTypes.func.isRequired,
};

OutdatedModal.defaultProps = {
  isSaveDisabled: false,
  isSaveLoading: false,
};
