import { useEffect, useState } from 'react';
import apiService from '../../../services/api.service';
import NotificationsStatusEnum from '../enums/notifications-status.enum';
import NotificationsTypeEnum from '../enums/notifications-type.enum';

const useOutdatedModal = (setSaveDisabled, parent, details, onSave) => {
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedMaterialIds, setSelectedMaterialIds] = useState([]);
  const [selectedMaterialTypes, setSelectedMaterialTypes] = useState([]);
  const [selectedMaterialLinks, setSelectedMaterialLinks] = useState([]);
  const [description, setDescription] = useState('');
  const [disabledMaterialIds, setDisabledMaterialIds] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const notifications = await apiService.developer.getNotifications();

      const disabledIds = notifications.flatMap(
        (notification) => notification.details?.materialIds || [],
      );

      setDisabledMaterialIds(disabledIds);
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    if (details.materials.length === 1) {
      const materialName = details.materials[0].name;
      const material = parent.details.find((m) => m.materialName === materialName);
      setSelectedMaterials([materialName]);
      setSelectedMaterialIds([material.itemId]);
      setSelectedMaterialTypes([material.materialType || '']);
      setSelectedMaterialLinks([material.link || '']);
    }
  }, [details.materials, parent.details]);

  useEffect(() => {
    if (selectedMaterials.length > 0 && description.trim() !== '') {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [selectedMaterials, description]);

  const handleChangeMaterials = (event, value) => {
    const filteredValues = value.filter(
      (materialName) =>
        !disabledMaterialIds.includes(
          parent.details.find((material) => material.materialName === materialName)?.itemId,
        ),
    );

    setSelectedMaterials(filteredValues);

    const selectedIds = filteredValues.map(
      (materialName) =>
        parent.details.find((material) => material.materialName === materialName)?.itemId,
    );

    const selectedTypes = filteredValues.map(
      (materialName) =>
        parent.details.find((material) => material.materialName === materialName)?.materialType ||
        '',
    );

    const selectedLinks = filteredValues.map(
      (materialName) =>
        parent.details.find((material) => material.materialName === materialName)?.link || '',
    );

    setSelectedMaterialIds(selectedIds);
    setSelectedMaterialTypes(selectedTypes);
    setSelectedMaterialLinks(selectedLinks);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleSave = async () => {
    const user = await apiService.developer.getMySelf();
    const { _id: userId, departmentId } = user;

    const heads = await apiService.developer.getDepartments();
    const head = heads.find((el) => el._id === departmentId);
    const headIds = head?.headIds || [];

    const data = {
      userId,
      type: NotificationsTypeEnum.REPORT,
      status: NotificationsStatusEnum.PENDING,
      details: {
        materialIds: selectedMaterialIds,
        materialTypes: selectedMaterialTypes,
        links: selectedMaterialLinks,
        parentId: parent._id,
        parentName: parent.name,
        detailName: details.name,
        materialNames: selectedMaterials,
        description,
      },
      headIds,
    };

    onSave(data);
  };

  return {
    selectedMaterials,
    description,
    disabledMaterialIds,
    handleSave,
    handleChangeDescription,
    handleChangeMaterials,
  };
};

export default useOutdatedModal;
