import React, { useEffect, useState } from 'react';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import SendIcon from '@mui/icons-material/Send';
import { Alert, Button, Grid, IconButton, Paper, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import ApiService from '../../../services/api.service';
import { reorder, remove, addOne, update, validate } from '../helpers/levels.helpers';
import LevelsList from './components/LevelsList';

const StyledPaper = styled(Paper)(() => ({
  marginBottom: '1em',
  padding: '2em 3em',
  borderRadius: '.15em',
}));

export default function LevelsPage() {
  const { department } = useParams();

  const [levels, setLevels] = useState([]);
  const [defaultLevels, setDefaultLevels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const loadLevels = () => {
    ApiService.head.getDepartmentLevels(department).then((data) => {
      setLevels(data);
      setDefaultLevels(defaultLevels);

      setIsLoading(false);
    });
  };

  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const items = reorder({
      levels,
      start: source.index,
      end: destination.index,
    });

    setLevels(items);
  };

  const onRemoveLevel = (index) => {
    const items = remove(levels, index);

    setLevels(items);
  };

  const onAddLevel = () => {
    const items = addOne(levels);

    setLevels(items);
  };

  const onInput = (title, index) => {
    if (title) {
      setError(false);
    }

    const items = update({
      levels,
      title,
      index,
    });

    setLevels(items);
  };

  const onSave = () => {
    const validationError = validate(levels);

    if (validationError) {
      enqueueSnackbar(validationError, { variant: 'error' });
      return;
    }

    ApiService.head
      .updateDepartmentLevels(department, levels)
      .then(() => {
        enqueueSnackbar('Saved!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        setIsLoading(true);
      })
      .catch(({ response }) => {
        enqueueSnackbar(response.data.messages, { variant: 'error' });
      });
  };

  useEffect(() => {
    if (isLoading) {
      loadLevels();
    }
  });

  return (
    <Grid sx={{ margin: '30px 36px 0' }}>
      <Grid container justifyContent="center">
        <Grid container item xs={12} md={9} lg={6} component={StyledPaper} elevation={8}>
          <Grid item mb={2} xs={12}>
            <Alert variant="outlined" severity="warning">
              Be careful! Saved levels cannot be deleted!
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <LevelsList
              setError={setError}
              isLoading={isLoading}
              levels={levels}
              onDragEnd={onDragEnd}
              onRemove={onRemoveLevel}
              onInput={onInput}
            />
          </Grid>
          <Grid item pt={3} container xs={12} justifyContent="space-between">
            <Grid>
              <IconButton disabled={isLoading} onClick={onAddLevel}>
                <AddCircleRoundedIcon />
              </IconButton>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={onSave}
                disabled={isLoading || error}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
