import { useState } from 'react';
import useDebouncedHandler from '../../../../hooks/useDebouncedHandler';
import apiService from '../../../../services/api.service';

const useEmployeesData = () => {
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [pickedEmployees, setPickedEmployees] = useState([]);

  const fetchEmployees = async (event) => {
    const search = event.target.value;

    if (search.length < 3) {
      return null;
    }

    const result = await apiService.admin.getUsers({
      params: { 'filter[email]': search },
    });

    const newEmployeesArr = [
      ...pickedEmployees,
      ...result.paginatedResult.map((item) => ({
        id: item._id,
        email: item.email,
      })),
    ].filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

    setEmployeesOptions(newEmployeesArr);

    return null;
  };

  const { debouncedHandler } = useDebouncedHandler(fetchEmployees);

  return {
    employeesOptions,
    pickedEmployees,
    fetchEmployees: debouncedHandler,
    setPickedEmployees,
  };
};

export default useEmployeesData;
