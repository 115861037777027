import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import {
  Grid,
  TableCell,
  TableRow,
  IconButton,
  Link,
  Collapse,
  Box,
  TableBody,
  Table,
} from '@mui/material';
import reviewConstants from '../../../../constants/review.constants';
import { BigHead } from '../../../../lib/core.cjs.development';
import LevelProgress from '../../../Head/components/LevelProgress';

export default function ReviewsTableRow({
  keyId,
  declined,
  confirmed,
  status,
  updatedAt,
  email,
  avatar,
  department,
  reviewGoal,
  newGoal,
  revertedIds,
  reviewHistoryData,
  progress,
  reviewRecordLink,
}) {
  const [open, setOpen] = useState(false);

  const reviewDate = new Date(updatedAt).toDateString();

  const filteredReviewHistoryData = reviewHistoryData.filter((reviewData) =>
    revertedIds?.includes(reviewData._id),
  );

  return (
    <>
      <TableRow key={keyId}>
        <TableCell padding="none">
          <Grid container alignItems="center">
            <Grid item width={50} height={50}>
              <BigHead {...avatar} />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="left">{email}</TableCell>
        <TableCell align="left">{department}</TableCell>
        <TableCell align="left">{reviewGoal}</TableCell>
        <TableCell align="left">
          {reviewRecordLink ? (
            <Link href={reviewRecordLink} target="_blank" rel="noopener" mr={0.5}>
              Record
            </Link>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell align="left">{newGoal}</TableCell>
        <TableCell align="left">
          {status === reviewConstants.REVIEW_STATUSES.CONFIRMED && (
            <CheckCircleRoundedIcon color="success" />
          )}
          {status === reviewConstants.REVIEW_STATUSES.DECLINED && <CancelIcon color="error" />}
          {status === reviewConstants.REVIEW_STATUSES.REVERTED && <KeyboardReturnRoundedIcon />}
          {status === reviewConstants.REVIEW_STATUSES.PROCESSED && (
            <AccessTimeFilledIcon color="warning" />
          )}
          {revertedIds && status === reviewConstants.REVIEW_STATUSES.CONFIRMED && (
            <KeyboardReturnRoundedIcon />
          )}
          {revertedIds && status === reviewConstants.REVIEW_STATUSES.DECLINED && (
            <KeyboardReturnRoundedIcon />
          )}
        </TableCell>
        <TableCell align="right">{confirmed}</TableCell>
        <TableCell align="right">{declined}</TableCell>
        <TableCell align="center">
          <Grid container justifyContent="center">
            <LevelProgress value={progress} />
          </Grid>
        </TableCell>
        <TableCell align="left">{reviewDate}</TableCell>
        <TableCell>
          {status !== reviewConstants.REVIEW_STATUSES.REVERTED && revertedIds && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={13} sx={{ borderBottom: 'none', padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableBody>
                {filteredReviewHistoryData.map((data) => (
                  <TableRow key={data._id} padding="0">
                    <TableCell sx={{ minWidth: 10, padding: 0 }}>
                      <Grid width={50} height={50}>
                        <BigHead {...data.avatar} />
                      </Grid>
                    </TableCell>
                    <TableCell width="10%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      {data.email}
                    </TableCell>
                    <TableCell width="15%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      {data.department}
                    </TableCell>
                    <TableCell width="5%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      {data.reviewGoal}
                    </TableCell>
                    <TableCell width="5%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      {data.reviewRecordLink ? (
                        <Link href={data.reviewRecordLink} target="_blank" rel="noopener" mr={0.5}>
                          Record
                        </Link>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell width="5%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      {data.newGoal}
                    </TableCell>
                    <TableCell width="5%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      <KeyboardReturnRoundedIcon />
                    </TableCell>
                    <TableCell width="5%" sx={{ textAlign: 'right', minWidth: 100 }}>
                      {data.confirmed}
                    </TableCell>
                    <TableCell width="5%" sx={{ textAlign: 'right', minWidth: 100 }}>
                      {data.declined}
                    </TableCell>
                    <TableCell width="15%" sx={{ textAlign: 'center', minWidth: 200 }}>
                      <Box container="true" justifyContent="center">
                        <LevelProgress value={data.progress} />
                      </Box>
                    </TableCell>
                    <TableCell width="15%" sx={{ textAlign: 'left', minWidth: 100 }}>
                      {new Date(data.updatedAt).toDateString()}
                    </TableCell>
                    <TableCell width="15%" sx={{ textAlign: 'left', minWidth: 100 }} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const userData = {
  _id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatar: PropTypes.shape({}).isRequired,
};

ReviewsTableRow.propTypes = {
  _id: PropTypes.string.isRequired,
  keyId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  declined: PropTypes.number.isRequired,
  confirmed: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatar: PropTypes.shape({}).isRequired,
  department: PropTypes.string.isRequired,
  departmentName: PropTypes.string.isRequired,
  reviewGoal: PropTypes.string.isRequired,
  newGoal: PropTypes.string,
  progress: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  revertedIds: PropTypes.arrayOf(PropTypes.string),
  reviewHistoryData: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.shape(userData),
  historySearchInput: PropTypes.string.isRequired,
  historySort: PropTypes.string.isRequired,
  reviewRecordLink: PropTypes.string,
};

ReviewsTableRow.defaultProps = {
  user: null,
  revertedIds: null,
  newGoal: '',
  reviewRecordLink: null,
};
