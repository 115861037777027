import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Box, Fab, Grid, Paper, Snackbar, styled } from '@mui/material';
import NoInfoFoundAlert from '../../../components/NoInfoFoundAlert';
import adminConstants from '../../../constants/adminPages';
import apiService from '../../../services/api.service';
import AdminBasicPage from '../../../templates/AdminBasicPage';
import ModalWindow from './components/ModalWindow';
import NewsLinksTable from './components/NewsLinksTable';

const StyledItem = styled(Paper)(() => ({
  marginBottom: '1em',
  padding: '0 3em',
  borderRadius: '.15em',
  marginTop: '4em',
}));

export default function AdminNewsLinks({ crumbs, title }) {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [successSnackbarText, setSuccessSnackbarText] = useState(null);
  const [infoSnackbarText, setInfoSnackbarText] = useState(null);
  const [errorSnackbarText, setErrorSnackbarText] = useState(null);
  const [warningSnackbarText, setWarningSnackbarText] = useState(null);
  const [linksData, setLinksData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [apiKeysSort, setApiKeysSort] = useState('expirationDate');
  const [department, setDepartment] = useState({ name: 'nodejs' });
  const fetchLinks = async (skip, limit, sort) => {
    try {
      const response = await apiService.admin.getRssLinks({
        params: {
          skip: skip * limit,
          limit,
          sort,
        },
      });
      setCurrentPage(skip);
      setLinksData(response[0].paginatedResult);
      setTotalCount(response[0].totalCount);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (isLoading) {
      fetchLinks(currentPage, rowsPerPage, apiKeysSort);
    }
  }, [isLoading]);
  return (
    <AdminBasicPage crumbs={crumbs} title={title}>
      <Grid sx={{ margin: '8px 36px 0' }} item>
        <StyledItem
          elevation={8}
          container="true"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid display="flex" justifyContent="flex-end">
            <Fab
              sx={{ top: '-28px' }}
              color="primary"
              aria-label="add"
              onClick={() => setIsModalOpened(true)}
            >
              <AddIcon />
            </Fab>
            <ModalWindow
              isOpened={isModalOpened}
              isLoading={isLoading}
              setIsOpened={setIsModalOpened}
              setIsLoading={setIsLoading}
              department={department}
              setDepartment={setDepartment}
              changeDepartment={setDepartment}
              setSuccessSnackbarText={setSuccessSnackbarText}
              setInfoSnackbarText={setInfoSnackbarText}
              setErrorSnackbarText={setErrorSnackbarText}
              setWarningSnackbarText={setWarningSnackbarText}
            />
          </Grid>
          {linksData.length || isLoading ? (
            <Grid xs={12} item>
              <NewsLinksTable
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                linksData={linksData}
                totalCount={totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setSort={setApiKeysSort}
                setSuccessSnackbarText={setSuccessSnackbarText}
                setInfoSnackbarText={setInfoSnackbarText}
                setErrorSnackbarText={setErrorSnackbarText}
                setWarningSnackbar={setWarningSnackbarText}
              />
            </Grid>
          ) : (
            <Box display="flex" justifyContent="center" marginBottom="24px">
              <NoInfoFoundAlert message={adminConstants.ERROR_MESSAGES.NO_AVAILABLE_LINKS} />
            </Box>
          )}
        </StyledItem>
      </Grid>
      <Snackbar
        open={!!successSnackbarText}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarText(null)}
      >
        <Alert
          onClose={() => setSuccessSnackbarText(null)}
          severity="success"
          sx={{ width: '100%' }}
        >
          {successSnackbarText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!infoSnackbarText}
        autoHideDuration={6000}
        onClose={() => setInfoSnackbarText(null)}
      >
        <Alert onClose={() => setInfoSnackbarText(null)} severity="info" sx={{ width: '100%' }}>
          {infoSnackbarText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorSnackbarText}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbarText(null)}
      >
        <Alert
          onClose={() => setErrorSnackbarText(null)}
          severity="error"
          sx={{
            width: '100%',
          }}
        >
          {errorSnackbarText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!warningSnackbarText}
        autoHideDuration={6000}
        onClose={() => setWarningSnackbarText(null)}
      >
        <Alert
          onClose={() => setWarningSnackbarText(null)}
          severity="warning"
          sx={{
            width: '100%',
          }}
        >
          {warningSnackbarText}
        </Alert>
      </Snackbar>
    </AdminBasicPage>
  );
}

AdminNewsLinks.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};
