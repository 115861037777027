import { Box, Grid, styled } from '@mui/material';
import TabsWrapper from './TabsWrapper';

export const StyledFilterContainer = styled(Box)(() => ({
  flex: '0 1 150px',
  marginRight: '8px',
}));

export const HeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '48px',
}));

export const StyledTabsWrapper = styled(TabsWrapper)(() => ({
  flex: '1',
}));

export const StyledSearchWrapper = styled(Grid)(() => ({
  flex: '1',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
}));
