import PropTypes from 'prop-types';
import { Box, Grid, TextField, ThemeProvider } from '@mui/material';
import StyledTreeItemCustom from '../styled-components/StyledTreeItemCustom';
import StyledTextFieldContainer from '../styled-components/TextFieldContainer';

import TreeNodeTheme from '../themes/TreeNodeTheme';
import PlainCodeDetailsForm from './PlainCodeDetailsForm';

export default function PlainTreeNode({ nodes, onClick }) {
  const node = nodes[0];

  const getSubTree = (itemId) => {
    const subTree = [];

    const recursion = (id) => {
      const parent = nodes.find((el) => el.itemId === id);
      subTree.push(parent);
      parent.children.forEach((el) => recursion(el));
    };

    recursion(itemId);

    return subTree;
  };

  return (
    <ThemeProvider theme={TreeNodeTheme}>
      <StyledTreeItemCustom
        nodeId={node.itemId}
        onClick={() => onClick(node.itemId)}
        label={
          <StyledTextFieldContainer container>
            <Grid item>
              <TextField
                defaultValue={node.name}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </StyledTextFieldContainer>
        }
      >
        {node.children.length > 0
          ? node.children
              .filter((child) => nodes.find((el) => el.itemId === child))
              .map((item) => (
                <PlainTreeNode key={item} nodes={getSubTree(item)} onClick={onClick} />
              ))
          : node.details.map((detail) => (
              <Box sx={{ margin: '1em 0' }} key={detail.itemId}>
                <PlainCodeDetailsForm key={detail.itemId} node={detail} />
              </Box>
            ))}
      </StyledTreeItemCustom>
    </ThemeProvider>
  );
}

const treeNodeShape = {
  itemId: PropTypes.string,
  name: PropTypes.string,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      successCriteria: PropTypes.string,
      materialName: PropTypes.string,
      materialType: PropTypes.string,
      link: PropTypes.string,
      level: PropTypes.string,
    }),
  ),
  children: PropTypes.arrayOf(PropTypes.string).isRequired,
};

PlainTreeNode.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.shape(treeNodeShape)).isRequired,
  onClick: PropTypes.func.isRequired,
};
