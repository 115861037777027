import React from 'react';
import { TableCell, TableRow, styled, Skeleton } from '@mui/material';

const SkeletonAvatarTableCell = styled(TableCell)({
  padding: 0,
  height: '57px',
});

export default function TableSkeleton() {
  return (
    <TableRow>
      <SkeletonAvatarTableCell>
        <Skeleton width={40} height={40} variant="circular" />
      </SkeletonAvatarTableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
    </TableRow>
  );
}
