import React from 'react';
import PropTypes from 'prop-types';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Helmet } from 'react-helmet-async';
import BreadcrumbsComponent from '../components/BreadcrumbsComponent';
import HeadTopBar from '../sections/HeadTopBar';

const theme = createTheme({ palette: { primary: { main: '#7553EA' } } });

export default function HeadBasicPage({ children, crumbs, title }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SnackbarProvider
        iconVariant={{
          error: (
            <Box sx={{ mr: 1 }}>
              <ErrorOutlineOutlinedIcon color="#fffff" />
            </Box>
          ),
        }}
        maxSnack={5}
      >
        <ThemeProvider theme={theme}>
          <HeadTopBar />
          <BreadcrumbsComponent crumbs={crumbs} />
          {children}
        </ThemeProvider>
      </SnackbarProvider>
    </>
  );
}

HeadBasicPage.propTypes = {
  children: PropTypes.node.isRequired,
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};
