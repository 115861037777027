import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import NET from 'vanta/dist/vanta.net.min';

export default function VantaBackground({ children }) {
  const vantaRef = useRef(null);

  useEffect(() => {
    let vantaEffect;

    const initializeVanta = () => {
      const isMobile = window.innerWidth <= 768;

      vantaEffect = NET({
        el: vantaRef.current,
        THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 500.0,
        minWidth: 300.0,
        scale: isMobile ? 1.5 : 1.0,
        scaleMobile: isMobile ? 1.0 : 1.0,
        scaleToWindow: true,
        spacing: isMobile ? 30.0 : 15.0,
        points: isMobile ? 6.0 : 9.0,
        maxDistance: isMobile ? 30.0 : 20.0,
        showDots: true,
        color: 0x7553ea,
        backgroundColor: 0xffffff,
      });
    };

    const handleResize = () => {
      if (vantaEffect) {
        vantaEffect.destroy();
        initializeVanta();
      }
    };

    initializeVanta();
    window.addEventListener('resize', handleResize);

    return () => {
      if (vantaEffect) vantaEffect.destroy();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{ width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0, zIndex: -1 }}
      ref={vantaRef}
    >
      {children}
    </div>
  );
}

VantaBackground.propTypes = {
  children: PropTypes.node.isRequired,
};
