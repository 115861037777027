import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import growLogo from '../assets/images/logo-gray.svg';
import FormInput from '../components/FormInput';
import useAuth from '../hooks/useAuth';
import useForm from '../hooks/useForm';

const theme = createTheme({ palette: { primary: { main: '#7553EA' } } });

export default function Register() {
  const { values, handleChange } = useForm({
    initialValues: {
      email: '',
      password: '',
    },
  });

  // eslint-disable-next-line no-unused-vars
  const { registerUser, error } = useAuth();

  const handleRegister = async (e) => {
    e.preventDefault();
    await registerUser(values);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            sx={{
              height: 56,
              width: 56,
            }}
            alt="logo"
            src={growLogo}
          />
          <Box component="form" noValidate onSubmit={handleRegister} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormInput
                  type="text"
                  label="Email"
                  name="email"
                  value={values.email}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  type="password"
                  label="Password"
                  name="password"
                  value={values.password}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
              }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Link to="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
