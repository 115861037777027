import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import RowSkeleton from '../../Users/components/RowSkeleton';
import ApiKeyTableRow from './ApiKeyTableRow';

const tableCells = [
  {
    id: 'name',
    name: 'Key name',
    width: '15%',
    textAlign: 'left',
    align: 'left',
    sort: true,
  },
  {
    id: 'prefix',
    name: 'Prefix',
    width: '15%',
    textAlign: 'left',
    align: 'left',
    sort: true,
  },
  {
    id: 'scopes',
    name: 'Scopes',
    width: '15%',
    textAlign: 'left',
    align: 'left',
    sort: false,
  },
  {
    id: 'createdAt',
    name: 'Created',
    width: '15%',
    textAlign: 'left',
    align: 'left',
    sort: true,
  },
  {
    id: 'updatedAt',
    name: 'Last Used',
    width: '15%',
    textAlign: 'left',
    align: 'left',
    sort: true,
  },
  {
    id: 'expirationDate',
    name: 'Expires',
    width: '15%',
    textAlign: 'left',
    align: 'left',
    sort: true,
  },
];

export default function ApiKeysTable({
  isLoading,
  setIsLoading,
  apiKeysData,
  totalCount,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  setSort,
}) {
  const [successSnackbarText, setSuccessSnackbarText] = useState(null);
  const [infoSnackbarText, setInfoSnackbarText] = useState(null);
  const [errorSnackbarText, setErrorSnackbarText] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('expirationDate');

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setIsLoading(true);
  };

  const handleChangeRowsPerPage = (event) => {
    const parsedPage = parseInt(event.target.value, 10);
    setRowsPerPage(parsedPage);
    setCurrentPage(0);
    setIsLoading(true);
  };

  useEffect(() => {
    if (orderBy) {
      setSort(order === 'asc' ? orderBy : `-${orderBy}`);
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getTableSortLabelDirection = (tableCell) => (orderBy === tableCell.id ? order : 'asc');

  const getTableCells = () =>
    tableCells.map((tableCell) => (
      <TableCell
        key={tableCell.id}
        width={tableCell.width}
        sx={{ textAlign: tableCell.textAlign, minWidth: 100 }}
        align={tableCell.align}
      >
        {tableCell.sort ? (
          <TableSortLabel
            active={orderBy === tableCell.id}
            direction={getTableSortLabelDirection(tableCell)}
            onClick={() => handleSort(tableCell.id)}
          >
            {tableCell.name}
          </TableSortLabel>
        ) : (
          tableCell.name
        )}
      </TableCell>
    ));

  return (
    <TableContainer>
      <Table size="small" aria-label="api keys table">
        <TableHead>
          <TableRow>
            {getTableCells()}
            <TableCell width="5%" sx={{ textAlign: 'center', minWidth: 100 }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? [1, 2, 3, 4, 5].map((item) => <RowSkeleton key={item} />)
            : apiKeysData.map((apiKey) => (
                <ApiKeyTableRow
                  key={apiKey._id}
                  apiKeyData={apiKey}
                  setSuccessSnackbar={(text) => setSuccessSnackbarText(text)}
                  setInfoSnackbar={(text) => setInfoSnackbarText(text)}
                  setErrorSnackbar={(text) => setErrorSnackbarText(text)}
                  setIsLoading={setIsLoading}
                />
              ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={10}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <Snackbar
        open={!!successSnackbarText}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarText(null)}
      >
        <Alert
          onClose={() => setSuccessSnackbarText(null)}
          severity="success"
          sx={{ width: '100%' }}
        >
          {successSnackbarText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!infoSnackbarText}
        autoHideDuration={6000}
        onClose={() => setInfoSnackbarText(null)}
      >
        <Alert onClose={() => setInfoSnackbarText(null)} severity="info" sx={{ width: '100%' }}>
          {infoSnackbarText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorSnackbarText}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbarText(null)}
      >
        <Alert onClose={() => setErrorSnackbarText(null)} severity="error" sx={{ width: '100%' }}>
          {errorSnackbarText}
        </Alert>
      </Snackbar>
    </TableContainer>
  );
}

const apiKeyData = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  scopes: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  lastUsedAt: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

ApiKeysTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  apiKeysData: PropTypes.arrayOf(PropTypes.shape(apiKeyData)).isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
};
