import { PropTypes } from 'prop-types';
import StyledAutocomplete from '../styles/StyledAutocomplete';

function EmployeesFilter({
  employeesOptions,
  pickedEmployees,
  fetchEmployees,
  setPickedEmployees,
}) {
  return (
    <StyledAutocomplete
      label="Employees"
      keyName="email"
      setValue={setPickedEmployees}
      options={employeesOptions}
      handleInputChange={fetchEmployees}
      value={pickedEmployees}
      setIsLoading={() => {}}
    />
  );
}

export default EmployeesFilter;

EmployeesFilter.propTypes = {
  employeesOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  pickedEmployees: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchEmployees: PropTypes.func.isRequired,
  setPickedEmployees: PropTypes.func.isRequired,
};
