import { createTheme } from '@mui/material';

const TreeNodeTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: { margin: '5px 0' },
        input: { padding: '7.5px' },
      },
    },
    MuiFormControl: { styleOverrides: { root: { width: '100%' } } },
  },
  palette: { primary: { main: '#7553EA' } },
});

export default TreeNodeTheme;
