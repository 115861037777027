import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { Box, TextField, Tooltip, IconButton, ThemeProvider, Grid } from '@mui/material';
import randomId from '../../../../utils/randomId';
import StyledTreeItemCustom from '../styled-components/StyledTreeItemCustom';
import StyledTextFieldContainer from '../styled-components/TextFieldContainer';
import TreeNodeTheme from '../themes/TreeNodeTheme';
import CodeDetailsForm from './CodeDetailsForm';

export default function TreeNode({
  node,
  treeData,
  updateTreeNode,
  addTreeNode,
  removeTreeNode,
  growLevels,
  onClick,
  isDetailActive,
  setIsDetailActive,
}) {
  const [detailsAmount, setDetailsAmount] = useState(node.details.length);

  const nodeChildren = treeData.filter((item) => node.children.includes(item.itemId));
  const treeDataChildrenNames = treeData.map((child) => child.name).toString();
  const elementsCount = treeData.reduce((acc, cur) => {
    const count = cur.details.length + 1;
    return acc + count;
  }, 0);

  const listenAddNewDetails = useCallback(() => {
    const itemId = randomId();
    updateTreeNode(node.itemId, {
      details: [
        ...node.details,
        {
          itemId,
          name: '',
          successCriteria: '',
          materialName: '',
          materialType: '',
          link: '',
          level: growLevels[0]._id,
        },
      ],
    });

    setTimeout(() => {
      const element = document.getElementById(itemId);
      element.scrollIntoView({ behavior: 'smooth' });
    });
  }, [treeDataChildrenNames, elementsCount, isDetailActive]);

  const listenRemoveDetails = useCallback(
    (data) => {
      updateTreeNode(node.itemId, {
        details: node.details.filter((d) => d.itemId !== data.itemId),
      });
    },
    [treeDataChildrenNames, elementsCount, isDetailActive],
  );

  const listenUpdateDetails = useCallback(
    (data) => {
      const newDetails = node.details.map((d) => (d.itemId === data.itemId ? data : d));
      if (node.details.findIndex((detail) => detail.itemId === data.itemId) === -1) {
        newDetails.push(data);
      }
      updateTreeNode(node.itemId, {
        details: newDetails,
      });
    },
    [treeDataChildrenNames, elementsCount, isDetailActive],
  );

  return (
    <ThemeProvider theme={TreeNodeTheme}>
      <StyledTreeItemCustom
        nodeId={node.itemId}
        onClick={() => onClick(node.itemId)}
        label={
          <StyledTextFieldContainer container>
            <Grid item md={6} sm={9}>
              <TextField
                value={node.name}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  updateTreeNode(node.itemId, { name: e.target.value });
                }}
              />
            </Grid>
            <Box>
              <Tooltip title="Add child">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    addTreeNode(node.itemId);
                  }}
                >
                  <AddCircleRoundedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remove">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    removeTreeNode(node.itemId);
                  }}
                >
                  <RemoveCircleRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </StyledTextFieldContainer>
        }
      >
        {node.children.length > 0
          ? nodeChildren.map((child) => (
              <TreeNode
                key={child.itemId}
                node={child}
                treeData={treeData}
                updateTreeNode={updateTreeNode}
                addTreeNode={addTreeNode}
                removeTreeNode={removeTreeNode}
                growLevels={growLevels}
                onClick={onClick}
                isDetailActive={isDetailActive}
                setIsDetailActive={setIsDetailActive}
              />
            ))
          : node.details.map((item, index) => (
              <CodeDetailsForm
                key={item.itemId}
                node={item}
                updateDetails={listenUpdateDetails}
                removeDetails={listenRemoveDetails}
                addNewDetails={listenAddNewDetails}
                isLastItem={index === node.details.length - 1}
                growLevels={growLevels}
                itemId={node.itemId}
                isDetailActive={isDetailActive}
                setIsDetailActive={setIsDetailActive}
                detailsAmount={detailsAmount}
                setDetailsAmount={setDetailsAmount}
              />
            ))}
      </StyledTreeItemCustom>
    </ThemeProvider>
  );
}

const treeNodeShape = {
  itemId: PropTypes.string,
  name: PropTypes.string,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      successCriteria: PropTypes.string,
      materialName: PropTypes.string,
      materialType: PropTypes.string,
      link: PropTypes.string,
      level: PropTypes.string,
    }),
  ),
  children: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TreeNode.propTypes = {
  node: PropTypes.shape(treeNodeShape).isRequired,
  treeData: PropTypes.arrayOf(PropTypes.shape(treeNodeShape)).isRequired,
  updateTreeNode: PropTypes.func.isRequired,
  addTreeNode: PropTypes.func.isRequired,
  removeTreeNode: PropTypes.func.isRequired,
  growLevels: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  isDetailActive: PropTypes.bool.isRequired,
  setIsDetailActive: PropTypes.func.isRequired,
};
