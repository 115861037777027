import styled from '@emotion/styled';
import { Paper } from '@mui/material';

const TreeViewFormWrapper = styled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1em',
  borderRadius: '.15em',
}));

export default TreeViewFormWrapper;
