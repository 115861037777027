import axios from 'axios';
import qs from 'qs';
import TokenService from './token.service';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  retries: 2,
  headers: { 'Content-Type': 'application/json' },
});

let count = 0;

instance.interceptors.request.use(
  (config) => {
    const _config = { ...config };
    const guestToken = sessionStorage.getItem('guestToken');
    const token = TokenService.getLocalAccessToken();

    if (guestToken || token) {
      _config.headers.Authorization = `Bearer ${guestToken || token}`;
    }
    return _config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  ({ data }) => data.data,
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig?.url !== '/auth/sign-in' && err.response) {
      count += 1;

      // Access Token was expired
      if (err.response.status === 401 && !(originalConfig.retries <= count)) {
        try {
          const params = new URLSearchParams(window.location.search);
          const oldRefreshToken = TokenService.getLocalRefreshToken();

          const { accessToken, refreshToken } = await instance.post('/auth/refresh-token', {
            refreshToken: oldRefreshToken || params.get('refresh-token'),
          });

          if (params.has('refresh-token') && params.has('access-token')) {
            // eslint-disable-next-line no-restricted-globals
            history.replaceState(null, null, window.location.href.split('?')[0]);
          }

          TokenService.updateLocalAccessToken(accessToken);
          TokenService.updateLocalRefreshToken(refreshToken);

          count = 0;

          return instance(originalConfig);
        } catch (_error) {
          TokenService.clearTokens();

          if (err.response.status === 401) {
            window.location.href = '/login';
          }

          return Promise.reject(_error);
        }
      }

      return Promise.reject(err);
    }

    return Promise.reject(err);
  },
);

instance.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, {
    arrayFormat: 'brackets',
  });
};

export default instance;
