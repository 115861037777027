import { useEffect, useRef, useState } from 'react';
import NotificationsStatusEnum from '../../pages/Home/enums/notifications-status.enum';
import apiService from '../../services/api.service';

const useNotification = (user) => {
  const [headNotification, setHeadNotification] = useState([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: '0px', left: '0px' });
  const [isHead, setIsHead] = useState(false);

  const notificationRef = useRef(null);

  const updateModalPosition = () => {
    if (notificationRef.current) {
      const rect = notificationRef.current.getBoundingClientRect();
      const popUpWidth = Math.min(350, window.innerWidth - 100);
      setModalPosition({
        top: `${rect.top + window.scrollY}px`,
        left: `${Math.max(5, rect.left + window.scrollX - popUpWidth)}px`,
        right: `${Math.max(5, rect.right + window.scrollX - popUpWidth)}px`,
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    const getNotification = async () => {
      const { roles, _id } = user;
      if (roles.includes('HEAD')) {
        setIsHead(true);
        const notifications = await apiService.head.getHeadNotification(_id);

        const newNotifications = notifications.filter(
          (el) =>
            el.status === NotificationsStatusEnum.PENDING ||
            el.status === NotificationsStatusEnum.OPENED,
        );

        if (isMounted && newNotifications.length) {
          setHeadNotification(newNotifications);
        }
      }
    };

    getNotification();

    return () => {
      isMounted = false;
    };
  }, [user]);

  useEffect(() => {
    window.addEventListener('resize', updateModalPosition);

    return () => {
      window.removeEventListener('resize', updateModalPosition);
    };
  }, []);

  const onOpenModal = () => {
    updateModalPosition();
    setIsModalOpened(true);
  };

  const onCloseModal = () => {
    setIsModalOpened(false);
  };

  const handleStatusChange = (id, status) => {
    setHeadNotification((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification._id === id ? { ...notification, status } : notification,
      ),
    );
  };

  const pendingNotificationsCount = headNotification.filter(
    (notification) => notification.status === NotificationsStatusEnum.PENDING,
  ).length;

  return {
    headNotification,
    isModalOpened,
    modalPosition,
    notificationRef,
    isHead,
    onOpenModal,
    onCloseModal,
    handleStatusChange,
    pendingNotificationsCount,
  };
};

export default useNotification;
