import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardActionArea, CardActions, CardContent, Grid, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import LineLoader from '../../components/LineLoader';
import NoInfoFoundAlert from '../../components/NoInfoFoundAlert';
import adminConstants from '../../constants/adminPages';
import UserContext from '../../hooks/context/UserContext';
import useOnScreen from '../../hooks/useOnScreen';
import ApiService from '../../services/api.service';
import BasicPage from '../../templates/BasicPage';

const LoadTrigger = styled('div')({
  position: 'absolute',
  minHeight: '1px',
  minWidth: '100%',
  bottom: '200px',
  left: 0,
});

export default function News({ title }) {
  const { user } = useContext(UserContext);
  const [news, setNews] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(20);
  const [lastElement, setLastElement] = useState(false);
  const [skip, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [, setImgError] = useState(false);

  const itemRef = useRef(null);

  const handleImageError = () => {
    setImgError(true);
  };

  const fetchNews = async () => {
    setIsLoading(true);
    try {
      const response = await ApiService.developer.getNews(user.departmentId, {
        params: {
          skip,
          limit,
        },
      });
      setTotalCount(response.totalCount ?? 0);
      setNews((prevNews) => [...prevNews, ...response.items]);
      setLastElement(response.isLastElement);
      setSkip((prevSkip) => prevSkip + limit);
    } catch (error) {
      console.error('Failed to fetch news:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isVisible = useOnScreen(itemRef);
  useEffect(() => {
    if (isVisible && news.length > 0 && !isLoading && !lastElement && totalCount) {
      fetchNews();
    }
  }, [isVisible]);

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <BasicPage title={title}>
      <Grid
        sx={{
          position: 'relative',
          marginRight: '10%',
          marginLeft: '10%',
          marginTop: '30px',
          '@media (max-width: 800px)': {
            marginRight: '5%',
            marginLeft: '5%',
          },
          '@media (max-width: 400px)': {
            marginRight: '2%',
            marginLeft: '2%',
          },
        }}
      >
        {news.length === 0 && !isLoading && (
          <Box display="flex" justifyContent="center" marginBottom="24px">
            <NoInfoFoundAlert message={adminConstants.ERROR_MESSAGES.NO_AVAILABLE_NEWS} />
          </Box>
        )}

        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 4 }}>
          <Masonry gutter="24px">
            {news.map((item) => (
              <a
                key={item.url}
                href={item.url}
                target="_blank"
                style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
                rel="noreferrer"
              >
                <Card>
                  <CardActionArea>
                    {item.image && (
                      <img
                        src={item.image}
                        srcSet={item.image}
                        alt={item.title}
                        loading="lazy"
                        style={{
                          borderBottomLeftRadius: 4,
                          borderBottomRightRadius: 4,
                          display: 'block',
                          width: '100%',
                        }}
                        onError={handleImageError}
                      />
                    )}
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {item.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button target="_blank" size="small">
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </a>
            ))}
          </Masonry>
        </ResponsiveMasonry>
        <LoadTrigger ref={itemRef} />
      </Grid>
      <LineLoader sx={{ visibility: isLoading && news.length > limit ? 'visible' : 'hidden' }} />
    </BasicPage>
  );
}

News.propTypes = {
  title: PropTypes.string.isRequired,
};
