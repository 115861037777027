import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  TableContainer,
  TableHead,
  styled,
  Snackbar,
  Alert,
  TableSortLabel,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import DepartmentTableRow from './DepartmentTableRow';
import RowSkeleton from './RowSkeleton';

const CircleHeaderCell = styled(TableCell)({});

const tableCells = [
  {
    id: uuid(),
    name: '',
    width: '5%',
    minWidth: 72,
    align: 'left',
  },
  {
    id: 'title',
    name: 'Departments',
    width: '10%',
    minWidth: 160,
    align: 'left',
    sort: true,
  },
  {
    id: 'heads',
    name: 'Heads',
    width: '75%',
    minWidth: 450,
    align: 'left',
  },
  {
    id: 'employeesCount',
    name: 'Employee',
    width: '5%',
    minWidth: 90,
    align: 'right',
    sort: true,
  },
];

export default function DepartmentsTable({
  isLoading,
  setIsLoading,
  departmentsData,
  totalCount,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  setSort,
}) {
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState({
    isOpen: false,
    message: '',
  });
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('employeesCount');

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setIsLoading(true);
  };

  const handleChangeRowsPerPage = (event) => {
    const parsedPage = parseInt(event.target.value, 10);
    setRowsPerPage(parsedPage);
    setCurrentPage(0);
    setIsLoading(true);
  };

  useEffect(
    () => {
      if (orderBy) {
        setSort(order === 'asc' ? orderBy : `-${orderBy}`);
        setIsLoading(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy],
  );

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'dsc';
    setOrder(isAsc ? 'asc' : 'dsc');
    setOrderBy(property);
  };

  const getTableSortLabelDirection = (tableCell) => (orderBy === tableCell.id ? order : 'asc');

  const getTableCells = () =>
    tableCells.map((tableCell) => (
      <TableCell
        key={tableCell.id}
        width={tableCell.width}
        align={tableCell.align}
        sx={{
          minWidth: tableCell.minWidth,
        }}
      >
        {tableCell.sort ? (
          <TableSortLabel
            active={orderBy === tableCell.id}
            direction={getTableSortLabelDirection(tableCell)}
            onClick={() => handleSort(tableCell.id)}
          >
            {tableCell.name}
          </TableSortLabel>
        ) : (
          tableCell.name
        )}
      </TableCell>
    ));

  return (
    <TableContainer>
      <Table size="small" aria-label="users table">
        <TableHead>
          <TableRow>
            {getTableCells()}
            <CircleHeaderCell sx={{ minWidth: 78 }} width="5%" align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? [1, 2, 3, 4, 5].map((item) => <RowSkeleton key={item} />)
            : departmentsData.map((department) => (
                <DepartmentTableRow
                  key={department._id}
                  department={department}
                  setSuccessSnackbar={setSuccessSnackbar}
                  setErrorSnackbar={setErrorSnackbar}
                />
              ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={7}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbar(false)}
      >
        <Alert onClose={() => setSuccessSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Saved!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar.isOpen}
        autoHideDuration={6000}
        onClose={() =>
          setErrorSnackbar({
            isOpen: false,
            message: '',
          })
        }
      >
        <Alert
          onClose={() =>
            setErrorSnackbar({
              isOpen: false,
              message: '',
            })
          }
          severity="error"
          sx={{ width: '100%' }}
        >
          {errorSnackbar.message}
        </Alert>
      </Snackbar>
    </TableContainer>
  );
}

const userData = {
  _id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  avatar: PropTypes.shape({}).isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  goal: PropTypes.string.isRequired,
};

DepartmentsTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  departmentsData: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      employeesCount: PropTypes.number.isRequired,
      heads: PropTypes.arrayOf(PropTypes.shape(userData)).isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
};
