import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  TableContainer,
  TableHead,
  styled,
  TableSortLabel,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import PreLoadUsersTableRow from './PreLoadUsersTableRow';
import UsersTableRow from './UsersTableRow';

const AvatarHeaderCell = styled(TableCell)({
  minWidth: 40,
  width: 50,
  maxWidth: 50,
});

const theme = createTheme({
  components: {
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          marginLeft: '-1.1em',
          marginRight: '0.1em',
        },
      },
    },
  },
});

const tableCells = [
  {
    id: uuid(),
    name: '',
    minWidth: 300,
    align: 'left',
  },
  {
    id: 'achievements',
    name: 'Achievements',
    minWidth: 256,
    align: 'left',
  },
  {
    id: 'level',
    name: 'Current level',
    minWidth: 120,
    align: 'right',
    sort: true,
  },
  {
    id: 'progress',
    name: 'Progress',
    minWidth: 150,
    align: 'right',
    sort: true,
  },
  {
    id: 'goal',
    name: 'Goal',
    minWidth: 80,
    align: 'right',
    sort: true,
  },
  {
    id: 'englishLevel',
    name: 'English',
    minWidth: 50,
    align: 'left',
    sort: true,
  },
  {
    id: 'lastLoginAt',
    name: 'LastLoginDate',
    minWidth: 80,
    align: 'right',
    sort: true,
  },
  {
    id: uuid(),
    name: '',
    minWidth: 80,
    align: 'right',
  },
];

const StyledTableContainer = styled(TableContainer)({
  width: '100%',
  overflowX: 'auto',
});

export default function UsersTable({
  onArchiveUser,
  onUnArchiveUser,
  setIsLoading,
  isLoading,
  usersData,
  growLevels,
  setSort,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalCount,
}) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('level');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsLoading(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setIsLoading(true);
  };

  useEffect(() => {
    if (orderBy) {
      setSort(order === 'asc' ? orderBy : `-${orderBy}`);
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getTableSortLabelDirection = (tableCell) => (orderBy === tableCell.id ? order : 'asc');

  const getTableCells = () => {
    return tableCells.map((tableCell) => (
      <TableCell
        key={tableCell.id}
        sx={{
          textAlign: 'center',
          minWidth: tableCell.minWidth,
        }}
        align={`${tableCell.align}`}
      >
        {tableCell.sort ? (
          <TableSortLabel
            active={orderBy === tableCell.id}
            direction={getTableSortLabelDirection(tableCell)}
            onClick={() => handleSort(tableCell.id)}
          >
            {tableCell.name}
          </TableSortLabel>
        ) : (
          tableCell.name
        )}
      </TableCell>
    ));
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledTableContainer>
        <Table size="small" aria-label="users table">
          <TableHead>
            <TableRow>
              <AvatarHeaderCell align="left" />
              {getTableCells()}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? [1, 2, 3, 4, 5].map((item) => <PreLoadUsersTableRow key={item} />)
              : usersData.map((user) => (
                  <UsersTableRow
                    {...user}
                    key={user._id}
                    onUnArchive={onUnArchiveUser}
                    onArchive={onArchiveUser}
                    growLevels={growLevels}
                    onSave={() => setIsLoading(true)}
                  />
                ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                colSpan={10}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </StyledTableContainer>
    </ThemeProvider>
  );
}

const userData = {
  _id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  avatar: PropTypes.shape({}).isRequired,
  departmentId: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  goal: PropTypes.string.isRequired,
  englishLevel: PropTypes.string.isRequired,
  lastLoginAt: PropTypes.string.isRequired,
};

UsersTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  onArchiveUser: PropTypes.func.isRequired,
  onUnArchiveUser: PropTypes.func.isRequired,
  growLevels: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setSort: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  usersData: PropTypes.arrayOf(PropTypes.shape(userData)).isRequired,
};
