import React from 'react';
import PropTypes from 'prop-types';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { IconButton, Tooltip } from '@mui/material';
import ContentTypes from '../../../constants/content-types';

export default function AttachmentButton({
  itemId,
  contentType,
  contentInput,
  uploadedItems,
  onConfirmCriteriaClick,
}) {
  return (
    <>
      {contentType === ContentTypes.comment && (
        <Tooltip title="Add a comment">
          <IconButton
            onClick={(event) =>
              onConfirmCriteriaClick(event, { itemId, contentType, contentInput })
            }
          >
            <CommentOutlinedIcon
              fontSize="small"
              color={contentInput || uploadedItems[itemId] ? 'primary' : 'disabled'}
            />
          </IconButton>
        </Tooltip>
      )}
      {contentType === ContentTypes.file && (
        <Tooltip title="Add an attachment">
          <IconButton
            onClick={(event) =>
              onConfirmCriteriaClick(event, { itemId, contentType, contentInput })
            }
          >
            <AttachmentIcon
              fontSize="small"
              color={contentInput || uploadedItems[itemId] ? 'primary' : 'disabled'}
            />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

AttachmentButton.propTypes = {
  itemId: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  contentInput: PropTypes.string,
  uploadedItems: PropTypes.shape({}).isRequired,
  onConfirmCriteriaClick: PropTypes.func.isRequired,
};

AttachmentButton.defaultProps = {
  contentType: null,
  contentInput: null,
};
