import React, { useContext, useMemo } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsNoneSharpIcon from '@mui/icons-material/NotificationsNoneSharp';
import { Grid, ThemeProvider } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import GrowLogo from '../components/GrowLogo';
import headPages from '../constants/headPages';
import userRoles from '../constants/userRoles';
import UserContext from '../hooks/context/UserContext';
import useLogout from '../hooks/useLogout';
import UserAvatar from '../pages/Admin/Users/components/UserAvatar';
import useNotification from './hooks/UseNotification';
import NotificationPreviewPopUp from './modals/NotificationPreviewPopUp';
import {
  StyledBadge,
  StyledIconButton,
  StyledMenuContainer,
  StyledTab,
  theme,
} from './styles/TopBar.styled';

export default function HeadTopBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const history = useHistory();
  const { user } = useContext(UserContext);

  const {
    headNotification,
    isModalOpened,
    modalPosition,
    notificationRef,
    isHead,
    onOpenModal,
    onCloseModal,
    handleStatusChange,
    pendingNotificationsCount,
  } = useNotification(user);

  const userAvatar = useMemo(() => user?.avatar, [user]);

  const { logoutUser } = useLogout();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const redirectTo = (path) => {
    history.push(`/head${path}`);
    handleCloseNavMenu();
  };

  const adminUser = user?.roles.includes(userRoles.ADMIN);

  const currentLocation = useMemo(() => {
    const pathArr = history.location.pathname.split('/');
    return `/${pathArr[pathArr.length - 1]}`;
  }, [history]);

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Container maxWidth="false">
          <Toolbar disableGutters>
            <Box>
              <GrowLogo width={100} height={30} />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: 'flex',
                  md: 'none',
                },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: {
                    xs: 'block',
                    md: 'none',
                  },
                }}
              >
                {headPages.map((page) => (
                  <MenuItem
                    key={page.path}
                    selected={currentLocation === page.path}
                    onClick={() => redirectTo(page.path)}
                  >
                    <Typography textTransform="capitalize" textAlign="center">
                      {page.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <StyledMenuContainer
              value={currentLocation}
              onChange={(event, newValue) => redirectTo(newValue)}
              textColor="secondary"
              indicatorColor="secondary"
            >
              {headPages.map((page) => (
                <StyledTab
                  key={page.path}
                  value={page.path === currentLocation ? page.path : currentLocation}
                  label={page.title}
                />
              ))}
            </StyledMenuContainer>
            {isHead && (
              <StyledIconButton onClick={onOpenModal}>
                <Tooltip
                  title="Notifications"
                  sx={{ display: 'flex', justifyContent: 'center' }}
                  ref={notificationRef}
                >
                  <StyledBadge
                    badgeContent={pendingNotificationsCount}
                    color="error"
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <NotificationsNoneSharpIcon />
                  </StyledBadge>
                </Tooltip>
              </StyledIconButton>
            )}
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <UserAvatar
                      cardContainerSize={40}
                      growAvatarWidth={58}
                      growAvatarHeight={58}
                      hydraAvatarWidth={42}
                      hydraAvatarHeight={42}
                      hydraMarginTop={3}
                      hydraProfileLink={user.hydraProfileLink}
                      avatar={userAvatar}
                    />
                  </Grid>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {adminUser && (
                  <MenuItem key="admin" onClick={() => history.push('/admin/departments')}>
                    <Typography textAlign="center">Admin</Typography>
                  </MenuItem>
                )}
                <MenuItem key="home" onClick={() => history.push('/home')}>
                  <Typography textAlign="center">Home</Typography>
                </MenuItem>
                <MenuItem key="logout" onClick={logoutUser}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
        {isModalOpened && (
          <NotificationPreviewPopUp
            isOpen={isModalOpened}
            onClose={onCloseModal}
            notificationData={headNotification}
            onStatusChange={handleStatusChange}
            position={modalPosition}
          />
        )}
      </AppBar>
    </ThemeProvider>
  );
}
