import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export default function LevelProgress({ value, height, fontSize, disabled }) {
  const [bar, setBar] = useState('');
  const [background, setBackground] = useState('');

  useEffect(() => {
    setBackground('#FFFFFF');

    if (disabled) {
      setBar('#999');
      return;
    }

    if (value < 30) {
      setBar('#F44336');
    } else if (value < 70) {
      setBar('#F5D498');
    } else {
      setBar('#73AF6D');
    }
  }, [disabled, value]);

  const Progress = styled(LinearProgress)(() => ({
    border: '1px solid #D7D7D7',
    borderRadius: '4px',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: background,
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: bar,
    },
  }));

  return (
    <Grid container justify="space-between">
      <Grid position="relative" item xs={12}>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography fontSize={fontSize}>{`${value}%`}</Typography>
        </div>
        <Progress sx={{ height }} variant="determinate" value={value} />
      </Grid>
    </Grid>
  );
}

LevelProgress.propTypes = {
  value: PropTypes.number.isRequired,
  height: PropTypes.number,
  fontSize: PropTypes.string,
  disabled: PropTypes.bool,
};

LevelProgress.defaultProps = {
  height: 24,
  fontSize: '1rem',
  disabled: false,
};
