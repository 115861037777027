import React from 'react';
import PropTypes from 'prop-types';
import { Link, ListItemText, TableCell, TableRow, Tooltip } from '@mui/material';

const EllipsisText = {
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export default function LinkViewsTableRow({ keyId, link, views }) {
  const ListItemPrimary = <div style={EllipsisText}>{link}</div>;

  return (
    <TableRow key={keyId}>
      <TableCell align="left">
        <Tooltip title={link} placement="top">
          <Link href={link} underline="none">
            <ListItemText primary={ListItemPrimary} />
          </Link>
        </Tooltip>
      </TableCell>
      <TableCell align="left">{views}</TableCell>
    </TableRow>
  );
}

LinkViewsTableRow.propTypes = {
  keyId: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  views: PropTypes.number.isRequired,
};
