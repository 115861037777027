import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Fab, Tab } from '@mui/material';
import Box from '@mui/material/Box';
import HexGrid from '../../../Hexagon/components/HexGrid';

export default function HexGridAdminTabs({
  setAnchorEl,
  setItemForAction,
  skills,
  isAdminPage,
  setIsModalOpened,
}) {
  const [tabValue, setTabValue] = useState('1');

  const storageTabKey = 'adminSkillsActiveTab';
  const getFromStorage = () => {
    try {
      const activeTab = localStorage.getItem(storageTabKey);

      if (!activeTab) {
        return null;
      }

      setTabValue(activeTab);
      return activeTab;
    } catch (err) {
      console.error(err);

      return null;
    }
  };

  const handleTabChange = (event, newValue) => {
    localStorage.setItem(storageTabKey, newValue);

    setTabValue(newValue);
  };

  useEffect(() => {
    getFromStorage();
  }, []);

  return (
    <div
      style={{
        gridTemplateColumns: 'repeat(2, 1fr)',
        columnGap: '20px',
      }}
    >
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'relative', left: '96%', top: '76px' }}
        onClick={() => setIsModalOpened(true)}
        style={{ visibility: tabValue === '1' ? 'visible' : 'hidden' }}
      >
        <AddIcon />
      </Fab>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab value="1" label="Approved" />
            <Tab value="2" label="Need to review" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <HexGrid
            setAnchorEl={setAnchorEl}
            setItemForAction={setItemForAction}
            skills={skills?.approvedSkills || []}
            isAdminPage={isAdminPage}
          />
        </TabPanel>
        <TabPanel value="2">
          <HexGrid
            setAnchorEl={setAnchorEl}
            setItemForAction={setItemForAction}
            skills={skills?.requestedSkills || []}
            isAdminPage={isAdminPage}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
}

HexGridAdminTabs.propTypes = {
  isAdminPage: PropTypes.bool,
  setAnchorEl: PropTypes.func.isRequired,
  setItemForAction: PropTypes.func.isRequired,
  setIsModalOpened: PropTypes.func.isRequired,
  skills: PropTypes.shape({
    approvedSkills: PropTypes.arrayOf(PropTypes.shape({})),
    requestedSkills: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

HexGridAdminTabs.defaultProps = {
  isAdminPage: false,
};
