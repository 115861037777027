import { useEffect, useState } from 'react';
import apiService from '../../../../services/api.service';
import buildActivityChartOptions from '../../../../utils/dashboard/build-activity-chart-options';
import generateDailyCategories from '../../../../utils/dashboard/generate-daily-categories';

const fetchDepartmentsActivity = async ({
  pickedDepartments,
  startPickerValue,
  endPickerValue,
}) => {
  const departmentIdsString = pickedDepartments.map((dept) => dept._id).join(',');
  const dayEventStatistic = await apiService.admin.getAdminDepartmentsDashboardStatistic(
    [
      `startDate=${startPickerValue.toISOString()}`,
      `endDate=${endPickerValue.toISOString()}`,
      `departmentIds=${departmentIdsString}`,
    ].join('&'),
  );

  const categories = generateDailyCategories(startPickerValue, endPickerValue);
  const series = [
    { name: 'LINK_CLICKED', data: Array(categories.length).fill(0) },
    { name: 'DONE', data: Array(categories.length).fill(0) },
    { name: 'ON_HOLD', data: Array(categories.length).fill(0) },
    { name: 'IN_PROGRESS', data: Array(categories.length).fill(0) },
  ];

  Object.keys(dayEventStatistic).forEach((key) => {
    const date = key;
    const eventCount = dayEventStatistic[date];

    const index = categories.indexOf(date);
    if (index > -1) {
      series.forEach((item) => {
        Reflect.set(item.data, index, eventCount[item.name] || 0);
      });
    }
  });

  return { categories, series };
};

const useDepartmentsActivity = ({ startPickerValue, endPickerValue, pickedDepartments }) => {
  const [departmentsChartSeries, setDepartmentsChartSeries] = useState([]);
  const [departmentsChartOptions, setDepartmentsChartOptions] = useState({});

  useEffect(() => {
    if (
      Number.isNaN(startPickerValue.toDate().valueOf()) ||
      Number.isNaN(endPickerValue.toDate().valueOf())
    )
      return;

    const loadData = async () => {
      const { categories, series } = await fetchDepartmentsActivity({
        pickedDepartments,
        startPickerValue,
        endPickerValue,
      });

      setDepartmentsChartSeries(series);
      setDepartmentsChartOptions(buildActivityChartOptions({ categories }));
    };

    loadData();
  }, [pickedDepartments, startPickerValue, endPickerValue]);

  return {
    departmentsChartSeries,
    departmentsChartOptions,
  };
};

export default useDepartmentsActivity;
