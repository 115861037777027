import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import NotificationsStatusEnum from '../../pages/Home/enums/notifications-status.enum';
import useNotificationItem from '../hooks/UseNotificationItem';
import { StatusIndicator, StyledItemBox, StyledTypography } from '../styles/Notification.styled';
import NotificationModal from './NotificationModal';

function NotificationItem({ notification, onStatusChange }) {
  const { user, isModalOpen, onOpenModal, onCloseModal, handleKeyPress, formatDate } =
    useNotificationItem(notification, onStatusChange);

  return (
    (notification.status === NotificationsStatusEnum.OPENED ||
      notification.status === NotificationsStatusEnum.PENDING) && (
      <StyledItemBox key={notification._id}>
        {notification.status === NotificationsStatusEnum.PENDING && <StatusIndicator />}
        <Box
          display="flex"
          alignItems="center"
          sx={{
            marginLeft: notification.status === NotificationsStatusEnum.PENDING ? '8px' : '0',
          }}
        >
          <Box>
            <StyledTypography sx={{ fontSize: '1.3rem', fontWeight: 700 }}>
              {notification.details.detailName} {notification.type.toLowerCase()}
            </StyledTypography>
            <StyledTypography sx={{ fontWeight: 400, color: 'rgb(48, 55, 65)' }}>
              {user.firstName} {user.lastName} sent a {notification.type.toLowerCase()} message
              about invalid data or an issue with data.&nbsp;
              <span
                role="button"
                tabIndex={0}
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={onOpenModal}
                onKeyPress={handleKeyPress}
              >
                Open for more information
              </span>
              .
            </StyledTypography>
            <Typography variant="caption" color="textSecondary">
              {formatDate(notification.createdAt)}
            </Typography>
          </Box>
        </Box>
        {isModalOpen && (
          <NotificationModal
            isOpen={isModalOpen}
            onClose={onCloseModal}
            notification={notification}
            user={user}
          />
        )}
      </StyledItemBox>
    )
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    headIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    details: PropTypes.shape({
      materialIds: PropTypes.arrayOf(PropTypes.string).isRequired,
      materialTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      links: PropTypes.arrayOf(PropTypes.string).isRequired,
      parentId: PropTypes.string.isRequired,
      parentName: PropTypes.string.isRequired,
      detailName: PropTypes.string.isRequired,
      materialNames: PropTypes.arrayOf(PropTypes.string).isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

export default NotificationItem;
