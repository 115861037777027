import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiService from '../../../../services/api.service';

const useHeadDepartment = () => {
  const [departmentId, setDepartmentId] = useState(null);
  const [departmentName, setDepartmentName] = useState('');
  const [loading, setLoading] = useState(true);
  const { department } = useParams();

  const fetchDepartment = async () => {
    try {
      const departments = await apiService.developer.getDepartments();
      const headDepartment = departments.find((el) => el.name === department);
      setDepartmentId(headDepartment?._id || null);
      setDepartmentName(headDepartment?.name || '');
    } catch (error) {
      console.error('Failed to fetch department:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, [department]);

  return {
    departmentName,
    departmentId,
    loading,
  };
};

export default useHeadDepartment;
