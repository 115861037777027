export default class ChartBuilder {
  constructor() {
    this.chart = {};
    this.colors = [];
    this.dataLabels = {};
    this.fill = {};
    this.forecastDataPoints = {};
    this.grid = {};
    this.labels = [];
    this.legend = {};
    this.markers = {};
    this.noData = {};
    this.plotOptions = {};
    this.responsive = [];
    this.states = {};
    this.stroke = {};
    this.subtitle = {};
    this.theme = {};
    this.title = {};
    this.tooltip = {};
    this.xaxis = {};
    this.yaxis = {};
  }

  setChart(chart) {
    this.chart = chart;
    return this;
  }

  setColors(colors) {
    this.colors = colors;
    return this;
  }

  setDataLabels(dataLabels) {
    this.dataLabels = dataLabels;
    return this;
  }

  setFill(fill) {
    this.fill = fill;
    return this;
  }

  setForecastDataPoints(forecastDataPoints) {
    this.forecastDataPoints = forecastDataPoints;
    return this;
  }

  setGrid(grid) {
    this.grid = grid;
    return this;
  }

  setLabels(labels) {
    this.labels = labels;
    return this;
  }

  setLegend(legend) {
    this.legend = legend;
    return this;
  }

  setMarkers(markers) {
    this.markers = markers;
    return this;
  }

  setNoData(noData) {
    this.noData = noData;
    return this;
  }

  setPlotOptions(plotOptions) {
    this.plotOptions = plotOptions;
    return this;
  }

  setResponsive(responsive) {
    this.responsive = responsive;
    return this;
  }

  setStates(states) {
    this.states = states;
    return this;
  }

  setStroke(stroke) {
    this.stroke = stroke;
    return this;
  }

  setSubtitle(subtitle) {
    this.subtitle = subtitle;
    return this;
  }

  setTheme(theme) {
    this.theme = theme;
    return this;
  }

  setTitle(title) {
    this.title = title;
    return this;
  }

  setTooltip(tooltip) {
    this.tooltip = tooltip;
    return this;
  }

  setXAxis(xaxis) {
    this.xaxis = xaxis;
    return this;
  }

  setYAxis(yaxis) {
    this.yaxis = yaxis;
    return this;
  }

  build() {
    const result = { ...this };

    this.chart = {};
    this.colors = [];
    this.dataLabels = {};
    this.fill = {};
    this.forecastDataPoints = {};
    this.grid = {};
    this.labels = [];
    this.legend = {};
    this.markers = {};
    this.noData = {};
    this.plotOptions = {};
    this.responsive = [];
    this.states = {};
    this.stroke = {};
    this.subtitle = {};
    this.theme = {};
    this.title = {};
    this.tooltip = {};
    this.xaxis = {};
    this.yaxis = {};

    return result;
  }
}
