import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DoneIcon from '@mui/icons-material/Done';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Avatar,
  AvatarGroup,
  Chip,
  Grid,
  TableCell,
  TableRow,
  styled,
  Tooltip,
  Alert,
  Snackbar,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import apiService from '../../../../services/api.service';
import getUserLastLoginDate from '../../../../utils/lastLoginDate';
import UserAvatar from '../../../Admin/Users/components/UserAvatar';
import EnglishLevelsEnum from '../../../Admin/Users/enums/englishLevels.enum';
import LevelProgress from '../../components/LevelProgress';
import HeadUserAutocomplete from './HeadUserAutocomplete';
import ModalWindow from './ModalWindow';
import RowActions from './RowActions';

const StyledTableRow = styled(TableRow)(({ archived }) => ({ opacity: archived ? '0.6' : '1' }));

export default function UsersTableRow({
  _id,
  avatar,
  email,
  level,
  goal,
  englishLevel,
  progress,
  onArchive,
  onUnArchive,
  isArchived,
  growLevels,
  onSave,
  lastLoginAt,
  hydraProfileLink,
}) {
  const history = useHistory();
  const { department } = useParams();
  const [editable, setEditable] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentLevel, setCurrentLevel] = useState({ key: level });
  const [currentGoal, setCurrentGoal] = useState({ key: goal });
  const [currentEnglishLevel, setCurrentEnglishLevel] = useState({ key: englishLevel });
  const [resetProgressConfirmationModal, setResetProgressConfirmationModal] = useState(false);
  const [message, setError] = useState('');
  const [reviews, setReviews] = useState([]);
  const [reviewProcessed, setReviewProcessed] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
    prevGoal: null,
    prevLevel: null,
    prevEnglishLevel: null,
    newGoal: null,
    newLevel: null,
    newEnglishLevel: null,
  });

  const englishLevelsArray = Object.entries(EnglishLevelsEnum).map(([key, { display, value }]) => ({
    key,
    display,
    value,
  }));

  useEffect(() => {
    apiService.head.getDepartmentReviews(department).then((data) => {
      setReviews(data);
    });
  }, [department]);

  const setInitialData = () => {
    setCurrentLevel(modal.prevLevel);
    setCurrentGoal(modal.prevGoal);
    setCurrentEnglishLevel(modal.prevEnglishLevel);
    setModal({
      ...modal,
      isOpen: false,
      newGoal: null,
      newLevel: null,
      newEnglishLevel: null,
    });
  };

  useEffect(() => {
    if (growLevels.length) {
      const findedLvl = growLevels.find((lvl) => lvl.key === level);
      const findedGoal = growLevels.find((lvl) => lvl.key === goal);

      const findedEnglishLevel = englishLevel
        ? englishLevelsArray.find((item) => item.value === englishLevel)
        : null;

      setCurrentEnglishLevel(findedEnglishLevel);
      setCurrentLevel(findedLvl);
      setCurrentGoal(findedGoal);
      setModal({
        isOpen: false,
        prevGoal: findedGoal,
        prevLevel: findedLvl,
        prevEnglishLevel: findedEnglishLevel,
        newGoal: null,
        newLevel: null,
        newEnglishLevel: null,
      });
    }
  }, [goal, growLevels, level]);

  const updateCurrentLevel = (newValue) => {
    setCurrentLevel(newValue);
    if (newValue.position > currentGoal.position) {
      setCurrentGoal(newValue);
    }
  };

  const updateEnglishLevel = (newValue) => {
    setCurrentEnglishLevel(newValue);
  };

  const handleReviewProcessed = () => {
    reviews.forEach((review) => {
      const { status: reviewStatus, goal: reviewGoal, userId: reviewUserId } = review;

      const checkReviewProcessed = reviewStatus === 'PROCESSED' && reviewUserId === _id;

      if (
        (checkReviewProcessed && reviewGoal === goal) ||
        (checkReviewProcessed && reviewGoal === currentGoal._id)
      ) {
        setReviewProcessed(true);
      }
    });
  };

  const handleCancel = () => {
    setEditable(false);
    setInitialData();
  };

  const handleSave = async () => {
    try {
      handleReviewProcessed();
      setIsSaving(true);

      const { prevGoal, prevLevel, prevEnglishLevel } = modal;

      if (reviewProcessed) {
        throw new Error('user pending review!');
      }

      const body = {
        ...(currentLevel._id !== prevLevel._id && { level: currentLevel._id }),
        ...(currentGoal._id !== prevGoal._id && { goal: currentGoal._id }),
        ...(currentEnglishLevel !== prevEnglishLevel && {
          englishLevel: currentEnglishLevel.value,
        }),
      };

      if (currentEnglishLevel === prevEnglishLevel) {
        await apiService.head.upgradeUserGoal(_id, currentGoal._id, true);
      }
      await apiService.admin.updateUser(_id, body);

      setIsSaving(false);
      setModal({
        isOpen: false,
        prevGoal: modal.newGoal || modal.prevGoal,
        prevLevel: modal.newLevel || modal.prevLevel,
        prevEnglishLevel: modal.newEnglishLevel || modal.prevEnglishLevel,
        newGoal: null,
        newLevel: null,
        newEnglishLevel: null,
      });

      onSave();
    } catch (error) {
      setError(`Save error: ${error.message}`);
      setIsSaving(false);
      handleCancel();
    }
  };

  const handleValidate = () => {
    const { prevGoal, prevLevel, prevEnglishLevel } = modal;

    if (
      prevGoal._id !== currentGoal._id ||
      prevLevel._id !== currentLevel._id ||
      prevEnglishLevel !== currentEnglishLevel
    ) {
      setModal({
        ...modal,
        isOpen: true,
        newGoal: prevGoal._id !== currentGoal._id ? currentGoal : null,
        newLevel: prevLevel._id !== currentLevel._id ? currentLevel : null,
        newEnglishLevel: prevEnglishLevel !== currentEnglishLevel ? currentEnglishLevel : null,
      });
    }

    setEditable(false);
  };

  const handleResetProgress = () => {
    handleReviewProcessed();
    setResetProgressConfirmationModal(true);
  };

  const handleAcceptResetProgress = async () => {
    try {
      if (reviewProcessed) {
        throw new Error('user pending review!');
      }

      const body = {
        level: currentLevel._id,
        goal: currentGoal._id,
        progress: 0,
      };

      await apiService.head.upgradeUserGoal(_id, currentGoal._id, true);
      await apiService.admin.updateUser(_id, body);
      onSave();
    } catch (error) {
      setError(`Reset progress error: ${error.message}`);
      setResetProgressConfirmationModal(false);
    }
  };

  const handleDeclineResetProgress = () => {
    setResetProgressConfirmationModal(false);
  };

  const handleLoginAs = async () => {
    const token = await apiService.head.signInAs(_id);
    window.open(`${process.env.REACT_APP_URL}/home?guestToken=${token}`, '_blank');
  };

  const handleChange = () => {
    history.push(`/head/departments/${department}/users/${_id}`);
  };

  const handlePersoalTasks = () => {
    history.push(`/head/departments/${department}/users/${_id}/personal-tasks/`);
  };

  const getResetProgressConfirmationModalContent = () => {
    return (
      <ModalWindow
        modal={{
          isOpen: resetProgressConfirmationModal,
          message: 'Are you sure you want to reset user progress?',
        }}
        onAccept={handleAcceptResetProgress}
        onDecline={handleDeclineResetProgress}
      />
    );
  };

  return (
    <>
      <StyledTableRow archived={isArchived ? 1 : 0}>
        <TableCell sx={{ padding: 0 }}>
          <Grid container alignItems="center">
            <Grid xs={12} item>
              <UserAvatar
                cardContainerSize={20}
                growAvatarWidth={40}
                growAvatarHeight={40}
                hydraAvatarWidth={30}
                hydraAvatarHeight={30}
                hydraMarginTop={-2}
                hydraProfileLink={hydraProfileLink}
                avatar={avatar}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="left">{email}</TableCell>
        <TableCell align="left">
          <Grid container justifyContent="center">
            <Grid>
              <AvatarGroup max={6}>
                <Avatar src="https://codersera.com/blog/wp-content/uploads/2020/11/nodejs_cover-346x188.png" />
                <Avatar src="https://miro.medium.com/max/766/1*dtWSNp2wqr_tWE13QHGNHw.png" />
                <Avatar src="https://cdn.hashnode.com/res/hashnode/image/upload/v1637843280574/cfUX7V1Vc.png" />
                <Avatar src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/erkxwhl1gd48xfhe2yld" />
                <Avatar src="https://p7.hiclipart.com/preview/593/30/489/github-computer-icons-commit-repository-github.jpg" />
                <Avatar src="https://i2.wp.com/www.howtogeek.com/wp-content/uploads/csit/2021/04/075c8694.jpeg?resize=350%2C200&ssl=1" />
                <Avatar src="https://image.chitra.live/api/v1/wps/2136edb/8efaf5e2-4687-46e9-a8df-3e1a7493e150/3/AWS-580x358.jpg" />
              </AvatarGroup>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="right">
          <Grid container justifyContent="center">
            <Grid>
              {editable && (
                <HeadUserAutocomplete
                  options={growLevels}
                  value={currentLevel}
                  setValue={updateCurrentLevel}
                  optionLabel={(option) => option.key}
                />
              )}
              {!editable && (
                <Chip
                  label={currentLevel.key}
                  color={!isArchived ? 'success' : 'default'}
                  size="small"
                  variant="outlined"
                  icon={<DoneIcon />}
                />
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="right">
          <Grid container justifyContent="center">
            <Grid xs={12} item>
              <LevelProgress disabled={isArchived} fontSize="1rem" height={24} value={progress} />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="right">
          <Grid container justifyContent="center">
            <Grid>
              {editable && (
                <HeadUserAutocomplete
                  options={growLevels}
                  value={currentGoal}
                  setValue={(newValue) => {
                    setCurrentGoal(newValue);
                  }}
                  optionLabel={(option) => option.key}
                  onOptionDisable={(option) => option.position < currentLevel.position}
                />
              )}
              {!editable && (
                <Chip
                  label={currentGoal.key}
                  color={!isArchived ? 'warning' : 'default'}
                  size="small"
                  variant="outlined"
                  icon={<SyncIcon />}
                />
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Grid container justifyContent="center">
            {editable ? (
              <HeadUserAutocomplete
                options={englishLevelsArray}
                value={currentEnglishLevel}
                setValue={updateEnglishLevel}
                optionLabel={(option) => {
                  return option?.display || '';
                }}
              />
            ) : (
              currentEnglishLevel?.value || ''
            )}
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Tooltip title={getUserLastLoginDate(lastLoginAt)} placement="top">
            <Grid container justifyContent="center">
              {getUserLastLoginDate(lastLoginAt).split(',')[0]}
            </Grid>
          </Tooltip>
        </TableCell>
        <TableCell align="right">
          <Grid container justifyContent="center">
            <RowActions
              onArchive={() => onArchive(_id)}
              onUnArchive={() => onUnArchive(_id)}
              isArchived={isArchived}
              editable={editable}
              onSaveClick={handleValidate}
              onCancelClick={handleCancel}
              onEditClick={() => setEditable(true)}
              disableEdit={!growLevels.length || isSaving}
              onLoginAsClick={handleLoginAs}
              onResetProgress={handleResetProgress}
              handleChange={handleChange}
              handlePersoalTasks={handlePersoalTasks}
            />
          </Grid>
        </TableCell>
      </StyledTableRow>
      {getResetProgressConfirmationModalContent()}
      <Snackbar open={!!message} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <ModalWindow modal={modal} onAccept={handleSave} onDecline={handleCancel} />
    </>
  );
}

UsersTableRow.propTypes = {
  _id: PropTypes.string.isRequired,
  avatar: PropTypes.shape({}).isRequired,
  email: PropTypes.string.isRequired,
  goal: PropTypes.string.isRequired,
  lastLoginAt: PropTypes.string.isRequired,
  hydraProfileLink: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  englishLevel: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  isArchived: PropTypes.bool.isRequired,
  onArchive: PropTypes.func.isRequired,
  onUnArchive: PropTypes.func.isRequired,
  growLevels: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSave: PropTypes.func.isRequired,
};
