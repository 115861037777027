import PropTypes from 'prop-types';
import { Dialog, Typography, Box } from '@mui/material';
import useNotificationScroll from '../hooks/UseNotificationScroll';
import { StyledDialogBody } from '../styles/Notification.styled';
import NotificationItem from './NotificationItem';

function NotificationPreviewPopUp({ isOpen, onClose, notificationData, onStatusChange, position }) {
  const popUpWidth = Math.min(350, window.innerWidth - 100);

  const allStatusesUpdated = (notifications) => {
    return notifications.every(
      (notification) => notification.status !== 'PENDING' && notification.status !== 'OPENED',
    );
  };

  return (
    <Dialog
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
      PaperProps={{
        style: {
          position: 'fixed',
          top: position.top,
          left: position.left,
          maxHeight: '600px',
          width: `${popUpWidth}px`,
          boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
          borderRadius: '12px',
        },
      }}
      open={isOpen}
      onClose={onClose}
    >
      <StyledDialogBody
        onScroll={useNotificationScroll}
        sx={{ boxShadow: 'rgba(170, 180, 190, 0.3) 0px 4px 20px' }}
      >
        {notificationData.length === 0 || allStatusesUpdated(notificationData) ? (
          <Box sx={{ padding: '10px 0' }}>
            <Typography variant="body2" color="textSecondary" align="center" fontSize={16}>
              There are no notifications yet
            </Typography>
          </Box>
        ) : (
          notificationData.map((notification) => (
            <NotificationItem
              key={notification._id}
              notification={notification}
              onStatusChange={onStatusChange}
            />
          ))
        )}
      </StyledDialogBody>
    </Dialog>
  );
}

NotificationPreviewPopUp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.shape({
    top: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
  }).isRequired,
  notificationData: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      headIds: PropTypes.arrayOf(PropTypes.string).isRequired,
      details: PropTypes.shape({
        materialIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        materialTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
        links: PropTypes.arrayOf(PropTypes.string).isRequired,
        parentId: PropTypes.string.isRequired,
        parentName: PropTypes.string.isRequired,
        detailName: PropTypes.string.isRequired,
        materialNames: PropTypes.arrayOf(PropTypes.string).isRequired,
        description: PropTypes.string.isRequired,
      }).isRequired,
      createdAt: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

export default NotificationPreviewPopUp;
