import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InfoOutlined } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  Button,
  Box,
  Grid,
  Menu,
  TextField,
  MenuItem,
  styled,
  Tooltip,
  IconButton,
} from '@mui/material';
import GoalsConstants from '../../../constants/goals.constants';

const StyledMenuItemList = styled(MenuItem)({
  width: '100%',
  '&:hover': { backgroundColor: 'transparent' },
});

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    float: 'none',
    textAlign: 'center',
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    float: 'right',
  },
}));

export default function ReviewStatus({ admin, parent, details, onApprove, onDecline }) {
  const [hidden, setHidden] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const [approveClicked, setApproveClicked] = useState(false);
  const [declineClicked, setDeclineClicked] = useState(false);

  const [declineAnchorMenu, setDeclineAnchorMenu] = useState(null);
  const [declineReason, setDeclineReason] = useState(null);
  const [updateDecline, setUpdateDecline] = useState(false);

  const [recommendationAnchorMenu, setRecommendationAnchorMenu] = useState(null);
  const [recommendationReason, setRecommendationReason] = useState(null);
  const [updateRecommendation, setUpdateRecommendation] = useState(false);

  const openDeclineMenu = Boolean(declineAnchorMenu);
  const openRecommendationMenu = Boolean(recommendationAnchorMenu);

  const handleOnTooltip = () => {
    setShowTooltip(false);
  };

  const handleOnClickDecline = (event) => {
    handleOnTooltip();

    event.stopPropagation();
    setDeclineAnchorMenu(event.currentTarget);
  };

  const handleOnClickRecommendation = (event) => {
    handleOnTooltip();

    event.stopPropagation();
    setRecommendationAnchorMenu(event.currentTarget);
  };

  const handleOnCloseDecline = () => {
    setDeclineAnchorMenu(null);
    setUpdateDecline(false);
  };

  const handleOnCloseRecommendation = () => {
    setRecommendationAnchorMenu(null);
    setUpdateRecommendation(false);
  };

  const handleOnRecommendationButtonClick = () => {
    setUpdateRecommendation(false);
    setApproveClicked(true);
    setDeclineClicked(false);

    setDeclineReason(null);
    setDeclineAnchorMenu(null);

    if (recommendationReason) {
      onApprove(parent._id, details.itemId, recommendationReason);
      setShowTooltip(true);
      handleOnCloseRecommendation();
    }

    setHidden(true);
  };

  const handleOnDeclineButtonClick = () => {
    setUpdateDecline(false);
    setDeclineClicked(true);
    setApproveClicked(false);

    setRecommendationReason(null);
    setRecommendationAnchorMenu(null);

    if (declineReason) {
      onDecline({
        goalId: parent._id,
        itemId: details.itemId,
        comment: declineReason,
      });

      setShowTooltip(true);
      setHidden(true);
    }
  };

  const handleOnApproveButtonClick = () => {
    setApproveClicked(true);
    setDeclineClicked(false);

    setDeclineReason(null);
    setDeclineAnchorMenu(null);

    onApprove(parent._id, details.itemId);

    setHidden(true);
  };

  const handleOnUpdateRecommendation = (event) => {
    setUpdateRecommendation(true);

    event.stopPropagation();

    setRecommendationAnchorMenu(event.currentTarget);
  };

  const handleOnUpdateDecline = (event) => {
    setUpdateDecline(true);

    event.stopPropagation();

    setDeclineAnchorMenu(event.currentTarget);
  };

  return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
      {((!approveClicked && declineClicked) ||
        (!hidden &&
          admin &&
          (details.status === GoalsConstants.GOAL_STATUSES.DONE ||
            details.status === GoalsConstants.GOAL_STATUSES.DECLINED))) && (
        <Grid item xs={12} md="auto">
          <StyledBox>
            <Button
              sx={{ m: 1 }}
              onClick={handleOnApproveButtonClick}
              size="small"
              variant="contained"
              color="success"
            >
              Approve
            </Button>
          </StyledBox>
        </Grid>
      )}
      {((approveClicked && !declineClicked) ||
        (!declineClicked &&
          !hidden &&
          admin &&
          details.status === GoalsConstants.GOAL_STATUSES.APPROVED)) && (
        <Grid item xs={12} md="auto">
          <StyledBox>
            {!(showTooltip && recommendationReason) && (
              <Button
                sx={{ m: 1 }}
                onClick={handleOnClickRecommendation}
                size="small"
                variant="contained"
                color="primary"
                id="recomandation-basic-menu"
                aria-controls={openRecommendationMenu ? 'recomandation-basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openRecommendationMenu ? 'true' : undefined}
              >
                Recommendation
              </Button>
            )}
            <Menu
              id="recomandation-basic-menu"
              anchorEl={recommendationAnchorMenu}
              open={openRecommendationMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={handleOnCloseRecommendation}
              MenuListProps={{ 'aria-labelledby': 'recomandation-basic-menu' }}
            >
              <StyledMenuItemList disableRipple>
                <TextField
                  multiline
                  id="standard-recommendation-reason"
                  placeholder="Enter your recommendation"
                  fullWidth
                  inputProps={{ maxLength: 512 }}
                  sx={{ pr: 2 }}
                  value={recommendationReason}
                  onChange={(event) => setRecommendationReason(event.target.value)}
                />
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleOnRecommendationButtonClick}
                  disabled={!recommendationReason}
                >
                  Save
                </Button>
              </StyledMenuItemList>
            </Menu>
            {(updateRecommendation || (showTooltip && recommendationReason)) && (
              <Tooltip
                title={recommendationReason}
                sx={{ m: 0.7 }}
                onChange={(event) => setRecommendationReason(event.target.value)}
                onClick={handleOnUpdateRecommendation}
              >
                <IconButton aria-label="description" size="small">
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            )}
          </StyledBox>
        </Grid>
      )}
      {(updateDecline ||
        (approveClicked && !declineClicked) ||
        (!hidden &&
          admin &&
          (details.status === GoalsConstants.GOAL_STATUSES.DONE ||
            details.status === GoalsConstants.GOAL_STATUSES.APPROVED))) && (
        <Grid item xs={12} md="auto">
          <StyledBox>
            {!(showTooltip && declineReason) && (
              <Button
                sx={{ m: 1 }}
                onClick={handleOnClickDecline}
                size="small"
                variant="contained"
                color="error"
                id="decline-basic-menu"
                aria-controls={openDeclineMenu ? 'decline-basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openDeclineMenu ? 'true' : undefined}
              >
                Decline
              </Button>
            )}
            <Menu
              id="decline-basic-menu"
              anchorEl={declineAnchorMenu}
              open={openDeclineMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={handleOnCloseDecline}
              MenuListProps={{ 'aria-labelledby': 'decline-basic-menu' }}
            >
              <StyledMenuItemList disableRipple>
                <TextField
                  multiline
                  id="standard-decline-reason-comment"
                  placeholder="Enter the reason"
                  fullWidth
                  inputProps={{ maxLength: 512 }}
                  sx={{ pr: 2 }}
                  onChange={(event) => setDeclineReason(event.target.value)}
                  value={declineReason}
                />
                <Button
                  size="large"
                  variant="contained"
                  color="error"
                  onClick={handleOnDeclineButtonClick}
                  disabled={!declineReason}
                >
                  Decline
                </Button>
              </StyledMenuItemList>
            </Menu>
          </StyledBox>
        </Grid>
      )}
      {(updateDecline || (showTooltip && declineReason)) && (
        <Tooltip
          title={declineReason}
          sx={{ m: 0.7 }}
          onChange={(event) => setDeclineReason(event.target.value)}
          onClick={handleOnUpdateDecline}
        >
          <IconButton aria-label="description" size="small">
            <DescriptionIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

ReviewStatus.propTypes = {
  admin: PropTypes.bool,
  details: PropTypes.shape({
    itemId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    successCriteria: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.string.isRequired,
    materials: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  parent: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    skillType: PropTypes.string.isRequired,
  }).isRequired,

  onApprove: PropTypes.func,
  onDecline: PropTypes.func,
};

ReviewStatus.defaultProps = {
  admin: false,
  onApprove: () => null,
  onDecline: () => null,
};
