import React from 'react';
import PropTypes from 'prop-types';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Modal, Box, Button, styled, Typography, Chip, Tooltip } from '@mui/material';

const StyledModalBody = styled(Box)(() => ({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  padding: '40px 30px',
  background: 'rgb(255, 255, 255)',
  boxShadow: 24,
  p: 4,
  borderRadius: '15px',
}));

const StyledButtonContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '30px',
}));

const StyledItemContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '8px',
}));

const StyledItemsContainer = styled(Box)(() => ({
  display: 'flex',
  marginTop: '8px',
}));

const StyledItemTitle = styled(Typography)(() => ({
  marginRight: '8px',
  fontSize: '13px',
  minWidth: '80px',
}));

const StyledChipContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledChipsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const StyledChip = styled(Chip)(() => ({
  width: '70px',
}));

const StyledChipItem = styled(Chip)(({ width }) => ({
  width,
  marginBottom: '4px',
}));

const StyledModalTitle = styled(Typography)(() => ({
  marginBottom: '24px',
}));

export default function ModalWindow({ modal, onAccept, onDecline }) {
  const { isOpen, prevDepartment, newDepartment, addedHeads, removedHeads } = modal;

  return (
    <Modal open={isOpen}>
      <StyledModalBody>
        <Box>
          <StyledModalTitle variant="h6" component="h4" sx={{ fontWeight: 400 }}>
            Are you sure want to set new values?
          </StyledModalTitle>
          <Box>
            {newDepartment && (
              <StyledItemContainer>
                <StyledItemTitle>Department:</StyledItemTitle>
                <StyledChipContainer>
                  <Tooltip title={prevDepartment} placement="right">
                    <StyledChip label={prevDepartment} />
                  </Tooltip>
                  <ArrowForwardRoundedIcon sx={{ margin: '0 16px' }} fontSize="small" />
                  <Tooltip title={newDepartment} placement="right">
                    <StyledChip label={newDepartment} />
                  </Tooltip>
                </StyledChipContainer>
              </StyledItemContainer>
            )}
            {addedHeads?.length > 0 && (
              <StyledItemsContainer>
                <StyledItemTitle sx={{ marginTop: '7px' }}>Added Heads:</StyledItemTitle>
                <StyledChipsContainer>
                  {addedHeads.map((addedHead) => (
                    <Tooltip key={addedHead.id} title={addedHead.email} placement="right">
                      <StyledChipItem width="190px" label={addedHead.email} />
                    </Tooltip>
                  ))}
                </StyledChipsContainer>
              </StyledItemsContainer>
            )}
            {removedHeads?.length > 0 && (
              <StyledItemsContainer>
                <StyledItemTitle sx={{ marginTop: '7px' }}>Removed Heads:</StyledItemTitle>
                <StyledChipsContainer>
                  {removedHeads.map((removedHead) => (
                    <Tooltip key={removedHead.id} title={removedHead.email} placement="right">
                      <StyledChipItem width="170px" label={removedHead.email} />
                    </Tooltip>
                  ))}
                </StyledChipsContainer>
              </StyledItemsContainer>
            )}
          </Box>
        </Box>
        <StyledButtonContainer>
          <Button
            sx={{ marginRight: '10px' }}
            variant="contained"
            onClick={onAccept}
            color="primary"
          >
            Accept
          </Button>
          <Button onClick={onDecline}>Decline</Button>
        </StyledButtonContainer>
      </StyledModalBody>
    </Modal>
  );
}

ModalWindow.propTypes = {
  modal: PropTypes.shape({
    isOpen: PropTypes.bool,
    prevDepartment: PropTypes.string,
    newDepartment: PropTypes.string,
    addedHeads: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        email: PropTypes.string,
      }),
    ),
    removedHeads: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        email: PropTypes.string,
      }),
    ),
  }).isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};
