import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';

export default function DashboardTitle({ title }) {
  return (
    <Grid item xs={12} sx={{ marginBottom: '16px' }}>
      <Typography variant="h6">{title}</Typography>
    </Grid>
  );
}

DashboardTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
