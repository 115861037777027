import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, FormControlLabel, FormGroup, styled, Paper } from '@mui/material';

export default function GrowLvlFilter({
  growLevels,
  selectedLvls,
  setSelectedLvls,
  isDisabled,
  setIsDisabled,
}) {
  const handleCheckbox = useCallback(
    ({ target }) => {
      setIsDisabled(true);
      return selectedLvls.includes(target.id)
        ? setSelectedLvls(selectedLvls.filter((sLvl) => sLvl !== target.id))
        : setSelectedLvls([...selectedLvls, target.id]);
    },
    [selectedLvls, setIsDisabled, setSelectedLvls],
  );
  const StyledItem = styled(Paper)(() => ({
    marginBottom: '1em',
    padding: '12px',
    borderRadius: '.15em',
  }));

  return (
    <StyledItem elevation={8}>
      <Box role="group" aria-labelledby="filter-status">
        <FormGroup
          style={{
            marginTop: '16px',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {growLevels.map((lvl) => (
            <FormControlLabel
              disabled={isDisabled}
              key={lvl.title}
              control={
                <Checkbox
                  size="small"
                  checked={selectedLvls.includes(lvl._id)}
                  onChange={handleCheckbox}
                  value={lvl.title}
                  id={lvl._id}
                />
              }
              label={lvl.key}
            />
          ))}
        </FormGroup>
      </Box>
    </StyledItem>
  );
}

GrowLvlFilter.propTypes = {
  growLevels: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedLvls: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedLvls: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  setIsDisabled: PropTypes.func,
};

GrowLvlFilter.defaultProps = {
  isDisabled: false,
  setIsDisabled: () => null,
};
