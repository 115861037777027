/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import Loading from '../components/Loading';
import UserContext from '../hooks/context/UserContext';
import crumbsGenerator from '../utils/crumbsGenerator';

export default function ProtectedRoute({ component: Component, routeMap, requiredRoles, ...rest }) {
  const { user, isLoading } = useContext(UserContext);

  const protectedUser = requiredRoles.some((role) => user?.roles.includes(role));

  if (isLoading) {
    return <Loading />;
  }

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (protectedUser) {
    return (
      <Route
        {...rest}
        render={(props) => {
          const keysArr = Object.keys(props.match.params);
          const crumbs = crumbsGenerator({
            routes: routeMap,
            keysArr,
            props,
          });

          return <Component {...props} crumbs={crumbs} />;
        }}
      />
    );
  }

  return <Redirect to="/home" />;
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  requiredRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  routeMap: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
    }),
  ).isRequired,
};
