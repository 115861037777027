import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
  Typography,
} from '@mui/material';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function CongratulatePopUp({ open, handleClose }) {
  return (
    <Dialog
      hideBackdrop
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="dialog-congratulation"
    >
      <DialogTitle>Congratulations on your promotion! 🎉</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Typography component="span">Dear Friend,</Typography>
          <br />
          <br />
          <Typography component="span">
            Congratulations on your level promotion! This is the result of your hard work.
          </Typography>
          <br />
          <br />
          <Typography component="span">
            You have the creativity and determination to do whatever you can dream. I hope you feel
            proud today and confident in your ability to GROW to your next challenge.
          </Typography>
          <br />
          <br />
          <Typography component="span">All the best,</Typography>
          <br />
          <Typography component="span">Onix-Systems</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Hurrah!</Button>
      </DialogActions>
    </Dialog>
  );
}

CongratulatePopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
