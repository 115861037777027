import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';
import Chart from 'react-apexcharts';
import '../styles/ant-picker.css';
import useDepartmentsActivity from '../hooks/useDepartmentsActivity';
import useDepartmentsData from '../hooks/useDepartmentsData';
import DepartmentsFilter from './DepartmentsFilter';
import ExportButton from './ExportButton';

function DepartmentsActivityChart({ startPickerValue, endPickerValue }) {
  const { departmentOptions, pickedDepartments, setPickedDepartments } = useDepartmentsData();

  const { departmentsChartSeries, departmentsChartOptions } = useDepartmentsActivity({
    startPickerValue,
    endPickerValue,
    pickedDepartments,
  });

  const exportUrl = new URL(
    '/api/v1/users-statistic/admin/export/departments',
    process.env.REACT_APP_API_URL,
  );
  exportUrl.searchParams.set('startDate', startPickerValue.toISOString());
  exportUrl.searchParams.set('endDate', endPickerValue.toISOString());
  exportUrl.searchParams.set('departmentIds', pickedDepartments.map((d) => d._id).join(','));

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'shrink', gap: '8px', width: '100%' }}>
          <Box sx={{ flex: 1 }}>
            <DepartmentsFilter
              departmentOptions={departmentOptions}
              pickedDepartments={pickedDepartments}
              setPickedDepartments={setPickedDepartments}
            />
          </Box>

          <Box sx={{ maxHeight: '38px' }}>
            <ExportButton url={exportUrl} />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Chart
          options={departmentsChartOptions}
          series={departmentsChartSeries}
          type="bar"
          height={450}
        />
      </Grid>
    </Grid>
  );
}

DepartmentsActivityChart.propTypes = {
  startPickerValue: PropTypes.instanceOf(dayjs).isRequired,
  endPickerValue: PropTypes.instanceOf(dayjs).isRequired,
};

export default DepartmentsActivityChart;
