import dayjs from 'dayjs';
import ChartBuilder from '../chartBuilder';

const buildActivityChartOptions = ({ categories }) => {
  let tickAmount;
  const categoriesLength = categories.length;

  if (categoriesLength < 33) {
    tickAmount = Math.ceil(categoriesLength / 3);
  } else if (categoriesLength < 66) {
    tickAmount = Math.ceil(categoriesLength / 8);
  } else if (categoriesLength < 124) {
    tickAmount = Math.ceil(categoriesLength / 15);
  } else if (categoriesLength < 184) {
    tickAmount = Math.ceil(categoriesLength / 22);
  } else {
    tickAmount = Math.ceil(categoriesLength / 32);
  }

  const chartBuilder = new ChartBuilder();
  return chartBuilder
    .setChart({
      stacked: true,
      toolbar: { show: false },
      zoom: { enabled: false },
    })
    .setDataLabels({
      enabled: true,
      formatter: (val) => (val < 2 ? '' : val),
    })
    .setTooltip({
      shared: true,
      intersect: false,
    })
    .setXAxis({
      labels: {
        rotate: 0,
        formatter: (value) => dayjs(value).format('DD MMM'),
      },
      tickAmount,
      categories,
    })
    .build();
};

export default buildActivityChartOptions;
