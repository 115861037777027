import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import userRoles from '../constants/userRoles';
import NotFound from '../pages/NotFound';
import PrivateRoute from './PrivateRoute';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import adminRoutes from './routesLists/adminRoutes';
import headRoutes from './routesLists/headRoutes';
import privateRoutes from './routesLists/privateRoutes';
import publicRoutes from './routesLists/publicRoutes';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/home" />} />
      {publicRoutes.map((route) => (
        <PublicRoute key={route.path} path={route.path} component={route.component} exact />
      ))}
      {privateRoutes.map((route) => (
        <PrivateRoute key={route.path} path={route.path} component={route.component} exact />
      ))}
      {headRoutes.map((route) => (
        <ProtectedRoute
          key={route.path}
          path={route.path}
          component={route.component}
          routeMap={headRoutes}
          requiredRoles={route.requiredRoles}
          exact
        />
      ))}
      {adminRoutes.map((route) => (
        <ProtectedRoute
          key={route.path}
          path={route.path}
          component={route.component}
          routeMap={adminRoutes}
          requiredRoles={[userRoles.ADMIN]}
          exact
        />
      ))}
      <Route component={NotFound} />
    </Switch>
  );
}
