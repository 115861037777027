import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  styled,
  Paper,
  Box,
  Tabs,
  Tab,
  createTheme,
  ThemeProvider,
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import GoalsConstants from '../../../constants/goals.constants';
import UserSkillsView from './UserSkillsView';

const componentTheme = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          maxHeight: '20px',
          marginLeft: '5px',
          cursor: 'pointer',
        },
        label: { padding: '3px 8px' },
      },
    },
  },
  palette: {
    primary: { main: '#7553EA' },
    warning: { main: '#FFE2B7' },
  },
});

const StyledItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  borderRadius: '.15em',
}));

const StyledTabLabelBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  margin: `0 ${theme.spacing(2)} ${theme.spacing(3)}`,
}));

export default function UserSkillsTable({
  goalsInProgress,
  goalsDone,
  isDisabled,
  expanded,
  onLinkClick,
}) {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const goalDetailsInProgress = useMemo(() => {
    const skillNamesSet = new Set();
    goalsInProgress.forEach((skill) => {
      skill.details.forEach((detail) => {
        if (detail.status !== 'DONE' && detail.status !== 'APPROVED') {
          skillNamesSet.add(detail.name);
        }
      });
    });
    return Array.from(skillNamesSet);
  });

  const goalDetailsDone = useMemo(() => {
    const skillNamesSet = new Set();
    goalsDone.forEach((skill) => {
      skill.details.forEach((detail) => {
        skillNamesSet.add(detail.name);
      });
    });
    return Array.from(skillNamesSet);
  }, [goalsDone]);

  return (
    <ThemeProvider theme={componentTheme}>
      <StyledBox>
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab
            label={
              <StyledTabLabelBox>
                In Progress
                <Chip label={goalDetailsInProgress.length} color="warning" />
              </StyledTabLabelBox>
            }
          />
          <Tab
            label={
              <StyledTabLabelBox>
                Done
                <Chip label={goalDetailsDone.length} color="success" />
              </StyledTabLabelBox>
            }
          />
        </Tabs>
        <StyledItem elevation={8}>
          {activeTab === 0 && !goalsInProgress.length && (
            <Grid container justifyContent="center" alignItems="center">
              <Grid>
                <Typography fontSize={16}>
                  {GoalsConstants.MESSAGES.NO_SKILLS_ADDED_YET}
                  <span style={{ paddingLeft: '8px' }} role="img" aria-label="finish">
                    👉
                  </span>
                </Typography>
              </Grid>
            </Grid>
          )}
          <>
            {activeTab === 0 && !!goalsInProgress.length && (
              <UserSkillsView
                skillsData={goalsInProgress}
                isDisabled={isDisabled}
                expanded={expanded}
                onLinkClick={onLinkClick}
                isHomePage
              />
            )}
            {activeTab === 1 && (
              <UserSkillsView
                skillsData={goalsDone}
                isDisabled={isDisabled}
                onLinkClick={onLinkClick}
              />
            )}
          </>
        </StyledItem>
      </StyledBox>
    </ThemeProvider>
  );
}

const node = {
  itemId: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.string).isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      successCriteria: PropTypes.string.isRequired,
      materialName: PropTypes.string.isRequired,
      materialType: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  skillType: PropTypes.string.isRequired,
  status: PropTypes.string,
};

UserSkillsTable.propTypes = {
  goalsInProgress: PropTypes.arrayOf(PropTypes.shape(node)).isRequired,
  goalsDone: PropTypes.arrayOf(PropTypes.shape(node)).isRequired,
  isDisabled: PropTypes.bool,
  expanded: PropTypes.string,
  onLinkClick: PropTypes.func,
};

UserSkillsTable.defaultProps = {
  isDisabled: false,
  expanded: null,
  onLinkClick: () => null,
};
