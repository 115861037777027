import { Box, styled } from '@mui/material';

const StyledModalBody = styled(Box)(() => ({
  margin: 20,
  minWidth: 300,
  padding: '40px 30px',
  background: 'rgb(255, 255, 255)',
  borderRadius: '.15em',
  display: 'flex',
  flexDirection: 'column',
}));

export default StyledModalBody;
