import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const TextWrapper = styled('span')`
  color: #545454;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 400;
  position: relative;
  top: 5px;
`;

export default function ChangePeriodButton({ today, previousHandler, nextHandler }) {
  return (
    <Box display="flex" justifyContent="flex-end">
      <Box style={{ display: 'block' }}>
        <Tooltip title="Previous month">
          <IconButton size="big" onClick={previousHandler} style={{ marginRight: '3px' }}>
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Next month">
          <IconButton size="big" onClick={nextHandler} style={{ marginLeft: '3px' }}>
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box style={{ marginLeft: '20px' }}>
        <TextWrapper>{today}</TextWrapper>
      </Box>
    </Box>
  );
}

ChangePeriodButton.propTypes = {
  today: PropTypes.string.isRequired,
  previousHandler: PropTypes.func.isRequired,
  nextHandler: PropTypes.func.isRequired,
};
