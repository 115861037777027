class TokenService {
  static clearTokens() {
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
  }

  static removeAccessToken() {
    localStorage.removeItem('access-token');
  }

  static getLocalRefreshToken() {
    return localStorage.getItem('refresh-token');
  }

  static getLocalAccessToken() {
    return localStorage.getItem('access-token');
  }

  static updateLocalAccessToken(token) {
    localStorage.setItem('access-token', token);
  }

  static updateLocalRefreshToken(token) {
    localStorage.setItem('refresh-token', token);
  }

  static removeGuestToken() {
    sessionStorage.removeItem('guestToken');
  }

  static setGuestToken(token) {
    sessionStorage.setItem('guestToken', token);
  }

  static getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  static setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  static removeUser() {
    localStorage.removeItem('user');
  }
}

export default TokenService;
