import randomId from '../../../utils/randomId';

export const setKeys = (arr) =>
  arr.map((v, index) => ({
    ...v,
    key: `L${index}`,
  }));

export const reorder = ({ levels, start, end }) => {
  const list = Array.from(levels);

  const [removed] = list.splice(start, 1);

  list.splice(end, 0, removed);

  return setKeys(list);
};

export const remove = (levels, index) => {
  const list = Array.from(levels);

  // remove level by index
  list.splice(index, 1);

  return setKeys(list);
};

export const addOne = (levels) => {
  const level = {
    _id: randomId(),
    title: '',
    isNew: true,
  };

  const result = [...levels, level];

  return setKeys(result);
};

export const update = ({ levels, title, index }) => {
  const list = Array.from(levels);

  Reflect.set(levels[index], 'title', title);

  return list;
};

export const validate = (levels) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const level of levels) {
    if (!level.title) {
      return 'Level title must not be empty!';
    }
  }

  return null;
};
