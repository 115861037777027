import React from 'react';
import PropTypes from 'prop-types';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { Box, Tooltip, IconButton, styled, ThemeProvider } from '@mui/material';
import NodeAndDetailStatus from '../enums/NodeAndDetailStatusEnum';
import StyledIconContainer from '../styled-components/IconContainer';
import CodeDetailsTheme from '../themes/CodeDetailsTheme';
import PlainCodeDetailsForm from './PlainCodeDetailsForm';

const StyledRootContainer = styled(Box)(() => ({
  display: 'flex',
  margin: '1em 0 1em 1em',
  maxWidth: '20em',
}));

function DialogCodeDetailsForm({
  node,
  removeDetails,
  isDefault,
  addDefaultTask,
  revertReplacedTask,
}) {
  return (
    <ThemeProvider theme={CodeDetailsTheme}>
      <Box
        sx={{ width: { md: '60%', sx: '100%' }, display: 'flex' }}
        id={isDefault ? '' : node.itemId}
      >
        <StyledRootContainer
          sx={{
            backgroundColor:
              [
                NodeAndDetailStatus.IS_NEW,
                NodeAndDetailStatus.IS_REPLACED,
                NodeAndDetailStatus.IS_REPLACED_DETAIL,
              ].includes(node.status) && 'rgba(0, 255, 45, 0.24)',
          }}
        >
          <PlainCodeDetailsForm node={node} />
        </StyledRootContainer>
        <StyledIconContainer>
          {node.status === NodeAndDetailStatus.IS_NEW && (
            <Tooltip title="Remove">
              <IconButton
                onClick={() => {
                  removeDetails(node);
                }}
              >
                <RemoveCircleRoundedIcon />
              </IconButton>
            </Tooltip>
          )}

          {isDefault && node.status !== NodeAndDetailStatus.IS_ADDED && (
            <Tooltip title="Add">
              <IconButton
                onClick={() => {
                  addDefaultTask(node);
                }}
              >
                <AddCircleRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
          {node.status === NodeAndDetailStatus.IS_REPLACED && (
            <Tooltip title="Revert">
              <IconButton
                onClick={() => {
                  revertReplacedTask(node);
                }}
              >
                <HistoryRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </StyledIconContainer>
      </Box>
    </ThemeProvider>
  );
}

export default DialogCodeDetailsForm;

const nodeItem = {
  itemId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  successCriteria: PropTypes.string.isRequired,
  materialName: PropTypes.string.isRequired,
  materialType: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
};

DialogCodeDetailsForm.propTypes = {
  node: PropTypes.shape(nodeItem).isRequired,
  removeDetails: PropTypes.func.isRequired,
  isDefault: PropTypes.bool,
  addDefaultTask: PropTypes.func,
  revertReplacedTask: PropTypes.func,
};

DialogCodeDetailsForm.defaultProps = {
  isDefault: false,
  addDefaultTask: () => null,
  revertReplacedTask: () => null,
};
