/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useState, useRef } from 'react';
import './hexagon.css';
import PropTypes from 'prop-types';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Hexagon } from 'react-honeycomb';
import HexBadge from './HexBadge';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function HexItem({
  isAdminPage,
  isUserPage,
  isHeadPage,
  setAnchorEl,
  setItemForAction,
  item,
  adminUser,
  headUser,
  isHeadUserSkill,
  isInvisibleBadge,
}) {
  const [isImageBroken, setIsImageBroken] = useState(false);
  const [hover, setHover] = useState(false);
  const ref = useRef(null);

  return (
    <Box
      onMouseEnter={(event) => {
        setHover(true);
        if (item.version === 0) return;
        ref.current.style.transform = 'scale(1.1)';
      }}
      onMouseLeave={() => {
        setHover(false);
        if (item.version === 0) return;
        ref.current.style.transform = '';
      }}
    >
      {item.version !== 0 && (
        <HexBadge ref={ref} version={item.version} hover={hover} invisible={isInvisibleBadge} />
      )}
      <Hexagon
        className="outerHexagon"
        style={{ backgroundColor: item.status === 'REJECTED' ? '#c2c2c2' : '#7553ea' }}
      >
        <Hexagon
          className="innerHexagon"
          style={{
            cursor: 'pointer',
          }}
        >
          {isUserPage && (
            <CustomTooltip
              title={
                <>
                  <Typography color="inherit">{item.title}</Typography>
                  {item.description}
                </>
              }
            >
              <a
                href={item.url}
                target="_blank"
                rel="noreferrer"
                style={{
                  display: 'flex',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={item.imageUrl}
                  width="60%"
                  height="auto"
                  alt="skill"
                  style={{ borderRadius: '25px' }}
                />
              </a>
            </CustomTooltip>
          )}

          {isAdminPage && adminUser && (
            <CustomTooltip
              title={
                <>
                  <Typography color="inherit">{item.title}</Typography>
                  {item.description}
                </>
              }
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setItemForAction(item);
                }}
                onKeyDown={(event) => setAnchorEl(event.currentTarget)}
                role="button"
                tabIndex={0}
              >
                {!isImageBroken ? (
                  <img
                    src={item.imageUrl}
                    width="60%"
                    height="auto"
                    alt="skill"
                    style={{ borderRadius: '25px' }}
                  />
                ) : (
                  <WarningAmberIcon
                    sx={{
                      color: '#7553EA',
                      width: '100%',
                      height: '78px',
                      paddingBottom: '16px',
                    }}
                  />
                )}
              </div>
            </CustomTooltip>
          )}

          {isHeadPage && headUser && (
            <CustomTooltip
              title={
                <>
                  <Typography color="inherit">{item.title}</Typography>
                  {item.description}
                </>
              }
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
                onClick={(event) => {
                  if (item.status === 'APPROVED') return;

                  setAnchorEl(event.currentTarget);
                  setItemForAction(item);
                }}
                onMouseEnter={(event) => {
                  setHover(true);
                }}
                onKeyDown={(event) => setAnchorEl(event.currentTarget)}
                role="button"
                tabIndex={0}
              >
                {!isImageBroken ? (
                  <img
                    src={item.imageUrl}
                    width="60%"
                    height="auto"
                    alt="skill"
                    style={{ borderRadius: '25px' }}
                  />
                ) : (
                  <WarningAmberIcon
                    sx={{
                      color: '#7553EA',
                      width: '100%',
                      height: '78px',
                      paddingBottom: '16px',
                    }}
                  />
                )}
              </div>
            </CustomTooltip>
          )}

          {isHeadUserSkill && headUser && (
            <CustomTooltip
              title={
                <>
                  <Typography color="inherit">{item.title}</Typography>
                  {item.description}
                </>
              }
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setItemForAction(item);
                }}
                onKeyDown={(event) => setAnchorEl(event.currentTarget)}
                role="button"
                tabIndex={0}
              >
                {!isImageBroken ? (
                  <img
                    src={item.imageUrl}
                    width="60%"
                    height="auto"
                    alt="skill"
                    style={{ borderRadius: '25px' }}
                  />
                ) : (
                  <WarningAmberIcon
                    sx={{
                      color: '#7553EA',
                      width: '100%',
                      height: '78px',
                      paddingBottom: '16px',
                    }}
                  />
                )}
              </div>
            </CustomTooltip>
          )}
        </Hexagon>
      </Hexagon>
    </Box>
  );
}

HexItem.propTypes = {
  isAdminPage: PropTypes.bool,
  isUserPage: PropTypes.bool,
  isHeadPage: PropTypes.bool,
  isHeadUserSkill: PropTypes.bool,
  setAnchorEl: PropTypes.func.isRequired,
  setItemForAction: PropTypes.func.isRequired,
  item: PropTypes.shape({}).isRequired,
  isInvisibleBadge: PropTypes.bool,
};

HexItem.defaultProps = {
  isAdminPage: false,
  isUserPage: false,
  isHeadPage: false,
  isHeadUserSkill: false,
  isInvisibleBadge: false,
};
