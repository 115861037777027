export const formInitialValues = {
  title: '',
  url: '',
  description: '',
  version: '',
  tags: [],
  imageUrl: '',
};

export const formErrorsInitialValues = {
  title: { status: false, messages: null },
  url: { status: false, messages: null },
  tags: { status: false, messages: null },
  description: { status: false, messages: null },
  version: { status: false, messages: null },
};

export const initialState = {
  isCropImage: false,
  croppedImg: null,
  isImageUpload: false,
  image: null,
  formValues: formInitialValues,
  formErrors: formErrorsInitialValues,
  previewImage: null,
  isCreate: false,
  isUpdate: false,
  isDelete: false,
  isOpenedUpdate: false,
};

export const ACTION_TYPES = {
  SET_IS_CROP_IMAGE: 'SET_IS_CROP_IMAGE',
  SET_IS_IMAGE_UPLOAD: 'SET_IS_IMAGE_UPLOAD',
  SET_FORM_VALUES: 'SET_FORM_VALUES',
  RESET_FORM_VALUES: 'RESET_FORM_VALUES',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
  RESET_FORM_ERRORS: 'RESET_FORM_ERRORS',
  SET_PREVIEW_IMAGE: 'SET_PREVIEW_IMAGE',
  RESET_PREVIEW_IMAGE: 'RESET_PREVIEW_IMAGE',
  RESET_ALL: 'RESET_ALL',
  BATCH_SET: 'BATCH_SET',
};

export function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_IS_CROP_IMAGE:
      return { ...state, isCropImage: action.payload };
    case ACTION_TYPES.SET_IS_IMAGE_UPLOAD:
      return { ...state, isImageUpload: action.payload };
    case ACTION_TYPES.SET_IMAGE:
      return { ...state, image: action.payload };
    case ACTION_TYPES.SET_FORM_VALUES:
      return { ...state, ...action.payload };
    case ACTION_TYPES.RESET_FORM_VALUES:
      return { ...state, formValues: formInitialValues };
    case ACTION_TYPES.SET_FORM_ERRORS:
      return { ...state, formErrors: action.payload };
    case ACTION_TYPES.RESET_FORM_ERRORS:
      return { ...state, formErrors: formErrorsInitialValues };
    case ACTION_TYPES.SET_PREVIEW_IMAGE:
      return { ...state, previewImage: action.payload };
    case ACTION_TYPES.RESET_PREVIEW_IMAGE:
      return { ...state, previewImage: null };
    case ACTION_TYPES.RESET_ALL:
      return initialState;
    case ACTION_TYPES.BATCH_SET:
      return { ...state, ...action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}
