import PropTypes from 'prop-types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';

export default function MultiSelect({
  formValues,
  handleInputChange,
  handleInputBlur,
  skillsType,
}) {
  return (
    <FormControl sx={{ width: '100%' }} size="small">
      <Autocomplete
        multiple
        options={skillsType}
        defaultValue={formValues.tags}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(propsLi, option, { selected }) => (
          <li {...propsLi}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        renderInput={(params) => <TextField {...params} label="Tags" />}
        onChange={(event, value) => {
          if (!value.length) {
            handleInputBlur({
              name: 'tags',
              value,
            });
          }

          handleInputChange({
            name: 'tags',
            value,
          });
        }}
        onClose={() =>
          handleInputBlur({
            name: 'tags',
            value: formValues.tags,
          })
        }
      />
    </FormControl>
  );
}

MultiSelect.propTypes = {
  formValues: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    version: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    imageUrl: PropTypes.string.isRequired,
  }).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleInputBlur: PropTypes.func.isRequired,
  formErrors: PropTypes.shape({
    title: PropTypes.shape({
      status: PropTypes.bool.isRequired,
      messages: PropTypes.string,
    }),
    url: PropTypes.shape({
      status: PropTypes.bool.isRequired,
      messages: PropTypes.string,
    }),
    tags: PropTypes.shape({
      status: PropTypes.bool.isRequired,
      messages: PropTypes.string,
    }),
    description: PropTypes.shape({
      status: PropTypes.bool.isRequired,
      messages: PropTypes.string,
    }),
    version: PropTypes.shape({
      status: PropTypes.bool.isRequired,
      messages: PropTypes.string,
    }),
  }).isRequired,
  skillsType: PropTypes.arrayOf(PropTypes.string).isRequired,
};
