import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, TableCell, TableRow, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import apiService from '../../../../services/api.service';
import RowOperations from './RowOperations';

export default function ApiKeyTableRow({
  setIsLoading,
  apiKeyData,
  setSuccessSnackbar,
  setInfoSnackbar,
}) {
  const [isEditable, setIsEditable] = useState(false);
  const [isInputError, setIsInputError] = useState(false);
  const [keyName, setKeyName] = useState(apiKeyData.name);
  const [keyExpirationDate, setKeyExpirationDate] = useState(apiKeyData.expirationDate);

  const scopes = Object.keys(apiKeyData.scopes).reduce((acc, cur) => {
    const scopeSpecifics = apiKeyData.scopes[cur].map((scopeSpecific) => `${cur}:${scopeSpecific}`);
    return acc.concat(scopeSpecifics);
  }, []);

  const checkNameUniqueness = async () => {
    try {
      const response = await apiService.admin.getApiKeysNames();

      if (response.includes(keyName) || !keyName) setIsInputError(true);
      else setIsInputError(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <TableRow>
      <TableCell>
        {isEditable ? (
          <TextField
            size="small"
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '0.875rem',
              },
            }}
            value={keyName}
            error={isInputError}
            onBlur={checkNameUniqueness}
            onInput={(e) => {
              setIsInputError(!e.target.value.match('^[a-zA-Z0-9-:_]*$'));
              setKeyName(e.target.value);
            }}
          />
        ) : (
          <Box>{keyName}</Box>
        )}
      </TableCell>
      <TableCell>{apiKeyData.prefix}</TableCell>
      <TableCell sx={{ pb: 0.5 }}>
        {scopes.map((scope) => (
          <Box key={scope}>
            <Chip fontSize="small" label={scope} size="small" sx={{ mb: 0.5 }} />
            <br />
          </Box>
        ))}
      </TableCell>
      <TableCell>{new Date(apiKeyData.createdAt).toDateString()}</TableCell>
      <TableCell>
        {apiKeyData.lastUsedAt === 'not used'
          ? apiKeyData.lastUsedAt
          : new Date(apiKeyData.lastUsedAt).toDateString()}
      </TableCell>
      <TableCell>
        {isEditable ? (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              value={keyExpirationDate}
              onChange={(value) => setKeyExpirationDate(value)}
              renderInput={(params) => (
                <TextField
                  size="small"
                  sx={{
                    minWidth: 168,
                    '& .MuiInputBase-input': {
                      fontSize: '0.875rem',
                    },
                  }}
                  margin="dense"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        ) : (
          `${new Date(apiKeyData.expirationDate).toDateString()} ${new Date(
            apiKeyData.expirationDate,
          ).toLocaleTimeString()}`
        )}
      </TableCell>
      <TableCell align="center">
        <RowOperations
          checkNameUniqueness={checkNameUniqueness}
          apiKeyData={apiKeyData}
          keyName={keyName}
          keyExpirationDate={keyExpirationDate}
          setSuccessSnackbar={setSuccessSnackbar}
          setIsLoading={setIsLoading}
          setKeyName={setKeyName}
          setKeyExpirationDate={setKeyExpirationDate}
          setIsEditable={setIsEditable}
          isEditable={isEditable}
          isInputError={isInputError}
          setInfoSnackbar={setInfoSnackbar}
        />
      </TableCell>
    </TableRow>
  );
}

const apiKeyInfo = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  scopes: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  lastUsedAt: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
};

ApiKeyTableRow.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
  apiKeyData: PropTypes.shape(apiKeyInfo).isRequired,
  setSuccessSnackbar: PropTypes.func.isRequired,
  setInfoSnackbar: PropTypes.func.isRequired,
  setErrorSnackbar: PropTypes.func.isRequired,
};
