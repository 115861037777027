import { TableCell, TableSortLabel } from '@mui/material';
import LinkViewsTableRowSkeleton from '../LinkViewsTableRowSkeleton';

const useTableActions = (onPaginationChange, order, orderBy, onChangeOrder, tableCells) => {
  const handleChangePage = (_, newPage) => {
    onPaginationChange({ currentPage: newPage });
  };

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    onPaginationChange({ rowsPerPage: +value });
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    onChangeOrder({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  const getTableSortLabelDirection = (tableCell) => (orderBy === tableCell.id ? order : 'asc');

  const getTableCells = () => {
    return tableCells.map((tableCell) => (
      <TableCell
        key={tableCell.id}
        width={tableCell.width}
        sx={{
          textAlign: tableCell.textAlign,
          minWidth: tableCell.minWidth,
          maxWidth: '300px',
        }}
      >
        <TableSortLabel
          active={orderBy === tableCell.id}
          direction={getTableSortLabelDirection(tableCell)}
          onClick={() => handleSort(tableCell.id)}
        >
          {tableCell.name}
        </TableSortLabel>
      </TableCell>
    ));
  };

  const renderSkeletonRows = (amount) => {
    return Array.from({ length: amount }, (_, index) => <LinkViewsTableRowSkeleton key={index} />);
  };

  return {
    handleChangePage,
    handleChangeRowsPerPage,
    getTableCells,
    renderSkeletonRows,
  };
};

export default useTableActions;
