import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  createTheme,
  FormHelperText,
  MenuItem,
  Modal,
  TextField,
  ThemeProvider,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { isURL } from 'validator';
import StepLoader from '../../../../components/stepper/StepLoader';
import apiService from '../../../../services/api.service';
import axios from '../../../../services/http.service';

const StyledModalBody = styled(Box)(() => ({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '40px 30px',
  background: 'rgb(255, 255, 255)',
  borderRadius: '.15em',
  minWidth: 350,
}));

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '0.875rem',
        },
      },
    },
  },
});

export default function ModalWindow({
  isOpened,
  setIsOpened,
  setIsLoading,
  setWarningSnackbarText,
}) {
  const [link, setLink] = useState('');
  const [isInputError, setIsInputError] = useState(false);
  const [fetchedDepartments, setFetchedDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [duplicateDepartmentsLink, setDuplicateDepartmentsLink] = useState([]);
  const handleChange = (event) => {
    setSelectedDepartments(event.target.value);

    return selectedDepartments;
  };
  const validateLink = (value) => {
    return isURL(value, {
      protocols: ['http', 'https'],
      require_protocol: true,
    });
  };
  const handleLinkChange = (event) => {
    const { value } = event.target;
    const isValid = validateLink(value);
    setIsInputError(!isValid);
    setLink(value);
  };

  useEffect(async () => {
    await axios
      .get('/departments')
      .then((res) => {
        setFetchedDepartments(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const saveLink = async () => {
    try {
      const result = await apiService.admin.saveRssLinks({
        departmentIds: selectedDepartments,
        rssLink: link,
      });
      setDuplicateDepartmentsLink(result);
      setIsOpened(false);
      setIsLoading(true);
      setLink('');
      setSelectedDepartments([]);
    } catch (error) {
      setIsOpened(false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (duplicateDepartmentsLink.length > 0) {
      setWarningSnackbarText(
        `Link for these departments (${duplicateDepartmentsLink.join(', ')}) are duplicated!`,
      );
    }
  }, [duplicateDepartmentsLink]);
  return (
    <ThemeProvider theme={theme}>
      <Modal open={isOpened} onClose={() => setIsOpened(false)}>
        <StyledModalBody>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="department-select-label" sx={{ top: '-8px' }}>
              Department
            </InputLabel>
            <Select
              sx={{ height: '36px' }}
              labelId="department-select-label"
              id="department-select"
              value={selectedDepartments}
              label="Departments"
              onChange={handleChange}
              multiple
            >
              {fetchedDepartments.map((department) => (
                <MenuItem key={department._id} value={department._id}>
                  {department.title}
                </MenuItem>
              ))}
            </Select>
            <StepLoader isHidden={false} />
          </FormControl>
          <div
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <TextField
              label="Link"
              id="outlined-basic"
              variant="outlined"
              value={link}
              size="small"
              margin="dense"
              error={isInputError}
              sx={{ width: '100%' }}
              onChange={handleLinkChange}
              onInput={(e) => {
                setLink(e.target.value);
              }}
            />
            {isInputError && (
              <FormHelperText error>
                Please enter a valid URL that starts with either `https` or `http`
                <br />
                and ends with either `.rss` or includes the word `feed`
              </FormHelperText>
            )}
          </div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 1,
            }}
          >
            <Button
              variant="outlined"
              disabled={!link || isInputError || selectedDepartments.length === 0}
              onClick={() => {
                saveLink();
              }}
            >
              Save link
            </Button>
          </Box>
        </StyledModalBody>
      </Modal>
    </ThemeProvider>
  );
}

ModalWindow.propTypes = {
  setWarningSnackbarText: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};
