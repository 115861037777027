import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
  Link,
} from '@mui/material';
import TreeItemCustom from '../../../../components/TreeItemCustom';
import MaterialTypeIcon from '../../../Home/components/MaterialTypeIcon';

const StyledTreeLabelBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
const StyledLabelBox = styled(Box)(() => ({
  textAlign: 'initial',
  fontSize: '18px',
  borderRadius: '4px',
  padding: '1em',
  border: '1px solid rgb(211, 211, 211)',
  margin: '5px',
}));

const StyledFieldsContainer = styled(Box)(() => ({
  textAlign: 'initial',
  fontSize: '1rem',
  fontWeight: 400,
}));
export default function SkillNodePreview({ node, onClick, onLinkClick }) {
  const { details } = node;

  const preparedDetails = useMemo(() => {
    const resultArr = [];

    details.forEach((detail) => {
      const foundedItem = resultArr.findIndex((item) => item.name === detail.name);

      if (foundedItem === -1) {
        resultArr.push({
          name: detail.name,
          itemId: detail.itemId,
          successCriteria: [detail.successCriteria],
          materials: [
            {
              name: detail.materialName,
              type: detail.materialType,
              link: detail.link,
            },
          ],
        });
      } else {
        const { successCriteria } = resultArr[foundedItem];
        resultArr[foundedItem].successCriteria = [
          ...new Set([...successCriteria, detail.successCriteria]),
        ];
        resultArr[foundedItem].materials.push({
          name: detail.materialName,
          type: detail.materialType,
          link: detail.link,
        });
      }
    });

    return resultArr;
  }, [details]);

  return (
    <StyledFieldsContainer key={node.itemId}>
      <TreeItemCustom
        nodeId={node.itemId}
        label={
          <StyledTreeLabelBox>
            <Typography
              sx={{
                m: 1,
                marginRight: '10px',
              }}
              variant="p"
              component="p"
            >
              {node.name}
            </Typography>
          </StyledTreeLabelBox>
        }
        onClick={() => onClick(node.itemId)}
      >
        {preparedDetails.map((pDetail) => (
          <StyledLabelBox key={pDetail.name}>
            <Grid>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '18px',
                }}
              >
                {pDetail.name}
              </Typography>
            </Grid>
            <Box mt={2}>
              <Typography sx={{ fontWeight: 600 }}>Success Criteria:</Typography>
              <List sx={{ padding: '8px 0 0 8px' }}>
                {pDetail.successCriteria.map((successCriteria) => (
                  <ListItem key={successCriteria} sx={{ padding: '0' }}>
                    <ListItemText primary={`- ${successCriteria}`} />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box mt={2}>
              <Grid container alignItems="end" justifyContent="space-between">
                <Grid>
                  <Typography sx={{ fontWeight: 600 }}>Materials:</Typography>
                  <List sx={{ padding: '8px 0 0 8px' }}>
                    {pDetail.materials.map(({ link, type, name }) => (
                      <ListItem key={link} sx={{ padding: '0' }}>
                        <ListItemIcon sx={{ minWidth: 0 }}>
                          <MaterialTypeIcon type={type} />
                        </ListItemIcon>
                        <Link
                          onClick={() => {
                            onLinkClick({ link, taskId: node.itemId });
                          }}
                          href={link}
                          target="_blank"
                          rel="noopener"
                        >
                          <ListItemText primary={name} />
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Box>
          </StyledLabelBox>
        ))}
      </TreeItemCustom>
    </StyledFieldsContainer>
  );
}

const nodeData = {
  itemId: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.string).isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      successCriteria: PropTypes.string.isRequired,
      materialName: PropTypes.string.isRequired,
      materialType: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  skillType: PropTypes.string.isRequired,
  status: PropTypes.string,
  isDetails: PropTypes.bool,
  onMenuItemClick: PropTypes.func,
};

SkillNodePreview.propTypes = {
  node: PropTypes.shape(nodeData).isRequired,
  onClick: PropTypes.func.isRequired,
  onLinkClick: PropTypes.func.isRequired,
};
