import { useContext } from 'react';
import PropTypes from 'prop-types';
import { TextField, ThemeProvider } from '@mui/material';
import GrowPageContext from '../contexts/GrowPageContext';
import StyledTextFieldContainer from '../styled-components/DetailsTextFieldContainer';
import CodeDetailsTheme from '../themes/CodeDetailsTheme';

function ReadonlyTextField(props) {
  return (
    <TextField
      {...props}
      InputProps={{
        readOnly: true,
      }}
    />
  );
}

export default function PlainCodeDetailsForm({ node }) {
  const { growLevels } = useContext(GrowPageContext);
  return (
    <ThemeProvider theme={CodeDetailsTheme}>
      <StyledTextFieldContainer>
        <ReadonlyTextField defaultValue={node.name} />
        <ReadonlyTextField defaultValue={node.successCriteria} />
        <ReadonlyTextField defaultValue={node.materialName} />
        <ReadonlyTextField defaultValue={node.materialType} />
        <ReadonlyTextField defaultValue={node.link} />
        <ReadonlyTextField
          defaultValue={
            growLevels.filter((level) => node.level === level._id)[0]?.key || node.level
          }
        />
      </StyledTextFieldContainer>
    </ThemeProvider>
  );
}

const nodeItem = {
  itemId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  successCriteria: PropTypes.string.isRequired,
  materialName: PropTypes.string.isRequired,
  materialType: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
};

PlainCodeDetailsForm.propTypes = {
  node: PropTypes.shape(nodeItem).isRequired,
};
