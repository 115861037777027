import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Checkbox,
  createTheme,
  FormControlLabel,
  Modal,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import apiService from '../../../../services/api.service';
import ActionsEnum from '../enums/actions.enum';
import EntitiesEnum from '../enums/entities.enum';
import ApiKeyCreatedBox from './ApiKeyCreatedBox';

const StyledModalBody = styled(Box)(() => ({
  width: '90%',
  maxWidth: '600px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  padding: '15px 15px',
  background: 'rgb(255, 255, 255)',
  borderRadius: '.15em',
  overflowY: 'auto',
}));

const CheckBoxContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  marginBottom: '10px',
  alignItems: 'center',
}));

const scopes = Object.values(ActionsEnum);
const entities = Object.values(EntitiesEnum);

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '0.875rem',
        },
      },
    },
  },
});

export default function ModalWindow({ isOpened, setIsOpened, setIsLoading }) {
  const [keyName, setKeyName] = useState('');
  const [keyExpirationDate, setKeyExpirationDate] = useState(null);
  const [keyScopes, setKeyScopes] = useState([]);
  const [isInputError, setIsInputError] = useState(false);
  const [isKeySaved, setIsKeySaved] = useState(false);
  const [apiKey, setApiKey] = useState('');

  const checkNameUniqueness = async () => {
    try {
      const response = await apiService.admin.getApiKeysNames();

      if (response.includes(keyName)) setIsInputError(true);
      else setIsInputError(false);
    } catch (error) {
      console.log(error);
      setIsOpened(false);
      setIsLoading(false);
    }
  };

  const saveApiKey = async () => {
    try {
      await checkNameUniqueness();
      const response = await apiService.admin.saveApiKey({
        name: keyName,
        expirationDate: keyExpirationDate,
        scopes: keyScopes.reduce((acc, cur) => {
          const [entity, scope] = cur.split(':');
          acc[entity] = acc[entity] ? [...acc[entity], scope] : [scope];
          return acc;
        }, {}),
      });

      setApiKey(response);
      setIsKeySaved(true);
    } catch (error) {
      console.log(error);
      setIsOpened(false);
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    if (isKeySaved) {
      setKeyName('');
      setKeyExpirationDate(null);
      setKeyScopes([]);
      setIsInputError(false);
      setIsInputError(false);
      setIsKeySaved(false);
      setApiKey('');
      setIsLoading(true);
    }

    setIsOpened(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={isOpened} onClose={handleCloseModal}>
        <StyledModalBody>
          <Typography fontSize="small" fontWeight={600}>
            Add an access api key
          </Typography>
          <Typography fontSize="small" sx={{ marginBottom: 2 }}>
            Enter the name of access api key, and we&apos;ll return a unique key value.
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
            <Typography fontSize="small" fontWeight={600} minWidth="120px">
              Key name
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={keyName}
              size="small"
              margin="dense"
              error={isInputError}
              sx={{ width: '20vw', minWidth: '196px' }}
              onBlur={() => {
                if (keyName) checkNameUniqueness();
              }}
              onInput={(e) => {
                setIsInputError(!e.target.value.match('^[a-zA-Z0-9-_:]*$'));
                setKeyName(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
            <Typography fontSize="small" fontWeight={600} minWidth="120px">
              Expiration date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                value={keyExpirationDate}
                onChange={(value) => setKeyExpirationDate(value)}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: '20vw', minWidth: '196px' }}
                    size="small"
                    margin="dense"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Typography fontSize="small" fontWeight={600}>
            Select scopes
          </Typography>
          <Typography fontSize="small" sx={{ marginBottom: 2 }}>
            Scopes set the permission levels granted to the key.
          </Typography>
          {entities.map((entity) => (
            <Box key={entity} sx={{ display: 'flex', marginBottom: 2 }}>
              <Typography
                fontSize="small"
                minWidth="96px"
                sx={{ alignItems: 'flex-start', marginRight: 2 }}
              >
                {entity}
              </Typography>
              <CheckBoxContainer>
                {scopes.map((scope) => (
                  <Box key={scope}>
                    <FormControlLabel
                      value={scope}
                      label={<Typography fontSize="small">{scope}</Typography>}
                      control={
                        <Checkbox
                          checked={keyScopes.indexOf(`${entity}:${scope}`) > -1}
                          name={scope}
                          size="small"
                          onChange={(e) =>
                            setKeyScopes(
                              e.target.checked
                                ? [...keyScopes, `${entity}:${e.target.name}`]
                                : keyScopes.filter(
                                    (keyScope) => keyScope !== `${entity}:${e.target.name}`,
                                  ),
                            )
                          }
                        />
                      }
                      sx={{
                        '& .MuiButtonBase-root': {
                          paddingTop: 0,
                          paddingBottom: 0,
                        },
                      }}
                    />
                  </Box>
                ))}
              </CheckBoxContainer>
            </Box>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
            <Button
              variant="outlined"
              disabled={
                !keyName || !keyExpirationDate || !keyScopes.length || isInputError || isKeySaved
              }
              onClick={saveApiKey}
            >
              Save key
            </Button>
          </Box>
          {isKeySaved && <ApiKeyCreatedBox apiKey={apiKey} />}
        </StyledModalBody>
      </Modal>
    </ThemeProvider>
  );
}

ModalWindow.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};
