import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Grid, Input, Link, Paper, styled, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import useDebouncedHandler from '../../../hooks/useDebouncedHandler';
import ApiService from '../../../services/api.service';
import TokenService from '../../../services/token.service';
import AdminBasicPage from '../../../templates/AdminBasicPage';
import FilterByDepartments from '../Users/components/FilterByDepartments';
import ReviewsTable from './components/ReviewsTable';

const StyledItem = styled(Paper)(() => ({
  display: 'flex',
  marginBottom: '1em',
  padding: '2em 3em',
  borderRadius: '.15em',
}));

export default function AdminReviews({ crumbs, title }) {
  const [reviewHistoryData, setReviewHistoryData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [historySearchInput, setHistorySearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [historySort, setHistorySort] = useState('updatedAt');
  const [departments, setDepartments] = useState([]);
  const [departmentSearch, setDepartmentSearch] = useState([]);
  const history = useHistory();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangeFilter = (departmentId) => {
    if (departmentSearch.find((department) => department === departmentId)) {
      const index = departmentSearch.indexOf(departmentId);
      const arr = departmentSearch.slice();
      arr.splice(index, 1);
      setDepartmentSearch(arr);
    } else {
      setDepartmentSearch([...departmentSearch, departmentId]);
    }

    if (departmentId === window.location.search.slice(1)) {
      history.replace({
        search: '',
      });
    }

    setCurrentPage(0);
    setIsLoading(true);
  };

  const fetchReviewHistory = async (skip, limit, search = '', sort = '') => {
    try {
      const response = await ApiService.admin.getReviews({
        params: {
          departmentIds: departmentSearch.join(','),
          search,
          skip: skip * limit,
          limit,
          sort,
        },
      });
      console.log('response', response);
      setCurrentPage(skip);
      setReviewHistoryData(response.paginatedResult);
      setTotalCount(response.totalCount ?? 0);
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  };

  const fetchDepartmentsLvls = async () => {
    try {
      setIsLoading(true);

      const response = await ApiService.admin.getDepartmentsLvls();

      setDepartments(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchReviewHistory(currentPage, rowsPerPage, historySearchInput, historySort);
    }
  }, [isLoading]);

  useEffect(() => fetchDepartmentsLvls(), []);

  const handleInputChange = (event) => {
    setHistorySearchInput(event.target.value.trim());
    setCurrentPage(0);
    setIsLoading(true);
  };

  const getExportCsvLink = (skip, limit, search = '', sort = '') => {
    const params = {
      departmentIds: departmentSearch.join(','),
      search,
      sort,
    };

    const query = Object.keys(params)
      .map((key) => `&${key}=${params[key]}`)
      .join('');

    const accessToken = TokenService.getLocalAccessToken();
    return `${process.env.REACT_APP_API_URL}/api/v1/admin/reviews/export?accessToken=${accessToken}${query}`;
  };

  const { debouncedHandler } = useDebouncedHandler(handleInputChange);

  return (
    <AdminBasicPage crumbs={crumbs} title={title}>
      <Grid justifyContent="center" sx={{ margin: '8px 36px 0' }} item>
        <Grid container>
          <Grid sx={{ flex: { md: '2 0 350px', xs: '2 0 140px' } }} item>
            <Grid
              container
              sx={{ justifyContent: { xs: 'left', sm: 'space-between' } }}
              alignItems="center"
              height={48}
            >
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  width: '30%',
                }}
              >
                <Grid
                  sx={{
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 0px',
                  }}
                >
                  <Link
                    href={getExportCsvLink(
                      currentPage,
                      rowsPerPage,
                      historySearchInput,
                      historySort,
                    )}
                    underline="none"
                    color="inherit"
                    sx={{
                      width: '100%',
                      height: '100%',
                      border: '1px solid #c1c1c1',
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FileDownloadOutlinedIcon
                      fontSize="small"
                      sx={{ width: '80%', height: '80%', color: '#c1c1c1' }}
                    />
                  </Link>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  width: '70%',
                  minWidth: '100px',
                  maxWidth: '195px',
                }}
              >
                <Input sx={{ width: '100%' }} placeholder="Search" onChange={debouncedHandler} />
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: 'grid',
                gridTemplateColumns: isSmallScreen ? '300px' : '168px 1fr',
                gap: '1em',
              }}
            >
              <FilterByDepartments
                departmentsData={departments}
                handleChangeFilter={handleChangeFilter}
                departmentSearch={departmentSearch}
              />
              <StyledItem elevation={8} container="true" sx={{ display: 'grid' }}>
                <ReviewsTable
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  reviewHistoryData={reviewHistoryData}
                  totalCount={totalCount}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  setSort={setHistorySort}
                  historySearchInput={historySearchInput}
                  historySort={historySort}
                />
              </StyledItem>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminBasicPage>
  );
}

AdminReviews.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};
