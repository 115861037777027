import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const mainColor = '#7553EA';

export const StyledModalBody = styled(Box)(() => ({
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  maxWidth: 600,
  minHeight: 300,
  maxHeight: 600,
  height: '100%',
  padding: '40px 32px',
  background: 'rgb(255, 255, 255)',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
}));

export const StyledButtonContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '32px',
}));

export const StyledButton = styled(Button)(() => ({
  background: mainColor,
  '&:hover': {
    backgroundColor: mainColor,
  },
}));
