import { io } from 'socket.io-client';
import TokenService from './token.service';

export default class SocketService {
  constructor() {
    this.tokenService = new TokenService();
  }

  static getSocketInstance(path = '') {
    return io(`${process.env.REACT_APP_API_URL}/v1/${path}`, {
      auth: {
        token: `Bearer ${
          sessionStorage.getItem('guestToken') || TokenService.getLocalAccessToken()
        }`,
      },
      transports: ['websocket', 'polling'],
    });
  }
}
