import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Alert,
  Snackbar,
  TableSortLabel,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import RowSkeleton from './RowSkeleton';
import UserTableRow from './UserTableRow';

const CircleHeaderCell = styled(TableCell)({
  minWidth: 64,
  maxWidth: 50,
});

const theme = createTheme({
  components: {
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          marginRight: '-1.1em',
          marginLeft: '0.1em',
        },
      },
    },
  },
});

const tableCells = [
  {
    id: 'lastName',
    name: 'LastName',
    width: '10%',
    textAlign: 'left',
    align: 'left',
  },
  {
    id: 'firstName',
    name: 'FirstName',
    width: '10%',
    textAlign: 'left',
    align: 'left',
  },
  {
    id: 'email',
    name: 'Email',
    width: '20%',
    textAlign: 'left',
    align: 'left',
  },
  {
    id: 'departmentId',
    name: 'Department',
    width: '7%',
    textAlign: 'left',
    align: 'left',
  },
  {
    id: 'levelKey',
    name: 'Level',
    width: '7%',
    textAlign: 'center',
    align: 'center',
  },
  {
    id: 'goalKey',
    name: 'Goal',
    width: '7%',
    textAlign: 'center',
    align: 'center',
  },
  {
    id: 'isAdmin',
    name: 'Admin',
    width: '7%',
    textAlign: 'left',
    align: 'left',
  },
  {
    id: 'englishLevel',
    name: 'English',
    width: '7%',
    textAlign: 'center',
    align: 'center',
  },
  {
    id: 'createdAt',
    name: 'CreatedAt',
    width: '7%',
    textAlign: 'left',
    align: 'left',
  },
  {
    id: 'lastLoginAt',
    name: 'LastLoginDate',
    width: '7%',
    textAlign: 'left',
    align: 'left',
  },
];

export default function UsersTable({
  isLoading,
  setIsLoading,
  usersData,
  totalCount,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  departments,
  setSort,
}) {
  const [successSnackBar, setSuccessSnackbar] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('lastName');

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setIsLoading(true);
  };

  const handleChangeRowsPerPage = (event) => {
    const parsedPage = parseInt(event.target.value, 10);
    setRowsPerPage(parsedPage);
    setCurrentPage(0);
    setIsLoading(true);
  };

  useEffect(() => {
    if (orderBy) {
      setSort(order === 'asc' ? orderBy : `-${orderBy}`);
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getTableSortLabelDirection = (tableCell) => (orderBy === tableCell.id ? order : 'asc');

  const getTableCells = () => {
    return tableCells.map((tableCell) => (
      <TableCell
        key={tableCell.id}
        width={tableCell.width}
        sx={{ textAlign: tableCell.textAlign, minWidth: 100 }}
        align={tableCell.align}
      >
        <TableSortLabel
          active={orderBy === tableCell.id}
          direction={getTableSortLabelDirection(tableCell)}
          onClick={() => handleSort(tableCell.id)}
        >
          {tableCell.name}
        </TableSortLabel>
      </TableCell>
    ));
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer>
        <Table size="small" aria-label="users table">
          <TableHead>
            <TableRow>
              <CircleHeaderCell width="5%" align="left" />
              {getTableCells()}
              <CircleHeaderCell width="5%" align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? [1, 2, 3, 4, 5].map((item) => <RowSkeleton key={item} />)
              : usersData.map((user) => (
                  <UserTableRow
                    key={user._id}
                    userData={user}
                    departments={departments}
                    setSuccessSnackbar={() => {
                      setSuccessSnackbar();
                      setIsLoading(true);
                    }}
                  />
                ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={12}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <Snackbar
          open={successSnackBar}
          autoHideDuration={6000}
          onClose={() => setSuccessSnackbar(false)}
        >
          <Alert
            onClose={() => setSuccessSnackbar(false)}
            severity="success"
            sx={{ width: '100%' }}
          >
            Saved!
          </Alert>
        </Snackbar>
      </TableContainer>
    </ThemeProvider>
  );
}

const userData = {
  _id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  avatar: PropTypes.shape({}).isRequired,
  hydraProfileLink: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  lastLoginAt: PropTypes.string.isRequired,
  departmentId: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  goal: PropTypes.string.isRequired,
  englishLevel: PropTypes.string,
};

UsersTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  usersData: PropTypes.arrayOf(PropTypes.shape(userData)).isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      levels: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          position: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          _id: PropTypes.string.isRequired,
        }),
      ).isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setSort: PropTypes.func.isRequired,
};
