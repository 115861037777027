import GoalsConstants from '../../../constants/goals.constants';

export const checkIsEverythingApproved = (skills) =>
  !skills.some((skill) => skill.status !== GoalsConstants.GOAL_STATUSES.APPROVED);

export const checkIsEverythingFinished = (skills) =>
  skills.every(
    (skill) =>
      skill.status === GoalsConstants.GOAL_STATUSES.APPROVED ||
      skill.status === GoalsConstants.GOAL_STATUSES.DECLINED,
  );

export const checkIsEverythingApprovedWithPercentage = (skillDetails) => {
  const flattenedSkillDetails = skillDetails.flat();
  const approvedSkills = flattenedSkillDetails.filter(
    ({ status }) => status === GoalsConstants.GOAL_STATUSES.APPROVED,
  ).length;

  return (
    (approvedSkills * 100) / flattenedSkillDetails.length >= GoalsConstants.APPROVED_PERCENTAGE
  );
};

export const checkIsEverySubSkillApproved = (skills) =>
  skills.every((skill) => {
    if (!skill.status) {
      return true;
    }

    return skill.status && skill.status === GoalsConstants.GOAL_STATUSES.APPROVED;
  });

export const checkIsEverySubSkillInProgress = (skills) =>
  skills.every((skill) => {
    if (!skill.status) {
      return true;
    }

    return skill.status && skill.status === GoalsConstants.GOAL_STATUSES.IN_PROGRESS;
  });

export const getSimilarItemIds = (details, itemId) => {
  const foundDetails = details.find((v) => v.itemId === itemId);

  const similarDetails = details.filter((j) => j.name === foundDetails.name);

  return similarDetails.map((i) => i.itemId);
};

export const refreshSkillStatus = ({
  skills,
  userId,
  goalId,
  itemId,
  status,
  comment,
  recommendation,
}) =>
  skills.map((skill) => {
    if (skill._id === goalId && skill.userId === userId) {
      const itemIds = getSimilarItemIds(skill.details, itemId);

      const details = skill.details.map((detail) => {
        if (itemIds.includes(detail.itemId)) {
          const commentObj = status === GoalsConstants.GOAL_STATUSES.DECLINED ? { comment } : {};
          const recommendationObj =
            status === GoalsConstants.GOAL_STATUSES.APPROVED ||
            GoalsConstants.GOAL_STATUSES.DECLINED
              ? { recommendation }
              : {};

          return {
            ...detail,
            ...commentObj,
            ...recommendationObj,
            status,
          };
        }

        return detail;
      });

      if (status === GoalsConstants.GOAL_STATUSES.APPROVED) {
        const isFullApproved = checkIsEverythingApproved(details);

        if (isFullApproved) {
          return {
            ...skill,
            status,
            details,
          };
        }
      }

      if (status === GoalsConstants.GOAL_STATUSES.DECLINED) {
        return {
          ...skill,
          status,
          details,
        };
      }

      return {
        ...skill,
        details,
      };
    }

    return skill;
  });

export const mergeSimilarSkillDetails = (details) => {
  if (!details.length) {
    return details;
  }

  return details.reduce((acc, detail) => {
    const foundDetailIndex = acc.findIndex((v) => v.name === detail.name);

    if (foundDetailIndex === -1) {
      acc.push(detail);
    } else if (acc[foundDetailIndex].status !== detail.status) {
      acc[foundDetailIndex].status = detail.status;
    }

    return acc;
  }, []);
};

export const getAllDetails = (reviewSkills) => {
  return reviewSkills.reduce((s, c) => {
    s.push(...c.details);
    return s;
  }, []);
};
