import React, { useEffect, useReducer, useState } from 'react';
import { TouchAppOutlined } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { Box, Divider, Grid, Icon, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import apiService from '../../../../services/api.service';
import { ACTION_TYPES, initialState, reducer } from '../../../Admin/Skills/reducer';
import HexGrid from '../../../Hexagon/components/HexGrid';
import FilterByTag from './FilterByTag';
import SearchBar from './SearchBar';
import UserProfileCard from './UserProfileCard';

export default function UserSkillsPage() {
  const [skills, setSkills] = useState([]);
  const [userData, setUserData] = useState({});
  const { department, id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [, setAnchorEl] = useState(null);
  const [itemForAction, setItemForAction] = useState(null);
  const [userSkills, setUserSkills] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterIcon, setFilterIcon] = useState(<FilterListIcon />);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
    setFilterIcon(filterOpen ? <FilterListIcon /> : <FilterListOffIcon />);
  };

  const disableScrollStyle = {
    msOverflowStyle: 'none',
    '::-webkit-scrollbar': { display: 'none' },
  };

  const getSkill = async () => {
    try {
      const [{ approvedSkills }, allUserSkills] = await Promise.all([
        apiService.head.getSkill(department),
        apiService.head.getUserSkills(id),
      ]);

      const filteredSkills = approvedSkills
        .filter((skill) => {
          return !allUserSkills.find((userSkill) => userSkill._id === skill._id);
        })
        .sort((a, b) => a._id.localeCompare(b._id));

      setSkills(filteredSkills);
      setUserSkills(allUserSkills.sort((a, b) => a._id.localeCompare(b._id)));
      setIsLoading(false);

      return approvedSkills;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return null;
    }
  };

  const getUser = async () => {
    try {
      const result = await apiService.head.getUser(id);

      setUserData(result);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const menuAddItemOnClick = () => {
    const index = skills.findIndex((item) => item._id === itemForAction._id);

    setSkills(skills.slice(0, index).concat(skills.slice(index + 1)));
    setUserSkills([...userSkills, itemForAction].sort((a, b) => a._id.localeCompare(b._id)));
    dispatch({
      type: ACTION_TYPES.SET_FORM_VALUES,
      payload: {
        formValues: { ...state.formValues, ...itemForAction },
        isOpenedUpdate: true,
      },
    });
  };

  const menuDeleteItemOnClick = () => {
    const index = userSkills.findIndex((item) => item._id === itemForAction._id);

    setSkills([...skills, itemForAction].sort((a, b) => a._id.localeCompare(b._id)));
    setUserSkills(userSkills.slice(0, index).concat(userSkills.slice(index + 1)));
    dispatch({
      type: ACTION_TYPES.SET_FORM_VALUES,
      payload: {
        formValues: { ...state.formValues, ...itemForAction },
        isDelete: true,
      },
    });
  };

  const addSkillForUser = async () => {
    try {
      await apiService.head.addSkillForUser(id, itemForAction._id);
      menuAddItemOnClick();
    } catch (e) {
      console.error(e);
    }
  };

  const deleteSkillForUser = async () => {
    try {
      await apiService.head.deleteSkillForUser(id, itemForAction._id);
      menuDeleteItemOnClick();
    } catch (e) {
      console.error(e);
    }
  };

  const handleTagSelect = (tags) => {
    setSelectedTags(tags);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredSkills = skills.filter((skill) => {
    const isInSelectedTags =
      selectedTags.length === 0 || selectedTags.some((tag) => skill.tags.includes(tag));
    const isMatchingSearchQuery =
      !searchQuery ||
      (skill.title &&
        skill.title
          .toLowerCase()
          .replace(/[^\w\s]/gi, '')
          .includes(searchQuery.toLowerCase().replace(/\s/g, ''))) ||
      (skill.description &&
        skill.description
          .toLowerCase()
          .replace(/[^\w\s]/gi, '')
          .includes(searchQuery.toLowerCase().replace(/\s/g, '')));

    return isInSelectedTags && isMatchingSearchQuery;
  });

  useEffect(() => {
    if (isLoading) {
      getUser();
      getSkill();
    }
  }, [isLoading]);

  useEffect(() => {
    if (itemForAction) {
      setItemForAction(null);
      if (skills.find((skill) => skill._id === itemForAction._id)) {
        addSkillForUser();
      } else {
        deleteSkillForUser();
      }
    }
  }, [itemForAction]);

  return isLoading ? null : (
    <Grid container justifyContent="center" alignItems="flex-start">
      <Grid
        item
        xs={12}
        md={5}
        lg={4}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop="25px"
          alignItems="flex-start"
          flexWrap="wrap"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            flexGrow={1}
            maxWidth="450px"
          >
            <Box display="flex" alignItems="center" marginBottom="16px">
              <SearchBar width="auto" onChange={handleSearch} />
              <IconButton onClick={toggleFilter}>{filterIcon}</IconButton>
            </Box>
            {filterOpen && (
              <FilterByTag
                tags={[
                  ...new Set(
                    skills
                      .flatMap((skill) => skill.tags)
                      .concat(userSkills.flatMap((skill) => skill.tags)),
                  ),
                ]}
                selectedTags={selectedTags}
                handleTagSelect={handleTagSelect}
                sx={{ flexGrow: 1 }}
              />
            )}
            <Box
              sx={{
                marginTop: '32px',
                maxHeight: '750px',
                overflowY: 'auto',
                width: '100%',
                ...disableScrollStyle,
              }}
            >
              <HexGrid
                isHeadUserSkill
                setAnchorEl={setAnchorEl}
                setItemForAction={setItemForAction}
                skills={filteredSkills}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Divider
        orientation={isMediumScreen ? 'vertical' : 'horizontal'}
        flexItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: isMediumScreen ? 'auto' : '80%',
        }}
      >
        <Icon component={TouchAppOutlined} fontSize="large" color="primary" />
      </Divider>
      <Grid
        item
        xs={12}
        md={5}
        lg={4}
        marginTop="16px"
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box
          display="flex"
          justifyContent="center"
          sx={{ width: { xs: '95%', md: '100%' } }}
          fontSize="30px"
          marginBottom="16px"
          height="150px"
        >
          <UserProfileCard userData={userData} approvedSkills={userSkills.length} />
        </Box>
        <Box
          sx={{
            maxHeight: '600px',
            overflowY: 'auto',
            maxWidth: '450px',
            width: '100%',
            ...disableScrollStyle,
          }}
        >
          <HexGrid
            isHeadUserSkill
            setAnchorEl={setAnchorEl}
            setItemForAction={setItemForAction}
            skills={userSkills}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
