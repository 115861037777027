import userRoles from '../../constants/userRoles';
import HeadDashboardPage from '../../pages/Head/Dashboard';
import GrowPage from '../../pages/Head/Grow';
import HeadDepartmentsPage from '../../pages/Head/HeadDepartmentsPage';
import LevelsPage from '../../pages/Head/Levels';
import ReviewHistoryPage from '../../pages/Head/ReviewHistory';
import ReviewsPage from '../../pages/Head/Reviews';
import SpecificReview from '../../pages/Head/Reviews/HeadSpecificReview';
import SkillsPage from '../../pages/Head/Skills/index';
import UsersPage from '../../pages/Head/Users';
import UserSkillsPage from '../../pages/Head/Users/components/Skills';
import UserPersonalTasksPage from '../../pages/Head/Users/components/UserPersonalTasks';
import HeadPageTemplate from '../../templates/HeadPageTemplate';

const adminRoutes = [
  {
    path: '/head/departments',
    title: 'Departments',
    requiredRoles: [userRoles.HEAD],
    component: ({ crumbs }) => <HeadDepartmentsPage crumbs={crumbs} title="Departments" />,
  },
  {
    path: '/head/departments/:department/grow',
    title: 'Grow',
    requiredRoles: [userRoles.ADMIN, userRoles.HEAD],
    component: ({ crumbs }) => (
      <HeadPageTemplate crumbs={crumbs} component={GrowPage} title="Grow" />
    ),
  },
  {
    path: '/head/departments/:department/users',
    title: 'Users',
    requiredRoles: [userRoles.HEAD],
    component: ({ crumbs }) => (
      <HeadPageTemplate crumbs={crumbs} component={UsersPage} title="Users" />
    ),
  },
  {
    path: '/head/departments/:department/users/:id/personal-tasks/',
    title: 'Personal tasks',
    requiredRoles: [userRoles.HEAD],
    component: ({ crumbs }) => {
      const filteredCrumbs = crumbs.filter((crumb) => crumb.title !== 'Personal tasks');
      return (
        <HeadPageTemplate
          crumbs={filteredCrumbs.concat([
            {
              path: `/head/departments/${
                filteredCrumbs[filteredCrumbs.length - 1].path
              }/personal-tasks/`,
              title: 'Personal tasks',
            },
          ])}
          component={UserPersonalTasksPage}
          title="User personal tasks"
        />
      );
    },
  },
  {
    path: '/head/departments/:department/skills',
    title: 'Skills',
    requiredRoles: [userRoles.HEAD],
    component: ({ crumbs }) => (
      <HeadPageTemplate crumbs={crumbs} component={SkillsPage} title="Skills" />
    ),
  },
  {
    path: '/head/departments/:department/users/:id',
    title: 'Skills',
    requiredRoles: [userRoles.HEAD],
    component: ({ crumbs }) => (
      <HeadPageTemplate
        crumbs={crumbs.concat([
          {
            path: crumbs[crumbs.length - 1].path,
            title: 'Skills',
          },
        ])}
        component={UserSkillsPage}
        title="User Skills"
      />
    ),
  },
  {
    path: '/head/departments/:department/levels',
    title: 'Levels',
    requiredRoles: [userRoles.HEAD],
    component: ({ crumbs }) => (
      <HeadPageTemplate crumbs={crumbs} component={LevelsPage} title="Levels" />
    ),
  },
  {
    path: '/head/departments/:department/reviews',
    title: 'Reviews',
    requiredRoles: [userRoles.HEAD],
    component: ({ crumbs }) => (
      <HeadPageTemplate crumbs={crumbs} component={ReviewsPage} title="Reviews" />
    ),
  },
  {
    path: '/head/departments/:department/reviews/:reviewId',
    title: 'Reviews',
    requiredRoles: [userRoles.HEAD],
    component: ({ crumbs }) => (
      <HeadPageTemplate crumbs={crumbs} component={SpecificReview} title="Reviews" />
    ),
  },
  {
    path: '/head/departments/:department/review-history',
    title: 'Review History',
    requiredRoles: [userRoles.HEAD],
    component: ({ crumbs }) => (
      <HeadPageTemplate crumbs={crumbs} component={ReviewHistoryPage} title="Review History" />
    ),
  },
  {
    path: '/head/departments/:department/dashboard',
    title: 'Dashboard',
    requiredRoles: [userRoles.HEAD],
    component: ({ crumbs }) => (
      <HeadPageTemplate crumbs={crumbs} component={HeadDashboardPage} title="Dashboard" />
    ),
  },
];

export default adminRoutes;
