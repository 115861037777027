import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Box,
  styled,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const StyledModalBody = styled(Box)(() => ({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  padding: '40px 30px',
  background: 'rgb(255, 255, 255)',
  boxShadow: 24,
  p: 4,
  borderRadius: '.15em',
}));

const StyledModalTitle = styled(Typography)(() => ({
  marginBottom: '24px',
  fontSize: '26px',
  display: 'flex',
  justifyContent: 'center',
}));

export default function ErrorsModalWindow({ isOpen, onClose, errors }) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <StyledModalBody>
        <Box>
          <StyledModalTitle variant="h6" component="h4" sx={{ fontWeight: 500 }}>
            Import errors
          </StyledModalTitle>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
              <TableRow>
                <TableCell>Error</TableCell>
                <TableCell align="right">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errors.map((row) => (
                <TableRow key="key">
                  <TableCell>{row.message}</TableCell>
                  <TableCell align="right">{row.errorCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledModalBody>
    </Modal>
  );
}

ErrorsModalWindow.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({ message: PropTypes.string, errorCount: PropTypes.number }),
  ).isRequired,
};
