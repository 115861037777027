import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Grid, Paper } from '@mui/material';
import NoInfoFoundAlert from '../../components/NoInfoFoundAlert';
import reviewConstants from '../../constants/review.constants';
import ApiService from '../../services/api.service';
import BasicPage from '../../templates/BasicPage';
import UserReviewHistoryTable from './components/UserReviewHistoryTable';

const StyledItem = styled(Paper)(() => ({
  display: 'flex',
  marginBottom: '1em',
  padding: '2em 3em',
  borderRadius: '.15em',
}));

export default function Reviews({ title }) {
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [reviewHistoryData, setReviewHistoryData] = useState([]);
  const [reviewSort, setReviewSort] = useState('updatedAt');

  const fetchReviews = async (skip, limit, sort) => {
    try {
      const response = await ApiService.developer.getReviewsHistory({
        params: {
          skip: skip * limit,
          limit,
          sort,
        },
      });

      setCurrentPage(skip);
      setReviewHistoryData(response.paginatedResult);
      setTotalCount(response.totalCount ?? 0);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReviews(currentPage, rowsPerPage, reviewSort);
  }, [currentPage, rowsPerPage, reviewSort]);

  return (
    <BasicPage title={title}>
      <Grid sx={{ margin: '32px 24px' }} item>
        <Grid container justifyContent="center">
          {reviewHistoryData.length || isLoading ? (
            <Grid item xs={12} md={10} lg={6}>
              <StyledItem elevation={8}>
                <UserReviewHistoryTable
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  reviewHistoryData={reviewHistoryData}
                  totalCount={totalCount}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  setSort={setReviewSort}
                />
              </StyledItem>
            </Grid>
          ) : (
            <Grid item xs={12} lg={6}>
              <NoInfoFoundAlert message={reviewConstants.ERROR_MESSAGES.REVIEWS_ARE_NOT_FOUND} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </BasicPage>
  );
}

Reviews.propTypes = {
  title: PropTypes.string.isRequired,
};
