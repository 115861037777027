import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { TreeView } from '@mui/lab';
import { Box, Button, Modal, Typography, Grid } from '@mui/material';
import StyledModalBody from '../styled-components/ModalBody';
import PlainTreeNode from './PlainTreeNode';

const StyledTreeView = styled(TreeView)(() => ({
  maxHeight: '70vh',
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: '20px',
}));

export default function AddDefaultNodeModal({ conflictedNodes, open, setIsOpened, replaceNode }) {
  const [expanded, setExpanded] = useState([
    ...conflictedNodes.replaceableNodes
      .filter((el) => el.details.length === 0)
      .map((elem) => elem.itemId),
    ...conflictedNodes.replacingNodes
      .filter((el) => el.details.length === 0)
      .map((elem) => elem.itemId),
  ]);

  const setExpandedOnClick = (id) =>
    setExpanded(expanded.includes(id) ? expanded.filter((item) => item !== id) : [...expanded, id]);

  return (
    <Modal
      open={open}
      onClose={() => setIsOpened(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledModalBody sx={{ overflow: { xs: 'scroll', sm: 'hidden' } }}>
        <Typography>Do you want to completely replace your current folder?</Typography>
        <Box sx={{ overflowY: { xs: 'auto', sm: 'hidden' }, maxHeight: '60vh' }}>
          <Grid container spacing={2} alignItems="start">
            <Grid item xs={12} sm={4}>
              <StyledTreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                expanded={expanded}
              >
                <PlainTreeNode
                  nodes={conflictedNodes.replaceableNodes}
                  onClick={(id) => setExpandedOnClick(id)}
                />
              </StyledTreeView>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <AutorenewIcon
                sx={{
                  fontSize: '3rem',
                  margin: '0 1rem',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledTreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                expanded={expanded}
              >
                <PlainTreeNode
                  nodes={conflictedNodes.replacingNodes}
                  onClick={(id) => setExpandedOnClick(id)}
                />
              </StyledTreeView>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => setIsOpened(false)}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            onClick={() => replaceNode(conflictedNodes.replacingNodes[0])}
          >
            Replace
          </Button>
        </Box>
      </StyledModalBody>
    </Modal>
  );
}

const skillTree = {
  itemId: PropTypes.string,
  name: PropTypes.string,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      successCriteria: PropTypes.string,
      materialName: PropTypes.string,
      materialType: PropTypes.string,
      link: PropTypes.string,
      level: PropTypes.string,
    }),
  ),
  children: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AddDefaultNodeModal.propTypes = {
  conflictedNodes: PropTypes.shape({
    replaceableNodes: PropTypes.arrayOf(PropTypes.shape(skillTree)),
    replacingNodes: PropTypes.arrayOf(PropTypes.shape(skillTree)),
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  replaceNode: PropTypes.func.isRequired,
};
