import PropTypes from 'prop-types';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Grid, TableCell, TableRow } from '@mui/material';
import reviewConstants from '../../../constants/review.constants';
import LevelProgress from '../../Head/components/LevelProgress';

export default function UserReviewHistoryTableRow({
  declined,
  confirmed,
  status,
  updatedAt,
  reviewGoal,
  newGoal,
  progress,
}) {
  const reviewDate = new Date(updatedAt).toDateString();

  return (
    <TableRow>
      <TableCell align="left">{reviewGoal}</TableCell>
      <TableCell align="left">{newGoal}</TableCell>
      <TableCell align="left">
        {status === reviewConstants.REVIEW_STATUSES.CONFIRMED && (
          <CheckCircleRoundedIcon color="success" />
        )}
        {status === reviewConstants.REVIEW_STATUSES.DECLINED && <CancelIcon color="error" />}
      </TableCell>
      <TableCell align="right">{confirmed}</TableCell>
      <TableCell align="right">{declined}</TableCell>
      <TableCell align="center">
        <Grid container justifyContent="center">
          <LevelProgress value={progress} />
        </Grid>
      </TableCell>
      <TableCell align="left">{reviewDate}</TableCell>
    </TableRow>
  );
}

UserReviewHistoryTableRow.propTypes = {
  declined: PropTypes.number.isRequired,
  confirmed: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  reviewGoal: PropTypes.string.isRequired,
  newGoal: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
};
