import { useEffect, useState, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Close, DownloadDone } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Alert, Snackbar } from '@mui/material';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SkillContext from '../../../hooks/context/SkillContext';
import apiService from '../../../services/api.service';
import AdminBasicPage from '../../../templates/AdminBasicPage';
import HexGridAdminTabs from './components/HexGridAdminTabs';
import ModalWindow from './components/ModalWindow';
import { ACTION_TYPES, initialState, reducer } from './reducer';

export default function AdminSkillsPage({ crumbs, title }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [data, setData] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [successSnackBar, setSuccessSnackbar] = useState({
    status: false,
    message: '',
    alertColor: 'success',
  });
  const [skills, setSkills] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [itemForAction, setItemForAction] = useState(null);

  const createSkill = async (skillData) => {
    try {
      const result = await apiService.admin.saveSkill(skillData);
      dispatch({ type: ACTION_TYPES.RESET_ALL });
      setSuccessSnackbar({ status: true, message: 'Saved!' });
      setSkills({
        approvedSkills: skills.approvedSkills.concat([result]),
        requestedSkills: skills.requestedSkills,
      });
      setIsModalOpened(false);

      return result;
    } catch (error) {
      dispatch({ type: ACTION_TYPES.BATCH_SET, payload: { isCreate: false } });

      if (error.response.data.error instanceof Array) {
        error.response.data.error.forEach((err) => {
          dispatch({
            type: ACTION_TYPES.SET_FORM_ERRORS,
            payload: {
              ...state.formErrors,
              [err.source.pointer.split('/')[2]]: {
                status: true,
                messages: err.meta.join(', '),
              },
            },
          });
        });

        return null;
      }

      setSuccessSnackbar({
        status: true,
        message: error.response.data?.messages,
        alertColor: 'error',
      });

      return null;
    }
  };

  const updateSkill = async (id, skillData) => {
    try {
      const result = await apiService.admin.updateSkill(id, skillData);

      setSuccessSnackbar({ status: true, message: 'Updated!' });
      dispatch({ type: ACTION_TYPES.RESET_ALL });
      setIsModalOpened(false);

      return result;
    } catch (error) {
      dispatch({ type: ACTION_TYPES.BATCH_SET, payload: { isUpdate: false } });

      if (error.response.data.error instanceof Array) {
        error.response.data.error.forEach((err) => {
          dispatch({
            type: ACTION_TYPES.SET_FORM_ERRORS,
            payload: {
              ...state.formErrors,
              [err.source.pointer.split('/')[2]]: {
                status: true,
                messages: err.meta.join(', '),
              },
            },
          });
        });

        return null;
      }

      setSuccessSnackbar({
        status: true,
        message: error.response.data?.messages,
        alertColor: 'error',
      });

      return null;
    }
  };

  const deleteSkill = async (id) => {
    try {
      const result = await apiService.admin.deleteSkill(id);

      setSuccessSnackbar({ status: true, message: 'Deleted!' });
      dispatch({ type: ACTION_TYPES.RESET_ALL });
      setSkills(
        itemForAction.status === 'APPROVED'
          ? {
              approvedSkills: skills.approvedSkills.filter((elem) => elem._id !== id),
              requestedSkills: skills.requestedSkills,
            }
          : {
              approvedSkills: skills.approvedSkills,
              requestedSkills: skills.requestedSkills.filter((elem) => elem._id !== id),
            },
      );

      return result;
    } catch (error) {
      dispatch({ type: ACTION_TYPES.BATCH_SET, payload: { isDelete: false } });

      return setSuccessSnackbar({
        status: true,
        message: error.response.data?.messages,
        alertColor: 'error',
      });
    }
  };

  const getSkill = async () => {
    try {
      const result = await apiService.admin.getSkill();
      setSkills(result);
      setIsLoading(false);

      return result;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return null;
    }
  };

  const uploadImage = async (url) => {
    try {
      const response = await fetch(url);
      const blobParts = await response.blob();
      const file = new File([blobParts], 'filename.jpeg', { type: 'image/jpeg' });
      const formData = new FormData();
      formData.append('file', file);

      const result = await apiService.admin.uploadSkillImage(formData);

      dispatch({
        type: ACTION_TYPES.SET_FORM_VALUES,
        payload: { formValues: { ...state.formValues, imageKey: result.Key, croppedImg: null } },
      });

      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const update = async (url, formValues) => {
    try {
      const skillData = {
        title: formValues.title,
        description: formValues.description,
        tags: formValues.tags,
        version: parseInt(formValues.version, 10),
        url: formValues.url,
      };

      if (url) {
        const image = await uploadImage(url);

        if (!image) return null;

        const result = await updateSkill(formValues._id, { ...skillData, imageKey: image?.Key });

        if (result) {
          const updateSkills =
            itemForAction.status === 'APPROVED' ? skills.approvedSkills : skills.requestedSkills;
          const index = updateSkills.findIndex((elem) => formValues._id === elem._id);
          updateSkills[index] = { ...updateSkills[index], ...skillData, imageUrl: image.Location };

          setSkills(
            itemForAction.status === 'APPROVED'
              ? {
                  approvedSkills: [...updateSkills],
                  requestedSkills: skills.requestedSkills,
                }
              : {
                  approvedSkills: skills.approvedSkills,
                  requestedSkills: [...updateSkills],
                },
          );
        }

        return result;
      }

      const result = await updateSkill(formValues._id, skillData);

      if (!result) return null;

      const updateSkills =
        itemForAction.status === 'APPROVED' ? skills.approvedSkills : skills.requestedSkills;
      const index = updateSkills.findIndex((elem) => formValues._id === elem._id);
      updateSkills[index] = { ...updateSkills[index], ...skillData };

      setSkills(
        itemForAction.status === 'APPROVED'
          ? {
              approvedSkills: [...updateSkills],
              requestedSkills: skills.requestedSkills,
            }
          : {
              approvedSkills: skills.approvedSkills,
              requestedSkills: [...updateSkills],
            },
      );

      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  useEffect(async () => {
    if (!state.isDelete) return;

    await deleteSkill(state.formValues._id);
  }, [state.isDelete]);

  useEffect(async () => {
    if (!state.isCreate || !state.croppedImg) return;

    if (!state.formValues.imageKey) await uploadImage(state.croppedImg);

    if (state.formValues.imageKey) {
      await createSkill({
        title: state.formValues.title,
        version: parseInt(state.formValues.version, 10),
        url: state.formValues.url,
        tags: state.formValues.tags,
        description: state.formValues.description,
        imageKey: state.formValues.imageKey,
      });
    }
  }, [state.isCreate, state.formValues.imageKey, state.croppedImg]);

  useEffect(async () => {
    if (!state.isUpdate) return;
    if (state.isUpdate && state.image && !state.croppedImg) return;

    await update(state.croppedImg, state.formValues);
  }, [state.croppedImg, state.isUpdate]);

  useEffect(async () => {
    if (isLoading) getSkill();
  }, [isLoading]);

  const contextValue = useMemo(
    () => ({
      data,
      deleteItem,
      setData,
      setDeleteItem,
    }),
    [data, setData, setDeleteItem, deleteItem],
  );

  const menuEditItemOnClick = () => {
    setAnchorEl(null);
    dispatch({
      type: ACTION_TYPES.SET_FORM_VALUES,
      payload: { formValues: { ...state.formValues, ...itemForAction }, isOpenedUpdate: true },
    });
    setIsModalOpened(true);
  };

  const menuDeleteItemOnClick = () => {
    setAnchorEl(null);
    dispatch({
      type: ACTION_TYPES.SET_FORM_VALUES,
      payload: { formValues: { ...state.formValues, ...itemForAction }, isDelete: true },
    });
  };

  const menuApproveItemOnClick = async () => {
    await apiService.admin.updateSkillStatus(itemForAction._id, 'APPROVED');

    const index = skills.requestedSkills.findIndex((skill) => skill._id === itemForAction._id);

    setAnchorEl(null);
    setSkills({
      approvedSkills: skills.approvedSkills.concat([
        {
          ...skills.requestedSkills[index],
          status: 'APPROVED',
        },
      ]),
      requestedSkills: skills.requestedSkills.toSpliced(index, 1),
    });
    setSuccessSnackbar({ status: true, message: 'Approved!' });
  };

  const menuRejectItemOnClick = async () => {
    await apiService.admin.updateSkillStatus(itemForAction._id, 'REJECTED');

    const index = skills.requestedSkills.findIndex((skill) => skill._id === itemForAction._id);

    setAnchorEl(null);
    setSkills({
      approvedSkills: skills.approvedSkills,
      requestedSkills: skills.requestedSkills.toSpliced(index, 1),
    });
    setSuccessSnackbar({ status: true, message: 'Rejected!' });
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch({ type: ACTION_TYPES.RESET_ALL });
  };

  return (
    <AdminBasicPage crumbs={crumbs} title={title}>
      <SkillContext.Provider value={contextValue}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box>
            <ModalWindow
              isOpened={isModalOpened}
              setIsOpened={setIsModalOpened}
              dispatch={dispatch}
              state={state}
            />
          </Box>
          <Box width="70%" mt="-30px">
            <HexGridAdminTabs
              isAdminPage
              setAnchorEl={setAnchorEl}
              setItemForAction={setItemForAction}
              skills={skills}
              setIsModalOpened={setIsModalOpened}
            />
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            TransitionComponent={Fade}
            PaperProps={{
              elevation: 0,
              sx: {
                border: 'solid 1px gray',
                borderRadius: '5px',
              },
            }}
          >
            <MenuItem onClick={menuEditItemOnClick}>
              <EditIcon sx={{ color: 'rgba(0, 0, 0, 0.6)', marginRight: '12px' }} />
              Edit
            </MenuItem>
            {itemForAction && itemForAction.status === 'APPROVED' ? (
              <MenuItem onClick={menuDeleteItemOnClick}>
                <DeleteIcon sx={{ color: 'rgba(0, 0, 0, 0.6)', marginRight: '12px' }} />
                Delete
              </MenuItem>
            ) : (
              <MenuItem onClick={menuApproveItemOnClick}>
                <DownloadDone sx={{ color: 'rgba(0, 0, 0, 0.6)', marginRight: '12px' }} />
                Approve
              </MenuItem>
            )}
            {itemForAction && itemForAction.status !== 'APPROVED' && (
              <MenuItem onClick={menuRejectItemOnClick}>
                <Close sx={{ color: 'rgba(0, 0, 0, 0.6)', marginRight: '12px' }} />
                Reject
              </MenuItem>
            )}
          </Menu>
          <Snackbar
            open={successSnackBar.status}
            autoHideDuration={6000}
            onClose={(event, reason) => {
              if (reason !== 'clickaway')
                setSuccessSnackbar((prev) => ({ ...prev, status: false }));
            }}
          >
            <Alert
              onClose={() => setSuccessSnackbar((prev) => ({ ...prev, status: false }))}
              severity={successSnackBar.alertColor}
              sx={{ width: '100%' }}
            >
              {successSnackBar.message}
            </Alert>
          </Snackbar>
        </Box>
      </SkillContext.Provider>
    </AdminBasicPage>
  );
}

AdminSkillsPage.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};
