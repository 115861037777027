import { useEffect, useState } from 'react';
import { Apartment, ListAlt, PeopleAlt, RateReviewOutlined, Subject } from '@mui/icons-material';
import apiService from '../../../../services/api.service';

const useCardsData = () => {
  const [cardsData, setCardsData] = useState([]);

  const fetchCardsData = async () => {
    try {
      const response = await apiService.admin.getAdminTotalCount();
      setCardsData([
        { title: 'Total Users', count: response.users, icon: PeopleAlt },
        { title: 'Total Reviews', count: response.reviews, icon: RateReviewOutlined },
        { title: 'Total Departments', count: response.departments, icon: Apartment },
        { title: 'Total Goals', count: response.goals, icon: ListAlt },
        { title: 'Total Activities', count: response.activities, icon: Subject },
      ]);
    } catch (error) {
      console.error('Failed to fetch card data:', error);
    }
  };

  useEffect(() => {
    fetchCardsData();
  }, []);

  return {
    cardsData,
  };
};

export default useCardsData;
