const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.height = 150;
    image.width = 150;
    image.src = url;
  });

export default async function getCroppedImg(imageSrc, pixelCrop) {
  try {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    const blob = await new Promise((resolve) => {
      canvas.toBlob(
        (file) => {
          resolve(file);
        },
        'image/jpeg',
        1,
      );
    });

    return URL.createObjectURL(blob);
  } catch (error) {
    console.error(error);
    return null;
  }
}
