import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Appointments,
  AppointmentTooltip,
  MonthView,
  Scheduler,
} from '@devexpress/dx-react-scheduler-material-ui';
import { Card, CardActions, CardContent, CardMedia } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import ApiService from '../../services/api.service';
import BasicPage from '../../templates/BasicPage';
import ChangePeriodButton from './components/ChangePeriodButton';

// eslint-disable-next-line react/prop-types
function Appointment({ children, style, ...restProps }) {
  return (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        backgroundColor: '#7553EA',
      }}
    >
      {children}
    </Appointments.Appointment>
  );
}

// eslint-disable-next-line react/prop-types
function TooltipContent({ appointmentData }) {
  // eslint-disable-next-line react/prop-types
  const { title, image, link } = appointmentData;
  return (
    <Card sx={{ maxWidth: 400 }}>
      <a
        href={link}
        target="_blank"
        style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
        rel="noreferrer"
      >
        <CardMedia
          sx={{
            height: 200,
            objectFit: 'contain',
            display: 'flex',
            justifyContent: 'center',
          }}
          image={image}
          title=""
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
        </CardContent>
        <CardActions>
          <Button href={link} target="_blank" size="small">
            Learn More
          </Button>
        </CardActions>
      </a>
    </Card>
  );
}

export default function Events({ title }) {
  dayjs.extend(utc);
  dayjs.extend(customParseFormat);
  const [events, setEvents] = useState([]);
  const [today, setToday] = useState(dayjs().format('YYYY/MM/DD'));
  const [calendarTitle, setCalendarTitle] = useState(new Date());

  const setPreviousTitleMonth = () => {
    const currentDate = new Date(calendarTitle);
    const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
    setCalendarTitle(previousMonth);
  };

  const setNextTitleMonth = () => {
    const currentDate = new Date(calendarTitle);
    const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
    setCalendarTitle(nextMonth);
  };

  const formattedTitle = calendarTitle.toLocaleString('en-US', {
    month: 'long',
    year: 'numeric',
  });

  const setPreviousNewToday = () => {
    const newToday = dayjs(today).subtract(1, 'month').format('YYYY/MM/DD HH:mm');
    setToday(newToday);
  };

  const setNextNewToday = () => {
    const newToday = dayjs(today).add(1, 'month').format('YYYY/MM/DD HH:mm');
    setToday(newToday);
  };

  const previousHandler = () => {
    setPreviousTitleMonth();
    setPreviousNewToday();
  };

  const nextHandler = () => {
    setNextTitleMonth();
    setNextNewToday();
  };
  const fetchEvents = async () => {
    try {
      const response = await ApiService.developer.getEvents();

      const transformedEvents = response.map((event) => {
        const start = new Date(
          event.startDate.year,
          event.startDate.month - 1,
          event.startDate.startDay,
        );
        const end = new Date(event.endDate.year, event.endDate.month - 1, event.endDate.endDay + 1);
        const startDate = new Date(start.getTime() - start.getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 16);
        const endDate = new Date(end.getTime() - start.getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 16);
        return { ...event, startDate, endDate };
      });

      setEvents(transformedEvents);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <BasicPage title={title}>
      <Grid
        sx={{
          boxShadow: '0px 0px 2px 1px #dddddd',
          borderRadius: '5px',
          marginRight: '10%',
          marginLeft: '10%',
          marginTop: '50px',
          '@media (max-width: 800px)': {
            marginRight: '5%',
            marginLeft: '5%',
          },
          '@media (max-width: 400px)': {
            marginRight: '2%',
            marginLeft: '2%',
          },
        }}
      >
        <Paper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px',
            }}
          >
            <ChangePeriodButton
              today={formattedTitle}
              previousHandler={previousHandler}
              nextHandler={nextHandler}
            />
          </div>
          <Scheduler data={events}>
            <ViewState currentDate={today} />
            <MonthView />
            <Appointments appointmentComponent={Appointment} />
            <AppointmentTooltip contentComponent={TooltipContent} />
          </Scheduler>
        </Paper>{' '}
      </Grid>
    </BasicPage>
  );
}
Events.propTypes = {
  title: PropTypes.string.isRequired,
};
