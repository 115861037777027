import React from 'react';
import PropTypes from 'prop-types';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Modal, Box, Button, styled, Typography, Chip, Tooltip } from '@mui/material';

const mainColor = '#7553EA';

const StyledModalBody = styled(Box)(() => ({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  padding: '40px 32px',
  background: 'rgb(255, 255, 255)',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
}));

const StyledButtonContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '32px',
}));

const StyledAcceptButton = styled(Button)(() => ({
  marginRight: '8px',
  background: mainColor,
  '&:hover': {
    backgroundColor: mainColor,
  },
}));

const StyledDeclineButton = styled(Button)(() => ({
  color: mainColor,
}));

const StyledItemContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '8px',
}));

const StyledItemTitle = styled(Typography)(() => ({
  marginRight: '8px',
  fontSize: '13px',
  minWidth: '80px',
}));

const StyledChipContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledChip = styled(Chip)(() => ({ width: '70px' }));

const StyledModalTitle = styled(Typography)(() => ({ marginBottom: '24px' }));

export default function ModalWindow({ modal, onAccept, onDecline }) {
  const {
    isOpen,
    prevGoal,
    prevLevel,
    prevEnglishLevel,
    newGoal,
    newLevel,
    newEnglishLevel,
    message,
  } = modal;

  return (
    <Modal open={isOpen}>
      <StyledModalBody>
        <Box>
          <StyledModalTitle variant="h6" component="h4" sx={{ fontWeight: 400 }}>
            {message || 'Are you sure want to set new values?'}
          </StyledModalTitle>
          <Box>
            {newLevel && (
              <StyledItemContainer>
                <StyledItemTitle>Level:</StyledItemTitle>
                <StyledChipContainer>
                  <Tooltip title={prevLevel.key} placement="right">
                    <StyledChip label={prevLevel.key} />
                  </Tooltip>
                  <ArrowForwardRoundedIcon sx={{ margin: '0 16px' }} fontSize="small" />
                  <Tooltip title={newLevel.key} placement="right">
                    <StyledChip label={newLevel.key} />
                  </Tooltip>
                </StyledChipContainer>
              </StyledItemContainer>
            )}
            {newGoal && (
              <StyledItemContainer>
                <StyledItemTitle>Goal:</StyledItemTitle>
                <StyledChipContainer>
                  <Tooltip title={prevLevel.key} placement="right">
                    <StyledChip label={prevGoal.key} />
                  </Tooltip>
                  <ArrowForwardRoundedIcon sx={{ margin: '0 16px' }} fontSize="small" />
                  <Tooltip title={newGoal.key} placement="right">
                    <StyledChip label={newGoal.key} />
                  </Tooltip>
                </StyledChipContainer>
              </StyledItemContainer>
            )}
            {newEnglishLevel && (
              <StyledItemContainer>
                <StyledItemTitle>English Level:</StyledItemTitle>
                <StyledChipContainer>
                  <Tooltip title={prevEnglishLevel?.display} placement="right">
                    <StyledChip label={prevEnglishLevel?.value} />
                  </Tooltip>
                  <ArrowForwardRoundedIcon sx={{ margin: '0 16px' }} fontSize="small" />
                  <Tooltip title={newEnglishLevel?.display} placement="right">
                    <StyledChip label={newEnglishLevel?.value} />
                  </Tooltip>
                </StyledChipContainer>
              </StyledItemContainer>
            )}
          </Box>
        </Box>
        <StyledButtonContainer>
          <StyledAcceptButton variant="contained" onClick={onAccept}>
            Accept
          </StyledAcceptButton>
          <StyledDeclineButton onClick={onDecline}>Decline</StyledDeclineButton>
        </StyledButtonContainer>
      </StyledModalBody>
    </Modal>
  );
}

ModalWindow.propTypes = {
  modal: PropTypes.shape({
    isOpen: PropTypes.bool,
    prevGoal: PropTypes.shape({ key: PropTypes.string }),
    prevLevel: PropTypes.shape({ key: PropTypes.string }),
    prevEnglishLevel: PropTypes.shape({ value: PropTypes.string, display: PropTypes.string }),
    newGoal: PropTypes.shape({ key: PropTypes.string }),
    newLevel: PropTypes.shape({ key: PropTypes.string }),
    newEnglishLevel: PropTypes.shape({ value: PropTypes.string, display: PropTypes.string }),
    message: PropTypes.string,
  }).isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};
