import { useEffect, useState } from 'react';
import { AddCircleRounded, Send as SendIcon } from '@mui/icons-material';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import ChangeCircleOutlined from '@mui/icons-material/ChangeCircleOutlined';
import HighlightOff from '@mui/icons-material/HighlightOff';
import Timeline from '@mui/lab/Timeline';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { v4 as uuid } from 'uuid';
import apiService from '../../../../services/api.service';
import axios from '../../../../services/http.service';
import ActualLevel from '../../../Progress/components/ActualLevel';
import FinishedLevel from '../../../Progress/components/FinishedLevel';
import FutureLevel from '../../../Progress/components/FutureLevel';
import TreeViewFormWrapper from '../../Grow/styled-components/TreeViewFormWrapper';
import PersonalTaskList from './PersonalTaskList';
import UserProfileCard from './UserProfileCard';

let isBeforeCurrentLevel = true;

export default function UserPersonalTasksPage() {
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [initialTasks, setInitialTasks] = useState([]);
  const { department, id } = useParams();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [detailsAmountMap, setDetailsAmountMap] = useState({});
  const [saveStatus, setSaveStatus] = useState(null);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [removed, setRemoved] = useState([]);
  const [added, setAdded] = useState([]);
  const [updated, setUpdated] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [tasksNames, setTasksNames] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [reviews, setReviews] = useState([]);
  const [reviewProcessed, setReviewProcessed] = useState(false);
  const [message, setMessage] = useState('');
  const [levels, setLevels] = useState([]);
  const [userLevel, setUserLevel] = useState([]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const handleReviewProcessed = () => {
    const processedReview = reviews.find(
      (review) => review.status === 'PROCESSED' && review.userId === userData._id,
    );
    setReviewProcessed(!!processedReview);
  };

  useEffect(() => {
    if (isLoading) {
      apiService.head.getDepartmentReviews(department).then((data) => {
        setReviews(data);
      });
    }
    handleReviewProcessed();
  }, [department, reviews, userData._id]);

  const clearData = () => {
    setAdded([]);
    setRemoved([]);
    setUpdated([]);
  };

  const fetchData = async () => {
    try {
      const user = await apiService.head.getUser(id);
      setUserData(user);

      const response = await apiService.head.getUserPersonalTask(id);
      const personalTasksArray = response.map((item) => {
        const filteredDetails = item.personalTasks.details.map((detail) => ({
          name: detail.name,
          successCriteria: detail.successCriteria,
          materialName: detail.name,
          link: detail.link,
          level: detail.level,
          itemId: detail.itemId,
          materialType: detail.materialType,
          contentType: detail.contentType,
        }));

        const status = filteredDetails.some((detail) => detail.level !== user.goal)
          ? 'Approved'
          : null;

        return {
          ...item.personalTasks,
          details: filteredDetails,
          status,
        };
      });

      setInitialTasks(personalTasksArray);
      setTasks(personalTasksArray);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isLoading && userData !== null) {
      fetchData();
    }
  }, [isLoading, id]);

  const detectChangedTasks = () => {
    return tasks.filter((task) => {
      const initialTask = initialTasks.find((initTask) => initTask.itemId === task.itemId);
      if (!initialTask) return false;

      if (task.name.trim() !== initialTask.name.trim()) {
        setUpdated([...new Set([...updated, initialTask.itemId])]);
        return true;
      }

      if (task.details.length !== initialTask.details.length) {
        setUpdated([...new Set([...updated, initialTask.itemId])]);
        return true;
      }

      if (task.deleteMark) return true;

      const detailChanged = task.details.some((detail, index) => {
        const initialDetail = initialTask.details[index];
        return (
          detail.name !== initialDetail.name ||
          detail.successCriteria !== initialDetail.successCriteria ||
          detail.materialName !== initialDetail.materialName ||
          detail.link !== initialDetail.link ||
          detail.level !== initialDetail.level ||
          detail.materialType !== initialDetail.materialType ||
          detail.contentType !== initialDetail.contentType
        );
      });

      if (detailChanged) {
        setUpdated([...new Set([...updated, initialTask.itemId])]);
      }

      return detailChanged;
    });
  };

  useEffect(() => {
    const taskAdded = tasks.some(
      (task) => !initialTasks.some((initTask) => initTask.itemId === task.itemId),
    );

    const tasksToUpdate = detectChangedTasks();

    const isSaveDisabled = tasksToUpdate.length === 0 && !taskAdded;
    setSaveButtonDisabled(isSaveDisabled);
  }, [tasks, initialTasks]);

  useEffect(() => {
    setTasks(initialTasks);

    const initialDetailsAmountMap = initialTasks.reduce((acc, task) => {
      acc[task.itemId] = task.details.length;
      return acc;
    }, {});
    setDetailsAmountMap(initialDetailsAmountMap);
  }, [initialTasks]);

  useEffect(() => {
    if (added.length > 0 || removed.length > 0 || updated.length > 0 || refresh)
      window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [added, removed, updated]);

  const handleDetailChange = (taskItemId, detailItemId, field, value) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.itemId === taskItemId
          ? {
              ...task,
              details: task.details.map((detail) =>
                detail.itemId === detailItemId ? { ...detail, [field]: value } : detail,
              ),
            }
          : task,
      ),
    );
  };

  const handleAddDetail = (taskItemId) => {
    const newDetailItemId = uuid();
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.itemId === taskItemId
          ? {
              ...task,
              details: [
                ...task.details,
                {
                  itemId: newDetailItemId,
                  name: '',
                  successCriteria: '',
                  materialName: '',
                  materialType: '',
                  link: '',
                  level: userData.goal,
                  contentType: null,
                },
              ],
            }
          : task,
      ),
    );
    setDetailsAmountMap((prevMap) => ({
      ...prevMap,
      [taskItemId]: (prevMap[taskItemId] || 0) + 1,
    }));
  };

  const handleRemoveDetail = (taskItemId, detailItemId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.itemId === taskItemId
          ? {
              ...task,
              details: task.details.filter((detail) => detail.itemId !== detailItemId),
            }
          : task,
      ),
    );
    setDetailsAmountMap((prevMap) => {
      const newMap = { ...prevMap };
      if (newMap[taskItemId] !== undefined) {
        newMap[taskItemId] -= 1;
      }
      return newMap;
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsSaveLoading(true);
    try {
      await handleReviewProcessed();

      if (reviewProcessed) {
        throw new Error('User pending review!');
      }

      const isEmpty = tasks.some(
        (task) =>
          task.name.trim() === '' ||
          task.details.some(
            (detail) =>
              Object.entries(detail).some(
                ([key, value]) => key !== 'contentType' && (value === null || value.trim() === ''),
              ) ||
              (detail.contentType === null && task.name.trim() === ''),
          ),
      );

      if (isEmpty) {
        throw new Error('All fields are required!');
      }

      const initialTaskIds = initialTasks.map((task) => task._id);

      const changedTasks = detectChangedTasks(tasks, initialTasks);
      const tasksToUpdate = changedTasks.filter(
        (task) => initialTaskIds.includes(task._id) && !task.deleteMark,
      );
      const newTasksToCreate = tasks.filter(
        (task) => !initialTaskIds.includes(task._id) && !task.deleteMark,
      );
      const tasksToDelete = tasks.filter((task) => task.deleteMark);

      if (tasksToDelete.length > 0) {
        await Promise.all(
          tasksToDelete.map(async (task) => {
            await apiService.head.deletePersonalTask(task._id, department);
            await apiService.head.deletePersonalTaskInGoal(task._id);
          }),
        );
      }

      if (tasksToUpdate.length > 0) {
        await Promise.all(
          tasksToUpdate.map(async (task) => {
            await axios.put(`head/departments/${department}/personalTasks/${task._id}`, task);
            await apiService.head.updatePersonalTaskInGoal(task._id, task);
          }),
        );
      }

      if (newTasksToCreate.length > 0) {
        await Promise.all(
          newTasksToCreate.map(async (task) => {
            const personalTask = await apiService.head.savePersonalTask(department, task);
            await apiService.head.addPersonalTaskToGoal(personalTask, personalTask._id);
            await apiService.head.addPersonalTaskForUser(userData._id, personalTask._id);
          }),
        );
      }
    } catch (error) {
      setOpenDialog(false);
      setSaveStatus('error');
      setMessage(error.message);
      setSaveButtonDisabled(true);
    } finally {
      setIsSaveLoading(false);

      await fetchData();

      setOpenDialog(false);
      clearData();
      setTasksNames([]);
      setRefresh(true);
      setSaveStatus('success');
    }
  };

  const handleRemoveTask = (taskItemId) => {
    const taskName = [];

    setTasks((prevTasks) =>
      prevTasks.map((task) => {
        if (task.itemId === taskItemId) {
          taskName.push(...task.details.map((detail) => detail.name));
          return { ...task, deleteMark: true };
        }
        return task;
      }),
    );

    setRemoved((prevRemoved) => [...prevRemoved, taskItemId]);
    setTasksNames([...tasksNames, ...taskName]);
  };

  const handleToggleTask = (taskItemId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.itemId === taskItemId ? { ...task, isOpen: !task.isOpen } : task,
      ),
    );
  };

  const handleTaskNameChange = (taskId, value) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) => (task.itemId === taskId ? { ...task, name: value } : task)),
    );
  };

  const handleAddTask = () => {
    const newTaskItemId = uuid();
    setTasks((prevTasks) => [
      ...prevTasks,
      {
        itemId: newTaskItemId,
        name: '',
        details: [],
        departmentId: null,
        skillType: 'personal',
        status: null,
        updatedStatusAt: Date.now(),
        userId: userData._id,
        isOpen: true,
      },
    ]);
    handleAddDetail(newTaskItemId);
    setAdded([...added, newTaskItemId].filter((elem) => elem !== id));
  };

  const handleOnCloseSave = () => {
    setOpenDialog(false);

    setTasksNames([]);
    setRefresh(false);
  };

  const handleMenuSave = () => {
    setOpenDialog(true);
    setRefresh(false);
  };

  const handleMenuDecline = () => {
    setOpenDialog(false);
    setTasks(initialTasks);
    clearData();
    setTasksNames([]);

    setRefresh(false);
  };

  const shortedText = () => {
    const duplicates = {};

    tasksNames.forEach((detail) => {
      duplicates[detail] = (duplicates[detail] || 0) + 1;
    });

    const duplicateArray = [];

    const duplicateKeys = Object.keys(duplicates);

    duplicateKeys.forEach((duplicate) => {
      duplicateArray.push(`${duplicate}: ${duplicates[duplicate]}`);
    });

    return duplicateArray.join('\n').length >= 400
      ? `${duplicateArray.join('\n').slice(0, 400)}...`
      : duplicateArray.join('\n');
  };

  useEffect(() => {
    async function fetchLevelData() {
      if (!userData.departmentId) {
        setMessage('User data is missing department ID');
        setSaveStatus('error');
        return;
      }
      const { departmentId, level } = userData;
      try {
        const departmentLevels = await apiService.developer.getDepartmentLevels(departmentId);
        setLevels(departmentLevels);
        setUserLevel(level);
      } catch (error) {
        setMessage('Error fetching department levels');
        setSaveStatus('error');
      }
    }

    if (userData.departmentId) {
      fetchLevelData();
    }
  }, [userData.departmentId]);

  return (
    <Box mt={5} mr={10} ml={10}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TreeViewFormWrapper
            sx={{
              padding: '1em',
              width: '100%',
              minHeight: '600px',
              minWidth: '300px',
              position: 'relative',
              marginLeft: { xs: '-30px', md: 0 },
            }}
            elevation={8}
          >
            <PersonalTaskList
              tasks={tasks}
              initialTasks={initialTasks}
              handleTaskNameChange={handleTaskNameChange}
              handleAddTask={handleAddTask}
              handleRemoveTask={handleRemoveTask}
              handleRemoveDetail={handleRemoveDetail}
              handleAddDetail={handleAddDetail}
              handleDetailChange={handleDetailChange}
              handleToggleTask={handleToggleTask}
              detailsAmountMap={detailsAmountMap}
            />
            <Box sx={{ mt: 8 }}>
              <Box sx={{ position: 'absolute', width: '40px', height: '40px', bottom: '10px' }}>
                <Tooltip title="Add task">
                  <IconButton variant="contained" onClick={handleAddTask}>
                    <AddCircleRounded />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ position: 'absolute', bottom: '10px', right: '20px' }}>
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={handleMenuSave}
                  sx={{ borderRadius: '4px' }}
                  disabled={saveButtonDisabled}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </TreeViewFormWrapper>
          <Dialog open={openDialog} onClose={handleOnCloseSave}>
            <DialogTitle id="alert-dialog-title">Are you sure you want to</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
              <DialogContentText id="alert-dialog-description">
                {added.length > 0 && (
                  <Typography component="span" display="flex">
                    Added:
                    <span style={{ paddingLeft: '10px', paddingRight: '5px' }}>{added.length}</span>
                    <AddCircleOutline color="success" viewBox="0 0 28 24" />
                  </Typography>
                )}
                {updated.length > 0 && (
                  <Typography component="span" display="flex">
                    Changed:
                    <span style={{ paddingLeft: '10px', paddingRight: '5px' }}>
                      {updated.length}
                    </span>
                    <ChangeCircleOutlined color="primary" viewBox="0 0 28 24" />
                  </Typography>
                )}
                {removed.length > 0 && (
                  <Tooltip
                    title={<span style={{ whiteSpace: 'pre-line' }}>{shortedText()}</span>}
                    placement="bottom-start"
                  >
                    <Typography component="span" display="flex" sx={{ cursor: 'pointer' }}>
                      Deleted:
                      <span style={{ paddingLeft: '10px', paddingRight: '5px' }}>
                        {tasksNames.length}
                      </span>
                      <HighlightOff color="warning" viewBox="0 0 28 24" />
                    </Typography>
                  </Tooltip>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ paddingRight: '15px' }}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleMenuDecline}
                style={{
                  borderColor: 'transparent',
                  outline: 0,
                  color: 'red',
                }}
              >
                Decline
              </Button>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={isSaveLoading}
              >
                {isSaveLoading ? <CircularProgress size={24} /> : 'Save'}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ maxWidth: '400px', ml: '50px' }}>
            <Box justifyContent="center" display={{ xs: 'none', md: 'block' }}>
              <UserProfileCard userData={userData} isPersonalTasks />
            </Box>
            <Box width="90%" display={{ xs: 'none', md: 'flex' }} justifyContent="center">
              <Timeline
                position="alternate"
                sx={{
                  mt: '20px',
                  minWidth: '400px',
                }}
              >
                {levels &&
                  userLevel &&
                  levels.map((level) => {
                    if (level._id === userLevel) {
                      isBeforeCurrentLevel = false;
                      return (
                        <ActualLevel key={level.key} levelKey={level.key} title={level.title} />
                      );
                    }
                    if (isBeforeCurrentLevel) {
                      return (
                        <FinishedLevel key={level.key} levelKey={level.key} title={level.title} />
                      );
                    }
                    return <FutureLevel key={level.key} levelKey={level.key} title={level.title} />;
                  })}
              </Timeline>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={saveStatus === 'success'}
        autoHideDuration={6000}
        onClose={() => setSaveStatus(null)}
      >
        <Alert onClose={() => setSaveStatus(null)} severity="success" sx={{ width: '100%' }}>
          Saved!
        </Alert>
      </Snackbar>
      <Snackbar
        open={saveStatus === 'error' && !!message}
        autoHideDuration={6000}
        onClose={() => {
          setSaveStatus(null);
          setMessage('');
        }}
      >
        <Alert
          onClose={() => {
            setSaveStatus(null);
            setMessage('');
          }}
          severity="error"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
