import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';
import Chart from 'react-apexcharts';
import '../styles/ant-picker.css';
import useEmployeesActivity from '../hooks/useEmployeesActivity';
import useEmployeesData from '../hooks/useEmployeesData';
import useHeadDepartment from '../hooks/useHeadDepartment';
import EmployeesFilter from './EmployeesFilter';
import ExportButton from './ExportButton';

function EmployeesActivityChart({ startPickerValue, endPickerValue }) {
  const { departmentId } = useHeadDepartment();

  const { employeesOptions, pickedEmployees, fetchEmployees, setPickedEmployees } =
    useEmployeesData();

  const { employeesChartOptions, employeesChartSeries } = useEmployeesActivity({
    startPickerValue,
    endPickerValue,
    pickedEmployees,
  });

  const exportUrl = new URL(
    '/api/v1/users-statistic/head/export/users',
    process.env.REACT_APP_API_URL,
  );
  exportUrl.searchParams.set('startDate', startPickerValue.toISOString());
  exportUrl.searchParams.set('endDate', endPickerValue.toISOString());
  exportUrl.searchParams.set('userIds', pickedEmployees.map((e) => e.id).join(','));
  exportUrl.searchParams.set('departmentId', departmentId);
  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'shrink', gap: '8px', width: '100%' }}>
          <Box sx={{ flex: 1 }}>
            <EmployeesFilter
              employeesOptions={employeesOptions}
              pickedEmployees={pickedEmployees}
              fetchEmployees={fetchEmployees}
              setPickedEmployees={setPickedEmployees}
            />
          </Box>

          <Box sx={{ maxHeight: '38px' }}>
            <ExportButton url={exportUrl} />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Chart
          options={employeesChartOptions}
          series={employeesChartSeries}
          type="bar"
          height={450}
        />
      </Grid>
    </Grid>
  );
}

EmployeesActivityChart.propTypes = {
  startPickerValue: PropTypes.objectOf(dayjs).isRequired,
  endPickerValue: PropTypes.objectOf(dayjs).isRequired,
};

export default EmployeesActivityChart;
