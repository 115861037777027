import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import TopBar from '../sections/TopBar';

const theme = createTheme({ palette: { primary: { main: '#7553EA' } } });

export default function BasicPage({ children, title }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <TopBar />
        {children}
      </ThemeProvider>
    </>
  );
}

BasicPage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
