import { styled } from '@mui/material';

const LineLoader = styled('div')(({ theme }) => ({
  width: 'calc((100% / cos(45deg)) - cos(45deg))',
  height: '14px',
  background: `repeating-linear-gradient(-45deg, ${theme.palette.primary.main} 0 15px, ${theme.palette.primary.light} 0 20px) left/200% 100%`,
  animation: 'loaderAnimation 20s infinite linear',
  '@keyframes loaderAnimation': {
    '100%': {
      backgroundPosition: 'right',
    },
  },
}));

export default LineLoader;
