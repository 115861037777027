import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Grid, Paper, Snackbar, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import { isURL } from 'validator';
import DeleteReviewModal from '../../../components/DeleteReviewModal/DeleteReviewModal.component';
import ReviewRecordModal from '../../../components/ReviewRecordModal/ReviewRecordModal.component';
import GoalsConstants from '../../../constants/goals.constants';
import ReviewConstants from '../../../constants/review.constants';
import ApiService from '../../../services/api.service';
import UserSkillsView from '../../Home/components/UserSkillsView';
import {
  checkIsEverythingApproved,
  refreshSkillStatus,
  checkIsEverythingApprovedWithPercentage,
  checkIsEverythingFinished,
  getAllDetails,
} from '../helpers/reviews.helpers';
import FinishReviewPanel from './components/FinishReviewPanel';

const StyledPaper = styled(Paper)(() => ({
  marginBottom: '1em',
  padding: '2em 3em',
  borderRadius: '.15em',
}));

const defaultReviewLinkState = {
  isOpen: false,
  link: '',
  isLinkError: false,
  callbackName: '',
};

const defaulReasonState = {
  isOpen: false,
  reason: '',
  callbackName: '',
};

export default function SpecificReview() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [userLevel, setUserLevel] = useState(null);
  const [userId, setUserId] = useState(null);
  const [skills, setSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAllApproved, setIsAllApproved] = useState(false);
  const [isAllFinished, setIsAllFinished] = useState(false);
  const [isAllApprovedWithPercentage, setIsAllApprovedWithPercentage] = useState(false);
  const [isLastLevel, setIsLastLevel] = useState(false);
  const [reviewLinkModal, setReviewLinkModal] = useState(defaultReviewLinkState);
  const [reasonValue, setReasonValue] = useState(defaulReasonState);
  const [isOpenReviewAlert, setIsOpenReviewAlert] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const { department, reviewId } = useParams();

  const getNextGoals = useCallback(
    async (isApproved, memberId) => {
      if (isApproved) {
        return;
      }

      const [levels, { goal }] = await Promise.all([
        ApiService.head.getDepartmentLevels(department),
        ApiService.head.getUser(memberId),
      ]);

      const goalIndex = levels.findIndex((v) => v._id === goal);

      const nextGoal = levels.splice(goalIndex + 1);

      if (!nextGoal.length) {
        setIsLastLevel(true);
      }

      setUserLevel(nextGoal);
    },
    [department],
  );

  const getSkillDetails = (skillsDetails) => {
    return skillsDetails.map((skill) => {
      return skill.details.map((detail) => ({
        name: detail.name,
        successCriteria: detail.successCriteria,
        materialName: detail.materialName,
        link: detail.link,
        status: detail.status,
      }));
    });
  };
  const getReviewGoals = useCallback(
    async (argUserId) => {
      const reviewSkills = await ApiService.head.getUserGoals(department, argUserId || userId);
      const allDetails = getAllDetails(reviewSkills);

      setSkills(reviewSkills);
      setIsLoading(false);

      const skillDetails = getSkillDetails(reviewSkills);

      const isApproved = checkIsEverythingApproved(skillDetails);
      const isApprovedWithPercentage = checkIsEverythingApprovedWithPercentage(skillDetails);
      const isFinished = checkIsEverythingFinished(allDetails);

      setIsAllApproved(isApproved);
      setIsAllApprovedWithPercentage(isApprovedWithPercentage);
      setIsAllFinished(isFinished);

      return getNextGoals(isApproved, argUserId);
    },
    [department, getNextGoals, userId],
  );

  const handleOnApprove = async (goalId, itemId, recommendation) => {
    await ApiService.head.acceptUserGoal({
      department,
      userId,
      goalId,
      itemId,
      recommendation,
    });

    const updatedSkills = refreshSkillStatus({
      skills,
      userId,
      goalId,
      itemId,
      recommendation,
      status: GoalsConstants.GOAL_STATUSES.APPROVED,
    });
    const allDetails = getAllDetails(updatedSkills);

    const skillDetails = getSkillDetails(updatedSkills);

    const isApproved = checkIsEverythingApproved(skillDetails);
    const isApprovedWithPercentage = checkIsEverythingApprovedWithPercentage(skillDetails);
    const isFinished = checkIsEverythingFinished(allDetails);

    setSkills(updatedSkills);
    setIsAllApproved(isApproved);
    setIsAllApprovedWithPercentage(isApprovedWithPercentage);
    setIsAllFinished(isFinished);

    return getNextGoals(isApproved, userId);
  };

  const handleOnDecline = async ({ goalId, itemId, comment }) => {
    await ApiService.head.declineUserGoal({
      department,
      userId,
      goalId,
      itemId,
      comment,
    });

    const updatedSkills = refreshSkillStatus({
      skills,
      userId,
      goalId,
      itemId,
      status: GoalsConstants.GOAL_STATUSES.DECLINED,
      comment,
    });
    const allDetails = getAllDetails(updatedSkills);

    const updatedSkillDetails = updatedSkills.map((skill) => {
      return skill.details.map((detail) => ({
        name: detail.name,
        successCriteria: detail.successCriteria,
        materialName: detail.materialName,
        link: detail.link,
        status: detail.status,
      }));
    });

    const isApproved = checkIsEverythingApproved(updatedSkillDetails);
    const isApprovedWithPercentage = checkIsEverythingApprovedWithPercentage(updatedSkillDetails);
    const isFinished = checkIsEverythingFinished(allDetails);

    setSkills(updatedSkills);
    setIsAllApproved(isApproved);
    setIsAllApprovedWithPercentage(isApprovedWithPercentage);
    setIsAllFinished(isFinished);

    return getNextGoals(isApproved, userId);
  };

  const getApprovedAndDeclinedDetails = () => {
    const uniqueDetails = {};

    skills.forEach((skill) => {
      skill.details.forEach((detail) => {
        const { name, status, comment, recommendation, itemId } = detail;

        if (!uniqueDetails[name]) {
          uniqueDetails[name] = {
            itemId: [],
            name,
            status,
            comment: [],
            recommendation: [],
          };
        }

        uniqueDetails[name].itemId.push(itemId);
        uniqueDetails[name].comment.push(comment);
        uniqueDetails[name].recommendation.push(recommendation);
      });
    });

    const confirmed = [];
    const declined = [];

    Object.values(uniqueDetails).forEach((detail) => {
      const { name, status, comment, recommendation, itemId } = detail;
      if (status === GoalsConstants.GOAL_STATUSES.APPROVED) {
        confirmed.push({
          itemId: itemId[0],
          name,
          status,
          recommendation: recommendation[0],
        });
      } else if (status === GoalsConstants.GOAL_STATUSES.DECLINED) {
        declined.push({
          itemId: itemId[0],
          name,
          status,
          comment: comment[0],
        });
      }
    });

    return { confirmed, declined };
  };

  const handleLinkInput = ({ target }) => {
    const value = target.value.trim();
    const isValidUrl = isURL(value, {
      protocols: ['http', 'https'],
      require_protocol: true,
    });

    setReviewLinkModal({
      ...reviewLinkModal,
      link: value,
      isLinkError: value.length === 0 ? false : !isValidUrl,
    });
  };

  const handleDeleteReasonInput = ({ target }) => {
    const { value } = target;

    setReasonValue({
      ...reasonValue,
      reason: value,
    });
  };

  const handleOnClearModal = (callbackName) => {
    if (callbackName !== 'handleOnDelete') {
      setReviewLinkModal({
        ...reviewLinkModal,
        link: '',
        isLinkError: false,
      });
    } else {
      setReasonValue({ ...reasonValue, reason: '' });
    }
  };

  const onOpenModal = (callbackName) => {
    if (callbackName !== 'handleOnDelete') {
      setReviewLinkModal({
        ...reviewLinkModal,
        callbackName,
        isOpen: true,
      });
    } else {
      setReasonValue({
        ...reasonValue,
        callbackName,
        isOpen: true,
      });
    }
  };

  const handleOnCloseModal = () => {
    setReviewLinkModal(defaultReviewLinkState);
    setReasonValue(defaulReasonState);
  };

  const handleOnArchive = async () => {
    try {
      const { confirmed, declined } = getApprovedAndDeclinedDetails();

      await Promise.all([
        ApiService.head.archiveReview(department, reviewId, {
          confirmed: confirmed.length,
          declined: declined.length,
          reviewRecordLink: reviewLinkModal.link,
        }),
        ApiService.head.sendTaskStatuses(department, reviewId, [...confirmed, ...declined]),
      ]);

      await ApiService.head.sendReviewStatistic(department, userId);
    } finally {
      handleOnCloseModal();
      setUserId(null);
      history.goBack();
    }
  };

  const handleOnDelete = async () => {
    try {
      const userGoals = await ApiService.head.getUserGoals(department, userId);
      const doneFlag = 'true';

      if (userGoals.length > 0) {
        await Promise.all(
          userGoals.map(async (goal) => {
            const goalId = goal._id;

            await Promise.all(
              goal.details.map(async (detail) => {
                const { itemId } = detail;

                await ApiService.head.acceptUserGoal({
                  department,
                  userId,
                  goalId,
                  itemId,
                  doneFlag,
                });
              }),
            );
          }),
        );

        const { reason } = reasonValue;

        await ApiService.head.deleteUserReview({ department, reviewId, reason });
      }
    } finally {
      handleOnCloseModal();
      setUserId(null);
      history.goBack();
    }
  };

  const handleOnSetNextLevelClick = async () => {
    try {
      const { confirmed, declined } = getApprovedAndDeclinedDetails();

      await Promise.all([
        ApiService.head.confirmReview(department, reviewId, {
          confirmed: confirmed.length,
          declined: declined.length,
          reviewRecordLink: reviewLinkModal.link,
        }),
        ApiService.head.setUserNextGoal(userId),
        ApiService.head.sendTaskStatuses(department, reviewId, [...confirmed, ...declined]),
      ]);

      await ApiService.head.sendReviewStatistic(department, userId);
    } finally {
      handleOnCloseModal();
      setUserId(null);
      history.goBack();
    }
  };

  const handleOnSaveModal = async () => {
    setIsSaveLoading(true);
    try {
      if (reviewLinkModal.callbackName === 'handleOnSetNextLevelClick') {
        await handleOnSetNextLevelClick();
      }
      if (reviewLinkModal.callbackName === 'handleOnArchive') {
        await handleOnArchive();
      }
      if (reasonValue.callbackName === 'handleOnDelete') {
        await handleOnDelete();
      }
    } finally {
      setIsSaveLoading(false);
      handleOnCloseModal();
    }
  };

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      try {
        const userReview = await ApiService.head.getDepartmentMemberReview(department, reviewId);

        if (!userReview || userReview.status !== ReviewConstants.REVIEW_STATUSES.PROCESSED) {
          if (isMounted) {
            enqueueSnackbar(ReviewConstants.ERROR_MESSAGES.REVIEW_NOT_FOUND, { variant: 'error' });
          }
          return;
        }

        setUserId(userReview.userId);

        await getReviewGoals(userReview.userId);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [department, enqueueSnackbar, getReviewGoals, reviewId]);

  const isSaveDisabled = reviewLinkModal.isLinkError || reviewLinkModal.link.length === 0;
  const isReasonSaveDisabled = reasonValue.reason.length === 0;

  return (
    <Grid sx={{ margin: '32px 36px 0' }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10} lg={8}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container component={StyledPaper} elevation={8}>
                <UserSkillsView
                  admin
                  skillsData={skills}
                  isLoading={isLoading}
                  onApprove={handleOnApprove}
                  onDecline={handleOnDecline}
                  isReviewsPage
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Grid>
                  <FinishReviewPanel
                    goals={userLevel}
                    onNextGoalClick={() => onOpenModal('handleOnSetNextLevelClick')}
                    onArchive={() => onOpenModal('handleOnArchive')}
                    onDelete={() => onOpenModal('handleOnDelete')}
                    isAllApproved={isAllApproved}
                    isAllFinished={isAllFinished}
                    isAllApprovedWithPercentage={isAllApprovedWithPercentage}
                    isDisabled={isLoading || !userId}
                    isLastLevel={isLastLevel}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ReviewRecordModal
        linkValue={reviewLinkModal.link}
        handleLinkInput={handleLinkInput}
        isOpen={reviewLinkModal.isOpen}
        onSave={handleOnSaveModal}
        onClose={handleOnCloseModal}
        onClear={handleOnClearModal}
        isInputError={reviewLinkModal.isLinkError}
        isSaveDisabled={isSaveDisabled}
        isSaveLoading={isSaveLoading}
      />
      <DeleteReviewModal
        reasonValue={reasonValue.reason}
        handleReasonInput={handleDeleteReasonInput}
        isOpen={reasonValue.isOpen}
        onSave={handleOnSaveModal}
        onClose={handleOnCloseModal}
        onClear={() => handleOnClearModal('handleOnDelete')}
        isReasonSaveDisabled={isReasonSaveDisabled}
        isSaveLoading={isSaveLoading}
      />
      <Snackbar open={isOpenReviewAlert} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setIsOpenReviewAlert(false)} severity="error">
          Review record is required for review
        </Alert>
      </Snackbar>
    </Grid>
  );
}
