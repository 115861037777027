export default Object.freeze({
  REVIEW_STATUSES: {
    PROCESSED: 'PROCESSED',
    DECLINED: 'DECLINED',
    CONFIRMED: 'CONFIRMED',
    REVERTED: 'REVERTED',
    PENDING: 'PENDING',
  },

  MESSAGES: {
    DO_YOU_WANT_TO_SEND_A_REVIEW_REQUEST: 'Send review?',
    YOU_GOT_ALL_LEVELS: 'Congratulations! You have reached all skill levels.',
    REVIEW_IS_REQUESTED_SUCCESSFULLY:
      'Your review is requested successfully! Please wait the review feedback.',
    ALL_SKILLS_ARE_APPROVED:
      'All available skills are approved! Please wait while new skills are added for your current goal level.',
  },

  ERROR_MESSAGES: {
    REVIEW_NOT_FOUND: 'Review not found!',
    REVIEWS_ARE_NOT_FOUND: 'No reviews found',
    URL_IS_INVALID: 'Please enter a valid URL that starts with either `https` or `http`',
  },
});
