import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MainCard from '../../../../components/MainCard';

export default function StyledCard({ title, count, icon }) {
  const Icon = icon;

  return (
    <MainCard
      contentSX={{
        padding: '16px',
        paddingBottom: '16px !important',
      }}
    >
      <Stack spacing={0.5}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" color="text.secondary" sx={{ marginRight: '8px' }}>
            {title}
          </Typography>
          {icon ? <Icon color="primary" /> : ''}
        </Box>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h5" color="inherit">
              {count}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </MainCard>
  );
}

StyledCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  icon: PropTypes.shape({}),
};

StyledCard.defaultProps = {
  icon: null,
};
