import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, styled } from '@mui/material';
import apiService from '../../services/api.service';
import BasicPage from '../../templates/BasicPage';
import debounce from '../../utils/debounce';
import SearchBar from '../Head/Users/components/SearchBar';
import CustomAutocomplete from './components/CustomAutocomplete';
import SkillsTable from './components/SkillsTable';

const StyledItem = styled(Paper)(() => ({
  display: 'flex',
  marginBottom: '1em',
  padding: '1em 2em',
  borderRadius: '.15em',
}));

export default function Employees({ title }) {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [departmentsFilter, setDepartmentsFilter] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setIsLoading(true);
  };

  const handleChangeRowsPerPage = ({ target }) => {
    const parsedPage = Number(target.value);
    setRowsPerPage(parsedPage);
    setCurrentPage(0);
    setIsLoading(true);
  };

  const preloadData = async () => {
    try {
      if (!departments.length) {
        const departmentsData = await apiService.developer.getDepartments();
        setDepartments(departmentsData);
      }

      const usersData = await apiService.developer.getUsersWithSkills(
        departmentsFilter,
        searchValue,
        currentPage,
        rowsPerPage,
      );
      const count = await apiService.developer.getCountUsers(
        departmentsFilter,
        searchValue,
        currentPage,
        rowsPerPage,
      );

      setUsers(usersData);
      setTotalCount(count);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      preloadData();
    }
  }, [isLoading]);

  return (
    <BasicPage title={title}>
      <Grid sx={{ margin: '8px 36px 0' }}>
        <Grid container sx={{ justifyContent: 'center' }}>
          <Grid width="90%" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Grid
              container
              justifyContent={{ xs: 'center', sm: 'space-between' }}
              alignItems={{ xs: 'center', sm: 'center' }}
              marginBottom="16px"
              marginTop="8px"
              sx={{
                flexDirection: { xs: 'column', sm: 'row' },
                width: { md: '90%', xs: '100%' },
                gap: '12px',
              }}
            >
              <Grid
                item
                sx={{
                  width: { xs: '100%', sm: 'auto' },
                  mt: { xs: 3 },
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <CustomAutocomplete
                  options={departments}
                  setIsLoading={setIsLoading}
                  setDepartmentsFilter={setDepartmentsFilter}
                />
              </Grid>
              <Grid
                item
                sx={{
                  mt: { xs: 3 },
                  width: { xs: '85%', sm: 'auto' },
                  justifyContent: 'center',
                }}
              >
                <SearchBar
                  onChange={debounce(({ target }) => {
                    const { value } = target;
                    setSearchValue(value);
                    setIsLoading(true);
                  }, 500)}
                  width="100%"
                />
              </Grid>
            </Grid>
            <StyledItem
              elevation={8}
              sx={{
                display: 'grid',
                width: { md: '90%', xs: '100%' },
                mt: { xs: 3, sm: 0 },
              }}
            >
              <SkillsTable
                isLoading={isLoading}
                users={users}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                totalCount={totalCount}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </StyledItem>
          </Grid>
        </Grid>
      </Grid>
    </BasicPage>
  );
}

Employees.propTypes = {
  title: PropTypes.string.isRequired,
};
