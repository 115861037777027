import { useEffect, useState } from 'react';
import { ListAlt, PeopleAlt, RateReviewOutlined, Subject } from '@mui/icons-material';
import apiService from '../../../../services/api.service';
import useHeadDepartment from './useHeadDepartment';

const useCardsData = () => {
  const [cardsData, setCardsData] = useState([]);
  const { departmentId, loading } = useHeadDepartment();

  const fetchCardsData = async () => {
    if (!departmentId) return;
    try {
      const response = await apiService.head.getHeadTotalCount(departmentId);
      setCardsData([
        { title: 'Total Users', count: response.users, icon: PeopleAlt },
        { title: 'Total Reviews', count: response.reviews, icon: RateReviewOutlined },
        { title: 'Total Goals', count: response.goals, icon: ListAlt },
        { title: 'Total Activities', count: response.activities, icon: Subject },
      ]);
    } catch (error) {
      console.error('Failed to fetch card data:', error);
    }
  };

  useEffect(() => {
    if (!loading && departmentId) {
      fetchCardsData();
    }
  }, [departmentId, loading]);

  return {
    cardsData,
  };
};

export default useCardsData;
