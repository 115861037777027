import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  createTheme,
  styled,
  ThemeProvider,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';
import TreeItemCustom from '../../../components/TreeItemCustom';

import FileTypes from '../../../constants/file-types.constants';
import GoalsConstants from '../../../constants/goals.constants';
import UserContext from '../../../hooks/context/UserContext';
import AdminConfirmSkillPopUp from './AdminConfirmSkillPopUp';
import ConfirmSkillPopUp from './ConfirmSkillPopUp';
import ImagePreviewPopUp from './ImagePreviewPopUp';
import TextHighlighterPopUp from './TextHighlighterPopUp';
import UserNestedSkillDetails from './UserNestedSkillDetails';

const theme = createTheme({
  components: {
    MuiTreeItem: {
      styleOverrides: {
        root: { marginLeft: '13px' },
        label: { fontSize: '16px' },
      },
    },
  },
  palette: { primary: { main: '#7553EA' } },
});

const StyledTreeLabelBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export default function UserSkillsDetails({
  admin,
  node,
  onClick,
  onApprove,
  onDecline,
  isDisabled,
  isInteractive,
  onLinkClick,
  isReviewsPage,
  isHomePage,
  isReviewedPage,
}) {
  const { socketInstance } = useContext(UserContext);
  const { details } = node;

  const [anchorMenu, setAnchorMenu] = useState(null);
  const [openConfirmCriteriaPopUp, setOpenConfirmCriteriaPopUp] = useState(false);
  const [openConfirmCriteriaItemId, setOpenConfirmCriteriaItemId] = useState(null);
  const [openConfirmCriteriaType, setOpenConfirmCriteriaType] = useState(null);
  const [openConfirmCriteriaInput, setOpenConfirmCriteriaInput] = useState(null);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [fileContent, setFileContent] = useState('');
  const [fileContentType, setFileContentType] = useState('');
  const [uploadedItems, setUploadedItems] = useState({});

  const openMenu = Boolean(anchorMenu);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenu(null);
  };

  const preparedDetails = useMemo(() => {
    const resultArr = [];

    details.forEach((detail) => {
      const foundedItem = resultArr.findIndex((item) => item.name === detail.name);

      if (foundedItem === -1) {
        resultArr.push({
          name: detail.name,
          itemId: detail.itemId,
          status: detail.status,
          successCriteria: [detail.successCriteria],
          comment: detail.comment || '',
          recommendation: detail.recommendation || '',
          criteria: [
            {
              itemId: detail.itemId,
              successCriteria: detail.successCriteria,
              contentType: detail.contentType,
              contentInput: detail.contentInput,
            },
          ],
          materials: [
            {
              name: detail.materialName,
              type: detail.materialType,
              link: detail.link,
            },
          ],
        });
      } else {
        if (
          resultArr[foundedItem].status !== detail.status ||
          resultArr[foundedItem].recommendation !== detail.recommendation
        ) {
          resultArr[foundedItem].status = detail.status;
          resultArr[foundedItem].recommendation = detail.recommendation;
        }

        const { successCriteria } = resultArr[foundedItem];
        resultArr[foundedItem].successCriteria = [
          ...new Set([...successCriteria, detail.successCriteria]),
        ];
        resultArr[foundedItem].criteria.push({
          itemId: detail.itemId,
          successCriteria: detail.successCriteria,
          contentType: detail.contentType,
          contentInput: detail.contentInput,
        });
        resultArr[foundedItem].materials.push({
          name: detail.materialName,
          type: detail.materialType,
          link: detail.link,
        });
      }
    });

    return resultArr;
  }, [details]);

  const handleMenuItemClick = (event, itemId, status, skillType) => {
    event.stopPropagation();
    socketInstance.emit('send-goal-status', {
      itemId,
      status,
      skillType,
    });
  };

  const handleOnNestedMenuItemClick = (event, { parentId, itemId, status }) => {
    event.stopPropagation();

    return socketInstance.emit('send-nested-goal-status', {
      parentId,
      itemId,
      status,
    });
  };

  const handleOnConfirmCriteriaClick = (event, { itemId, contentType, contentInput }) => {
    event.stopPropagation();

    setOpenConfirmCriteriaType(contentType);
    setOpenConfirmCriteriaInput(contentInput);
    setOpenConfirmCriteriaItemId(itemId);
    setOpenConfirmCriteriaPopUp(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <TreeItemCustom
        nodeId={node.itemId}
        label={
          <StyledTreeLabelBox>
            <Typography
              sx={{
                m: 1,
                marginRight: '10px',
              }}
              variant="p"
              component="p"
            >
              {node.name}
            </Typography>

            {admin && (
              <Box>
                {node.status === GoalsConstants.GOAL_STATUSES.APPROVED && (
                  <Box>
                    <CheckCircleRoundedIcon sx={{ verticalAlign: 'middle' }} />
                  </Box>
                )}
                {node.status === GoalsConstants.GOAL_STATUSES.DECLINED && (
                  <Box>
                    <CancelRoundedIcon sx={{ verticalAlign: 'middle' }} />
                  </Box>
                )}
              </Box>
            )}

            {isInteractive &&
              !isDisabled &&
              !admin &&
              GoalsConstants.USER_SKILLS_DETAILS_MENU_ALLOW_ROLES.includes(node.status) && (
                <>
                  <IconButton
                    disabled={admin}
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <MoreHorizIcon fontSize="medium" />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorMenu}
                    open={openMenu}
                    onClose={handleClose}
                    MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                  >
                    <MenuItem
                      onClick={(event) =>
                        handleMenuItemClick(
                          event,
                          node.itemId,
                          GoalsConstants.GOAL_STATUSES.ON_HOLD,
                          node.skillType,
                        )
                      }
                    >
                      On Hold
                    </MenuItem>
                  </Menu>
                </>
              )}
          </StyledTreeLabelBox>
        }
        onClick={() => onClick(node.itemId)}
      >
        {preparedDetails &&
          preparedDetails.map((v) => (
            <UserNestedSkillDetails
              key={v.itemId}
              admin={admin}
              parent={node}
              details={v}
              onMenuItemClick={handleOnNestedMenuItemClick}
              onConfirmCriteriaClick={handleOnConfirmCriteriaClick}
              onApprove={onApprove}
              onDecline={onDecline}
              isDisabled={isDisabled}
              isInteractive={isInteractive}
              onLinkClick={onLinkClick}
              isReviewsPage={isReviewsPage}
              isHomePage={isHomePage}
              isReviewedPage={isReviewedPage}
              uploadedItems={uploadedItems}
            />
          ))}
      </TreeItemCustom>
      {admin || isReviewedPage ? (
        <AdminConfirmSkillPopUp
          open={openConfirmCriteriaPopUp}
          setOpen={setOpenConfirmCriteriaPopUp}
          type={openConfirmCriteriaType}
          itemId={openConfirmCriteriaItemId}
          input={uploadedItems[openConfirmCriteriaItemId] || openConfirmCriteriaInput}
          setIsOpenPreview={setIsOpenPreview}
          setFileContent={setFileContent}
          setFileContentType={setFileContentType}
          uploadedItems={uploadedItems}
          setUploadedItems={setUploadedItems}
        />
      ) : (
        <ConfirmSkillPopUp
          open={openConfirmCriteriaPopUp}
          setOpen={setOpenConfirmCriteriaPopUp}
          type={openConfirmCriteriaType}
          itemId={openConfirmCriteriaItemId}
          input={uploadedItems[openConfirmCriteriaItemId] || openConfirmCriteriaInput}
          setIsOpenPreview={setIsOpenPreview}
          setFileContent={setFileContent}
          setFileContentType={setFileContentType}
          uploadedItems={uploadedItems}
          setUploadedItems={setUploadedItems}
        />
      )}
      {fileContentType === FileTypes.file ? (
        <TextHighlighterPopUp
          setOpenPopup={setIsOpenPreview}
          openPopup={isOpenPreview}
          fileContent={fileContent}
        />
      ) : (
        <ImagePreviewPopUp
          admin={admin}
          setOpenPopup={setIsOpenPreview}
          imageUrl={fileContent}
          openPopup={isOpenPreview}
        />
      )}
    </ThemeProvider>
  );
}

UserSkillsDetails.propTypes = {
  node: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.string).isRequired,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        itemId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        successCriteria: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
        materialType: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        level: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    name: PropTypes.string.isRequired,
    skillType: PropTypes.string.isRequired,
    status: PropTypes.string,
  }).isRequired,
  admin: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onLinkClick: PropTypes.func.isRequired,
  isInteractive: PropTypes.bool.isRequired,
  isHomePage: PropTypes.bool.isRequired,
  isReviewsPage: PropTypes.bool.isRequired,
  isReviewedPage: PropTypes.bool.isRequired,
};

UserSkillsDetails.defaultProps = {
  admin: false,
  isDisabled: false,
};
