import { capitalize } from 'lodash';

export default function crumbsGenerator({ routes, keysArr, props }) {
  return routes
    .filter(({ path }) => props.match.path.includes(path))
    .map(({ path, title }) => {
      const lastKey = keysArr[keysArr.length - 1];
      const pathArr = path.split('/:');
      const value = props.match.params[lastKey];

      return {
        path: keysArr.length
          ? keysArr.reduce(
              (paramPath, param) => paramPath.replace(`:${param}`, props.match.params[param]),
              path,
            )
          : path,
        title: capitalize(pathArr.includes(lastKey) ? value : title),
      };
    });
}
