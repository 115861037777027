import { PropTypes } from 'prop-types';
import StyledAutocomplete from '../styles/StyledAutocomplete';

function DepartmentsFilter({ departmentOptions, pickedDepartments, setPickedDepartments }) {
  return (
    <StyledAutocomplete
      label="Departments"
      keyName="title"
      setValue={setPickedDepartments}
      options={departmentOptions}
      value={pickedDepartments}
      setIsLoading={() => {}}
    />
  );
}

export default DepartmentsFilter;

DepartmentsFilter.propTypes = {
  departmentOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  pickedDepartments: PropTypes.arrayOf(PropTypes.string).isRequired,
  setPickedDepartments: PropTypes.func.isRequired,
};
