import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Input, Paper, styled, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import apiService from '../../../services/api.service';
import AdminBasicPage from '../../../templates/AdminBasicPage';
import FilterByDepartments from './components/FilterByDepartments';
import UsersTable from './components/UsersTable';

const StyledItem = styled(Paper)(() => ({
  display: 'flex',
  marginBottom: '1em',
  padding: '2em 3em',
  borderRadius: '.15em',
}));

export default function AdminUsersPage({ crumbs, title }) {
  const [usersData, setUsersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [usersSearch, setUsersSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [departments, setDepartments] = useState([]);
  const [usersSort, setUsersSort] = useState('createdAt');
  const [departmentSearch, setDepartmentSearch] = useState([]);
  const history = useHistory();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangeFilter = (departmentId) => {
    if (departmentSearch.find((department) => department === departmentId)) {
      const index = departmentSearch.indexOf(departmentId);
      const arr = departmentSearch.slice();
      arr.splice(index, 1);
      setDepartmentSearch(arr);
    } else {
      setDepartmentSearch([...departmentSearch, departmentId]);
    }

    if (departmentId === window.location.search.slice(1)) {
      history.replace({
        search: '',
      });
    }

    setCurrentPage(0);
    setIsLoading(true);
  };

  const fetchUsers = async (skip, limit, sort = '') => {
    try {
      const departmentParam = window.location.search.slice(1);
      if (departmentParam) {
        handleChangeFilter(departmentParam);
      }

      const response = await apiService.admin.getUsers({
        params: {
          'filter[email]': usersSearch,
          'filter[departments]': departmentParam ? [departmentParam] : departmentSearch,
          skip: skip * limit,
          limit,
          sort,
        },
      });

      setCurrentPage(skip);
      setUsersData(response.paginatedResult);
      setTotalCount(response.totalCount);
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  };

  const fetchDepartmentsLvls = async () => {
    try {
      setIsLoading(true);

      const response = await apiService.admin.getDepartmentsLvls();

      setDepartments(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchUsers(currentPage, rowsPerPage, usersSort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleInputChange = (event) => {
    if (!isLoading) {
      setUsersSearch(event.target.value);
      setCurrentPage(0);
      setIsLoading(true);
    }
  };

  useEffect(() => fetchDepartmentsLvls(), []);

  return (
    <AdminBasicPage crumbs={crumbs} title={title}>
      <Grid sx={{ margin: '8px 36px 0' }} item>
        <Grid container>
          <Grid sx={{ flex: { md: '2 0 350px', xs: '2 0 140px' } }} item>
            <Grid
              container
              sx={{ justifyContent: { xs: 'left', sm: 'right', md: 'right' } }}
              alignItems="center"
              height={48}
            >
              <Grid>
                <Input
                  value={usersSearch}
                  sx={{ width: { md: 240, xs: 300 } }}
                  placeholder="Search"
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: 'grid',
                gridTemplateColumns: isSmallScreen ? '300px' : '168px 1fr',
                gap: '1em',
              }}
            >
              <FilterByDepartments
                departmentsData={departments}
                handleChangeFilter={handleChangeFilter}
                departmentSearch={departmentSearch}
              />
              <StyledItem elevation={8} container="true" sx={{ display: 'grid' }}>
                <UsersTable
                  isLoading={isLoading}
                  usersData={usersData}
                  totalCount={totalCount}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  departments={departments}
                  setSort={setUsersSort}
                  setIsLoading={setIsLoading}
                />
              </StyledItem>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminBasicPage>
  );
}

AdminUsersPage.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};
