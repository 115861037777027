import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Input } from '@mui/material';

export default function SearchBar({ onChange, width }) {
  return (
    <Grid>
      <Input sx={{ width: width ?? 240 }} placeholder="Search" onChange={onChange} />
    </Grid>
  );
}

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
};
