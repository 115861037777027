import React from 'react';
import { TableCell, TableRow, Skeleton } from '@mui/material';

export default function RowSkeleton() {
  return (
    <TableRow>
      <TableCell>
        <Skeleton width={35} height={35} variant="circular" />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton width={30} height={30} variant="circular" />
      </TableCell>
    </TableRow>
  );
}
