import ReviewConstants from '../constants/review.constants';
import axios from './http.service';

export default Object.freeze({
  admin: {
    archiveUser: (userId) => axios.delete(`admin/users/${userId}/archive`),
    getDepartments: (params) => axios.get('admin/departments', params),
    getUsers: (params) => axios.get('admin/users', params),
    updateDepartment: (id, params) => axios.patch(`admin/departments/${id}`, params),
    getDepartmentsLvls: () => axios.get('admin/departments/levels'),
    updateUser: (id, params) => axios.patch(`admin/users/${id}/update`, params),
    getApiKeys: (params) => axios.get('admin/api-keys', params),
    saveApiKey: (data) => axios.post('admin/api-keys', data),
    updateApiKey: (id, data) => axios.patch(`admin/api-keys/${id}`, data),
    deleteApiKey: (id) => axios.delete(`admin/api-keys/${id}`),
    getApiKeysNames: () => axios.get('admin/api-keys/names'),
    getRssLinks: () => axios.get('rss/links'),
    saveRssLinks: (data) => axios.post('rss/links', data),
    updateRssLinks: (linkId, data) => axios.patch(`rss/links/${linkId}`, data),
    removeRssLinks: (linkId) => axios.delete(`rss/links/${linkId}`),
    getReviews: (params) => axios.get('admin/reviews', params),
    getReviewStatisticTemplate: () => axios.get('admin/templates/review-statistic'),
    saveSkill: (data) => axios.post('admin/skills', data),
    updateSkill: (skillId, data) => axios.put(`admin/skills/${skillId}`, data),
    updateSkillStatus: (skillId, status) => axios.patch(`admin/skills/${skillId}?status=${status}`),
    deleteSkill: (skillId) => axios.delete(`admin/skills/${skillId}`),
    getSkill: () => axios.get('admin/skills'),
    uploadSkillImage: (data) => axios.post('upload/skills', data),
    getAdminUsersDashboardStatistic: (query) =>
      axios.get(`users-statistic/admin/count/users?${query}`),
    getAdminDepartmentsDashboardStatistic: (query) =>
      axios.get(`users-statistic/admin/count/departments?${query}`),
    getAdminTotalCount: () => axios.get('users-statistic/admin/count/total'),
    getAdminDashboardReviews: (query) => axios.get(`users-statistic/reviews/dashboard?${query}`),
    getAdminLinkViews: ({ skip, limit, order, orderBy, createdAt }) =>
      axios.get(`users-history/reports/links-views`, {
        params: { skip, limit, order, orderBy, createdAt },
      }),
  },
  head: {
    getUser: (userId) => axios.get(`head/users/${userId}`),
    archiveUser: (userId) => axios.delete(`head/users/${userId}/archive`),
    unArchiveUser: (userId) => axios.patch(`head/users/${userId}/unarchive`),
    getDepartmentLevels: (department) => axios.get(`head/departments/${department}/levels`),
    getGrowLevels: (department) => axios.get(`head/grow/${department}`),
    getDepartmentMembers: (department, data) =>
      axios.post(`head/departments/${department}/members`, data),
    getDepartmentReviews: (department, status = ReviewConstants.REVIEW_STATUSES.PROCESSED) =>
      axios.get(`head/departments/${department}/reviews?status=${status}`),
    getDepartmentMemberReview: (department, reviewId) =>
      axios.get(`head/departments/${department}/reviews/${reviewId}`),
    getUserGoals: (department, userId) =>
      axios.get(`head/departments/${department}/users/${userId}/goals`),
    updateDepartmentLevels: (department, levels) =>
      axios.patch(`head/departments/${department}/levels`, { levels }),
    acceptUserGoal: ({ department, userId, goalId, itemId, recommendation, doneFlag }) =>
      axios.patch(
        `head/departments/${department}/users/${userId}/goals/${goalId}/items/${itemId}/accept`,
        { recommendation, doneFlag },
      ),
    declineUserGoal: ({ department, userId, goalId, comment, itemId }) =>
      axios.patch(
        `head/departments/${department}/users/${userId}/goals/${goalId}/items/${itemId}/decline`,
        { comment },
      ),
    archiveReview: (department, reviewId, body) =>
      axios.post(`head/departments/${department}/reviews/${reviewId}/archive`, body),
    confirmReview: (department, reviewId, body) =>
      axios.post(`head/departments/${department}/reviews/${reviewId}/confirm`, body),
    processedReview: (department, reviewId, body) =>
      axios.post(`head/departments/${department}/reviews/${reviewId}/processed`, body),
    setUserNextGoal: (userId) => axios.post(`head/users/${userId}/goals/up`),
    upgradeUserGoal: (userId, goalId, resetProgress = false) =>
      axios.post(`head/users/${userId}/goals/${goalId}/reset`, { resetProgress }),
    getReviewHistory: (department, data) =>
      axios.get(`head/departments/${department}/review-history`, data),
    exportCsv: (department, skillType) => axios.get(`/head/grow/${department}/csv/${skillType}`),
    sendJson: ({ department, skillType, body }) =>
      axios.post(`/head/grow/${department}/json/${skillType}`, body),
    sendCsv: ({ department, skillType, body }) =>
      axios.post(`/head/grow/${department}/csv/${skillType}`, body),
    signInAs: (userId) => axios.post(`auth/sign-in-as/${userId}`),
    sendReviewStatistic: (department, userId) =>
      axios.post(`users-statistic/head/${department}?userId=${userId}`),
    getReviewStatistic: (department, userId, reviewId) =>
      axios.get(`users-statistic/head/${department}?userId=${userId}&reviewId=${reviewId}`),
    sendTaskStatuses: (department, reviewId, tasks) =>
      axios.post(`/head/task-status/${department}?reviewId=${reviewId}`, { tasks }),
    updateReviewRecordLink: ({ department, reviewId, body }) =>
      axios.patch(`head/departments/${department}/review-record-link/${reviewId}`, body),
    deleteUserReview: ({ department, reviewId, reason }) =>
      axios.delete(`head/departments/${department}/delete-user-review/${reviewId}`, {
        params: { reason },
      }),
    getSkill: (department) => axios.get(`head/departments/${department}/skills`),
    saveSkill: (department, data) => axios.post(`head/departments/${department}/skills`, data),
    updateSkill: ({ id: skillId, department, data }) =>
      axios.put(`head/departments/${department}/skills/${skillId}`, data),
    deleteSkill: (skillId, department) =>
      axios.delete(`head/departments/${department}/skills/${skillId}`),
    requestSkill: (skillId, department) =>
      axios.patch(`head/departments/${department}/skills/${skillId}`),
    getUserSkills: (userId) => axios.get(`skills/${userId}`),
    addSkillForUser: (userId, skillId) => axios.post(`skills/${userId}/${skillId}`),
    deleteSkillForUser: (userId, skillId) => axios.delete(`skills/${userId}/${skillId}`),
    addPersonalTaskToGoal: (personalTask, personalTasksId) =>
      axios.post(`goals/${personalTasksId}`, personalTask),
    updatePersonalTaskInGoal: (personalGoalId, personalTask) =>
      axios.put(`goals/${personalGoalId}`, personalTask),
    deletePersonalTaskInGoal: (personalGoalId) => axios.delete(`goals/${personalGoalId}`),
    savePersonalTask: (department, data) =>
      axios.post(`head/departments/${department}/personalTasks`, data),
    deletePersonalTask: (id, department) =>
      axios.delete(`head/departments/${department}/personalTasks/${id}`),
    getUserPersonalTask: (userId) => axios.get(`personalTasks/${userId}`),
    addPersonalTaskForUser: (userId, personalTasksId) =>
      axios.post(`personalTasks/${userId}/${personalTasksId}`),
    updateNotification: (id, status) => axios.patch(`notifications/${id}?status=${status}`),
    getHeadNotification: (headIds) => axios.get(`notifications/${headIds}/get-head-notification`),
    getHeadTotalCount: (departmentId) =>
      axios.get(`users-statistic/head/count/total/${departmentId}`),
    getHeadUsersDashboardStatistic: (query) =>
      axios.get(`users-statistic/head/count/users?${query}`),
    getHeadLinkViews: ({ skip, limit, order, orderBy, createdAt, departmentId }) =>
      axios.get(`users-history/reports/links-views`, {
        params: { skip, limit, order, orderBy, createdAt, departmentId },
      }),
    getHeadDashboardReviews: (query) => axios.get(`users-statistic/reviews/dashboard?${query}`),
  },
  developer: {
    getMySelf: () => axios.get('users/me'),
    getDepartmentLevels: (departmentId) => axios.get(`departments/${departmentId}/levels`),
    requestReview: () => axios.post('reviews'),
    getRequestedReview: () => axios.get('reviews'),
    getConfirmedReview: () => axios.get('reviews/confirmed?isCongratulated=false'),
    setAllConfirmedAsCongratulated: () => axios.patch('reviews/confirmed'),
    getReviewsHistory: (params) => axios.get('reviews/history', params),
    getNews: (departmentId, params) => axios.get(`rss/${departmentId}`, params),
    getEvents: () => axios.get(`rss/events/list`),
    getReviewedSkills: (filterByInfo) => axios.get('goals/reviewed', { params: { filterByInfo } }),
    getDeclinedSummaryById: (id) => axios.get(`goals/declined-summary/${id}`),
    sendSession: (time) => axios.post('users-history/session', { time }),
    getUserSkills: () => axios.get('skills'),
    getUsersWithSkills: (departments, search, skip, limit) =>
      axios.get(
        `skills/users?departments=${departments}&search=${search}&skip=${skip}&limit=${limit}`,
      ),
    getCountUsers: (departments, search, skip, limit) =>
      axios.get(
        `skills/users/count?departments=${departments}&search=${search}&skip=${skip}&limit=${limit}`,
      ),
    sendGoalContent: (itemId, contentInput) =>
      axios.patch(`goals/content/${itemId}`, { contentInput }),
    uploadAttachment: (data) => axios.post('/upload/attachments', data),
    getDepartments: () => axios.get('departments'),
    updateUser: (id, params) => axios.put(`users/${id}/update`, params),
    getHydraEnglishLevel: (email) => axios.get(`users/${email}/english-level`),
    refreshToken: (userId) => axios.get(`users/${userId}/refresh-token`),
    getPreview: (url) => axios.get('goals/preview', { params: { url } }),
    sendReport: (data) => axios.post('notifications/create-notification', data),
    getNotifications: () => axios.get('notifications'),
  },
});
