export const DEPARTMENT_NAMES = Object.freeze({
  VUEJS: 'vuejs',
  REACTJS: 'reactjs',
  GRAPHQL: 'graphQL',
  NODEJS: 'nodejs',
  PHP: 'php',
  ANDROID: 'android',
  DEVOPS: 'devops',
  IOS: 'ios',
  FLUTTER: 'flutter',
  IOT: 'iot',
  JAVA: 'java',
  ML: 'ml',
  DESIGN: 'design',
  PYTHON: 'python',
  QA: 'qa',
  RUBY: 'ruby',
  UNITY: 'unity',
  WORDPRESS: 'wordpress',
  PROJECT_MANAGEMENT: 'pm',
  PRODUCT_MANAGEMENT: 'pdm',
  TREE_D: 'trid',
  HR: 'hr',
  GENERAL: 'general',
});

export const DEPARTMENT_NAME_VALUES = Object.values(DEPARTMENT_NAMES);
