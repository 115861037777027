import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../../services/http.service';

const useGrow = () => {
  const { department } = useParams();

  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hardSkillTree, setHardSkillTree] = useState([]);
  const [softSkillTree, setSoftSkillTree] = useState([]);
  const [initialTreeDataHard, setInitialTreeDataHard] = useState([]);
  const [initialTreeDataSoft, setInitialTreeDataSoft] = useState([]);
  const [growLevels, setGrowLevels] = useState([]);
  const [selectedLvls, setSelectedLvls] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [foundedSkills, setFoundedSkills] = useState([]);

  useEffect(() => {
    function fetchData() {
      try {
        setIsLoading(true);
        axios({
          method: 'GET',
          url: `head/grow/${department}`,
        }).then((res) => {
          setHardSkillTree(res.hard);
          setSoftSkillTree(res.soft);
          setInitialTreeDataHard(res.hard);
          setInitialTreeDataSoft(res.soft);
          setGrowLevels(res.levels);
          setIsLoading(false);
        });
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    }

    fetchData();
  }, [department]);

  useEffect(() => {
    setHardSkillTree(initialTreeDataHard);
    setSoftSkillTree(initialTreeDataSoft);
  }, [initialTreeDataHard, initialTreeDataSoft]);

  const handleChange = useCallback(
    (event, newValue) => {
      setHardSkillTree(initialTreeDataHard);
      setSoftSkillTree(initialTreeDataSoft);
      setActiveTab(newValue);
    },
    [initialTreeDataHard, initialTreeDataSoft],
  );

  const filterUnused = useCallback((tree) => {
    let unusedNodesIds = [];
    let filteredTree = tree;

    tree.forEach((node) => {
      if (node.children.length === 0 && node.details.length === 0) {
        unusedNodesIds = [...unusedNodesIds, node.itemId];
      }
    });

    if (unusedNodesIds.length > 0) {
      filteredTree = tree
        .map((node) => ({
          ...node,
          children: node.children.filter((child) => !unusedNodesIds.includes(child)),
        }))
        .filter((node) => !unusedNodesIds.includes(node.itemId));
      return filterUnused(filteredTree);
    }
    return filteredTree;
  }, []);

  const filteredHardTree = useMemo(() => {
    if (selectedLvls.length > 0) {
      const newArr = hardSkillTree.map((node) => ({
        ...node,
        details: node.details.filter((detail) => selectedLvls.includes(detail.level)),
      }));
      return filterUnused(newArr);
    }
    return hardSkillTree;
  }, [filterUnused, hardSkillTree, selectedLvls]);

  const filteredSoftTree = useMemo(() => {
    if (selectedLvls.length > 0) {
      const newArr = softSkillTree.map((node) => ({
        ...node,
        details: node.details.filter((detail) => selectedLvls.includes(detail.level)),
      }));
      return filterUnused(newArr);
    }
    return softSkillTree;
  }, [selectedLvls, softSkillTree, filterUnused]);

  const handleSearch = useCallback(() => {
    if (searchValue.trim() === '') return;

    const searchInTree = (tree) => {
      const matchingIds = new Set();
      const pathIds = new Set();

      const searchNode = (node, processedNodes, parentPath = []) => {
        if (processedNodes.has(node.itemId)) return;

        processedNodes.add(node.itemId);

        const nodeNameMatch = node.name?.toLowerCase().includes(searchValue.toLowerCase());

        const detailsMatch = node.details?.filter((detail) =>
          detail.name?.toLowerCase().includes(searchValue.toLowerCase()),
        );

        if (nodeNameMatch || (detailsMatch && detailsMatch.length > 0)) {
          if (nodeNameMatch) {
            pathIds.add(node.itemId);
          }
          if (detailsMatch?.length > 0) {
            detailsMatch.forEach((detail) => matchingIds.add(detail.itemId));
          }
          parentPath.forEach((id) => pathIds.add(id));
          pathIds.add(node.itemId);
        }

        if (node.children) {
          node.children.forEach((childId) => {
            const childNode = tree.find((n) => n.itemId === childId);
            if (childNode) {
              searchNode(childNode, processedNodes, [...parentPath, node.itemId]);
            }
          });
        }
      };

      const processedNodes = new Set();
      tree.forEach((node) => searchNode(node, processedNodes));
      return Array.from(pathIds);
    };

    const results = activeTab === 0 ? searchInTree(hardSkillTree) : searchInTree(softSkillTree);
    setFoundedSkills(results);
  }, [searchValue, activeTab, hardSkillTree, softSkillTree]);

  return {
    department,
    isLoading,
    activeTab,
    handleChange,
    growLevels,
    selectedLvls,
    setSelectedLvls,
    foundedSkills,
    handleSearch,
    setSearchValue,
    softSkillTree,
    hardSkillTree,
    setHardSkillTree,
    setSoftSkillTree,
    filteredHardTree,
    filteredSoftTree,
    initialTreeDataHard,
    initialTreeDataSoft,
    setInitialTreeDataHard,
    setInitialTreeDataSoft,
  };
};

export default useGrow;
