import AdminApplicationsPage from '../../pages/Admin/Applications';
import AdminDashboardPage from '../../pages/Admin/Dashboard';
import AdminDepartmentsPage from '../../pages/Admin/Departments';
import AdminNewsLinks from '../../pages/Admin/News';
import AdminReviews from '../../pages/Admin/Reviews';
import AdminSkillsPage from '../../pages/Admin/Skills';
import AdminUsersPage from '../../pages/Admin/Users';

const adminRoutes = [
  {
    path: '/admin/departments',
    title: 'Departments',
    component: ({ crumbs }) => <AdminDepartmentsPage crumbs={crumbs} title="Departments" />,
  },
  {
    path: '/admin/users',
    title: 'Users',
    component: ({ crumbs }) => <AdminUsersPage crumbs={crumbs} title="Users" />,
  },
  {
    path: '/admin/applications',
    title: 'Applications',
    component: ({ crumbs }) => <AdminApplicationsPage crumbs={crumbs} title="Applications" />,
  },
  {
    path: '/admin/rss',
    title: 'rss',
    component: ({ crumbs }) => <AdminNewsLinks crumbs={crumbs} title="News" />,
  },
  {
    path: '/admin/reviews',
    title: 'reviews',
    component: ({ crumbs }) => <AdminReviews crumbs={crumbs} title="Reviews" />,
  },
  {
    path: '/admin/skills',
    title: 'Skills',
    component: ({ crumbs }) => <AdminSkillsPage crumbs={crumbs} title="Skills" />,
  },
  {
    path: '/admin/dashboard',
    title: 'Dashboard',
    component: ({ crumbs }) => <AdminDashboardPage crumbs={crumbs} title="Dashboard" />,
  },
];

export default adminRoutes;
