/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useContext } from 'react';
import './hexagon.css';
import PropTypes from 'prop-types';
import { ResponsiveHoneycomb } from 'react-honeycomb';

import userRoles from '../../../constants/userRoles';
import UserContext from '../../../hooks/context/UserContext';
import HexItem from './HexItem';

export default function HexGrid({
  isAdminPage,
  isUserPage,
  isHeadPage,
  setAnchorEl,
  setItemForAction,
  skills,
  isHeadUserSkill,
  size,
  isInvisibleBadge,
}) {
  const { user } = useContext(UserContext);
  const adminUser = user?.roles.includes(userRoles.ADMIN);
  const headUser = user?.roles.includes(userRoles.HEAD);

  return (
    <ResponsiveHoneycomb
      size={size}
      items={skills}
      renderItem={(item) => (
        <HexItem
          anchorEl
          item={item}
          isAdminPage={isAdminPage}
          isUserPage={isUserPage}
          isHeadPage={isHeadPage}
          setAnchorEl={setAnchorEl}
          setItemForAction={setItemForAction}
          adminUser={adminUser}
          headUser={headUser}
          isHeadUserSkill={isHeadUserSkill}
          isInvisibleBadge={isInvisibleBadge}
        />
      )}
    />
  );
}

HexGrid.propTypes = {
  isAdminPage: PropTypes.bool,
  isUserPage: PropTypes.bool,
  isHeadPage: PropTypes.bool,
  isHeadUserSkill: PropTypes.bool,
  setAnchorEl: PropTypes.func.isRequired,
  setItemForAction: PropTypes.func.isRequired,
  skills: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  size: PropTypes.number,
  isInvisibleBadge: PropTypes.bool,
};

HexGrid.defaultProps = {
  isAdminPage: false,
  isUserPage: false,
  isHeadPage: false,
  isHeadUserSkill: false,
  size: 43,
  isInvisibleBadge: false,
};
