import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Typography } from 'antd';

export default class LinkViewsErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch() {
    this.setState();
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Grid item container>
          <Typography>Something went wrong. {error.message}</Typography>
        </Grid>
      );
    }

    return children;
  }
}

LinkViewsErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
