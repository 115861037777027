import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, Box, styled, Snackbar } from '@mui/material';
import _ from 'lodash';
import Loading from '../../components/Loading';
import GoalsConstants from '../../constants/goals.constants';
import ReviewConstants from '../../constants/review.constants';
import UserContext from '../../hooks/context/UserContext';
import ApiService from '../../services/api.service';
import axios from '../../services/http.service';
import SocketService from '../../services/socket.service';
import BasicPage from '../../templates/BasicPage';
import { checkIsEverySubSkillInProgress } from '../Head/helpers/reviews.helpers';
import AllSkillsTable from './components/AllSkillsTable';
import Congratulation from './components/Congratulation';
import UserSkillsTable from './components/UserSkillsTable';

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(2)} 0`,
}));

const CustomLoadingButton = styled(LoadingButton)`
  &&& {
    &.Mui-disabled {
      background-color: rgba(117, 83, 234, 1);
    }
  }
`;

export default function Home({ title }) {
  const [progress, setProgress] = useState(0);
  const [isReviewRequested, setIsReviewRequested] = useState(null);
  const [hardSkills, setHardSkills] = useState([]);
  const [softSkills, setSoftSkills] = useState([]);
  const [personalSkills, setPersonalSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLastLevel, setIsLastLevel] = useState(false);
  const [isEverythingInProgress, setIsEverythingInProgress] = useState(false);
  const [congratulate, setCongratulate] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  const [isLoadingBtn, setLoadingBtn] = useState(false);

  const { user, socketInstance, setSocketInstance } = useContext(UserContext);

  const handleOnRequestReviewClick = async () => {
    setLoadingBtn(true);

    const result = await ApiService.developer.requestReview();

    if (result.status) {
      setLoadingBtn(false);
      setIsReviewRequested(true);
    }
  };

  const handleLinkClick = useCallback(
    async (payload) => {
      if (!user) {
        return;
      }

      await axios({
        method: 'POST',
        url: 'users-history/task-link-clicked',
        data: {
          taskId: payload.taskId,
          link: payload.link,
        },
      });
    },
    [user],
  );

  const checkReview = useCallback(
    async (goalProgress) => {
      const data = await ApiService.developer.getRequestedReview();

      if (data) {
        setReviewData(data);
        setIsReviewRequested(true);
      } else {
        const confirmed = await ApiService.developer.getConfirmedReview();

        if (confirmed) {
          const foundNonCongratulated = confirmed.some((v) => !v.isCongratulated);

          if (foundNonCongratulated) {
            await ApiService.developer.setAllConfirmedAsCongratulated();
            setCongratulate(foundNonCongratulated);
          }
        }
      }

      if (goalProgress < 100) {
        return;
      }

      const [me, levels] = await Promise.all([
        ApiService.developer.getMySelf(),
        ApiService.developer.getDepartmentLevels(user.departmentId),
      ]);

      setIsLastLevel(_.last(levels)._id === me.level);
    },
    [user.departmentId],
  );

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        await axios({
          method: 'GET',
          url: 'goals',
        }).then((data) => {
          setProgress(data.progress);
          setHardSkills(data?.hardSkills);
          setSoftSkills(data?.softSkills);
          setPersonalSkills(data?.personalSkills);
          setIsLoading(false);

          const isProgress = checkIsEverySubSkillInProgress([
            ...data.hardSkills,
            ...data.softSkills,
            ...data.personalSkills,
          ]);

          setIsEverythingInProgress(isProgress);

          return checkReview(data.progress);
        });
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    }

    fetchData();
  }, [checkReview]);

  useEffect(() => {
    setSocketInstance(SocketService.getSocketInstance('goals'));

    return () => {
      socketInstance.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSocketInstance]);

  useEffect(() => {
    const handler = async ({ goal, progress: actualProgress }) => {
      const { itemId, status, details, skillType, updatedStatusAt } = goal;

      setProgress(actualProgress);

      await checkReview(actualProgress);

      if (skillType === 'hard') {
        setHardSkills(
          hardSkills.map((item) =>
            item.itemId === itemId
              ? {
                  ...item,
                  details,
                  status,
                  updatedStatusAt,
                }
              : item,
          ),
        );
      }
      if (skillType === 'soft') {
        setSoftSkills(
          softSkills.map((item) =>
            item.itemId === itemId
              ? {
                  ...item,
                  details,
                  status,
                  updatedStatusAt,
                }
              : item,
          ),
        );
      }
      if (skillType === 'personal') {
        setPersonalSkills(
          personalSkills.map((item) =>
            item.itemId === itemId
              ? {
                  ...item,
                  details,
                  status,
                  updatedStatusAt,
                }
              : item,
          ),
        );
      }
    };
    socketInstance.on('receive-goal-status', handler);

    return () => socketInstance.off('receive-goal-status', handler);
  }, [hardSkills, softSkills, personalSkills, socketInstance, checkReview]);

  const goalsInProgress = useMemo(() => {
    const filteredHardSkills = hardSkills.filter(
      (goal) => goal.status === GoalsConstants.GOAL_STATUSES.IN_PROGRESS,
    );
    const filteredSoftSkills = softSkills.filter(
      (goal) => goal.status === GoalsConstants.GOAL_STATUSES.IN_PROGRESS,
    );
    const filteredPersonalSkills = personalSkills.filter(
      (goal) => goal.status === GoalsConstants.GOAL_STATUSES.IN_PROGRESS,
    );

    return [...filteredHardSkills, ...filteredSoftSkills, ...filteredPersonalSkills].sort(
      (a, b) => new Date(b.updatedStatusAt) - new Date(a.updatedStatusAt),
    );
  }, [hardSkills, softSkills, personalSkills]);

  const goalsDone = useMemo(() => {
    const filteredHardSkills = hardSkills.filter((goal) =>
      GoalsConstants.FILTERED_DONE_HARD_SKILL_STATUSES.includes(goal.status),
    );
    const filteredSoftSkills = softSkills.filter((goal) =>
      GoalsConstants.FILTERED_DONE_SOFT_SKILL_STATUSES.includes(goal.status),
    );
    const filteredPersonalSkills = personalSkills.filter((goal) =>
      GoalsConstants.FILTERED_DONE_PERSONAL_SKILL_STATUSES.includes(goal.status),
    );

    return [...filteredHardSkills, ...filteredSoftSkills, ...filteredPersonalSkills].sort(
      (a, b) => new Date(b.updatedStatusAt) - new Date(a.updatedStatusAt),
    );
  }, [hardSkills, softSkills, personalSkills]);

  const [hardSkillsOnHold, softSkillsOnHold, personalSkillsOnHold] = useMemo(() => {
    const filteredHardSkills = hardSkills.filter((goal) =>
      GoalsConstants.ON_HOLD_HARD_SKILLS_STATUSES.includes(goal.status),
    );
    const filteredSoftSkills = softSkills.filter((goal) =>
      GoalsConstants.ON_HOLD_SOFT_SKILLS_STATUSES.includes(goal.status),
    );
    const filteredPersonalSkills = personalSkills.filter((goal) =>
      GoalsConstants.ON_HOLD_PERSONAL_SKILLS_STATUSES.includes(goal.status),
    );

    return [filteredHardSkills, filteredSoftSkills, filteredPersonalSkills];
  }, [hardSkills, softSkills, personalSkills]);

  const snackBarMessage = useMemo(() => {
    if (!isReviewRequested) {
      if (progress >= 100) {
        if (!isLastLevel) {
          if (!isEverythingInProgress) {
            return ReviewConstants.MESSAGES.DO_YOU_WANT_TO_SEND_A_REVIEW_REQUEST;
          }
          return ReviewConstants.MESSAGES.ALL_SKILLS_ARE_APPROVED;
        }

        return ReviewConstants.MESSAGES.YOU_GOT_ALL_LEVELS;
      }
    }

    if (isReviewRequested) {
      return ReviewConstants.MESSAGES.REVIEW_IS_REQUESTED_SUCCESSFULLY;
    }

    return null;
  }, [isEverythingInProgress, isLastLevel, isReviewRequested, progress]);

  const snackbarAction = useMemo(() => {
    if (!isReviewRequested && progress >= 100 && !isLastLevel && !isEverythingInProgress) {
      return (
        <CustomLoadingButton
          onClick={handleOnRequestReviewClick}
          loading={isLoadingBtn}
          variant="contained"
        >
          SEND
        </CustomLoadingButton>
      );
    }

    return null;
  }, [isEverythingInProgress, isLastLevel, isReviewRequested, progress, isLoadingBtn]);

  return (
    <BasicPage title={title}>
      {isLoading && !_.isBoolean(isReviewRequested) ? (
        <Loading />
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <StyledGridContainer container>
            <Grid item md={6} sm={12} xs={12}>
              <UserSkillsTable
                goalsInProgress={goalsInProgress}
                goalsDone={goalsDone}
                isDisabled={isReviewRequested}
                onLinkClick={handleLinkClick}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <AllSkillsTable
                hardSkills={hardSkills}
                softSkills={softSkills}
                personalSkills={personalSkills}
                setHardSkills={setHardSkills}
                setSoftSkills={setSoftSkills}
                setPersonalSkills={setPersonalSkills}
                hardSkillsOnHold={hardSkillsOnHold}
                softSkillsOnHold={softSkillsOnHold}
                personalSkillsOnHold={personalSkillsOnHold}
                isDisabled={isReviewRequested}
                reviewData={reviewData}
              />
            </Grid>
            {!congratulate && snackBarMessage && (
              <Snackbar
                open
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                message={snackBarMessage}
                action={snackbarAction}
              />
            )}
          </StyledGridContainer>
          <Congratulation congratulate={congratulate} handleClose={() => setCongratulate(false)} />
        </Box>
      )}
    </BasicPage>
  );
}

Home.propTypes = {
  title: PropTypes.string.isRequired,
};
