import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
  Typography,
} from '@mui/material';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function SummaryDialog({ isOpened, declinedSummary, setSummaryOpen }) {
  const handleClose = () => {
    setSummaryOpen(false);
  };

  return (
    <Dialog
      hideBackdrop
      open={isOpened}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="dialog-congratulation"
    >
      <DialogTitle>Declined Summary Skills!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Typography style={{ whiteSpace: 'pre-line' }}>{declinedSummary}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

SummaryDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  declinedSummary: PropTypes.string.isRequired,
  setSummaryOpen: PropTypes.func.isRequired,
};
