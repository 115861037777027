import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  createTheme,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  ThemeProvider,
} from '@mui/material';
import reviewConstants from '../../../../constants/review.constants';
import HistoryTableRow from './HistoryTableRow';
import TableSkeleton from './TableSkeleton';

export const AvatarHeaderCell = styled(TableCell)({
  minWidth: 40,
  width: 50,
  maxWidth: 50,
});

const theme = createTheme({
  components: {
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          marginRight: '-1.1em',
          marginLeft: '0.1em',
        },
      },
    },
  },
});

const tableCells = [
  {
    id: 'email',
    name: 'Email',
    width: '10%',
    textAlign: 'left',
    minWidth: 100,
  },
  {
    id: 'department',
    name: 'Department',
    width: '15%',
    textAlign: 'left',
    minWidth: 100,
  },
  {
    id: 'reviewGoal',
    name: 'Review Goal',
    width: '5%',
    textAlign: 'left',
    minWidth: 100,
  },
  {
    id: 'reviewRecordLink',
    name: 'Review Record',
    width: '10%',
    textAlign: 'left',
    minWidth: 100,
  },
  {
    id: 'newGoal',
    name: 'New Goal',
    width: '5%',
    textAlign: 'left',
    minWidth: 100,
  },
  {
    id: 'status',
    name: 'Status',
    width: '5%',
    textAlign: 'left',
    minWidth: 100,
  },
  {
    id: 'confirmed',
    name: 'Confirmed',
    width: '5%',
    textAlign: 'right',
    minWidth: 100,
  },
  {
    id: 'declined',
    name: 'Declined',
    width: '5%',
    textAlign: 'right',
    minWidth: 100,
  },
  {
    id: 'progress',
    name: 'Progress',
    width: '15%',
    textAlign: 'center',
    minWidth: 200,
  },
  {
    id: 'updatedAt',
    name: 'Review Date',
    width: '15%',
    textAlign: 'left',
    minWidth: 100,
  },
];

export default function HistoryTable({
  isLoading,
  setIsLoading,
  reviewHistoryData,
  totalCount,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  setSort,
  fetchData,
  historySearchInput,
  historySort,
  template,
  skillStatistic,
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [errorSnackbarText, setErrorSnackbarText] = useState(null);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setIsLoading(true);
  };

  const handleChangeRowsPerPage = (event) => {
    const parsedPage = parseInt(event.target.value, 10);
    setRowsPerPage(parsedPage);
    setCurrentPage(0);
    setIsLoading(true);
  };

  useEffect(() => {
    if (orderBy) {
      setSort(order === 'asc' ? orderBy : `-${orderBy}`);
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getTableSortLabelDirection = (tableCell) => (orderBy === tableCell.id ? order : 'asc');

  const filteredReviewHistoryData = reviewHistoryData.filter(
    (reviewData) => reviewData.status !== reviewConstants.REVIEW_STATUSES.REVERTED,
  );

  const getTableCells = () => {
    return tableCells.map((tableCell) => (
      <TableCell
        key={tableCell.id}
        width={tableCell.width}
        sx={{ textAlign: tableCell.textAlign, minWidth: tableCell.minWidth }}
      >
        <TableSortLabel
          active={orderBy === tableCell.id}
          direction={getTableSortLabelDirection(tableCell)}
          onClick={() => handleSort(tableCell.id)}
        >
          {tableCell.name}
        </TableSortLabel>
      </TableCell>
    ));
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer>
        <Table size="small" aria-label="users table">
          <TableHead>
            <TableRow>
              <AvatarHeaderCell width="10%" sx={{ minWidth: 10 }} />
              {getTableCells()}
              <TableCell width="15%" sx={{ textAlign: 'left', minWidth: 100 }}>
                More
              </TableCell>
              <TableCell width="10%" sx={{ textAlign: 'left', minWidth: 100 }}>
                Revert
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? Array.from({ length: 10 }, (_, index) => <TableSkeleton key={index} />)
              : filteredReviewHistoryData.map((history) => (
                  <HistoryTableRow
                    key={history._id}
                    setIsLoading={setIsLoading}
                    currentPage={currentPage}
                    rowsPerPage={rowsPerPage}
                    {...history}
                    reviewHistoryData={reviewHistoryData}
                    fetchData={fetchData}
                    historySearchInput={historySearchInput}
                    historySort={historySort}
                    template={template}
                    skillStatistic={skillStatistic}
                    setErrorSnackbarText={setErrorSnackbarText}
                  />
                ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                colSpan={13}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Snackbar
        open={!!errorSnackbarText}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbarText(null)}
      >
        <Alert onClose={() => setErrorSnackbarText(null)} severity="error" sx={{ width: '100%' }}>
          {errorSnackbarText}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

HistoryTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  reviewHistoryData: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      declined: PropTypes.number.isRequired,
      confirmed: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      avatar: PropTypes.shape({}).isRequired,
      department: PropTypes.string.isRequired,
      departmentName: PropTypes.string.isRequired,
      reviewGoal: PropTypes.string.isRequired,
      newGoal: PropTypes.string,
      progress: PropTypes.number.isRequired,
      more: PropTypes.string,
    }),
  ).isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  setSort: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  historySearchInput: PropTypes.string.isRequired,
  historySort: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired,
  skillStatistic: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

HistoryTable.defaultProps = { setOpen: () => null, open: null };
