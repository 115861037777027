import React, { useState, useEffect } from 'react';
import { Paper, Alert, Snackbar, Grid, useMediaQuery, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import ApiService from '../../../services/api.service';
import GrowLvlFilter from '../components/GrowLvlFilter';
import ArchivedUserFilter from './components/ArchivedUserFilter';
import SearchBar from './components/SearchBar';
import UsersTable from './components/UsersTable';

const StyledItem = styled(Paper)(() => ({
  display: 'flex',
  marginBottom: '1em',
  padding: '2em 3em',
  borderRadius: '.15em',
  flexGrow: 1,
}));

const FiltersContainer = styled(Grid)({
  flex: '0 1 150px',
  marginTop: '48px',
});

export default function UsersPage() {
  const { department } = useParams();

  const [usersData, setUsersData] = useState([]);
  const [growLevels, setGrowLevels] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [email, setEmail] = useState();
  const [archived, setArchived] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [usersSort, setUsersSort] = useState('-level');
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchUsers = async (skip, limit, sort) => {
    try {
      const response = await ApiService.head.getDepartmentMembers(department, {
        filter: {
          levels: selectedLevels,
          email,
          archived,
        },
        skip: skip * limit,
        limit,
        sort,
      });

      setCurrentPage(skip);
      setUsersData(response.members);
      setTotalCount(response.total);
      setIsLoading(false);
    } catch {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchUsers(currentPage, rowsPerPage, usersSort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleInput = (event) => {
    if (!isLoading) {
      setEmail(event.target.value.trim());
      setCurrentPage(0);
      setIsLoading(true);
    }
  };

  const handleArchiveUser = (userId) => {
    ApiService.head
      .archiveUser(userId)
      .then(() => {
        setMessage('User archived successfully.');
        setIsLoading(true);
      })
      .catch(({ response }) => {
        setError(response.data.messages);
      });
  };

  const handleUnArchiveUser = (userId) => {
    ApiService.head
      .unArchiveUser(userId)
      .then(() => {
        setMessage('User unarchived successfully.');
        setIsLoading(true);
      })
      .catch(({ response }) => {
        setError(response.data.messages);
      });
  };

  useEffect(() => {
    ApiService.head.getGrowLevels(department).then((res) => {
      setGrowLevels(res.levels);
    });
  }, [department]);

  return (
    <>
      <Grid container>
        <Grid
          item
          width="98%"
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            gap: '1em',
          }}
        >
          <FiltersContainer
            xs={8}
            sx={{ mt: 8, maxWidth: { sm: 150 }, marginLeft: { xs: '60px', sm: '20px' } }}
          >
            <GrowLvlFilter
              growLevels={growLevels}
              selectedLvls={selectedLevels}
              setSelectedLvls={setSelectedLevels}
              isDisabled={isLoading}
              setIsDisabled={setIsLoading}
            />
            <ArchivedUserFilter
              archived={archived}
              setArchived={setArchived}
              isDisabled={isLoading}
              setIsDisabled={setIsLoading}
            />
          </FiltersContainer>
          <Grid
            item
            xs={8}
            sm={8}
            md={8}
            lg={10.5}
            sx={{ display: 'flex', flexDirection: 'column', marginLeft: { xs: '60px', sm: 0 } }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                height: 48,
                marginBottom: '1em',
              }}
            >
              <SearchBar onChange={handleInput} width="auto" />
            </Box>
            <StyledItem elevation={8} sx={{ flexGrow: 1 }}>
              <UsersTable
                usersData={usersData}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                onArchiveUser={handleArchiveUser}
                onUnArchiveUser={handleUnArchiveUser}
                growLevels={growLevels}
                setSort={setUsersSort}
                page={currentPage}
                setPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                totalCount={totalCount}
              />
            </StyledItem>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={!!message} autoHideDuration={6000} onClose={() => setMessage('')}>
        <Alert onClose={() => setMessage('')} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}
