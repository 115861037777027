const TextFileExtensions = {
  ADA: 'ada',
  ADB: 'adb',
  ADS: 'ads',
  APPLESCRIPT: 'applescript',
  AS: 'as',
  ASC: 'asc',
  ASCII: 'ascii',
  ASCX: 'ascx',
  ASM: 'asm',
  ASMX: 'asmx',
  ASP: 'asp',
  ASPX: 'aspx',
  ATOM: 'atom',
  AU3: 'au3',
  AWK: 'awk',
  BAS: 'bas',
  BASH: 'bash',
  BASHRC: 'bashrc',
  BAT: 'bat',
  BBCOLORS: 'bbcolors',
  BCP: 'bcp',
  BDSGROUP: 'bdsgroup',
  BDSPROJ: 'bdsproj',
  BIB: 'bib',
  BOWERRC: 'bowerrc',
  C: 'c',
  CBL: 'cbl',
  CC: 'cc',
  CFC: 'cfc',
  CFG: 'cfg',
  CFM: 'cfm',
  CFML: 'cfml',
  CGI: 'cgi',
  CJS: 'cjs',
  CLJ: 'clj',
  CLJS: 'cljs',
  CLS: 'cls',
  CMAKE: 'cmake',
  CMD: 'cmd',
  CNF: 'cnf',
  COB: 'cob',
  'CODE-SNIPPETS': 'code-snippets',
  COFFEE: 'coffee',
  COFFEEKUP: 'coffeekup',
  CONF: 'conf',
  CP: 'cp',
  CPP: 'cpp',
  CPT: 'cpt',
  CPY: 'cpy',
  CRT: 'crt',
  CS: 'cs',
  CSH: 'csh',
  CSON: 'cson',
  CSPROJ: 'csproj',
  CSR: 'csr',
  CSS: 'css',
  CSSLINTRC: 'csslintrc',
  CSV: 'csv',
  CTL: 'ctl',
  CURLRC: 'curlrc',
  CXX: 'cxx',
  D: 'd',
  DART: 'dart',
  DFM: 'dfm',
  DIFF: 'diff',
  DOF: 'dof',
  DPK: 'dpk',
  DPR: 'dpr',
  DPROJ: 'dproj',
  DTD: 'dtd',
  ECO: 'eco',
  EDITORCONFIG: 'editorconfig',
  EJS: 'ejs',
  EL: 'el',
  ELM: 'elm',
  EMACS: 'emacs',
  EML: 'eml',
  ENT: 'ent',
  ERB: 'erb',
  ERL: 'erl',
  ESLINTIGNORE: 'eslintignore',
  ESLINTRC: 'eslintrc',
  EX: 'ex',
  EXS: 'exs',
  F: 'f',
  F03: 'f03',
  F77: 'f77',
  F90: 'f90',
  F95: 'f95',
  FISH: 'fish',
  FOR: 'for',
  FPP: 'fpp',
  FRM: 'frm',
  FS: 'fs',
  FSPROJ: 'fsproj',
  FSX: 'fsx',
  FTN: 'ftn',
  GEMRC: 'gemrc',
  GEMSPEC: 'gemspec',
  GITATTRIBUTES: 'gitattributes',
  GITCONFIG: 'gitconfig',
  GITIGNORE: 'gitignore',
  GITKEEP: 'gitkeep',
  GITMODULES: 'gitmodules',
  GO: 'go',
  GPP: 'gpp',
  GRADLE: 'gradle',
  GRAPHQL: 'graphql',
  GROOVY: 'groovy',
  GROUPPROJ: 'groupproj',
  GRUNIT: 'grunit',
  GTMPL: 'gtmpl',
  GVIMRC: 'gvimrc',
  H: 'h',
  HAML: 'haml',
  HBS: 'hbs',
  HGIGNORE: 'hgignore',
  HH: 'hh',
  HPP: 'hpp',
  HRL: 'hrl',
  HS: 'hs',
  HTA: 'hta',
  HTACCESS: 'htaccess',
  HTC: 'htc',
  HTM: 'htm',
  HTML: 'html',
  HTPASSWD: 'htpasswd',
  HXX: 'hxx',
  ICED: 'iced',
  IML: 'iml',
  INC: 'inc',
  INF: 'inf',
  INFO: 'info',
  INI: 'ini',
  INO: 'ino',
  INT: 'int',
  IRBRC: 'irbrc',
  ITCL: 'itcl',
  ITERMCOLORS: 'itermcolors',
  ITK: 'itk',
  JADE: 'jade',
  JAVA: 'java',
  JHTM: 'jhtm',
  JHTML: 'jhtml',
  JS: 'js',
  JSCSRC: 'jscsrc',
  JSHINTIGNORE: 'jshintignore',
  JSHINTRC: 'jshintrc',
  JSON: 'json',
  JSON5: 'json5',
  JSONLD: 'jsonld',
  JSP: 'jsp',
  JSPX: 'jspx',
  JSX: 'jsx',
  KSH: 'ksh',
  LESS: 'less',
  LHS: 'lhs',
  LISP: 'lisp',
  LOG: 'log',
  LS: 'ls',
  LSP: 'lsp',
  LUA: 'lua',
  M: 'm',
  M4: 'm4',
  MAK: 'mak',
  MAP: 'map',
  MARKDOWN: 'markdown',
  MASTER: 'master',
  MD: 'md',
  MDOWN: 'mdown',
  MDWN: 'mdwn',
  MDX: 'mdx',
  METADATA: 'metadata',
  MHT: 'mht',
  MHTML: 'mhtml',
  MJS: 'mjs',
  MK: 'mk',
  MKD: 'mkd',
  MKDN: 'mkdn',
  MKDOWN: 'mkdown',
  ML: 'ml',
  MLI: 'mli',
  MM: 'mm',
  MXML: 'mxml',
  NFM: 'nfm',
  NFO: 'nfo',
  NOON: 'noon',
  NPMIGNORE: 'npmignore',
  NPMRC: 'npmrc',
  NUSPEC: 'nuspec',
  NVMRC: 'nvmrc',
  OPS: 'ops',
  PAS: 'pas',
  PASM: 'pasm',
  PATCH: 'patch',
  PBXPROJ: 'pbxproj',
  PCH: 'pch',
  PEM: 'pem',
  PG: 'pg',
  PHP: 'php',
  PHP3: 'php3',
  PHP4: 'php4',
  PHP5: 'php5',
  PHPT: 'phpt',
  PHTML: 'phtml',
  PIR: 'pir',
  PL: 'pl',
  PM: 'pm',
  PMC: 'pmc',
  POD: 'pod',
  POT: 'pot',
  PRETTIERRC: 'prettierrc',
  PROPERTIES: 'properties',
  PROPS: 'props',
  PT: 'pt',
  PUG: 'pug',
  PURS: 'purs',
  PY: 'py',
  PYX: 'pyx',
  R: 'r',
  RAKE: 'rake',
  RB: 'rb',
  RBW: 'rbw',
  RC: 'rc',
  RDOC: 'rdoc',
  RDOC_OPTIONS: 'rdoc_options',
  RESX: 'resx',
  REXX: 'rexx',
  RHTML: 'rhtml',
  RJS: 'rjs',
  RLIB: 'rlib',
  RON: 'ron',
  RS: 'rs',
  RSS: 'rss',
  RST: 'rst',
  RTF: 'rtf',
  RVMRC: 'rvmrc',
  RXML: 'rxml',
  S: 's',
  SASS: 'sass',
  SCALA: 'scala',
  SCM: 'scm',
  SCSS: 'scss',
  SEESTYLE: 'seestyle',
  SH: 'sh',
  SHTML: 'shtml',
  SLN: 'sln',
  SLS: 'sls',
  SPEC: 'spec',
  SQL: 'sql',
  SQLITE: 'sqlite',
  SQLPROJ: 'sqlproj',
  SRT: 'srt',
  SS: 'ss',
  SSS: 'sss',
  ST: 'st',
  STRINGS: 'strings',
  STY: 'sty',
  STYL: 'styl',
  STYLUS: 'stylus',
  SUB: 'sub',
  'SUBLIME-BUILD': 'sublime-build',
  'SUBLIME-COMMANDS': 'sublime-commands',
  'SUBLIME-COMPLETIONS': 'sublime-completions',
  'SUBLIME-KEYMAP': 'sublime-keymap',
  'SUBLIME-MACRO': 'sublime-macro',
  'SUBLIME-MENU': 'sublime-menu',
  'SUBLIME-PROJECT': 'sublime-project',
  'SUBLIME-SETTINGS': 'sublime-settings',
  'SUBLIME-WORKSPACE': 'sublime-workspace',
  SV: 'sv',
  SVC: 'svc',
  SVG: 'svg',
  SWIFT: 'swift',
  T: 't',
  TCL: 'tcl',
  TCSH: 'tcsh',
  TERMINAL: 'terminal',
  TEX: 'tex',
  TEXT: 'text',
  TEXTILE: 'textile',
  TG: 'tg',
  TK: 'tk',
  TMLANGUAGE: 'tmLanguage',
  TMPL: 'tmpl',
  TMTHEME: 'tmTheme',
  TPL: 'tpl',
  TS: 'ts',
  TSV: 'tsv',
  TSX: 'tsx',
  TT: 'tt',
  TT2: 'tt2',
  TTML: 'ttml',
  TWIG: 'twig',
  TXT: 'txt',
  V: 'v',
  VB: 'vb',
  VBPROJ: 'vbproj',
  VBS: 'vbs',
  VCPROJ: 'vcproj',
  VCXPROJ: 'vcxproj',
  VH: 'vh',
  VHD: 'vhd',
  VHDL: 'vhdl',
  VIM: 'vim',
  VIMINFO: 'viminfo',
  VIMRC: 'vimrc',
  VM: 'vm',
  VUE: 'vue',
  WEBAPP: 'webapp',
  WEBMANIFEST: 'webmanifest',
  WSC: 'wsc',
  'X-PHP': 'x-php',
  XAML: 'xaml',
  XHT: 'xht',
  XHTML: 'xhtml',
  XML: 'xml',
  XS: 'xs',
  XSD: 'xsd',
  XSL: 'xsl',
  XSLT: 'xslt',
  Y: 'y',
  YAML: 'yaml',
  YML: 'yml',
  ZSH: 'zsh',
  ZSHRC: 'zshrc',
};

export default TextFileExtensions;
