import '../../../../assets/css/avatar.css';
import React from 'react';
import PropTypes from 'prop-types';
import { BigHead } from '@bigheads/core';
import { Avatar, Box } from '@mui/material';

export default function UserAvatar({
  cardContainerSize,
  hydraAvatarWidth,
  hydraAvatarHeight,
  hydraMarginTop,
  growAvatarWidth,
  growAvatarHeight,
  hydraProfileLink,
  avatar,
}) {
  return (
    <Box
      className="user-avatar__card"
      style={{
        minHeight: `${cardContainerSize.toString()}px`,
        minWidth: `${cardContainerSize.toString()}px`,
      }}
    >
      {avatar && (
        <Box
          className="user-avatar__front"
          style={{
            width: `${growAvatarWidth.toString()}px`,
            height: `${growAvatarHeight.toString()}px`,
          }}
        >
          <BigHead {...avatar} style={{ position: 'relative', top: '-5px', width: '100%' }} />
        </Box>
      )}
      <Box className="user-avatar__back" style={{ marginTop: `${hydraMarginTop.toString()}px` }}>
        <Avatar
          src={hydraProfileLink}
          sx={{
            width: `${hydraAvatarWidth}px`,
            height: `${hydraAvatarHeight}px`,
          }}
        />
      </Box>
    </Box>
  );
}

UserAvatar.propTypes = {
  avatar: PropTypes.shape({}).isRequired,
  hydraProfileLink: PropTypes.string.isRequired,
  cardContainerSize: PropTypes.number.isRequired,
  hydraAvatarWidth: PropTypes.number.isRequired,
  hydraAvatarHeight: PropTypes.number.isRequired,
  hydraMarginTop: PropTypes.number.isRequired,
  growAvatarWidth: PropTypes.number.isRequired,
  growAvatarHeight: PropTypes.number.isRequired,
};
