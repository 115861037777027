import { Skeleton, styled, TableCell, TableRow } from '@mui/material';

const SkeletonAvatarTableCell = styled(TableCell)({
  padding: '16px',
  height: '57px',
});

export default function TableSkeleton() {
  return (
    <TableRow>
      <TableCell />
      <SkeletonAvatarTableCell align="center">
        <Skeleton width={40} height={40} variant="circular" />
      </SkeletonAvatarTableCell>
      <SkeletonAvatarTableCell align="center">
        <Skeleton width={40} height={40} variant="circular" />
      </SkeletonAvatarTableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell />
    </TableRow>
  );
}
