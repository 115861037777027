import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import apiService from '../../../../services/api.service';
import ReviewsTable from '../../Reviews/components/ReviewsTable';
import useDepartmentsData from '../hooks/useDepartmentsData';
import useEmployeesData from '../hooks/useEmployeesData';
import DepartmentsFilter from './DepartmentsFilter';
import EmployeesFilter from './EmployeesFilter';

export default function DashboardReviews({ startPickerValue, endPickerValue }) {
  const [reviewHistoryData, setReviewHistoryData] = useState([]);
  const [reviewsTableCount, setReviewsTableCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [historySort, setHistorySort] = useState('updatedAt');

  const { employeesOptions, pickedEmployees, fetchEmployees, setPickedEmployees } =
    useEmployeesData();
  const { departmentOptions, pickedDepartments, setPickedDepartments } = useDepartmentsData();

  const fetchReviewHistory = async () => {
    try {
      setIsLoading(true);

      const userIdsString = pickedEmployees.map((user) => user.id).join(',');
      const departmentIdsString = pickedDepartments.map((department) => department._id).join(',');
      const query = [
        `startDate=${startPickerValue.toISOString()}`,
        `endDate=${endPickerValue.toISOString()}`,
        `skip=${currentPage * rowsPerPage}`,
        `limit=${rowsPerPage}`,
        `sort=${historySort}`,
        `userIds=${userIdsString}`,
        `departmentIds=${departmentIdsString}`,
      ].join('&');

      const response = await apiService.admin.getAdminDashboardReviews(query);

      setReviewHistoryData(response.paginatedResult || []);
      setReviewsTableCount(response.totalCount || 0);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReviewHistory();
  }, [
    pickedEmployees,
    pickedDepartments,
    startPickerValue,
    endPickerValue,
    currentPage,
    rowsPerPage,
    historySort,
  ]);

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid
        container
        item
        spacing={2}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginBottom: '10px' }}
      >
        <Grid item xs={5} sm={5} md={4} lg={3} xl={3}>
          <DepartmentsFilter
            departmentOptions={departmentOptions}
            pickedDepartments={pickedDepartments}
            setPickedDepartments={setPickedDepartments}
          />
        </Grid>

        <Grid item xs={5} sm={5} md={4} lg={3} xl={3}>
          <EmployeesFilter
            employeesOptions={employeesOptions}
            pickedEmployees={pickedEmployees}
            fetchEmployees={fetchEmployees}
            setPickedEmployees={setPickedEmployees}
          />
        </Grid>
      </Grid>

      <ReviewsTable
        historySearchInput=""
        historySort={historySort}
        reviewHistoryData={reviewHistoryData}
        setCurrentPage={setCurrentPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={reviewsTableCount}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        setSort={setHistorySort}
        setIsLoading={setIsLoading}
        currentPage={currentPage}
      />
    </Grid>
  );
}

DashboardReviews.propTypes = {
  startPickerValue: PropTypes.instanceOf(dayjs).isRequired,
  endPickerValue: PropTypes.instanceOf(dayjs).isRequired,
};
