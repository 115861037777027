import React, { useState, useMemo } from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Tab, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';
import Loading from '../../../components/Loading';
import { ScrollableTabs } from '../../Home/styles/allSkillsTable.styled';
import GrowLvlFilter from '../components/GrowLvlFilter';
import DefaultTasksDialog from './components/DefaultTasksDialog';
import SkillsTreePreview from './components/SkillsTreePreview';
import TreeViewForm from './components/TreeViewForm';
import GrowPageContext from './contexts/GrowPageContext';
import useGrow from './hooks/useGrow';
import {
  HeaderWrapper,
  StyledFilterContainer,
  StyledSearchWrapper,
  StyledTabsWrapper,
} from './styled-components/Grow.styled';
import TreeViewFormWrapper from './styled-components/TreeViewFormWrapper';

export default function GrowPage() {
  const [saved, setSaved] = useState(false);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    isOpen: false,
    message: '',
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    department,
    isLoading,
    activeTab,
    handleChange,
    growLevels,
    selectedLvls,
    setSelectedLvls,
    foundedSkills,
    handleSearch,
    setSearchValue,
    softSkillTree,
    hardSkillTree,
    setHardSkillTree,
    setSoftSkillTree,
    filteredHardTree,
    filteredSoftTree,
    initialTreeDataHard,
    initialTreeDataSoft,
    setInitialTreeDataHard,
    setInitialTreeDataSoft,
  } = useGrow();

  const debouncedSearch = useDebouncedCallback(handleSearch, 500);

  const growPageProviderValue = useMemo(
    () => ({ department, growLevels, setIsDialogOpened }),
    [department, growLevels, setIsDialogOpened],
  );

  return (
    <GrowPageContext.Provider value={growPageProviderValue}>
      <Box sx={{ ml: { xs: 1, sm: 5, md: 26 }, mr: { xs: 1, sm: 5, md: 26 }, mt: 1 }}>
        <HeaderWrapper>
          <StyledTabsWrapper>
            <ScrollableTabs
              variant="scrollable"
              scrollButtons="auto"
              value={activeTab}
              onChange={handleChange}
              style={{ display: 'flex' }}
            >
              <Tab label="Hard Skills" />
              <Tab label="Soft Skills" />
              <Tab
                icon={<VisibilityIcon />}
                iconPosition="start"
                label="Preview"
                style={{ minHeight: '0px' }}
              />
            </ScrollableTabs>
          </StyledTabsWrapper>
          <StyledSearchWrapper container>
            <TextField
              id="input-with-icon-textfield"
              InputProps={{
                startAdornment: <SearchRoundedIcon />,
              }}
              variant="standard"
              onChange={(e) => {
                setSearchValue(e.target.value.trim());
                debouncedSearch();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
          </StyledSearchWrapper>
        </HeaderWrapper>
        <Box display="flex" width="100%">
          <Box flex="2 0 350px">
            <TreeViewFormWrapper
              sx={{ padding: activeTab === 2 ? 1 : '2em 3em' }}
              elevation={8}
              container="true"
            >
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  {activeTab === 0 && (
                    <TreeViewForm
                      type="hard"
                      treeData={hardSkillTree}
                      setTreeData={setHardSkillTree}
                      setInitialTreeData={setInitialTreeDataHard}
                      activeTab={activeTab}
                      initialTreeData={initialTreeDataHard}
                      saved={saved}
                      setSaved={setSaved}
                      errorMessage={errorMessage}
                      setErrorMessage={setErrorMessage}
                      foundedSkills={foundedSkills}
                    />
                  )}
                  {activeTab === 1 && (
                    <TreeViewForm
                      type="soft"
                      treeData={softSkillTree}
                      setTreeData={setSoftSkillTree}
                      setInitialTreeData={setInitialTreeDataSoft}
                      activeTab={activeTab}
                      initialTreeData={initialTreeDataSoft}
                      saved={saved}
                      setSaved={setSaved}
                      errorMessage={errorMessage}
                      setErrorMessage={setErrorMessage}
                      foundedSkills={foundedSkills}
                    />
                  )}
                  {activeTab === 2 && (
                    <StyledFilterContainer>
                      <GrowLvlFilter
                        growLevels={growLevels}
                        selectedLvls={selectedLvls}
                        setSelectedLvls={setSelectedLvls}
                      />
                      <SkillsTreePreview
                        hardSkillsData={filteredHardTree}
                        softSkillsData={filteredSoftTree}
                      />
                    </StyledFilterContainer>
                  )}
                </>
              )}
            </TreeViewFormWrapper>
          </Box>
        </Box>
      </Box>
      {isDialogOpened && (
        <DefaultTasksDialog
          isOpened={isDialogOpened}
          commonHardSkillTree={initialTreeDataHard}
          commonSoftSkillTree={initialTreeDataSoft}
          setCommonHardSkillTree={setInitialTreeDataHard}
          setCommonSoftSkillTree={setInitialTreeDataSoft}
          saved={saved}
          setSaved={setSaved}
          closeSnackbar={closeSnackbar}
          enqueueSnackbar={enqueueSnackbar}
          activeTab={activeTab}
        />
      )}
    </GrowPageContext.Provider>
  );
}
