import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Input, Paper, styled } from '@mui/material';
import apiService from '../../../services/api.service';
import AdminBasicPage from '../../../templates/AdminBasicPage';
import DepartmentsTable from './components/DepartmentsTable';

const StyledItem = styled(Paper)(() => ({
  display: 'flex',
  marginBottom: '1em',
  padding: '2em 3em',
  borderRadius: '.15em',
}));

export default function AdminDepartmentsPage({ crumbs, title }) {
  const [departmentsData, setDepartmentsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [departmentSearch, setDepartmentSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [departmentSort, setDepartmentSort] = useState('employeesCount');

  const fetchDepartments = async (skip, limit, search = '', sort = '') => {
    try {
      const response = await apiService.admin.getDepartments({
        params: {
          search,
          skip: skip * limit,
          limit,
          sort,
        },
      });

      setCurrentPage(skip);
      setDepartmentsData(response.paginatedResult);
      setTotalCount(response.totalCount);
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    if (!isLoading) {
      setDepartmentSearch(event.target.value);
      setCurrentPage(0);
      setIsLoading(true);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchDepartments(currentPage, rowsPerPage, departmentSearch, departmentSort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <AdminBasicPage crumbs={crumbs} title={title}>
      <Grid sx={{ margin: { md: '8px 36px 0', xs: '8px 12px 0' } }} item>
        <Grid container>
          <Grid sx={{ flex: '2 0 350px' }} item>
            <Grid container justifyContent="end" alignItems="center" height={48}>
              <Grid>
                <Input
                  value={departmentSearch}
                  sx={{ width: 240 }}
                  placeholder="Search"
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <StyledItem elevation={8} container="true" sx={{ display: 'grid' }}>
              <DepartmentsTable
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                departmentsData={departmentsData}
                totalCount={totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setSort={setDepartmentSort}
              />
            </StyledItem>
          </Grid>
        </Grid>
      </Grid>
    </AdminBasicPage>
  );
}

AdminDepartmentsPage.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};
