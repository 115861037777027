import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FileDownloadOutlined, InsertDriveFileOutlined } from '@mui/icons-material';
import { Dialog, DialogContent, DialogActions, Button, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ContentTypes from '../../../constants/content-types';
import FileRegexps from '../../../constants/file-regexps.constants';
import FileTypes from '../../../constants/file-types.constants';

export default function AdminConfirmSkillPopUp({
  open,
  setOpen,
  type,
  input,
  setIsOpenPreview,
  setFileContent,
  setFileContentType,
}) {
  const isFileImage = (fileName) => FileRegexps.IMG.test(fileName);

  const isReadableFile = (fileName) => FileRegexps.READABLE.test(fileName);

  const handleClose = () => {
    setOpen(false);
    setIsOpenPreview(false);
    setFileContent('');
  };

  useEffect(() => {
    if (isReadableFile(input)) {
      setOpen(false);
      fetch(input)
        .then((res) => res.blob())
        .then((res) => {
          const reader = new FileReader();

          reader.onload = (event) => {
            setFileContent(event.target.result);
            setFileContentType(FileTypes.file);
            setIsOpenPreview(true);
          };

          reader.readAsText(res);
        });
    } else if (isFileImage(input)) {
      setFileContent(input);
      setFileContentType(FileTypes.image);
      setOpen(false);
      setIsOpenPreview(true);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="dialog-congratulation"
      maxWidth={type === ContentTypes.file && /\.(jpg|jpeg|png|webp)$/.test(input) ? 'xl' : 'sm'}
    >
      {type === ContentTypes.comment && (
        <DialogTitle>
          <Typography style={{ wordWrap: 'break-word' }}>{input}</Typography>
        </DialogTitle>
      )}
      <DialogContent
        id="alert-dialog-slide-description"
        sx={{
          width: type === ContentTypes.comment ? '400px' : 'inherit',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '16px 8px 0 8px',
        }}
      >
        {type === ContentTypes.file && input && (
          <Button startIcon={<InsertDriveFileOutlined />} sx={{ overflow: 'hidden' }}>
            {input.split('/').slice(-1)}
          </Button>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Box>
          {type === ContentTypes.file && (
            <IconButton href={input}>
              <FileDownloadOutlined color="primary" />
            </IconButton>
          )}
        </Box>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

AdminConfirmSkillPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  type: PropTypes.string,
  input: PropTypes.string,
  setIsOpenPreview: PropTypes.func.isRequired,
  setFileContent: PropTypes.func.isRequired,
  setFileContentType: PropTypes.func.isRequired,
  uploadedItems: PropTypes.shape({}).isRequired,
};

AdminConfirmSkillPopUp.defaultProps = {
  type: null,
  input: null,
};
