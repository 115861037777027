import { Box, Button, DialogContent, styled, Typography } from '@mui/material';

export const StyledDialogBody = styled(DialogContent)(() => ({
  width: '100%',
  minHeight: '40px',
  height: '100%',
  padding: 0,
  backgroundColor: 'transparent',
  overflowY: 'auto',
  position: 'relative',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-button:vertical:increment, &::-webkit-scrollbar-button:vertical:decrement':
    {
      display: 'none',
    },
  scrollbarWidth: 'auto',
  scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
}));

export const StyledItemBox = styled(Box)(() => ({
  padding: '10px 15px',
  backgroundColor: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  border: '1px solid rgb(223, 226, 231)',
  borderTop: 'none',
}));

export const StyledTypography = styled(Typography)(({ color }) => ({
  margin: '4px 0',
  lineHeight: 1.5,
  color,
}));

export const StatusIndicator = styled(Box)(({ theme }) => ({
  width: 7,
  height: 7,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  position: 'absolute',
  left: 10,
  top: '20%',
  transform: 'translateY(-50%)',
}));

export const StyledModalBody = styled(Box)(() => ({
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  maxWidth: 600,
  minHeight: 300,
  maxHeight: 950,
  padding: '20px 32px',
  background: 'rgb(255, 255, 255)',
  boxShadow: 24,
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const StyledModalTypography = styled(Typography)(({ color }) => ({
  fontSize: '1.3rem',
  lineHeight: '1.5',
  fontWeight: 500,
  color,
}));

export const StyledValueTypography = styled(Typography)(() => ({
  marginBottom: '4px',
  fontSize: '1.3rem',
  lineHeight: '1.5',
  fontWeight: 400,
  marginLeft: '8px',
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

export const StyledButton = styled(Button)(({ color }) => ({
  mt: 2,
  mr: 2,
  m: 1,
  width: 100,
  size: 'medium',
  color,
}));

export const ScrollableDescription = styled(Box)(() => ({
  maxHeight: '115px',
  overflowY: 'auto',
  backgroundColor: '#f5f5f5',
  padding: '10px',
  borderRadius: '12px',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
  scrollbarWidth: 'thin',
}));

export const StyledValueBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'baseline',
  marginBottom: '4px',
}));
