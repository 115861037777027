import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import {
  Box,
  TextField,
  FormControlLabel,
  Tooltip,
  IconButton,
  styled,
  Autocomplete,
  Select,
  MenuItem,
  Typography,
  ThemeProvider,
  Radio,
} from '@mui/material';
import ContentTypes from '../../../../constants/content-types';
import materialTypes from '../../../../constants/materialTypes';
import axios from '../../../../services/http.service';
import StyledTextFieldContainer from '../styled-components/DetailsTextFieldContainer';
import StyledIconContainer from '../styled-components/IconContainer';
import CodeDetailsTheme from '../themes/CodeDetailsTheme';

const StyledRootContainer = styled(Box)(() => ({
  display: 'flex',
  margin: '1em 0 1em 1em',
  maxWidth: '55%',
}));

const CodeDetailsForm = React.memo(
  ({
    node,
    updateDetails,
    removeDetails,
    addNewDetails,
    isLastItem,
    growLevels,
    itemId,
    setIsDetailActive,
    isDetailActive,
    detailsAmount,
    setDetailsAmount,
  }) => {
    const [detailsNamesArray, setDetailsNamesArray] = useState([]);
    const handleLevelChange = ({ target }) => {
      updateDetails({
        ...node,
        level: target.value,
      });
    };

    useEffect(() => {
      try {
        axios({
          method: 'GET',
          url: `head/grow/${itemId}/details`,
        }).then((res) => {
          setDetailsNamesArray([...res]);
          setDetailsAmount(detailsAmount);
        });
      } catch (err) {
        console.error(err);
      }
    }, [itemId]);

    const onBlur = () => {
      setIsDetailActive(!isDetailActive);
    };

    const handleBlur = useCallback(
      (e) => {
        const { currentTarget } = e;

        requestAnimationFrame(() => {
          if (!currentTarget.contains(document.activeElement)) {
            onBlur();
          }
        });
      },
      [onBlur],
    );

    return (
      <ThemeProvider theme={CodeDetailsTheme}>
        <Box display="flex" width="80%" id={node.itemId}>
          <StyledRootContainer onBlur={handleBlur}>
            <StyledTextFieldContainer>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.email === value.email}
                disablePortal
                options={[...new Set(detailsNamesArray)]}
                value={node.name}
                onInputChange={(event, newValue, reason) => {
                  if (node.name !== newValue) {
                    updateDetails({
                      ...node,
                      name: reason === 'clear' ? '' : newValue,
                    });
                  }
                }}
                renderInput={(params) => <TextField {...params} placeholder="Name" />}
              />
              <TextField
                value={node.successCriteria}
                placeholder="Success Criteria"
                onChange={({ target }) =>
                  updateDetails({
                    ...node,
                    successCriteria: target.value,
                  })
                }
              />
              <TextField
                value={node.materialName}
                placeholder="Material Name"
                onChange={({ target }) =>
                  updateDetails({
                    ...node,
                    materialName: target.value,
                  })
                }
              />
              <Select
                displayEmpty
                value={node.materialType}
                onChange={({ target }) =>
                  updateDetails({
                    ...node,
                    materialType: target.value,
                  })
                }
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <Typography component="p" sx={{ opacity: '0.4 ' }}>
                        Material Type
                      </Typography>
                    );
                  }

                  return selected;
                }}
              >
                {Object.keys(materialTypes).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                value={node.link}
                placeholder="Link"
                onChange={({ target }) =>
                  updateDetails({
                    ...node,
                    link: target.value,
                  })
                }
              />

              <Select
                displayEmpty
                value={node?.contentType || ''}
                onChange={({ target }) =>
                  updateDetails({
                    ...node,
                    contentType: target.value,
                  })
                }
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <Typography component="p" sx={{ opacity: '0.4 ' }}>
                        Content Type
                      </Typography>
                    );
                  }

                  return selected;
                }}
              >
                <MenuItem value={null}>
                  <em>None</em>
                </MenuItem>
                {Object.keys(ContentTypes).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>

              <Box>
                {growLevels.map((level) => (
                  <FormControlLabel
                    key={level._id}
                    control={
                      <Radio
                        checked={node.level === level._id}
                        onChange={handleLevelChange}
                        value={level._id}
                      />
                    }
                    label={level.key}
                  />
                ))}
              </Box>
            </StyledTextFieldContainer>
          </StyledRootContainer>
          <StyledIconContainer>
            {detailsAmount !== 1 ? (
              <Tooltip title="Remove">
                <IconButton
                  onClick={() => {
                    setDetailsNamesArray(detailsNamesArray.filter((el) => el.name !== node.name));
                    setDetailsAmount(detailsAmount - 1);
                    removeDetails(node);
                  }}
                >
                  <RemoveCircleRoundedIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Box />
            )}

            {isLastItem && (
              <Tooltip title="Add">
                <IconButton
                  onClick={() => {
                    setDetailsNamesArray([...detailsNamesArray, '']);
                    setDetailsAmount(detailsAmount + 1);
                    addNewDetails();
                  }}
                >
                  <AddCircleRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </StyledIconContainer>
        </Box>
      </ThemeProvider>
    );
  },
);

export default CodeDetailsForm;

const nodeItem = {
  itemId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  successCriteria: PropTypes.string.isRequired,
  materialName: PropTypes.string.isRequired,
  materialType: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
};

CodeDetailsForm.propTypes = {
  node: PropTypes.shape(nodeItem).isRequired,
  updateDetails: PropTypes.func.isRequired,
  removeDetails: PropTypes.func.isRequired,
  addNewDetails: PropTypes.func.isRequired,
  isLastItem: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  growLevels: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isDetailActive: PropTypes.bool.isRequired,
  setIsDetailActive: PropTypes.func.isRequired,
  setDetailsAmount: PropTypes.func.isRequired,
  detailsAmount: PropTypes.number.isRequired,
};
