import React from 'react';
import PropTypes from 'prop-types';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { Modal, Box, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import {
  StyledButton,
  StyledButtonContainer,
  StyledModalBody,
  StyledModalTitle,
  StyledTextField,
} from '../ReviewRecordModal/ReviewRecordModal.styles';

export default function DeleteReviewModal({
  reasonValue,
  handleReasonInput,
  isOpen,
  onSave,
  onClose,
  onClear,
  isReasonSaveDisabled,
  isSaveLoading,
}) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <StyledModalBody sx={{ width: { xs: '90%', sm: '70%', md: '30%' } }}>
        <Box>
          <StyledModalTitle variant="h6" component="h4">
            Are you sure you want to delete review?
          </StyledModalTitle>
          <Box sx={{ width: '100%' }}>
            <StyledTextField
              multiline
              rows={4}
              value={reasonValue}
              variant="outlined"
              onChange={handleReasonInput}
              label="Reason"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onClear} size="small">
                      <CancelSharpIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: '100%', whiteSpace: 'pre-wrap' }}
            />
          </Box>
        </Box>
        <StyledButtonContainer>
          <StyledButton
            onClick={onSave}
            variant="contained"
            disabled={isReasonSaveDisabled || isSaveLoading}
          >
            {isSaveLoading ? <CircularProgress size={24} /> : 'Save'}
          </StyledButton>
        </StyledButtonContainer>
      </StyledModalBody>
    </Modal>
  );
}

DeleteReviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  reasonValue: PropTypes.string,
  handleReasonInput: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  isReasonSaveDisabled: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
};

DeleteReviewModal.defaultProps = {
  reasonValue: '',
  isReasonSaveDisabled: false,
  isSaveLoading: false,
};
