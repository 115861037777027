import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, styled } from '@mui/material';

const StyledInput = styled(Input)(({ theme }) => ({
  '.MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: theme.palette.text.primary,
    color: theme.palette.text.primary,
  },
  '&:after': { borderBottomWidth: 'thin' },
}));

export default function LevelTitleInput({
  width,
  value,
  onInput,
  isDefaultFocus,
  isLoading,
  isEditable,
  setIsEditable,
}) {
  const [isDefaultFocused, setIsDefaultFocused] = useState(isDefaultFocus);

  const handleOnBlur = () => {
    setIsEditable(false);
    setIsDefaultFocused(false);
  };

  return (
    <StyledInput
      sx={{ width }}
      onInput={onInput}
      onBlur={handleOnBlur}
      disableUnderline={!isEditable && !!value}
      disabled={!isDefaultFocused && (!isEditable || isLoading)}
      defaultValue={value}
      error={!value}
      inputRef={(input) => (isEditable || isDefaultFocused) && input && input.focus()}
    />
  );
}

LevelTitleInput.propTypes = {
  width: PropTypes.string.isRequired,
  value: PropTypes.string,
  onInput: PropTypes.func.isRequired,
  isDefaultFocus: PropTypes.bool,
  isLoading: PropTypes.bool,
  isEditable: PropTypes.bool,
  setIsEditable: PropTypes.func.isRequired,
};

LevelTitleInput.defaultProps = {
  value: '',
  isDefaultFocus: false,
  isLoading: false,
  isEditable: true,
};
