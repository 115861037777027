import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import ExportButton from '../ExportButton';
import LinkViewsTable from './LinkViewsTable/LinkViewsTable';
import useLinksViewsData from './hooks/useLinkViewsData';

export default function DashboardLinkViews({ startPickerValue, endPickerValue }) {
  const {
    linkViewsData,
    isLoading,
    totalCount,
    rowsPerPage,
    currentPage,
    order,
    orderBy,
    handleChangePagination,
    handleChangeOrder,
  } = useLinksViewsData(startPickerValue, endPickerValue);

  const exportUrl = new URL(
    '/api/v1/users-history/export/links-views',
    process.env.REACT_APP_API_URL,
  );
  exportUrl.searchParams.set('order', order === 'asc' ? 1 : -1);
  exportUrl.searchParams.set('orderBy', orderBy);
  exportUrl.searchParams.set('createdAt[gte]', startPickerValue.toISOString());
  exportUrl.searchParams.set('createdAt[lte]', endPickerValue.toISOString());

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flex: 1 }} />
        <ExportButton url={exportUrl} />
      </Box>

      <LinkViewsTable
        linkViewsData={linkViewsData}
        isLoading={isLoading}
        onPaginationChange={handleChangePagination}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        onChangeOrder={handleChangeOrder}
      />
    </>
  );
}

DashboardLinkViews.propTypes = {
  startPickerValue: PropTypes.instanceOf(dayjs).isRequired,
  endPickerValue: PropTypes.instanceOf(dayjs).isRequired,
};
