import React from 'react';
import PropTypes from 'prop-types';
import { InfoOutlined } from '@mui/icons-material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { Button, IconButton, Tooltip } from '@mui/material';
import ContentTypes from '../../../constants/content-types';

export default function AdminAttachmentButton({
  itemId,
  contentType,
  contentInput,
  emRefs,
  index,
  isOverflowing,
  onConfirmCriteriaClick,
}) {
  return (
    <>
      {contentType === ContentTypes.comment && (
        <>
          <Tooltip title="Comment">
            <IconButton sx={{ paddingTop: '5px' }}>
              <InfoOutlined fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
          <em
            ref={(el) => {
              // eslint-disable-next-line no-param-reassign
              emRefs.current[index] = el;
            }}
          >
            {isOverflowing.includes(index)
              ? contentInput.slice(
                  0,
                  (() => {
                    const endIndex = Math.floor(
                      (emRefs.current[index].parentElement.offsetWidth -
                        50 -
                        emRefs.current[index].parentElement.innerHTML.split('&nbsp;')[0].length *
                          8) /
                        8,
                    );

                    return endIndex < 0 ? 0 : endIndex;
                  })(),
                )
              : contentInput}
          </em>
          {isOverflowing.includes(index) && (
            <Button
              variant="text"
              sx={{ minWidth: '32px' }}
              onClick={(event) => {
                onConfirmCriteriaClick(event, { itemId, contentType, contentInput });
              }}
            >
              (...)
            </Button>
          )}
        </>
      )}
      {contentType === ContentTypes.file && (
        <Tooltip title="Attachment">
          <IconButton
            onClick={(event) =>
              onConfirmCriteriaClick(event, { itemId, contentType, contentInput })
            }
          >
            <AttachmentIcon fontSize="small" color={contentInput ? 'primary' : 'disabled'} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

AdminAttachmentButton.propTypes = {
  itemId: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  contentInput: PropTypes.string,
  emRefs: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.any).isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  isOverflowing: PropTypes.arrayOf(PropTypes.number).isRequired,
  onConfirmCriteriaClick: PropTypes.func.isRequired,
};

AdminAttachmentButton.defaultProps = {
  contentType: null,
  contentInput: null,
};
