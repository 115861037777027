import React from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Button, Chip, Grid, TableCell, TableRow } from '@mui/material';
import { BigHead } from '../../../../lib/core.cjs.development';

export default function ReviewsTableRow({ onReview, ...review }) {
  const { _id, goal, user, createdAt } = review;

  const preparedCreatedAt = new Date(createdAt);

  return (
    <TableRow key={_id}>
      <TableCell sx={{ padding: 0 }}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <BigHead {...user.avatar} />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left">{user.email}</TableCell>
      <TableCell align="left">
        <Grid container justifyContent="flex-start">
          <Grid>
            <Chip
              label={goal}
              color="warning"
              size="small"
              variant="outlined"
              icon={<ArrowUpwardIcon />}
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left">{preparedCreatedAt.toDateString()}</TableCell>
      <TableCell align="left">
        <Button
          onClick={() => onReview(review)}
          size="small"
          variant="contained"
          endIcon={<ArrowForwardIcon />}
        >
          Review
        </Button>
      </TableCell>
    </TableRow>
  );
}

ReviewsTableRow.propTypes = {
  _id: PropTypes.string.isRequired,
  goal: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    avatar: PropTypes.shape({}).isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  onReview: PropTypes.func.isRequired,
};
