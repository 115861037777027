import { useState } from 'react';

export default function useTableOrder(initialOrder, initialOrderBy) {
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);

  const handleChangeOrder = (newOrder) => {
    if (newOrder.order) {
      setOrder(newOrder.order);
    }

    if (newOrder.orderBy) {
      setOrderBy(newOrder.orderBy);
    }
  };

  return { order, orderBy, handleChangeOrder };
}
