import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const path1 = `M40.4773 8.08791V16.2886C40.4773 18.0947 
40.0075 19.4371 39.0678 20.3158C38.1282 21.1944 36.7553 
21.6337 34.9492 21.6337C33.9973 21.6337 33.0943 21.5178 
32.24 21.2859C31.3858 21.0541 30.678 20.7185 30.1166 
20.2791L31.2515 18.229C31.6664 18.5707 32.1912 18.8391
32.8258 19.0344C33.4604 19.2418 34.0949 19.3456 34.7295 
19.3456C35.718 19.3456 36.4441 19.1198 36.9078 18.6683C37.3838 
18.229 37.6217 17.5578 37.6217 16.6547V16.2337C36.8773 17.0513 
35.84 17.4601 34.5098 17.4601C33.6068 17.4601 32.777 17.2649 
32.0203 16.8744C31.2759 16.4717 30.6841 15.9103 30.2447 15.1903C29.8054
14.4703 29.5858 13.6405 29.5858 12.7008C29.5858 11.7611 29.8054 10.9313
30.2447 10.2113C30.6841 9.49131 31.2759 8.93605 32.0203 8.54554C32.777 
8.14283 33.6068 7.94147 34.5098 7.94147C35.9377 7.94147 37.0238 8.4113 
37.7682 9.35097V8.08791H40.4773ZM35.0773 15.1171C35.8339 15.1171 36.4502
14.8974 36.9261 14.4581C37.4143 14.0066 37.6583 13.4208 37.6583 12.7008C37.6583 
11.9808 37.4143 11.4011 36.9261 10.9618C36.4502 10.5103 35.8339 10.2845 35.0773 
10.2845C34.3207 10.2845 33.6983 10.5103 33.2102 10.9618C32.722 11.4011 32.478 
11.9808 32.478 12.7008C32.478 13.4208 32.722 14.0066 33.2102 14.4581C33.6983 
14.8974 34.3207 15.1171 35.0773 15.1171ZM45.8626 9.38758C46.2043 8.91164 
46.6619 8.55164 47.2355 8.30757C47.8213 8.06351 48.4925 7.94147 49.2491 
7.94147V10.5774C48.9318 10.553 48.7182 10.5408 48.6084 10.5408C47.7908 
10.5408 47.1501 10.7727 46.6863 11.2364C46.2226 11.6879 45.9908 12.3713
45.9908 13.2866V17.9361H43.1351V8.08791H45.8626V9.38758ZM55.5437 
18.0825C54.5065 18.0825 53.5729 17.869 52.7431 17.4418C51.9254
17.0025 51.2848 16.3984 50.821 15.6296C50.3573 14.8608 50.1254
13.9883 50.1254 13.012C50.1254 12.0357 50.3573 11.1632 50.821
10.3944C51.2848 9.62554 51.9254 9.02758 52.7431 8.60046C53.5729
8.16113 54.5065 7.94147 55.5437 7.94147C56.581 7.94147 57.5085 
8.16113 58.3261 8.60046C59.1438 9.02758 59.7844 9.62554 60.2482 
10.3944C60.7119 11.1632 60.9438 12.0357 60.9438 13.012C60.9438 13.9883
60.7119 14.8608 60.2482 15.6296C59.7844 16.3984 59.1438 17.0025 58.3261
17.4418C57.5085 17.869 56.581 18.0825 55.5437 18.0825ZM55.5437 15.7395C56.276
15.7395 56.8739 15.4954 57.3377 15.0073C57.8136 14.5069 58.0516 13.8418 
58.0516 13.012C58.0516 12.1822 57.8136 11.5232 57.3377 11.035C56.8739 
10.5347 56.276 10.2845 55.5437 10.2845C54.8115 10.2845 54.2075 10.5347
53.7315 11.035C53.2556 11.5232 53.0176 12.1822 53.0176 13.012C53.0176
13.8418 53.2556 14.5069 53.7315 15.0073C54.2075 15.4954 54.8115 15.7395
55.5437 15.7395ZM78.4302 8.08791V13.5245C78.4302 14.9767 78.0336 16.0995
77.2403 16.8927C76.4471 17.6859 75.3183 18.0825 73.8539 18.0825C73.1461 
18.0825 72.5176 17.9727 71.9685 17.753C71.4315 17.5334 70.98 17.1795 
70.6139 16.6913C70.2478 17.1795 69.7963 17.5334 69.2593 17.753C68.7224 
17.9727 68.0878 18.0825 67.3556 18.0825C65.879 18.0825 64.7501 17.6859 
63.9691 16.8927C63.1881 16.0995 62.7976 14.9767 62.7976 
13.5245V8.08791H65.6532V13.3049C65.6532 14.1225 65.7935 14.7266 66.0742 
15.1171C66.3549 15.4954 66.7942 15.6845 67.3922 15.6845C68.0024 15.6845 
68.4539 15.4893 68.7468 15.0988C69.0396 14.7083 69.1861 14.1103 69.1861 
13.3049V8.08791H72.0417V13.3049C72.0417 14.1103 72.1881 14.7083 72.481 
15.0988C72.7739 15.4893 73.2254 15.6845 73.8356 15.6845C74.4336 15.6845 
74.8729 15.4954 75.1536 15.1171C75.4342 14.7266 75.5746 14.1225 75.5746 
13.3049V8.08791H78.4302Z`;

const path2 = `M11.6295 23.8225C18.0522 23.8225 23.2589 18.6158 23.2589 
12.193C23.2589 5.77023 18.0522 0.563538 11.6295 0.563538C5.20669 0.563538 
0 5.77023 0 12.193C0 16.3176 2.14723 19.9407 5.38484 22.0054C5.52785 
21.3122 5.68574 20.6261 5.78128 20.3672C5.82501 20.2509 5.84253 20.1713 
5.72363 20.0782C3.7189 18.4985 3.66829 16.2137 4.44493 14.1564C5.3976 
11.6332 7.21926 9.92523 9.52214 8.64777C11.7524 7.41025 14.1302 6.56545 
16.5973 5.95101C16.6271 5.94619 16.6571 5.94381 16.6872 5.94388C16.7409 
5.99607 16.7172 6.04151 16.6953 6.0837C16.6918 6.09031 16.6884 6.09683 
16.6854 6.10328C15.6153 8.34371 15.1965 10.7324 15.0462 13.1869C15.0249 
13.5343 15.0088 13.8822 14.9927 14.23L14.9927 14.2301C14.9563 15.0145 
14.9199 15.7989 14.8251 16.5793C14.4629 19.5899 11.9487 21.66 8.93072 
21.3654C8.2287 21.2971 8.21626 21.2955 8.29817 20.5863C8.65716 17.3816 
9.92999 14.1057 11.5952 11.3393C11.6452 11.2568 11.6909 11.1718 11.7367 
11.0868L11.7367 11.0868C11.755 11.0528 11.7733 11.0188 11.7919 10.9849C11.7961 
10.9777 11.8019 10.969 11.8076 10.9604C11.8199 10.9418 11.8321 10.9234 11.8283 
10.9201C11.7933 10.8832 11.765 10.9087 11.7361 10.9349L11.7327 10.9379C10.8901 
11.6681 10.2295 12.5478 9.67626 13.5041C8.30758 15.8677 7.14342 19.8089 6.76161 
22.7577C8.24246 23.4412 9.89144 23.8225 11.6295 23.8225Z`;

export default function GrowLogo({ width, height }) {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 80 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d={path1} fill="white" />
        <path d={path2} fill="white" />
      </svg>
    </Box>
  );
}

GrowLogo.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
