const GOAL_STATUSES = {
  ON_HOLD: 'ON_HOLD',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
};

export default Object.freeze({
  GOAL_STATUSES,

  USER_SKILLS_DETAILS_MENU_ALLOW_ROLES: [GOAL_STATUSES.DONE, GOAL_STATUSES.IN_PROGRESS],

  ON_HOLD_HARD_SKILLS_STATUSES: [GOAL_STATUSES.ON_HOLD, GOAL_STATUSES.DECLINED],
  ON_HOLD_SOFT_SKILLS_STATUSES: [GOAL_STATUSES.ON_HOLD, GOAL_STATUSES.DECLINED],
  ON_HOLD_PERSONAL_SKILLS_STATUSES: [GOAL_STATUSES.ON_HOLD, GOAL_STATUSES.DECLINED],

  FILTERED_DONE_HARD_SKILL_STATUSES: [GOAL_STATUSES.DONE, GOAL_STATUSES.APPROVED],
  FILTERED_DONE_SOFT_SKILL_STATUSES: [GOAL_STATUSES.DONE, GOAL_STATUSES.APPROVED],
  FILTERED_DONE_PERSONAL_SKILL_STATUSES: [GOAL_STATUSES.DONE, GOAL_STATUSES.APPROVED],

  MESSAGES: {
    NO_SKILLS_ADDED_YET: 'No skills added yet',
  },

  APPROVED_PERCENTAGE: 95,
});
