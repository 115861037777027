import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { AvatarHeaderCell } from '../../Head/ReviewHistory/components/HistoryTable';
import SkillsTableRow from './SkillsTableRow';
import TableSkeleton from './TableSkeleton';

export default function SkillsTable({
  isLoading,
  users,
  currentPage,
  rowsPerPage,
  totalCount,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="5%" key="emptyStart" sx={{ minWidth: 50 }} />
            <AvatarHeaderCell key="avatar" width="5%" sx={{ minWidth: 10 }} />
            <AvatarHeaderCell width="5%" key="department" sx={{ textAlign: 'left', minWidth: 100 }}>
              Department
            </AvatarHeaderCell>
            <TableCell key="email" width="25%" sx={{ textAlign: 'center', minWidth: 100 }}>
              Email
            </TableCell>
            <TableCell
              key="level"
              width="10%"
              sx={{
                textAlign: 'center',
                minWidth: 100,
              }}
            >
              Current Level
            </TableCell>
            <TableCell key="skills" width="40%" sx={{ textAlign: 'center', minWidth: 100 }}>
              Skills
            </TableCell>
            <TableCell key="expand" width="5%" sx={{ textAlign: 'left', minWidth: 50 }} />
            <TableCell width="5%" key="emptyEnd" />
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? Array.from({ length: 10 }, (_, index) => <TableSkeleton key={index} />)
            : users.map((user) => <SkillsTableRow key={user._id} {...user} />)}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={13}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

SkillsTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};
