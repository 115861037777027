import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useDebouncedHandler from '../../../../hooks/useDebouncedHandler';
import apiService from '../../../../services/api.service';

const useEmployeesData = () => {
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [pickedEmployees, setPickedEmployees] = useState([]);
  const { department } = useParams();

  const fetchEmployees = async (event) => {
    const search = event.target.value;
    const departments = await apiService.developer.getDepartments();
    const headDepartment = departments.find((el) => el.name === department).name;

    if (search.length < 3) {
      return null;
    }

    const result = await apiService.head.getDepartmentMembers(headDepartment, {
      filter: { email: search },
      skip: 0,
      limit: 10,
    });

    const newEmployeesArr = [
      ...pickedEmployees,
      ...result.members.map((item) => ({
        id: item._id,
        email: item.email,
      })),
    ].filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

    setEmployeesOptions(newEmployeesArr);

    return null;
  };

  const { debouncedHandler } = useDebouncedHandler(fetchEmployees);

  return {
    employeesOptions,
    pickedEmployees,
    fetchEmployees: debouncedHandler,
    setPickedEmployees,
  };
};

export default useEmployeesData;
