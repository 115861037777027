import styled from '@emotion/styled';
import { Box } from '@mui/material';

const TabsWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export default TabsWrapper;
