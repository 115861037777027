import { useState } from 'react';

export default function useTablePagination(
  initialPage = 0,
  initialRowsPerPage = 10,
  initialTotalCount = 0,
) {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [totalCount, setTotalCount] = useState(initialTotalCount);

  const handleChangePagination = (newPagination) => {
    if (newPagination.currentPage !== undefined) {
      setCurrentPage(+newPagination.currentPage);
    }

    if (newPagination.rowsPerPage !== undefined) {
      setRowsPerPage(+newPagination.rowsPerPage);
      setCurrentPage(0);
    }

    if (newPagination.totalCount !== undefined) {
      setTotalCount(+newPagination.totalCount);
    }
  };

  return {
    currentPage,
    rowsPerPage,
    totalCount,
    handleChangePagination,
  };
}
