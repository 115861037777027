import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import pages from '../constants/adminPages';
import HeadBasicPage from './HeadBasicPage';

export default function HeadPageTemplate({ crumbs, title, component: Component }) {
  const history = useHistory();
  const { department } = useParams();
  const { adminSubPages, defaultTab } = pages;

  useEffect(() => {
    if (!adminSubPages.includes(department)) {
      history.push(`/head/${defaultTab}`);
    }
  }, [adminSubPages, defaultTab, department, history]);

  return (
    <HeadBasicPage crumbs={crumbs} title={title}>
      <Component />
    </HeadBasicPage>
  );
}

HeadPageTemplate.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  component: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
