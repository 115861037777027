import PropTypes from 'prop-types';
import { CheckCircleRounded } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { Box, IconButton, Tooltip, TextField } from '@mui/material';
import PersonalTaskDetail from './PersonalTaskDetail';

export default function PersonalTask({
  task,
  handleTaskNameChange,
  handleRemoveTask,
  handleRemoveDetail,
  handleAddDetail,
  handleDetailChange,
  handleToggleTask,
  detailsAmountMap,
}) {
  return (
    <Box key={task.itemId} sx={{ position: 'relative' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '130%' }}>
          <Tooltip title="">
            <IconButton onClick={() => handleToggleTask(task.itemId)}>
              {task.isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </IconButton>
          </Tooltip>
          <TextField
            fullWidth
            placeholder="Name"
            size="small"
            sx={{}}
            value={task.name}
            onChange={(e) => handleTaskNameChange(task.itemId, e.target.value)}
            disabled={task.status === 'Approved'}
            variant="outlined"
            margin="normal"
          />
          <Tooltip title="Remove">
            <span>
              {task.status !== 'Approved' && (
                <IconButton onClick={() => handleRemoveTask(task.itemId)}>
                  <RemoveCircleRoundedIcon />
                </IconButton>
              )}
            </span>
          </Tooltip>
          {task.status === 'Approved' && (
            <IconButton>
              <CheckCircleRounded
                color="success"
                fontSize="medium"
                sx={{ verticalAlign: 'middle' }}
              />
            </IconButton>
          )}
        </Box>
        {task.isOpen && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {task.details.map((detail) => (
              <PersonalTaskDetail
                key={detail.itemId}
                task={task}
                detail={detail}
                handleDetailChange={handleDetailChange}
                handleRemoveDetail={handleRemoveDetail}
                handleAddDetail={handleAddDetail}
                detailsAmountMap={detailsAmountMap}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

PersonalTask.propTypes = {
  task: PropTypes.shape({
    itemId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        itemId: PropTypes.string.isRequired,
        successCriteria: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
        materialType: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        level: PropTypes.string.isRequired,
      }),
    ).isRequired,
    status: PropTypes.string,
  }).isRequired,
  handleTaskNameChange: PropTypes.func.isRequired,
  handleRemoveTask: PropTypes.func.isRequired,
  handleRemoveDetail: PropTypes.func.isRequired,
  handleAddDetail: PropTypes.func.isRequired,
  handleDetailChange: PropTypes.func.isRequired,
  handleToggleTask: PropTypes.func.isRequired,
  detailsAmountMap: PropTypes.objectOf(PropTypes.number.isRequired).isRequired,
};
