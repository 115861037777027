import React, { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  styled,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Link,
  Input,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import TokenService from '../../../../services/token.service';
import GrowPageContext from '../contexts/GrowPageContext';

const StyledButtonContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export default function TreeViewFooter({
  addTreeNodeHandler,
  saveHandler,
  type,
  disabled,
  sendJson,
  disabledSave,
  disabledImportCvs,
  disabledExportCsv,
  disabledExportJson,
  sendCsv,
  removed,
  added,
  updated,
  initialTreeData,
  clearData,
  setTreeData,
  setAdded,
  setAlertOpen,
  validateFields,
  treeSkills,
}) {
  const { setIsDialogOpened } = useContext(GrowPageContext);

  const [openMenu, setOpenMenu] = useState(false);
  const [saveAnchorMenu, setSaveAnchorMenu] = React.useState(null);
  const [show, setShow] = useState(false);
  const [detailsNames, setDetailsNames] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [fileInputNone, setFileInput] = useState();
  const [disabledSaveButton, setDisabledSave] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const menuRef = useRef(null);

  const fileInputRef = useRef(null);

  const { department } = useParams();

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    if (added.length > 0 || removed.length > 0 || updated.length > 0 || refresh)
      window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [added, removed, updated]);

  const openSaveMenu = Boolean(saveAnchorMenu);

  const removedTasksRecursion = (deletedSkills) => {
    const detailNames = [];

    const getDetailsNames = (removedTask) => {
      removedTask.forEach((task) => {
        if (task.children.length > 0) {
          const curTaskName = initialTreeData.find((taskName) => taskName.name === task.name);
          const allChildren = initialTreeData.filter((treeData) =>
            curTaskName.children.includes(treeData.itemId),
          );

          getDetailsNames(allChildren);
        }

        detailNames.push(...task.details.map((detail) => detail.name));
      });
    };

    getDetailsNames(deletedSkills);

    setDetailsNames([...detailsNames, ...detailNames]);
  };

  const handleOnClickSave = (event) => {
    setRefresh(true);
    event.stopPropagation();

    const isError = validateFields(treeSkills);

    if (isError) {
      setAlertOpen({ open: true, message: 'All fields are required!' });
      setDisabledSave(true);
    } else {
      setDisabledSave(false);
    }

    setShow(false);

    setSaveAnchorMenu(event.currentTarget);

    removedTasksRecursion(removed);
  };

  const handleMenuSave = () => {
    setShow(false);
    saveHandler();
    clearData();
    setDetailsNames([]);

    if (fileInputNone) {
      fileInputRef.current.value = null;
    }

    setRefresh(false);
    setShow(true);
  };

  const handleMenuDecline = () => {
    setShow(false);
    setTreeData(initialTreeData);
    clearData();
    setDetailsNames([]);

    if (fileInputRef.current.value) {
      setAdded([]);
      fileInputRef.current.value = null;
    }

    fileInputRef.current.value = null;

    setRefresh(false);
    setShow(true);
  };

  const handleOnCloseSave = () => {
    setSaveAnchorMenu(false);

    setDetailsNames([]);
  };

  const handleToggleMenu = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = (event) => {
    if (menuRef.current && menuRef.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };

  const handleCsvExport = () => setOpenMenu(false);

  const handleJsonExport = () => setOpenMenu(false);

  const handleCsvImportClick = () => {
    setOpenMenu(false);
    fileInputRef.current.click();
    setFileInput(false);
  };

  const handleJsonImportClick = () => {
    setOpenMenu(false);
    fileInputRef.current.click();
    setFileInput(false);
  };

  const handleCsvImport = ({ target }) => {
    sendCsv(target.files[0]);
    setFileInput(true);
  };

  const handleJsonImport = ({ target }) => {
    sendJson(target.files[0]);
    setFileInput(true);
  };

  const getDownloadCsvLink = () => {
    const accessToken = TokenService.getLocalAccessToken();
    return `${process.env.REACT_APP_API_URL}/api/v1/head/grow/${department}/csv/${type}?accessToken=${accessToken}`;
  };

  const getDownloadJsonLink = () => {
    const accessToken = TokenService.getLocalAccessToken();
    return `${process.env.REACT_APP_API_URL}/api/v1/head/grow/${department}/json/${type}?accessToken=${accessToken}`;
  };

  const handleImportCsvOrJson = ({ target }) => {
    const targetValue = target.value;

    const splitTargetValue = targetValue.split('.');

    const lastValue = splitTargetValue[splitTargetValue.length - 1];

    switch (true) {
      case lastValue === 'csv':
        return handleCsvImport({ target });
      case lastValue === 'json':
        return handleJsonImport({ target });
      default:
        return enqueueSnackbar(`Invalid format`, { variant: 'error' });
    }
  };

  const shortedText = () => {
    const duplicates = {};

    detailsNames.forEach((detail) => {
      duplicates[detail] = (duplicates[detail] || 0) + 1;
    });

    const duplicateArray = [];

    const duplicateKeys = Object.keys(duplicates);

    duplicateKeys.forEach((duplicate) => {
      duplicateArray.push(`${duplicate}: ${duplicates[duplicate]}`);
    });

    return duplicateArray.join('\n').length >= 400
      ? `${duplicateArray.join('\n').slice(0, 400)}...`
      : duplicateArray.join('\n');
  };

  return (
    <StyledButtonContainer>
      <Input
        type="file"
        onChange={handleImportCsvOrJson}
        inputRef={fileInputRef}
        sx={{ display: 'none' }}
      />

      <Tooltip title="Add child">
        <IconButton onClick={addTreeNodeHandler}>
          <AddCircleRoundedIcon />
        </IconButton>
      </Tooltip>
      <ButtonGroup variant="contained" ref={menuRef} aria-label="split button">
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleOnClickSave}
          disabled={disabledSave}
          id="save-basic-menu"
          aria-controls={openSaveMenu ? 'save-basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openSaveMenu ? 'true' : undefined}
        >
          Save
        </Button>
        {!show && (
          <Dialog id="save-basic-menu" open={openSaveMenu} onClose={handleOnCloseSave}>
            <DialogTitle id="alert-dialog-title">Are you sure you want to</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
              <DialogContentText id="alert-dialog-description">
                {added.length > 0 && (
                  <Typography component="span" display="flex">
                    Added:
                    <span style={{ paddingLeft: '10px', paddingRight: '5px' }}>
                      {added.length}
                    </span>{' '}
                    <AddCircleOutlineIcon color="success" viewBox="0 0 28 24" />
                  </Typography>
                )}
                {updated.length > 0 && (
                  <Typography component="span" display="flex">
                    Changed:{' '}
                    <span style={{ paddingLeft: '10px', paddingRight: '5px' }}>
                      {updated.length}
                    </span>{' '}
                    <ChangeCircleOutlinedIcon color="primary" viewBox="0 0 28 24" />
                  </Typography>
                )}
                {removed.length > 0 && (
                  <Tooltip
                    title={<span style={{ whiteSpace: 'pre-line' }}>{shortedText()}</span>}
                    placement="bottom-start"
                  >
                    <Typography component="span" display="flex" sx={{ cursor: 'pointer' }}>
                      Deleted:{' '}
                      <span style={{ paddingLeft: '10px', paddingRight: '5px' }}>
                        {detailsNames.length}
                      </span>{' '}
                      <HighlightOffIcon color="warning" viewBox="0 0 28 24" />
                    </Typography>
                  </Tooltip>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ paddingRight: '15px' }}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleMenuDecline}
                style={{
                  borderColor: 'transparent',
                  outline: 0,
                  color: 'red',
                }}
              >
                Decline
              </Button>
              <Button onClick={handleMenuSave} disabled={disabledSaveButton}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <Button onClick={handleToggleMenu} size="small">
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={openMenu}
        anchorEl={menuRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <Tooltip title="Export available only after save" placement="right">
                    <MenuItem onClick={handleCsvExport} disabled={disabledExportCsv}>
                      <Link href={getDownloadCsvLink()} underline="none" color="inherit">
                        Export CSV
                      </Link>
                    </MenuItem>
                  </Tooltip>
                  <Tooltip title="Import available only after save" placement="right">
                    <MenuItem onClick={handleCsvImportClick} disabled={disabledImportCvs}>
                      Import CSV
                    </MenuItem>
                  </Tooltip>
                  <MenuItem
                    onClick={handleJsonExport}
                    disabled={disabledExportJson}
                    variant="contained"
                  >
                    <Link href={getDownloadJsonLink()} underline="none" color="inherit">
                      Export JSON
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleJsonImportClick} disabled={disabled} variant="contained">
                    Import JSON
                  </MenuItem>
                  <MenuItem
                    onClick={() => setIsDialogOpened(true)}
                    disabled={disabled}
                    variant="contained"
                  >
                    Import tasks
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </StyledButtonContainer>
  );
}

TreeViewFooter.propTypes = {
  sendJson: PropTypes.func.isRequired,
  addTreeNodeHandler: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  sendCsv: PropTypes.func.isRequired,
  disabledSave: PropTypes.bool,
  disabledImportCvs: PropTypes.bool,
  disabledExportCsv: PropTypes.bool,
  disabledExportJson: PropTypes.bool,
  disabled: PropTypes.bool,
  removed: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  added: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updated: PropTypes.arrayOf(PropTypes.string).isRequired,
  treeSkills: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      name: PropTypes.string,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          itemId: PropTypes.string,
          successCriteria: PropTypes.string,
          materialName: PropTypes.string,
          materialType: PropTypes.string,
          link: PropTypes.string,
          level: PropTypes.string,
        }),
      ),
      children: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
  initialTreeData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  clearData: PropTypes.func.isRequired,
  setTreeData: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  setAdded: PropTypes.func.isRequired,
  setAlertOpen: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
};

TreeViewFooter.defaultProps = {
  disabledSave: false,
  disabledImportCvs: false,
  disabledExportCsv: false,
  disabledExportJson: false,
  disabled: false,
};
