import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ContentCopy, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';

const StyledBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 24,
  padding: '0.5em 1em',
  borderRadius: '.15em',
  marginTop: '1em',
  backgroundColor: 'rgba(117, 83, 234, 0.1)',
}));

export default function ApiKeyCreatedBox({ apiKey }) {
  const [isKeyShown, setIsKeyShown] = useState(false);

  return (
    <StyledBox>
      <Typography fontSize="small" fontWeight={600}>
        New Access Api Key created
      </Typography>
      <FormControl sx={{ mt: 1, mb: 1 }} variant="outlined">
        <OutlinedInput
          id="outlined-adornment-password"
          type={isKeyShown ? 'text' : 'password'}
          value={apiKey}
          size="small"
          disabled
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label="toggle api key visibility"
                onClick={() => {
                  setIsKeyShown(!isKeyShown);
                }}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {isKeyShown ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <IconButton
                size="small"
                aria-label="copy api key"
                onClick={() => {
                  navigator.clipboard.writeText(apiKey);
                }}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                <ContentCopy />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Typography fontSize="small">
        Make sure you save it - you won&apos;t be able to access it again.{' '}
      </Typography>
    </StyledBox>
  );
}

ApiKeyCreatedBox.propTypes = {
  apiKey: PropTypes.string.isRequired,
};
