import { useEffect, useState } from 'react';
import { format, formatDistanceToNow } from 'date-fns';
import NotificationsStatusEnum from '../../pages/Home/enums/notifications-status.enum';
import apiService from '../../services/api.service';

const useNotificationItem = (notification, onStatusChange) => {
  const [user, setUser] = useState({ firstName: '', lastName: '' });
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const findUserData = async () => {
      try {
        const foundedUser = await apiService.head.getUser(notification.userId);
        setUser(foundedUser);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };

    findUserData();
  }, [notification.userId]);

  const onOpenModal = async () => {
    await apiService.head.updateNotification(notification._id, NotificationsStatusEnum.OPENED);
    onStatusChange(notification._id, NotificationsStatusEnum.OPENED);
    setModalOpen(true);
  };

  const onCloseModal = async (status) => {
    if (status) {
      await apiService.head.updateNotification(notification._id, status);
      onStatusChange(notification._id, status);
      setModalOpen(false);
    }
    setModalOpen(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onOpenModal();
    }
  };

  const formatDate = (date) => {
    const now = new Date();
    const createdAt = new Date(date);
    const diff = now - createdAt;

    if (diff < 24 * 60 * 60 * 1000) {
      return formatDistanceToNow(createdAt, { addSuffix: true });
    }
    if (diff < 7 * 24 * 60 * 60 * 1000) {
      return formatDistanceToNow(createdAt, { addSuffix: true });
    }
    if (diff < 30 * 24 * 60 * 60 * 1000) {
      return formatDistanceToNow(createdAt, { addSuffix: true });
    }
    return format(createdAt, 'dd MMM yyyy');
  };

  return {
    user,
    isModalOpen,
    onOpenModal,
    onCloseModal,
    handleKeyPress,
    formatDate,
  };
};

export default useNotificationItem;
