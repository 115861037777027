import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Chip, createTheme, Tab, ThemeProvider } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  ScrollableTabs,
  StyledBox,
  StyledItem,
  StyledTabLabelBox,
} from '../styles/allSkillsTable.styled';
import SkillsTreeView from './SkillsTreeView';

export const componentTheme = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          maxHeight: '20px',
          marginLeft: '5px',
          cursor: 'pointer',
          background: grey[100],
        },
        label: { padding: '3px 8px' },
      },
    },
  },
  palette: {
    primary: { main: '#7553EA' },
  },
});

export default function AllSkillsTable({
  hardSkills,
  softSkills,
  personalSkills,
  setHardSkills,
  setSoftSkills,
  setPersonalSkills,
  hardSkillsOnHold,
  softSkillsOnHold,
  personalSkillsOnHold,
  isDisabled,
  reviewData,
}) {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const hardSkillDetailsOnHold = useMemo(() => {
    const skillNamesSet = new Set();
    hardSkillsOnHold.forEach((skill) => {
      skill.details.forEach((detail) => {
        if (detail.status !== 'APPROVED') {
          skillNamesSet.add(detail.name);
        }
      });
    });
    return Array.from(skillNamesSet);
  }, [hardSkillsOnHold]);

  const softSkillDetailsOnHold = useMemo(() => {
    const skillNamesSet = new Set();
    softSkillsOnHold.forEach((skill) => {
      skill.details.forEach((detail) => {
        if (detail.status !== 'APPROVED') {
          skillNamesSet.add(detail.name);
        }
      });
    });
    return Array.from(skillNamesSet);
  }, [softSkillsOnHold]);

  const personalSkillDetailsOnHold = useMemo(() => {
    const skillNamesSet = new Set();
    personalSkillsOnHold.forEach((skill) => {
      skill.details.forEach((detail) => {
        if (detail.status !== 'APPROVED') {
          skillNamesSet.add(detail.name);
        }
      });
    });
    return Array.from(skillNamesSet);
  }, [personalSkillsOnHold]);

  return (
    <ThemeProvider theme={componentTheme}>
      <StyledBox>
        <ScrollableTabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={
              <StyledTabLabelBox>
                Hard Skills
                <Chip label={hardSkillDetailsOnHold.length} />
              </StyledTabLabelBox>
            }
          />
          <Tab
            label={
              <StyledTabLabelBox>
                Soft Skills
                <Chip label={softSkillDetailsOnHold.length} />
              </StyledTabLabelBox>
            }
          />
          <Tab
            label={
              <StyledTabLabelBox>
                Personal Skills
                <Chip label={personalSkillDetailsOnHold.length} />
              </StyledTabLabelBox>
            }
          />
        </ScrollableTabs>
        <StyledItem elevation={8}>
          <>
            {activeTab === 0 && (
              <SkillsTreeView
                skillsData={hardSkills}
                updateTreeData={setHardSkills}
                isDisabled={isDisabled}
                reviewData={reviewData}
              />
            )}
            {activeTab === 1 && (
              <SkillsTreeView
                skillsData={softSkills}
                updateTreeData={setSoftSkills}
                isDisabled={isDisabled}
                reviewData={reviewData}
              />
            )}
            {activeTab === 2 && (
              <SkillsTreeView
                skillsData={personalSkills}
                updateTreeData={setPersonalSkills}
                isDisabled={isDisabled}
                reviewData={reviewData}
              />
            )}
          </>
        </StyledItem>
      </StyledBox>
    </ThemeProvider>
  );
}

const SkillItem = {
  itemId: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.string).isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      name: PropTypes.string,
      successCriteria: PropTypes.string,
      materialName: PropTypes.string,
      materialType: PropTypes.string,
      link: PropTypes.string,
      level: PropTypes.string,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  skillType: PropTypes.string.isRequired,
  status: PropTypes.string,
};

AllSkillsTable.propTypes = {
  hardSkills: PropTypes.arrayOf(PropTypes.shape(SkillItem)).isRequired,
  softSkills: PropTypes.arrayOf(PropTypes.shape(SkillItem)).isRequired,
  personalSkills: PropTypes.arrayOf(PropTypes.shape(SkillItem)).isRequired,
  setHardSkills: PropTypes.func.isRequired,
  setSoftSkills: PropTypes.func.isRequired,
  setPersonalSkills: PropTypes.func.isRequired,
  hardSkillsOnHold: PropTypes.arrayOf(PropTypes.shape(SkillItem)).isRequired,
  softSkillsOnHold: PropTypes.arrayOf(PropTypes.shape(SkillItem)).isRequired,
  personalSkillsOnHold: PropTypes.arrayOf(PropTypes.shape(SkillItem)).isRequired,
  isDisabled: PropTypes.bool,
  reviewData: PropTypes.shape({}),
};

AllSkillsTable.defaultProps = { isDisabled: false, reviewData: {} };
