import React from 'react';
import PropTypes from 'prop-types';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import {
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
} from '@mui/lab';
import { Typography } from '@mui/material';

export default function ActualLevel({ title, levelKey }) {
  return (
    <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: 'auto 0' }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        You are here
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary">
          <RocketLaunchRoundedIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent
        sx={{
          py: '12px',
          px: 2,
        }}
      >
        <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
          {levelKey}
        </Typography>
        <Typography
          sx={{
            fontWeight: 'bold',
            whiteSpace: {
              xs: 'normal',
              md: 'nowrap',
            },
          }}
        >
          {title}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

ActualLevel.propTypes = {
  title: PropTypes.string.isRequired,
  levelKey: PropTypes.string.isRequired,
};
