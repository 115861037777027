import { Box, Paper, styled, Tabs } from '@mui/material';

export const StyledItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  borderRadius: '.15em',
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  margin: `0 ${theme.spacing(2)} ${theme.spacing(3)}`,
}));

export const StyledTabLabelBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const ScrollableTabs = styled(Tabs)({
  overflowX: 'auto',
  WebkitOverflowScrolling: 'touch',
  '&::-webkit-scrollbar': {
    height: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
});
