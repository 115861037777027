import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Modal } from '@mui/material';
import MaterialTypeIcon from '../../pages/Home/components/MaterialTypeIcon';
import NotificationsStatusEnum from '../../pages/Home/enums/notifications-status.enum';
import NotificationsTypeEnum from '../../pages/Home/enums/notifications-type.enum';
import {
  ButtonContainer,
  ScrollableDescription,
  StyledButton,
  StyledModalBody,
  StyledModalTypography,
  StyledValueBox,
  StyledValueTypography,
} from '../styles/Notification.styled';

export default function NotificationModal({ isOpen, onClose, notification, user }) {
  const linkRefs = useRef({});

  const onSetStatus = async (status) => {
    if (status === NotificationsStatusEnum.FIXED) {
      onClose(NotificationsStatusEnum.FIXED);
    } else {
      onClose(NotificationsStatusEnum.DISMISSED);
    }
  };

  const handleClose = async (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <StyledModalBody>
        <Box>
          <Box display="flex" justifyContent="center" mb={2}>
            <StyledModalTypography
              sx={{ fontWeight: 600, fontSize: '2rem' }}
              color={notification.type !== NotificationsTypeEnum.REPORT ? 'inherit' : '#F53535'}
            >
              {notification.type}
            </StyledModalTypography>
          </Box>
          <StyledValueBox>
            <StyledModalTypography>Sended by</StyledModalTypography>
            <StyledValueTypography>
              {user.firstName} {user.lastName}
            </StyledValueTypography>
          </StyledValueBox>
          <StyledValueBox>
            <StyledModalTypography>Skill name</StyledModalTypography>
            <StyledValueTypography>{notification.details.parentName}</StyledValueTypography>
          </StyledValueBox>
          <StyledValueBox>
            <StyledModalTypography>Detail name</StyledModalTypography>
            <StyledValueTypography>{notification.details.detailName}</StyledValueTypography>
          </StyledValueBox>
          <StyledValueBox>
            <StyledModalTypography>Materials</StyledModalTypography>
          </StyledValueBox>
          <StyledValueBox>
            <List sx={{ paddingLeft: '8px' }}>
              {notification.details.materialIds.map((materialId, index) => (
                <ListItem
                  key={materialId}
                  sx={{ padding: '0', display: 'flex', alignItems: 'center' }}
                >
                  <ListItemIcon sx={{ minWidth: 0, fontSize: '2rem' }}>
                    <MaterialTypeIcon
                      type={notification.details.materialTypes[index] || 'default'}
                      sx={{ fontSize: '2rem' }}
                    />
                  </ListItemIcon>
                  {notification.details.links && notification.details.links[index] ? (
                    <Link
                      ref={(el) => {
                        linkRefs.current[notification.details.links[index]] = el;
                      }}
                      href={notification.details.links[index]}
                      target="_blank"
                      rel="noopener"
                      sx={{ marginLeft: '8px', fontSize: '1.2rem' }}
                    >
                      <ListItemText
                        primary={notification.details.materialNames[index]}
                        primaryTypographyProps={{ sx: { fontSize: '1.2rem' } }}
                      />
                    </Link>
                  ) : (
                    <ListItemText primary={notification.details.materialNames[index]} />
                  )}
                </ListItem>
              ))}
            </List>
          </StyledValueBox>
          <StyledValueBox mb={1}>
            <StyledModalTypography>Description</StyledModalTypography>
          </StyledValueBox>
          <ScrollableDescription>
            <StyledValueTypography>{notification.details.description}</StyledValueTypography>
          </ScrollableDescription>
        </Box>
        <Grid item xs={12} md="auto">
          <ButtonContainer>
            <StyledButton
              onClick={() => onSetStatus(NotificationsStatusEnum.FIXED)}
              variant="contained"
              color="success"
            >
              Fixed
            </StyledButton>
            <StyledButton
              onClick={() => onSetStatus(NotificationsStatusEnum.DISMISSED)}
              variant="contained"
              color="error"
            >
              Dismissed
            </StyledButton>
          </ButtonContainer>
        </Grid>
      </StyledModalBody>
    </Modal>
  );
}

NotificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  notification: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    headIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    details: PropTypes.shape({
      materialIds: PropTypes.arrayOf(PropTypes.string).isRequired,
      parentId: PropTypes.string.isRequired,
      parentName: PropTypes.string.isRequired,
      detailName: PropTypes.string.isRequired,
      materialNames: PropTypes.arrayOf(PropTypes.string).isRequired,
      materialTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      links: PropTypes.arrayOf(PropTypes.string).isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
  }).isRequired,
};
