import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, Link, TableCell, TableRow, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import StepLoader from '../../../../components/stepper/StepLoader';
import axios from '../../../../services/http.service';
import RowOperations from './RowOperations';

export default function NewsLinksTableRow({
  isLoading,
  setIsLoading,
  linksData,
  setSuccessSnackbar,
  setInfoSnackbar,
  setWarningSnackbar,
}) {
  const [isEditable, setIsEditable] = useState(false);
  const [isInputError] = useState(false);
  const [link, setLink] = useState(linksData.rssLink);
  const [editable, setEditable] = useState(false);
  const [fetchedDepartments, setFetchedDepartments] = useState([]);

  const [selectedDepartments, setSelectedDepartments] = useState([]);

  useEffect(async () => {
    setSelectedDepartments(linksData.departmentIds);
    setLink(linksData.rssLink);
    await axios
      .get('/departments')
      .then((res) => {
        setFetchedDepartments(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleChange = (event) => {
    setSelectedDepartments(event.target.value);
    return selectedDepartments;
  };
  return (
    <TableRow>
      <TableCell>
        {!isEditable ? (
          <Link href={linksData.rssLink} target="_blank" rel="noopener">
            <Chip
              fontSize="small"
              label={linksData.rssLink}
              key={linksData._id}
              size="small"
              sx={{ mb: 0.5 }}
              onClick={() => {
                console.log('The link was clicked.', linksData.rssLink);
                // return false;
                // window.open(linksData.rssLink, '_blank');
              }}
            />
          </Link>
        ) : (
          <TextField
            label="Link"
            id="outlined-basic"
            variant="outlined"
            value={link}
            size="small"
            margin="dense"
            error={isInputError}
            sx={{
              width: '20vw',
              minWidth: '196px',
            }}
            onInput={(e) => {
              setLink(e.target.value);
            }}
            onChange={(event) => setLink(event.target.value)}
          />
        )}
      </TableCell>
      <TableCell sx={{ pb: 0.5 }}>
        {!isEditable ? (
          linksData.department.map((department) => (
            <Chip
              fontSize="small"
              label={department.title}
              key={department._id}
              size="small"
              sx={{
                mb: 0.5,
                mr: 0.3,
              }}
            />
          ))
        ) : (
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="department-select-label">Department</InputLabel>
            <Select
              sx={{ height: '36px' }}
              labelId="department-select-label"
              id="department-select"
              value={selectedDepartments}
              defaultValue={linksData.departmentIds}
              label="Departments"
              onChange={handleChange}
              multiple
            >
              {fetchedDepartments.map((department) => (
                <MenuItem key={department._id} value={department._id}>
                  {department.title}
                </MenuItem>
              ))}
            </Select>
            <StepLoader isHidden={false} />
          </FormControl>
        )}
      </TableCell>
      <TableCell align="center">
        <RowOperations
          editable={editable}
          linksData={linksData}
          link={link}
          departmentIds={selectedDepartments}
          setSuccessSnackbar={setSuccessSnackbar}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setLink={setLink}
          setIsEditable={setIsEditable}
          onEditClick={() => setEditable(true)}
          isEditable={isEditable}
          isInputError={isInputError}
          setInfoSnackbar={setInfoSnackbar}
          setWarningSnackbar={setWarningSnackbar}
        />
      </TableCell>
    </TableRow>
  );
}

const linkInfo = {
  _id: PropTypes.string.isRequired,
  rssLink: PropTypes.string.isRequired,
  department: PropTypes.arrayOf(PropTypes.object).isRequired,
};

NewsLinksTableRow.propTypes = {
  setWarningSnackbar: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  linksData: PropTypes.shape(linkInfo).isRequired,
  setSuccessSnackbar: PropTypes.func.isRequired,
  setInfoSnackbar: PropTypes.func.isRequired,
};
