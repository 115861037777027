import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { Box, Button, IconButton, Menu, MenuItem, Modal, Typography } from '@mui/material';
import apiService from '../../../../services/api.service';

const StyledModalBody = styled(Box)(() => ({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  padding: '2em',
  background: 'rgb(255, 255, 255)',
  boxShadow: 24,
  borderRadius: '.15em',
}));

export default function RowOperations({
  checkNameUniqueness,
  apiKeyData,
  keyName,
  keyExpirationDate,
  setSuccessSnackbar,
  setIsLoading,
  setKeyName,
  setKeyExpirationDate,
  setIsEditable,
  isEditable,
  isInputError,
  setInfoSnackbar,
}) {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const updateApiKey = async () => {
    try {
      await checkNameUniqueness();
      setIsSaving(true);

      await apiService.admin.updateApiKey(apiKeyData._id, {
        name: keyName,
        expirationDate: keyExpirationDate,
      });

      setIsSaving(false);
      setSuccessSnackbar('Saved!');
      setIsLoading(true);
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      setIsLoading(false);
    }
  };

  const deleteApiKey = async () => {
    try {
      await apiService.admin.deleteApiKey(apiKeyData._id);
      setIsLoading(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return isEditable ? (
    <Box display="flex">
      <Button
        onClick={() => {
          setKeyName(apiKeyData.name);
          setKeyExpirationDate(apiKeyData.expirationDate);
          setIsEditable(false);
        }}
        sx={{ mr: 1 }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        endIcon={<SendRoundedIcon />}
        onClick={() => {
          updateApiKey();
          setIsEditable(false);
        }}
        disabled={
          isInputError ||
          isSaving ||
          (keyName === apiKeyData.name && keyExpirationDate === apiKeyData.expirationDate)
        }
      >
        Save
      </Button>
    </Box>
  ) : (
    <>
      <IconButton aria-label="more" onClick={(e) => setAnchorMenu(e.currentTarget)}>
        <MoreHorizIcon fontSize="medium" />
      </IconButton>
      <Menu
        anchorEl={anchorMenu}
        id="account-menu"
        open={!!anchorMenu}
        onClose={() => setAnchorMenu(null)}
        onClick={() => setAnchorMenu(null)}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem onClick={() => setIsEditable(true)}>Edit</MenuItem>
        <MenuItem onClick={() => setIsModalOpened(true)}>Delete</MenuItem>
      </Menu>
      <Modal open={isModalOpened} onClose={() => setIsModalOpened(false)}>
        <StyledModalBody>
          <Typography fontSize="small">
            Are you sure you want to delete this access api key? This action cannot be undone.
          </Typography>
          <Box display="flex" justifyContent="flex-end" marginTop={2}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setIsModalOpened(false);
              }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              color="error"
              variant="contained"
              onClick={() => {
                deleteApiKey();
                setInfoSnackbar('Deleted!');
              }}
              sx={{ ml: 1 }}
            >
              Delete
            </Button>
          </Box>
        </StyledModalBody>
      </Modal>
    </>
  );
}

const apiKeyInfo = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  scopes: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  lastUsedAt: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
};

RowOperations.propTypes = {
  checkNameUniqueness: PropTypes.func.isRequired,
  apiKeyData: PropTypes.shape(apiKeyInfo).isRequired,
  keyName: PropTypes.string.isRequired,
  keyExpirationDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  setSuccessSnackbar: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setKeyName: PropTypes.func.isRequired,
  setKeyExpirationDate: PropTypes.func.isRequired,
  setIsEditable: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isInputError: PropTypes.bool.isRequired,
  setInfoSnackbar: PropTypes.func.isRequired,
};
