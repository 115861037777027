import React, { useEffect, useState } from 'react';
import { Grid, Input, Paper, styled } from '@mui/material';
import { useParams } from 'react-router-dom';
import useDebouncedHandler from '../../../hooks/useDebouncedHandler';
import ApiService from '../../../services/api.service';
import HistoryTable from './components/HistoryTable';

const StyledItem = styled(Paper)(() => ({
  display: 'flex',
  marginBottom: '1em',
  padding: '2em 3em',
  borderRadius: '.15em',
}));

export default function ReviewHistoryPage() {
  const [reviewHistoryData, setReviewHistoryData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [historySearchInput, setHistorySearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [historySort, setHistorySort] = useState('updatedAt');
  const [template, setTemplate] = useState('');
  const [skillStatistic, setSkillStatistic] = useState([]);

  const { department } = useParams();

  const fetchReviewHistory = async (skip, limit, search = '', sort = '') => {
    try {
      const response = await ApiService.head.getReviewHistory(department, {
        params: {
          search,
          skip: skip * limit,
          limit,
          sort,
        },
      });

      const statistic = await Promise.all(
        response.paginatedResult.map(({ userId, _id }) =>
          ApiService.head.getReviewStatistic(department, userId, _id),
        ),
      );

      setSkillStatistic(statistic);
      setCurrentPage(skip);
      setReviewHistoryData(response.paginatedResult);
      setTotalCount(response.totalCount ?? 0);
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  };

  const fetchTemplate = async () => {
    try {
      const response = await ApiService.admin.getReviewStatisticTemplate();

      setTemplate(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchTemplate();
      fetchReviewHistory(currentPage, rowsPerPage, historySearchInput, historySort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleInputChange = (event) => {
    setHistorySearchInput(event.target.value.trim());
    setCurrentPage(0);
    setIsLoading(true);
  };

  const { debouncedHandler } = useDebouncedHandler(handleInputChange);

  return (
    <Grid item mt={1} mr={4} ml={4}>
      <Grid sx={{ flex: '2 0 350px' }} item>
        <Grid container justifyContent="end" alignItems="center" height={48}>
          <Grid>
            <Input sx={{ width: 240 }} placeholder="Search" onChange={debouncedHandler} />
          </Grid>
        </Grid>
        <StyledItem elevation={8} container="true">
          <HistoryTable
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            reviewHistoryData={reviewHistoryData}
            totalCount={totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setSort={setHistorySort}
            fetchData={fetchReviewHistory}
            historySearchInput={historySearchInput}
            historySort={historySort}
            template={template}
            skillStatistic={skillStatistic}
          />
        </StyledItem>
      </Grid>
    </Grid>
  );
}
