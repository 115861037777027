import Avatar from '../../pages/Avatar';
import Employees from '../../pages/Employees';
import Events from '../../pages/Events';
import Home from '../../pages/Home';
import News from '../../pages/News';
import Progress from '../../pages/Progress';
import Reviewed from '../../pages/Reviewed';
import Reviews from '../../pages/Reviews';

const privateRoutes = [
  {
    path: '/home',
    title: 'Home',
    component: () => <Home title="Home" />,
  },
  {
    path: '/avatar',
    title: 'Avatar',
    component: () => <Avatar />,
  },
  {
    path: '/progress',
    title: 'Progress',
    component: () => <Progress title="Progress" />,
    exact: true,
  },
  {
    path: '/reviews',
    title: 'Reviews',
    component: () => <Reviews title="Reviews" />,
    exact: true,
  },
  {
    path: '/reviewed',
    title: 'Reviewed',
    component: () => <Reviewed title="Reviewed" />,
    exact: true,
  },
  {
    path: '/Reader',
    title: 'Reader',
    component: () => <News title="Reader" />,
    exact: true,
  },
  {
    path: '/events',
    title: 'Events',
    component: () => <Events title="Events" />,
    exact: true,
  },
  {
    path: '/employees',
    title: 'Employees',
    component: () => <Employees title="Employees" />,
    exact: true,
  },
];

export default privateRoutes;
