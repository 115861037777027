import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChangeCircleSharpIcon from '@mui/icons-material/ChangeCircleSharp';
import { Box, Button, IconButton, Link, Tooltip } from '@mui/material';
import { isURL } from 'validator';
import ReviewRecordModal from '../../../../components/ReviewRecordModal/ReviewRecordModal.component';

export default function ReviewRecordLink({ reviewRecordLink, updateReviewLink }) {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [link, setLink] = useState(reviewRecordLink || '');
  const [isLinkError, setIsLinkError] = useState(false);

  const handleLinkInput = ({ target }) => {
    const value = target.value.trim();
    const isValidUrl = isURL(value, {
      protocols: ['http', 'https'],
      require_protocol: true,
    });

    setLink(value);
    setIsLinkError(value.length === 0 ? false : !isValidUrl);
  };

  const handleOnClearModal = () => {
    setLink('');
    setIsLinkError(false);
  };

  const handleOnSaveModal = async () => {
    setIsModalOpened(false);

    await updateReviewLink(link || null);

    setLink('');
  };

  const handleOnCloseModal = () => {
    setIsModalOpened(false);
    setLink('');
  };

  const onOpenModal = () => {
    setLink(reviewRecordLink || '');
    setIsModalOpened(true);
  };

  const isSaveDisabled = isLinkError || link.length === 0;

  return (
    <>
      {reviewRecordLink ? (
        <Box p={0.8} display="flex" alignItems="center">
          <Link href={reviewRecordLink} target="_blank" rel="noopener" mr={0.5}>
            Record
          </Link>
          <Tooltip title="Update record">
            <IconButton onClick={onOpenModal} size="small">
              <ChangeCircleSharpIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Box>
          <Button sx={{ padding: 0 }} onClick={onOpenModal}>
            set record
          </Button>
        </Box>
      )}
      <ReviewRecordModal
        isOpen={isModalOpened}
        linkValue={link}
        handleLinkInput={handleLinkInput}
        onSave={handleOnSaveModal}
        onClear={handleOnClearModal}
        onClose={handleOnCloseModal}
        isInputError={isLinkError}
        isSaveDisabled={isSaveDisabled}
      />
    </>
  );
}

ReviewRecordLink.propTypes = {
  reviewRecordLink: PropTypes.string,
  updateReviewLink: PropTypes.func.isRequired,
};

ReviewRecordLink.defaultProps = {
  reviewRecordLink: null,
};
