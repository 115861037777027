import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TreeView } from '@mui/lab';
import { styled } from '@mui/material';
import SkillNode from './SkillNode';

const StyledTreeView = styled(TreeView)(() => ({
  maxHeight: '76vh',
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: '20px',
}));

export default function SkillsTreeView({ skillsData, updateTreeData, isDisabled, reviewData }) {
  const [expanded, setExpanded] = useState(skillsData.map((item) => item.itemId));

  const filteredSkills = useMemo(
    () => skillsData.filter((i) => !skillsData.some((item) => item.children.includes(i.itemId))),
    [skillsData],
  );

  const onTreeItemClick = (itemId) => {
    setExpanded(
      expanded.includes(itemId) ? expanded.filter((id) => id !== itemId) : [...expanded, itemId],
    );
  };
  return (
    <StyledTreeView
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      expanded={expanded}
    >
      {filteredSkills.map((node) => {
        return (
          <SkillNode
            key={node.itemId}
            node={node}
            skillsData={skillsData}
            onClick={onTreeItemClick}
            updateTreeData={updateTreeData}
            isDisabled={isDisabled}
            reviewData={reviewData}
          />
        );
      })}
    </StyledTreeView>
  );
}

SkillsTreeView.propTypes = {
  skillsData: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(PropTypes.string).isRequired,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          itemId: PropTypes.string,
          name: PropTypes.string,
          successCriteria: PropTypes.string,
          materialName: PropTypes.string,
          materialType: PropTypes.string,
          link: PropTypes.string,
          level: PropTypes.string,
        }),
      ).isRequired,
      name: PropTypes.string.isRequired,
      skillType: PropTypes.string.isRequired,
      status: PropTypes.string,
    }),
  ).isRequired,
  updateTreeData: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  reviewData: PropTypes.shape({}),
};

SkillsTreeView.defaultProps = { isDisabled: false, reviewData: {} };
