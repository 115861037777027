import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Loading from '../../components/Loading';
import NoInfoFoundAlert from '../../components/NoInfoFoundAlert';
import apiService from '../../services/api.service';
import BasicPage from '../../templates/BasicPage';
import UserSkillsView from '../Home/components/UserSkillsView';

export default function Reviewed({ title }) {
  const [isLoading, setIsLoading] = useState(true);
  const [reviewedSkills, setReviewedSkills] = useState([]);
  const [searchedSkills, setSearchedSkills] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiService.developer.getReviewedSkills(checked);
        setReviewedSkills(data || []);
        setSearchedSkills(data || []);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    };

    fetchData();
  }, [checked]);

  const handleSearch = (value) => {
    const filteredSkills = (reviewedSkills || []).reduce((acc, cur) => {
      const filteredTaskName = cur.name.toLowerCase().includes(value.toLowerCase());

      const filteredDetails = cur.details.filter((el) => {
        const words = el.name.split(' ');

        const matchFound = words.some((word) => {
          return value.toLowerCase() === word.slice(0, value.length).toLowerCase();
        });

        return matchFound;
      });

      if (filteredTaskName || filteredDetails.length > 0) {
        const filteredTask = {
          ...cur,
          details: filteredTaskName ? cur.details : filteredDetails,
        };
        acc.push(filteredTask);
      }

      return acc;
    }, []);

    setSearchedSkills(filteredSkills);
    setExpanded(filteredSkills.length ? filteredSkills[0].itemId : null);
  };

  const pageContent =
    checked || reviewedSkills.length ? (
      <Paper
        elevation={8}
        sx={{
          minWidth: {
            xs: '330px',
            md: '960px',
          },
          padding: 2,
          xs: { width: '100%', maxWidth: '950px', flexDirection: 'column' },
          md: { width: '50vw' },
        }}
      >
        <Grid
          display="flex"
          spacing={2}
          sx={{
            mt: 1,
            mb: { xs: 3 },
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />}
            label="Skills with additional info"
          />
          <Grid sx={{ mt: { xs: 2 } }}>
            <TextField
              id="input-with-icon-textfield"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              onChange={(e) => handleSearch(e.target.value.trim())}
            />
          </Grid>
        </Grid>
        {reviewedSkills.length ? (
          <UserSkillsView
            skillsData={searchedSkills}
            isDisabled={false}
            expanded={expanded}
            isInteractive={false}
            isReviewedPage
          />
        ) : (
          <Grid item xs={10} lg={6}>
            <NoInfoFoundAlert message="No skills with additional info" />
          </Grid>
        )}
      </Paper>
    ) : (
      <Grid item xs={10} lg={6}>
        <NoInfoFoundAlert message="No acquired knowledge found" />
      </Grid>
    );

  return (
    <BasicPage title={title}>
      {isLoading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            margin: '32px 24px',
          }}
        >
          <Box spacing={3} justifyContent="center">
            <Grid item xs={12}>
              {pageContent}
            </Grid>
          </Box>
        </Box>
      )}
    </BasicPage>
  );
}

Reviewed.propTypes = {
  title: PropTypes.string.isRequired,
};
