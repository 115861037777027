import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { ListItem, ListItemAvatar, Avatar, IconButton, ListItemText, Grid } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import LevelTitleInput from './LevelTitleInput';

function DraggableListItem({ isLoading, item, index, onRemove, onInput, setError }) {
  const [edit, setEdit] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const handleOver = () => {
    setEdit(true);
  };

  const handleLeave = () => {
    setEdit(false);
  };

  useEffect(() => {
    if (!item.title) {
      setError(true);
    }
  });

  return (
    <Draggable isDragDisabled={isLoading} draggableId={item._id} index={index}>
      {(provided) => (
        <ListItem
          onMouseOver={handleOver}
          onMouseLeave={handleLeave}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Grid pr={1}>
            <DragIndicatorIcon sx={{ verticalAlign: 'middle' }} color="disabled" fontSize="small" />
          </Grid>

          <ListItemAvatar>
            <Avatar>{item.key}</Avatar>
          </ListItemAvatar>

          <ListItemText>
            <LevelTitleInput
              width="100%"
              value={item.title}
              isDefaultFocus={item.isNew}
              isLoading={isLoading}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              onInput={(event) => onInput(event.target.value, index)}
            />
          </ListItemText>

          <Grid width="15%">
            <Grid container justifyContent="end">
              <Grid sx={{ textAlign: 'end' }}>
                {edit && (
                  <>
                    <IconButton
                      disabled={isLoading}
                      color="default"
                      aria-label="edit"
                      onClick={() => {
                        setIsEditable(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    {item.isNew && (
                      <IconButton disabled={isLoading} onClick={() => onRemove(index)}>
                        <RemoveCircleRoundedIcon />
                      </IconButton>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      )}
    </Draggable>
  );
}

DraggableListItem.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isNew: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onInput: PropTypes.func,
  onRemove: PropTypes.func,
  setError: PropTypes.func,
};

DraggableListItem.defaultProps = {
  onInput: () => null,
  onRemove: () => null,
  setError: () => null,
};

export default DraggableListItem;
