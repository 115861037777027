const EnglishLevelsEnum = {
  0: { display: 'Empty', value: '?' },
  1: { display: 'Elementary', value: 'A1' },
  2: { display: 'Pre-intermediate', value: 'A2' },
  3: { display: 'Pre-intermediate+', value: 'A2+' },
  4: { display: 'Intermediate', value: 'B1' },
  5: { display: 'Intermediate+', value: 'B1+' },
  6: { display: 'Upper-Intermediate', value: 'B2' },
  7: { display: 'Advanced', value: 'C1' },
  8: { display: 'Native', value: 'C2' },
};

export default EnglishLevelsEnum;
