import { Box, styled, TextField } from '@mui/material';

export const StyledTextField = styled(TextField)(() => ({
  margin: '16px 8px',
  width: '100%',
  maxHeight: '400px',
  '& .MuiInputBase-root': {
    padding: '16.5px 14px',
  },
  '& .MuiInputBase-input': {
    display: 'block',
    maxHeight: '200px',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

export const StyledModalBody = styled(Box)(() => ({
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  maxWidth: 650,
  minHeight: 300,
  maxHeight: 600,
  padding: '30px 32px',
  background: 'rgb(255, 255, 255)',
  boxShadow: 24,
  p: 4,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));
