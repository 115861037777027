import React from 'react';
import PropTypes from 'prop-types';
import { AddCircleRounded, RemoveCircleRounded } from '@mui/icons-material';

import {
  Box,
  IconButton,
  Tooltip,
  TextField,
  MenuItem,
  Select,
  Typography,
  styled,
} from '@mui/material';
import ContentTypes from '../../../../constants/content-types';
import MaterialTypes from '../../../../constants/materialTypes';

const StyledDetailContainer = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 39px;
  position: relative;
`;

const StyledTextFieldContainer = styled(Box)`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 10px;
`;

const StyledTextField = styled(TextField)`
  & input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default function PersonalTaskDetail({
  task,
  detail,
  handleDetailChange,
  handleRemoveDetail,
  handleAddDetail,
  detailsAmountMap,
}) {
  const isLastDetail = task.details[task.details.length - 1].itemId === detail.itemId;

  return (
    <StyledDetailContainer key={detail.itemId}>
      <StyledTextFieldContainer>
        <Box
          sx={{
            width: { xs: '80%', md: '95%' },
            maxWidth: '700px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <StyledTextField
            placeholder="Name"
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mb: 1 }}
            value={detail.name || ''}
            onChange={(e) => handleDetailChange(task.itemId, detail.itemId, 'name', e.target.value)}
            disabled={task.status === 'Approved'}
          />
          <StyledTextField
            placeholder="Success Criteria"
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mb: 1 }}
            value={detail.successCriteria || ''}
            onChange={(e) =>
              handleDetailChange(task.itemId, detail.itemId, 'successCriteria', e.target.value)
            }
            disabled={task.status === 'Approved'}
          />
          <StyledTextField
            placeholder="Material Name"
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mb: 1 }}
            value={detail.materialName || ''}
            onChange={(e) =>
              handleDetailChange(task.itemId, detail.itemId, 'materialName', e.target.value)
            }
            disabled={task.status === 'Approved'}
          />
          <Select
            displayEmpty
            size="small"
            fullWidth
            sx={{ mb: 1 }}
            renderValue={(selected) => (
              <Typography
                noWrap
                variant="inherit"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  opacity: selected ? 1 : 0.4,
                }}
              >
                {selected || 'Material Type'}
              </Typography>
            )}
            value={detail.materialType || ''}
            onChange={(e) =>
              handleDetailChange(task.itemId, detail.itemId, 'materialType', e.target.value)
            }
            disabled={task.status === 'Approved'}
          >
            {Object.keys(MaterialTypes).map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <StyledTextField
            placeholder="Link"
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mb: 1 }}
            value={detail.link || ''}
            onChange={(e) => handleDetailChange(task.itemId, detail.itemId, 'link', e.target.value)}
            disabled={task.status === 'Approved'}
          />
          <Select
            displayEmpty
            size="small"
            fullWidth
            sx={{ mb: 1 }}
            renderValue={(selected) => (
              <Typography
                noWrap
                variant="inherit"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  opacity: selected ? 1 : 0.4,
                }}
              >
                {selected || 'Content Type'}
              </Typography>
            )}
            value={detail.contentType || ''}
            onChange={(e) =>
              handleDetailChange(task.itemId, detail.itemId, 'contentType', e.target.value)
            }
            disabled={task.status === 'Approved'}
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            {Object.keys(ContentTypes).map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </StyledTextFieldContainer>
      <Box>
        <Box position="absolute" top={0}>
          <Tooltip sx={{ height: '100%' }} title="Remove detail">
            <span>
              {detailsAmountMap[task.itemId] > 1 && task.status !== 'Approved' && (
                <IconButton
                  onClick={() => {
                    handleRemoveDetail(task.itemId, detail.itemId);
                  }}
                >
                  <RemoveCircleRounded />
                </IconButton>
              )}
            </span>
          </Tooltip>
        </Box>
        {isLastDetail && (
          <Box position="absolute" bottom={0}>
            <Tooltip sx={{ height: '100%' }} title="Add detail">
              {task.status !== 'Approved' && (
                <IconButton
                  sx={{ mb: 1 }}
                  onClick={() => {
                    handleAddDetail(task.itemId);
                  }}
                >
                  <AddCircleRounded />
                </IconButton>
              )}
            </Tooltip>
          </Box>
        )}
      </Box>
    </StyledDetailContainer>
  );
}

PersonalTaskDetail.propTypes = {
  task: PropTypes.shape({
    itemId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        itemId: PropTypes.string.isRequired,
        successCriteria: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
        materialType: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        level: PropTypes.string.isRequired,
      }),
    ).isRequired,
    departmentId: PropTypes.string,
    skillType: PropTypes.string,
    status: PropTypes.string,
    userId: PropTypes.string,
    isOpen: PropTypes.bool,
  }).isRequired,
  detail: PropTypes.shape({
    itemId: PropTypes.string,
    name: PropTypes.string,
    successCriteria: PropTypes.string,
    materialName: PropTypes.string,
    materialType: PropTypes.string,
    link: PropTypes.string,
    contentType: PropTypes.string,
  }).isRequired,
  handleDetailChange: PropTypes.func.isRequired,
  handleRemoveDetail: PropTypes.func.isRequired,
  handleAddDetail: PropTypes.func.isRequired,
  detailsAmountMap: PropTypes.objectOf(PropTypes.number.isRequired).isRequired,
};
