import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, IconButton, NativeSelect } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
  monokai,
  atomOneDarkReasonable,
  dracula,
  vs2015,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';

const styles = {
  monokai,
  atomOneDarkReasonable,
  dracula,
  vs2015,
};

export default function TextHighlighterPopUp({ fileContent, openPopup, setOpenPopup }) {
  const [selectedStyle, setSelectedStyle] = useState(styles.monokai);

  const handleClosePopup = () => {
    setSelectedStyle(styles.monokai);
    setOpenPopup(false);
  };

  const handleChangeStyle = (event) => {
    const selectedStyleName = event.target.value;
    setSelectedStyle(styles[selectedStyleName]);
  };

  return (
    <Dialog open={openPopup} onClose={handleClosePopup} fullWidth maxWidth="md">
      <DialogTitle>
        <Box
          sx={{ minWidth: 20 }}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: '5px' }}
        >
          <FormControl>
            <InputLabel variant="standard">Highlight theme</InputLabel>
            <NativeSelect onChange={handleChangeStyle}>
              {Object.keys(styles).map((styleName) => (
                <option key={styleName} value={styleName}>
                  {styleName}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <IconButton onClick={handleClosePopup}>
            <Close style={{ color: 'black' }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <SyntaxHighlighter language="javascript" style={selectedStyle}>
          {fileContent}
        </SyntaxHighlighter>
      </DialogContent>
    </Dialog>
  );
}

TextHighlighterPopUp.propTypes = {
  fileContent: PropTypes.string.isRequired,
  openPopup: PropTypes.bool.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
};
