import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { Grid, IconButton, Menu, MenuItem, styled, Tooltip, Box, Button } from '@mui/material';

const StyledButtonContainer = styled(Box)(() => ({ display: 'flex' }));

export default function RowActions({
  onArchive,
  onUnArchive,
  isArchived,
  editable,
  onSaveClick,
  onCancelClick,
  onEditClick,
  disableEdit,
  onLoginAsClick,
  onResetProgress,
  handleChange,
  handlePersoalTasks,
}) {
  const [anchorMenu, setAnchorMenu] = useState(null);

  const handleMoreClick = (event) => {
    event.stopPropagation();
    setAnchorMenu(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorMenu(null);
  };

  const handleSave = () => {
    setAnchorMenu(null);
    onSaveClick();
  };

  const handleCancel = () => {
    setAnchorMenu(null);
    onCancelClick();
  };

  return (
    <Grid>
      {!editable ? (
        <>
          <Tooltip title="More">
            <IconButton disableRipple aria-label="more" id="long-button" onClick={handleMoreClick}>
              <MoreHorizIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorMenu}
            id="account-menu"
            open={!!anchorMenu}
            onClose={handleMoreClose}
            onClick={handleMoreClose}
          >
            {!isArchived && <MenuItem onClick={onArchive}>Archive</MenuItem>}
            {isArchived && <MenuItem onClick={onUnArchive}>Unarchive</MenuItem>}
            <MenuItem onClick={onEditClick} disabled={disableEdit || isArchived}>
              Edit
            </MenuItem>
            <MenuItem onClick={handleChange} disabled={disableEdit || isArchived}>
              Edit Skills
            </MenuItem>
            <MenuItem onClick={onLoginAsClick} disabled={disableEdit || isArchived}>
              Log in as
            </MenuItem>
            <MenuItem onClick={handlePersoalTasks} disabled={disableEdit || isArchived}>
              Personal tasks
            </MenuItem>
            <MenuItem onClick={onResetProgress} disabled={disableEdit || isArchived}>
              Reset progress
            </MenuItem>
          </Menu>
        </>
      ) : (
        <StyledButtonContainer>
          <Button onClick={handleCancel} sx={{ marginRight: '8px' }}>
            Cancel
          </Button>
          <Button variant="contained" endIcon={<SendRoundedIcon />} onClick={handleSave}>
            Save
          </Button>
        </StyledButtonContainer>
      )}
    </Grid>
  );
}

RowActions.propTypes = {
  onArchive: PropTypes.func.isRequired,
  onUnArchive: PropTypes.func.isRequired,
  isArchived: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  disableEdit: PropTypes.bool.isRequired,
  onLoginAsClick: PropTypes.func.isRequired,
  onResetProgress: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handlePersoalTasks: PropTypes.func.isRequired,
};
