import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  badge: {
    fontSize: 9,
    height: 15,
    minWidth: 15,
  },
  badgeRoot: () => ({
    position: 'absolute',
    top: 14,
    right: 20,
    color: '#7553ea',
    zIndex: 10000,
  }),
}));

const HexBadge = forwardRef(function HexBadge({ version, hover, invisible }, ref) {
  const classes = useStyles({ hover });

  return (
    <Badge
      ref={ref}
      invisible={invisible}
      badgeContent={`v.${version}`}
      color="primary"
      classes={{
        badge: classes.badge,
        root: classes.badgeRoot,
      }}
    />
  );
});

HexBadge.propTypes = {
  version: PropTypes.number.isRequired,
  hover: PropTypes.bool.isRequired,
  invisible: PropTypes.bool,
};

HexBadge.defaultProps = {
  invisible: false,
};

export default HexBadge;
