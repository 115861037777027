import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BigHead } from '@bigheads/core';
import { ExpandMore } from '@mui/icons-material';
import { Chip, Grid, TableCell, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Honeycomb } from 'react-honeycomb';
import { DepartmentIcon } from '../../../lib/core.cjs.development';
import HexGrid from '../../Hexagon/components/HexGrid';
import HexItem from '../../Hexagon/components/HexItem';

export default function SkillsTableRow({ _id, avatar, department, email, level, skills }) {
  const ref = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [skillCount, setSkillCount] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setSkillCount(Math.floor(ref.current.offsetWidth / 30));
    }
  }, [ref]);

  return (
    <TableRow key={_id}>
      <TableCell sx={{ padding: '0px 16px' }} />
      <TableCell padding="none">
        <Grid container alignItems="center" sx={{ marginBottom: '16px' }}>
          <Grid item width={50} height={50}>
            <BigHead {...avatar} />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="center" sx={{ padding: '0px 16px' }}>
        <DepartmentIcon name={department} style={{ width: 42, height: 42 }} />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          padding: '0px 16px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '350px',
        }}
      >
        {email}
      </TableCell>
      <TableCell align="center" sx={{ padding: '0px 16px' }}>
        <Chip label={level} color="success" size="small" variant="outlined" />
      </TableCell>
      <TableCell align="left" ref={ref} style={{ padding: '0px 16px 0px 0px' }}>
        <Box sx={{ display: !isExpanded ? 'initial' : 'none' }}>
          <Honeycomb
            columns={skillCount}
            size={20}
            items={skills.slice(0, skillCount)}
            renderItem={(item) => (
              <HexItem
                isUserPage
                setAnchorEl={() => {}}
                item={item}
                setItemForAction={() => {}}
                isInvisibleBadge
              />
            )}
          />
        </Box>
        <Box sx={{ display: isExpanded ? 'initial' : 'none' }}>
          <HexGrid
            isUserPage
            skills={skills}
            setAnchorEl={() => {}}
            setItemForAction={() => {}}
            size={20}
            isInvisibleBadge
          />
        </Box>
      </TableCell>
      <TableCell
        style={{ visibility: skills.length <= skillCount ? 'hidden' : 'visible', padding: '0px' }}
      >
        <IconButton
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            isExpanded ? setIsExpanded(false) : setIsExpanded(true);
          }}
        >
          <ExpandMore />
        </IconButton>
      </TableCell>
      <TableCell sx={{ padding: '0px 16px' }} />
    </TableRow>
  );
}

SkillsTableRow.propTypes = {
  _id: PropTypes.string.isRequired,
  avatar: PropTypes.shape({}).isRequired,
  department: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  skills: PropTypes.arrayOf(PropTypes.object).isRequired,
};
