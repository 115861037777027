import PropTypes, { string } from 'prop-types';
import {
  Checkbox,
  createTheme,
  FormControl,
  ListItemText,
  ThemeProvider,
  ListItem,
  List,
} from '@mui/material';

const ITEM_HEIGHT = 32;

const theme = createTheme({
  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
          cursor: 'pointer',
          ':hover': {
            backgroundColor: 'rgb(224, 224, 224)',
          },
        },
      },
    },
  },
});

export default function FilterByDepartments({
  departmentsData,
  departmentSearch,
  handleChangeFilter,
}) {
  const isSmallScreen = window.innerWidth <= 600;

  return (
    <div style={{ marginRight: { md: '8px', xs: '0px' } }}>
      <ThemeProvider theme={theme}>
        <FormControl
          sx={{
            m: 0,
            mt: { xs: 1, md: 0 },
            alignContent: { xs: 'center' },
            width: { xs: '100%', md: 160 },
            borderRadius: '.15em',
            boxShadow:
              '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
          }}
        >
          <List
            sx={{
              overflow: 'auto',
              maxHeight: ITEM_HEIGHT * 8,
              display: 'flex',
              flexDirection: isSmallScreen ? 'row' : 'column',
              gap: '4px',
            }}
          >
            {departmentsData.map((department) => (
              <ListItem
                key={department._id}
                value={department.title}
                onClick={() => handleChangeFilter(department._id)}
                sx={{
                  height: `${ITEM_HEIGHT}px`,
                  flex: { sm: '1 1 0' },
                }}
              >
                <Checkbox checked={departmentSearch.indexOf(department._id) > -1} />
                <ListItemText primary={department.title} />
              </ListItem>
            ))}
          </List>
        </FormControl>
      </ThemeProvider>
    </div>
  );
}

FilterByDepartments.propTypes = {
  departmentsData: PropTypes.arrayOf(
    PropTypes.shape({
      levels: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          position: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          _id: PropTypes.string.isRequired,
        }),
      ).isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  departmentSearch: PropTypes.arrayOf(string).isRequired,
  handleChangeFilter: PropTypes.func.isRequired,
};
