import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import LinkViewsTableRow from './LinkViewsTableRow';
import useTableActions from './hooks/useTableActions';

const tableCells = [
  {
    id: 'link',
    name: 'Link',
    width: '80%',
    textAlign: 'left',
    minWidth: 80,
  },
  {
    id: 'views',
    name: 'Views',
    width: '20%',
    textAlign: 'left',
    minWidth: 20,
  },
];

const rowsPerPageOptions = [10, 25, 50];

export default function LinkViewsTable({
  linkViewsData,
  isLoading,
  totalCount,
  currentPage,
  onPaginationChange,
  rowsPerPage,
  order,
  orderBy,
  onChangeOrder,
}) {
  const { handleChangePage, handleChangeRowsPerPage, getTableCells, renderSkeletonRows } =
    useTableActions(onPaginationChange, order, orderBy, onChangeOrder, tableCells);

  return (
    <TableContainer>
      <Table size="small" aria-label="most visited links table" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>{getTableCells()}</TableRow>
        </TableHead>

        <TableBody>
          {isLoading
            ? renderSkeletonRows(rowsPerPage)
            : linkViewsData.map(({ link, views }) => (
                <LinkViewsTableRow key={link} keyId={link} link={link} views={views} />
              ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              colSpan={12}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

LinkViewsTable.propTypes = {
  linkViewsData: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      views: PropTypes.number.isRequired,
    }),
  ).isRequired,

  isLoading: PropTypes.bool.isRequired,

  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPaginationChange: PropTypes.func.isRequired,

  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onChangeOrder: PropTypes.func.isRequired,
};
