import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import PersonalTask from './PersonalTask';

export default function PersonalTaskList({
  tasks,
  handleTaskNameChange,
  handleAddTask,
  handleRemoveTask,
  handleRemoveDetail,
  handleAddDetail,
  handleDetailChange,
  handleToggleTask,
  detailsAmountMap,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        {tasks
          .filter((task) => !task.deleteMark)
          .map((task) => (
            <PersonalTask
              key={task.itemId}
              task={task}
              handleAddTask={handleAddTask}
              handleTaskNameChange={handleTaskNameChange}
              handleRemoveTask={handleRemoveTask}
              handleRemoveDetail={handleRemoveDetail}
              handleAddDetail={handleAddDetail}
              handleDetailChange={handleDetailChange}
              handleToggleTask={handleToggleTask}
              detailsAmountMap={detailsAmountMap}
            />
          ))}
      </Grid>
    </Grid>
  );
}

PersonalTaskList.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          itemId: PropTypes.string.isRequired,
          successCriteria: PropTypes.string.isRequired,
          materialName: PropTypes.string.isRequired,
          materialType: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
          level: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  ).isRequired,
  handleTaskNameChange: PropTypes.func.isRequired,
  handleAddTask: PropTypes.func.isRequired,
  handleRemoveTask: PropTypes.func.isRequired,
  handleRemoveDetail: PropTypes.func.isRequired,
  handleAddDetail: PropTypes.func.isRequired,
  handleDetailChange: PropTypes.func.isRequired,
  handleToggleTask: PropTypes.func.isRequired,
  detailsAmountMap: PropTypes.objectOf(PropTypes.number.isRequired).isRequired,
};
