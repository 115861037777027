import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

export default function DashboardRowSector({ children, ...rest }) {
  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} {...rest}>
      {children}
    </Grid>
  );
}

DashboardRowSector.propTypes = {
  children: PropTypes.node.isRequired,
};
