import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import useCardsData from '../hooks/useCardsData';
import StyledCard from '../styles/StyledCard';

export default function DashboardCards() {
  const { cardsData } = useCardsData();

  return (
    <Grid container spacing={2}>
      {!cardsData.length &&
        Array.from({ length: 5 }, (_, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <Skeleton sx={{ minHeight: '96px', transform: 'none' }} />
          </Grid>
        ))}

      {cardsData.map(({ title, count, icon }) => (
        <Grid key={title} item xs={12} sm={6} md={4} lg={3} xl={2.4}>
          <StyledCard title={title} count={count} icon={icon} />
        </Grid>
      ))}
    </Grid>
  );
}
