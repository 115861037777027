import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Checkbox, FormControlLabel, FormGroup, Paper, styled } from '@mui/material';

const StyledItem = styled(Paper)(() => ({
  marginBottom: '1em',
  padding: '20px',
  borderRadius: '.15em',
}));

export default function ArchivedUserFilter({ archived, setArchived, isDisabled, setIsDisabled }) {
  return (
    <StyledItem elevation={8}>
      <Grid>
        <FormGroup>
          <FormControlLabel
            disabled={isDisabled}
            control={
              <Checkbox
                checked={archived}
                onChange={() => {
                  setIsDisabled(true);
                  setArchived(!archived);
                }}
              />
            }
            label="Archived"
          />
        </FormGroup>
      </Grid>
    </StyledItem>
  );
}

ArchivedUserFilter.propTypes = {
  archived: PropTypes.bool.isRequired,
  setArchived: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  setIsDisabled: PropTypes.func.isRequired,
};

ArchivedUserFilter.defaultProps = { isDisabled: false };
