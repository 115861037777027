import React from 'react';
import PropTypes from 'prop-types';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import {
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
  TimelineItem,
} from '@mui/lab';
import { Typography } from '@mui/material';

export default function FutureLevel({ title, levelKey }) {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary" variant="outlined">
          <RocketLaunchRoundedIcon htmlColor="#7553EA" />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent
        sx={{
          py: '12px',
          px: 2,
        }}
      >
        <Typography variant="h6" component="span">
          {levelKey}
        </Typography>
        <Typography
          sx={{
            whiteSpace: {
              xs: 'normal',
              md: 'nowrap',
            },
          }}
        >
          {title}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

FutureLevel.propTypes = {
  title: PropTypes.string.isRequired,
  levelKey: PropTypes.string.isRequired,
};
