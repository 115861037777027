import { createTheme } from '@mui/material';

const CodeDetailsTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '0',
          margin: '5px 0',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          width: '100%',
          margin: '5px 0',
        },
        select: { padding: '7.5px' },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { margin: '5px 0' },
        input: { padding: '7.5px' },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: { width: '100%' },
      },
    },
  },
  palette: {
    primary: { main: '#7553EA' },
  },
});

export default CodeDetailsTheme;
