const dateOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
};
const dateFormatter = new Intl.DateTimeFormat('en-GB', dateOptions);

export default dateFormatter;
