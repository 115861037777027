import { forwardRef, useCallback } from 'react';

import { TreeItem } from '@mui/lab';

// eslint-disable-next-line react/prop-types
function TreeItemCustom({ children, ...otherProps }, ref) {
  const localRef = useCallback(
    (elt) => {
      if (ref) {
        ref(elt);
      }

      elt?.addEventListener('focusin', (e) => {
        e.stopImmediatePropagation();
      });
    },
    [ref],
  );

  return (
    <TreeItem {...otherProps} ref={localRef}>
      {children}
    </TreeItem>
  );
}

export default forwardRef(TreeItemCustom);
