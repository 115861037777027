const materialTypes = {
  video: 'video',
  audio: 'audio',
  site: 'site',
  book: 'book',
  training: 'training',
  other: 'other',
};

export default materialTypes;
