import { DEPARTMENT_NAME_VALUES } from './department.constants';

const adminPages = [
  {
    path: '/departments',
    title: 'Departments',
  },
  {
    path: '/users',
    title: 'Users',
  },
  {
    path: '/applications',
    title: 'Applications',
  },
  {
    path: '/rss',
    title: 'Rss',
  },
  {
    path: '/reviews',
    title: 'Reviews',
  },
  {
    path: '/skills',
    title: 'Skills',
  },
  {
    path: '/dashboard',
    title: 'dashboard',
  },
];

const adminSubPages = DEPARTMENT_NAME_VALUES;
const defaultTab = 'departments';

const ERROR_MESSAGES = {
  NO_AVAILABLE_KEYS: 'No api keys found!',
  NO_AVAILABLE_LINKS: 'No links found!',
  NO_AVAILABLE_NEWS: 'No news found!',
};

export default {
  adminPages,
  adminSubPages,
  defaultTab,
  ERROR_MESSAGES,
};
