const NodeAndDetailStatus = Object.freeze({
  NONE: 'none',
  IS_ADDED: 'isAdded',
  IS_NEW: 'isNew',
  IS_REPLACED: 'isReplaced',
  IS_REPLACED_DETAIL: 'isReplacedDetail',
  IS_REPLACED_CHILD: 'isReplacedChild',
});

export default NodeAndDetailStatus;
