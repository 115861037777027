import React from 'react';
import PropTypes from 'prop-types';
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ReviewsTableRow from './ReviewsTableRow';

const AvatarHeaderCell = styled(TableCell)({
  minWidth: 40,
  width: 50,
  maxWidth: 50,
});

export default function ReviewsTable({ reviews, onReview }) {
  return (
    <TableContainer>
      <Table size="small" aria-label="users table">
        <TableHead>
          <TableRow>
            <AvatarHeaderCell align="left" />
            <TableCell sx={{ minWidth: 300 }} align="left" />
            <TableCell sx={{ minWidth: 256 }} align="left">
              Goal
            </TableCell>
            <TableCell sx={{ minWidth: 256 }} align="left">
              Sended
            </TableCell>
            <TableCell sx={{ minWidth: 80 }} align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {reviews.map((review) => (
            <ReviewsTableRow key={review._id} onReview={onReview} {...review} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ReviewsTable.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onReview: PropTypes.func.isRequired,
};
